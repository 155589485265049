<template>
  <section id="how" class="relative md:mb-30 lg:mb-30 mb-0">
        <div class="2xl:justify-between xl:justify-between flex flex-col md:flex-row lg:flex-row justify-items-stretch gap-4  2xl:gap-12 hidden lg:flex">

          <div class="row-span-2 bg-black-lighter w-full md:w-60 lg:w-60 h-56 md:h-72 xl:w-80 2xl:w-98 2xl:h-80">
            <button
                class="relative z-10 grid justify-center text-sm md:text-2xl lg:text-2xl font-light tracking-wider text-white uppercase duration-200 md:text-3xl md:absolute f-w-400 how-btn btn-bg tiempos">
              how it works
            </button>

            <div class="relative h-full details details-1">
              <img class="object-cover w-full h-56 md:h-72 xl:72 2xl:h-80" src="../assets/car-1.png" alt="">
              <div
                  class="absolute bottom-12 md:bottom-0 lg:bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">1</h1>
                <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">
                  Choose your desired car</h2>
                <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                  Find a suitable car from our curated list of super saving cars </p>
              </div>
            </div>
          </div>

          <div class="row-span-2 bg-black-lighter w-full md:w-60 lg:w-60 h-56 md:h-72 lg:80 xl:w-80 2xl:w-98 2xl:h-80">
            <div class="relative h-full details details-1">
              <img class="object-cover w-full h-56 md:h-72 lg:72 2xl:h-80" src="../assets/car-2.png" alt="">
              <div
                  class="absolute bottom-12 md:bottom-0 lg:bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">2</h1>
                <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">
                  Reserve your car</h2>
                <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                  We procure the car for you directly from wholesale with all the savings </p>
              </div>
            </div>
          </div>

          <div class="row-span-2 bg-black-lighter w-full md:w-60 lg:w-60 h-56 md:h-72 xl:w-80 2xl:w-98 2xl:h-80">
            <div class="relative h-full details ">
              <img class="object-cover w-full  h-56 md:h-72 2xl:h-80" src="../assets/car-4.png" alt="">
              <div
                  class="absolute bottom-12 md:bottom-0 lg:bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">3</h1>
                <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">
                  Get your car delivered</h2>
                <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                  We will deliver the car to your door step </p>
              </div>
            </div>
          </div>

          <div class="row-span-2 bg-black-lighter w-full md:w-60 lg:w-60 h-56 md:h-72 lg:80 xl:w-80 2xl:w-98 2xl:h-80">
            <div class="relative h-full details ">
              <img class="object-cover w-full  h-56 md:h-72 lg:72 2xl:h-80" src="../assets/car-3.png" alt="">
              <div
                  class="absolute bottom-12 md:bottom-0 lg:bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">4</h1>
                <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">
                  Drive it or Return it</h2>
                <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                  You have 7 days/250 miles to make your decision on the car. If you don’t like it return it with a full refund </p>
              </div>
            </div>
          </div>

          <div class="row-span-2 bg-black-full w-full md:w-60 lg:w-60 h-56 md:h-72 lg:h-80 xl:h-80 xl:w-80 2xl:w-98 2xl:h-96" style="margin-top: -1rem">
            <div class="relative h-full details ">
              <div
                  class="absolute  bottom-12 md:bottom-0 lg:bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-6 lg:bottom-6">
                <h4 class="mt-12 text-2xl leading-10 text-white uppercase font-extralight sm:text-2xl md:text-2xl lg:text-2xl md:pb-4 lg:pb-4 tiempos">
                  need some more information?</h4>
                <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                  Watch our video walkthrough to get a better idea on how FairPY works </p>
                <button @click="openGallery()" class="w-auto px-5 text-sm tracking-wider text-white uppercase shadow-md font-extralight md:my-2 lg:my-2 sm:w-full md:w-f play-btn calibre"> PLAY VIDEO</button>
              </div>
            </div>
          </div>

        </div>

        <FsLightbox  :toggler="toggler" :sources="[  'https://www.youtube.com/watch?v=na83SqRHALg',  ]"   />


    <div class="relative z-50 grid gap-3 p-5  res-top md:top-0 md:py-8 md:px-5 bottom-10 lg:right-10 play-video-bg block  lg:hidden xl:hidden 2xl:hidden mx-2">
      <h1 class="text-xl leading-10 text-white uppercase font-extralight sm:text-2xl md:text-3xl lg:text-3xl md:pb-4 lg:pb-4 tiempos need-line">need some more information?</h1>
      <p class="text-xs font-normal tracking-wider text-white res-font-size md:pb-5 lg:pb-4 f-w-100 calibre">
        Watch our video walkthrough to get a better idea on how FairPY works
      </p>
      <button @click="openGallery()"
              class="w-auto px-5 text-sm tracking-wider text-white uppercase shadow-md font-extralight md:my-5 lg:my-5 sm:w-full md:w-f play-btn calibre">
        PLAY VIDEO
      </button>
    </div>

        <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 hidden">
          <div>
            <button
                class="relative z-10 grid justify-center text-sm md:text-2xl lg:text-2xl font-light tracking-wider text-white uppercase duration-200 md:text-3xl md:absolute f-w-400 how-btn btn-bg tiempos">
              how it works
            </button>
            <div class="grid grid-flow-col grid-rows-2 gap-3">
              <div class="row-span-1 bg-black-lighter">
                <div class="relative h-full details details-1">
                  <img class="object-cover w-full h-full " src="../assets/car-1.png" alt="">
                  <div class="absolute bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-5 lg:bottom-5">
                    <h1 class="text-2xl font-light text-white uppercase md:text-2xl lg:text-5xl calibre">1</h1>
                    <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">Choose your desired car</h2>
                    <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre"> Find a suitable car from our curated list of super saving cars</p>
                  </div>
                </div>
              </div>
              <div class="row-span-1 bg-black-lighter">
                <div class="relative h-full details details-1">
                  <img class="object-cover w-full h-full" src="../assets/car-2.png" alt="">
                  <div class="absolute bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                    <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">2</h1>
                    <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">Get a fair estimate</h2>
                    <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre"> Use our AI to accurately predict the total cost breakdown and price recommendations </p>
                  </div>
                </div>
              </div>
              <div class="row-span-2 bg-black-lighter">
                <div class="relative h-full details ">
                  <img class="object-cover w-full h-full" src="../assets/car-4.png" alt="">
                  <div class="absolute bottom-0 grid w-full gap-1 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                    <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">3</h1>
                    <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg f-w-400 tiempos">Submit your bid</h2>
                    <p class="text-xs font-light tracking-wider text-white transition-all duration-500 f-w-100 tiempos md:text-sm lg:text-sm calibre"> Bid hassle-free using our user-friendly platform </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="relative h-full ">
              <img class="object-cover w-full h-full car-hei" src="../assets/car-3.png" alt="">
              <div class="absolute bottom-0 grid w-full gap-4 p-2 sm:p-3 md:p-4 lg:p-4 sm:gap-3 md:gap-3 lg:gap-3 sm:bottom-2 md:bottom-10 lg:bottom-10">
                <div
                    class="relative grid gap-1 details sm:gap-3 top-40 hover: sm:top-0 md:top-0 lg:top-0 md:gap-3 lg:gap-3">
                  <h1 class="text-2xl font-light text-white uppercase md:text-4xl lg:text-5xl calibre">4</h1>
                  <h2 class="text-sm font-light tracking-wider text-white uppercase sm:text-2xl md:text-lg lg:text-lg md:w-72 lg:w-72 f-w-400 tiempos">
                    Get your car delivered</h2>
                  <p class="relative text-xs font-light tracking-wider text-white transition-all duration-500 w-60 f-w-100 tiempos md:text-sm lg:text-sm calibre">
                    Once you win the bid, we will deliver the car to your Doorstep</p>
                </div>
                <div class="relative z-50 grid gap-3 p-5  res-top md:top-18 lg:18 md:py-8 md:px-5 md:absolute bottom-10 md:w-72 md:right-10 lg:right-10 play-video-bg">
                  <h1 class="text-xl leading-10 text-white uppercase font-extralight sm:text-2xl md:text-3xl lg:text-3xl md:pb-4 lg:pb-4 tiempos need-line">need some more information?</h1>
                  <p class="text-xs font-normal tracking-wider text-white res-font-size md:pb-5 lg:pb-4 f-w-100 calibre">
                    Watch our video walkthrough to get a better idea on how FairPY works
                  </p>
                  <button @click="openGallery()"
                          class="w-auto px-5 text-sm tracking-wider text-white uppercase shadow-md font-extralight md:my-5 lg:my-5 sm:w-full md:w-f play-btn calibre">
                    PLAY VIDEO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div>
      </div>

  </section>
</template>
<script>

import FsLightbox from "fslightbox-vue/v3";

  export default {
  name: 'How',
  components:{
    FsLightbox,
  },
  data(){
    return{
      isDesktop: screen.width > 768,
      toggler: false,
      isVisibility:false
    }
  },
    methods: {
      toggleModal() {
        this.isVisibility = !this.isVisibility;
      },
      openGallery() {
        this.toggler = !this.toggler;
      },
    },
  }
</script>
<style scoped>
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.815);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal{

  box-shadow: 1px 2px 5px rgba(153, 155, 168, 0.12);
  border-radius: 5px;
  background: #1b1d1f;
}


.how-btn{
  top: -30px;
  /*left: 0px;*/
}
  .details p{
    display: none;
  }
    .details:hover p{
      display: block;
    }

  .tiempos {
    font-family: 'Tiempos Headline', sans-serif;
  }

  .calibre {
    font-family: 'Calibre', sans-serif;
  }

  .text-blue {
    color: #0d213a;
  }

  .f-w-400 {
    font-weight: 400;
  }

  .f-w-300 {
    font-weight: 300;
  }

  .text-des {
    color: #191919;
  }

.btn-bg {
  background-color: #0f2744;
  height: 65px;
  min-width: 380px;
  text-align: center;
  line-height: 70px;
  clip-path: polygon(10% 0%, 100% 0%, 95% 100%, 5% 100%);
}

.btn-bg:hover {
  background-color: #0b1d33;
}

.play-video-bg {
  background: #1b1d1f;
  /*bottom: -260px;*/
}

.play-btn {
  background-color: #008de9;
  height: 50px;
  min-width: 190px;
  text-align: center;
  line-height: 55px;
  clip-path: polygon(0% 0, 90% 0, 100% 100%, 10% 100%);
  background-image: url(../assets/play-btn.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 20% 50%;
  box-shadow: 1px 1px 1px 1px #191919;
}

.play-btn:hover {
  background-color: #0163a3;
}

.video-player iframe {
  width: 600px;
  height: 400px;
}

.need-line {
    line-height: 45px;
  }
  @media (max-width: 640px) { 
  .video-player iframe{
    width: 350px;
    height: 220px;
  }
  .how-btn{
    position: relative;
  top: -10px;
}
  .btn-bg {
    height: 40px;
    min-width: 180px;
    line-height: 40px;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-bottom: 10px;
  }
  .how-btn{
  top: 25px;
  left: 0px;
}
  .need-line{
    line-height: normal;
  }
.car-hei{
  max-height: 230px;
}
  .need-line{
    line-height: 35px;
  }
  .res-font-size{
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #d8d8d8;
  }
  .details-1{
    min-height: 200px;
  }
  .res-top {
    top: 0.5rem;
  }
 }

</style>