<template>
    <div class="w-100 lg:py-24 py-4 relative overflow-hidden">
      <div class="absolute z-0 right-0 bottom-0 flex flex-row justify-end items-center">
          <img src="/images/track.png" class="p-16 pr-0 pb-0 object-contain"/>
        </div>
        <div class="container mx-auto lg:px-3 px-5 py-6 z-10 relative">
              <SpecialTitle title="CARS BY LIFESTYLE" caption="Choose Lifestyle"/>
              <div class="grid gap-4 lg:grid-cols-3 grid-cols-2 lg:grid-rows-2 grid-rows-3 lg:mb-11">
                  <LifeStyleTile image="1-Family.png" title="Family" caption="Take everyone along"/>
                  <LifeStyleTile @click="loadCars('comfort')" image="2-Comfort.png" title="Comfort" caption="Enjoy the home in vehicle"/>
                  <LifeStyleTile @click="loadCars('cost')" image="3-Value.png" title="Value" caption="Easy economic options"/>
                  <LifeStyleTile image="4-Fuel.png" title="FUEL EFFICIENCY" caption="Save your expenses"/>
                  <LifeStyleTile image="5-Luxury.png" title="Luxury" caption="Announce your arrival"/>
                  <LifeStyleTile image="6-Feature.png" title="FEATURE PACKED" caption="Everything you need"/>
              </div>
        </div>
    </div>
</template>
<script>
import SpecialTitle from "./SpecialTitle.vue";
import LifeStyleTile from "./LifeStyleTile.vue";

export default {
  name: "LifeStyleSection",
  components: {
    SpecialTitle,
    LifeStyleTile,
  },
  data: function () {
    return {};
  },
  methods: {
    loadCars(type) {
      this.$store.dispatch("searchDealsByLifestyle", type);
      if (this.$route.name != "Search") {
        this.$router.push("search");
      }
    },
  },
};
</script>
<style>

</style>