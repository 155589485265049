// import store from './store';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import mitt from 'mitt';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import vue3GoogleLogin from 'vue3-google-login';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router';
import store from './store';


// const options = {
//   pk: 'pk_test_51J2HpJSBoiyZEY3Xxi4V0A1Iy8btAClT2jni9lkZjx9C6yBR9x9ZCgv437IyzSjAwt1FTwjxpX74R9vBvw2Ddo7c00tarww5VD',
//   stripeAccount: '',
//   apiVersion: '',
//   locale: '',
// };

const emitter = mitt();

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBnmAmMecl6tjhaFjigz6-eQFuUBJrMlFw',
  },
});

app.use(vue3GoogleLogin, {
  clientId:
    '477666906199-me6d25734ef96tb3o1f4emod39ns846b.apps.googleusercontent.com',
});

store.dispatch(
  'card/setStripe',
  window.Stripe(
    // 'pk_test_51KQizwIAEzjjm81Sdihy56KPnqmgRmaYYzWNTWvNU9Ev0oNNQy1bge4vTe5GeX0jHJ4kSL9BSuwB5gVaLxdRkZxF00rirnRXAO'
    'pk_live_51KQizwIAEzjjm81SNHZSsldZ1B9krffZdoWk0lugv15DbM35o1JCgjRba2Juj6MYnxslpLtlqnw5fZQSSIXrjM2200hjpMOBAB'
  )
);

const toastOptions = {};

app.use(Toast, toastOptions);
// app.use(StripePlugin, options);

app.config.globalProperties.emitter = emitter;
app.mount('#app');
