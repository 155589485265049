<template>
  <TopBar/>
  <div class="container m-auto mt-4 text-justify lg:mt-12 p-4 watermark">
    <h1 class="text-3xl lg:text-5xl font-bold"><span>FairPy</span> <span style="color: #00D6EC">Investments</span></h1>

    <hr class="w-4/12 lg:w-2/12 h-1 my-6" style="background-color: #00D6EC"/>
    <p class="mt-4">We leverage our investment experience and advanced data science to acquire the best ROI rental cars
      and make it simple for any investor to easily buy shares & start earning income quickly.</p>

    <p class="mt-4 mb-4">Our goal at FairPY is to make investing in car rentals simple and accessible to anyone. We have
      created a radically improved investor experience that reduces the difficulties and simplifies the complex
      decision-making traditionally associated with investing. Additionally, we have leveraged our experience in the
      technology and the car auctions industries so that our users are able to access advanced investing strategies and
      achieve strong short-term gains. </p>

    <h1 class="text-2xl lg:text-3xl font-semibold mt-8">A Better Experience For Investors</h1>

    <p class="my-4">
      Investing in rental income today is very complex and time-consuming. Below is the 3-step process needed for
      purchasing a rental home. It is complicated, has a steep learning curve, requires tens of thousands of dollars,
      and takes several months to complete.
    </p>

    <div class="flex flex-row">
      <img src="/images/investors/contract/image-1.png" class="w-full my-8">
    </div>

    <p class="mt-4">So, we at FairPY set out to radically change the philosophy of rental income investing by making
      rental income via cars and make it accessible to anyone.</p>

    <div class="flex flex-row my-8">
      <img src="/images/investors/contract/image-2.png" class="w-full">
    </div>


    <p>Our platform allows anyone to buy shares of an LLC consisting of a cohort of cars. We make the process of
      investing and earning rental income as passive as it can get</p>

    <div class="flex flex-row my-8">
      <img src="/images/investors/contract/image-3.png" class="w-full">
    </div>

    <h1 class="text-3xl font-semibold mt-12 mb-4">Our Process</h1>

    <p>In addition to making the investing experience both faster and easier, we have spent a great deal of time
      developing an operational model that allows us to maximize returns for our investors. While there are many
      different components that go into maximizing investor returns, the three main areas that we focus on doing really
      well are the acquisition, management & sales of our rental cars. Below you can learn more about the FairPY
      Investment process. </p>

    <h3 class="text-xl font-semibold mt-8 mb-4">Robust Car Acquisition: </h3>

    <p>FairPY uses data science to ensure that we find and acquire the right car at the right price. This process starts
      with analyzing millions of data points from different auto auction markets and rental pricing from rental
      platforms. We identify which cars offer the best utilization and rental prices. We then pick the cars which have
      undergone the most depreciation and are in the bottom of the depreciation cycle, thus increasing our ROI
      potential. Once we find cars that fit the bill, we create a financial analysis for each of the cars and review
      exit strategies. This process ensures that we are only selecting and acquiring the cars that will maximize the
      returns for our investors. </p>

    <h3 class="text-xl font-semibold mt-8 mb-4">Efficient Rental Management: </h3>

    <p>Once we have acquired the right car at the right price, our operations team steps in to ensure that the car is
      managed efficiently and that we are able to reap the benefits of both scale and technology adoption. Our
      Operations is responsible for executing all of the following</p>

    <ul class="mt-4 leading-8">
      <li>1. <span class="font-semibold">120 point inspection </span> – We inspect every single car to ensure if it
        meets all our standards for rentals
      </li>
      <li>2. <span class="font-semibold">Return/Repair</span> – Post inspection we make a decision of whether to return
        the car or repair the car and rent or just list it on rental platforms. This decision is based on the optimal
        ROI
      </li>
      <li>3. <span class="font-semibold">Gap Insurance</span> – Work with commercial Period X (Gap) Insurance companies
        to add insurance to the car
      </li>
      <li>4. <span class="font-semibold">State Inspection</span> – Every car in the state of Texas needs to undergo a
        state inspection. If the state inspection fails then FairPY will determine between returning the car back to the
        auction or fixing the issue and bringing it back to state inxpection
      </li>
      <li>5. <span class="font-semibold">Registration & Title</span> – Register the car with FairPY as the owner and
        individual investors in the Lien
      </li>
      <li>6. <span class="font-semibold">Car Staging</span> - Clean/Detail the car and get ready of photoshoot</li>
      <li>7. <span class="font-semibold">Toll Tag</span> – Apply for toll tags and configure in carsync to maintain
        receipts for tolls
      </li>
      <li>8. <span class="font-semibold">Tracker/Remote lock/unlock</span> – Install car tracker with remote lock &
        unlock feature
      </li>
      <li>9. <span class="font-semibold">Car Rental Listing</span> – List cars in various (Turo, Getaround,Hyrecar)
        platforms
      </li>
      <li>10. <span class="font-semibold">Car Calendar Syncing</span> – Sync calendars between car sharing platforms
      </li>
      <li>11. <span class="font-semibold">Rental Pricing</span> – Price cars rentals based on demand, days of week &
        trip duration
      </li>

      <li>12. <span class="font-semibold">Cleaning</span> – Clean car before every car check-in</li>
      <li>13. <span class="font-semibold">Delivery</span> – Deliver cars to customer based on customer requests. Focus
        will be on delivery to DFW Airport,
        Pickup the car from DFW Airport short term parking or from customer location
      </li>
      <li>14. <span class="font-semibold">Checkin/Checkout</span> – Take pictures, enter odometer, fuel level & verify
        customer license
      </li>

      <li>15. <span class="font-semibold">Car Parking</span> – Park the cars with all needed securities to prevent car
        breakins when not in use
      </li>
      <li>16. <span class="font-semibold">Invoice incidentals (Gas, Tolls, Late fee etc)</span> – Invoice the customer
        on incidentals immediately after or
        within a week of customer checkout
      </li>
      <li>17. <span class="font-semibold">Insurance Claim Processing</span> – In case of accidents work with Turo to
        process claims
      </li>
      <li>18. <span class="font-semibold">Car Damages/Thefts</span> – Work with Gap Insurance to get recover car damages
        & theft costs. FairPY will try to
        identify the location of the car using its tracker in case of theft. Car break-ins are not preventable, FairPY
        will use all its available resources to deter car breakins
      </li>
      <li>19. <span class="font-semibold">Oil Change</span> – Change oil every 5000 miles and or as needed</li>
      <li>20. <span class="font-semibold">Tires Rotation & Alignment</span> – Rotate tires and ensure vehicle alignment.
        Vehicle alignment should be
        checked every 6 months
      </li>
      <li>21. <span class="font-semibold">Repairs</span> – Minor car repairs below $200 will be done by FairPY, Major
        car repairs will be dealt with case
        by case basis. FairPY will decide the right course of action based on returns for investors. Following are the
        possible course of actions 1. Fix the car, Charge every investor equally & list it back on the car sharing
        platform 2. Fix the car, Charge every investor equally, sell it in Retail/Auction market & purchase another car
        for the same value 3. Sell the car as is in auction market & purchase another car for the same value
      </li>
    </ul>

    <h3 class="text-xl font-semibold mt-8 mb-4">Profitable sales process: </h3>
    <p class="mt-4 mb-16">Our sales team is constantly involved in understanding the market trends and listing our
      rental cars in car sales platforms like TrueCar, Cars.com, Facebook Marketplace, Offerup & Craiglist. Ideally the
      plan is to keep all cars till the end of 3rd year and then sell. On a case by case basis FairPY might decide its
      best to sell the car and buy a different car. This decision will always be based on maximizing the returns to our
      investors. </p>
  </div>
  <PageTop/>
  <Footer/>
</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import Footer from "@/components/Footer";
import PageTop from "@/views/investors/components/PageTop";

export default {
  name: "contract",
  components: {
    PageTop,
    TopBar,
    Footer
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
}
</script>

<style scoped>

</style>