<template>
  <TopBar :disableSlide="true"/>
  <NavBar/>
  <div class="mt-16">
    <search-vin-number id="email" class="mb-4 " type="text" label="email" v-model="email"></search-vin-number>
  </div>
  <div class="mt-16">
    <div class="container justify-center m-auto">


      <form method="post" action="#" id="" enctype="multipart/form-data">
        <div class="flex flex-row justify-center">
          <div class="grid-cols-4 w-4/12">
            <input type="file" class="w-full p-1 hidden border-2 border-black-lighter" ref="file" multiple="multiple"
                   accept="image/*" @change="uploadImages">

            <input type="file" accept="image/*" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my-file">
          </div>
          <div class="grid-cols-3">
            <button
                class="border-2 px-6 h-full uppercase flex flex-row items-center justify-center relative font-medium text-white bg-blue border-none lg:ml-6 mt-4 lg:mt-0 lg:py-0 py-3">
              IMAGE UPLOAD
            </button>
          </div>
        </div>
      </form>


    </div>
    <!--    <form method="post" action="#" id="" enctype="multipart/form-data">-->
    <!--      <image-upload id="imageUpload" type="file" v-model="images" @change="uploadImages"-->
    <!--                    ref="file" accept="image/*"></image-upload>-->

    <!--      <input type="file" ref="file" multiple="multiple" @change="uploadImages">-->
    <!--    </form>-->
    <div class="border p-2 mt-3">
      <p>Preview Here:</p>
      <template v-if="preview_list.length">
        <div v-for="item, index in preview_list" :key="index">
          <img :src="item" class="img-fluid" />
          <p class="mb-0">file name: {{ image_list[index].name }}</p>
          <p>size: {{ image_list[index].size/1024 }}KB</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import NavBar from "@/components/NavBar";
import SearchVinNumber from "@/components/searchVinNumber";

export default {
  name: "seller",
  components: {
    TopBar,
    NavBar,
    SearchVinNumber
  },
  data() {
    return {
      email: "",
      images: [],
      imagePreview: [],
      selectedFile: null,
      showPreview: false,

      preview: null,
      image: null,
      preview_list: [],
      image_list: []
    };
  },
  methods: {

    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
    },

    uploadImages(e) {
      this.files = this.$refs.file.files;


      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(this.files[i])
        this.$emit('input', this.files[i])
      }

console.log( this.$emit);
      // let formData = new FormData();

      // for (var i = 0; i < this.$refs.file.files.length; i++) {
      //   let file = this.$refs.file.files[i]; console.log(file);
      //   formData.append('files[' + i + ']', file);
      // }

      this.selectedFile = e.target.files[0];

      let reader = new FileReader();
      reader.addEventListener("load", function () {
        this.showPreview = true;
        this.imagePreview = reader.result;
      }.bind(this), false);
      console.log(this.selectedFile);
      if (this.selectedFile) {
        if (/\.(jpe?g|png|gif)$/i.test(this.selectedFile.name)) {
          // console.log("here");
          reader.readAsDataURL(this.selectedFile);
        }
      }
    },

  }
}
</script>

<style scoped>

</style>