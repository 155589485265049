<template lang="">
<transition name="bounce">

  <div
    class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-40 flex justify-center items-center z-100 overflow-y-scroll"
  >
    <div :class="bodyClass" class="modal bg-white shadow-xl flex flex-col items-center relative z-auto">
      <button
        type="button"
        class="absolute top-4 right-4 border-0 p-0 bg-transparent z-10"
        @click="close"
      >
        <i class="icon close small"></i>
      </button>
      <img v-if="showModalBg" class="absolute right-0 bottom-0 z-0" src="/images/modal-bg.png" />
      <slot name="header"></slot>

      <slot name="body"></slot>

      <slot name="footer"></slot>
    </div>
  </div>
</transition>
</template>
<script>
export default {
  props: {
    bodyClass: String,
    showModalBg: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.25s;
}
.bounce-leave-active {
  animation: bounce-in 0.25s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
