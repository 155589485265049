import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Landing from '../views/Landing.vue';
import Raffle from '../views/Raffle.vue';
import Landings from '../views/Landing-page.vue';
import ProfileInfo from '../views/ProfileInfo.vue';
import Comparison from "@/views/comparison";
import Seller from "@/views/seller";
import Investors from "@/views/Investors";
import InvestorsMaster from "@/views/InvestorsMaster";
import InvestInCars from "@/views/InvestInCars";
import ContactUs from "@/views/investors/ContactUs";
import AboutUs from "@/views/investors/AboutUs";
import WishListCars from "@/views/WishListCars";
import SearchHistory from "@/views/SearchHistory";
import InvestorsPaymentDetails from "@/views/investors/InvestorsPaymentDetails";
import Contract from "@/views/investors/contract";
import PurchaseAgreement from "@/views/investors/PurchaseAgreement";
import RiskFactors from "@/views/investors/RiskFactors";
import PartnershipAgreement from "@/views/investors/PartnershipAgreement";
import FaqCategory from "@/views/investors/FaqCategory";
// import Faq from "@/views/investors/Faq";


const routes = [
  {
    path: '/search_history',
    name: 'SearchHistory',
    component: SearchHistory,
  },
  {
    path: '/investors/faqs/categories/:id',
    name: 'FaqCategory',
    component: FaqCategory,
  },
  {
    path: '/investors/card-details',
    name: 'InvestorsPaymentDetails',
    component: InvestorsPaymentDetails,
  },
  {
    path: '/investors/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/investors/faqs',
    name: 'Faq',
    component: () => import('../views/investors/Faq'),
  },
  {
    path: '/wishlist-cars',
    name: 'WishListCars',
    component: WishListCars,
  },
  {
    path: '/investors/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/investors/partnership-agreement',
    name: 'PartnershipAgreement',
    component: PartnershipAgreement,
  },
  {
    path: '/investors/purchase-agreement',
    name: 'PurchaseAgreement',
    component: PurchaseAgreement,
  },
  {
    path: '/investors/risk-factors',
    name: 'RiskFactors',
    component: RiskFactors,
  },
  {
    path: '/investors/invest-in-cars',
    name: 'InvestInCars',
    component: InvestInCars,
  },
  {
    path: '/investors/offering-memorandum',
    name: 'Contract',
    component: Contract,
  },
  {
    path: '/investors',
    name: 'Investors',
    component: Investors,
  },
  {
    path: '/investors-master',
    name: 'InvestorsMaster',
    component: InvestorsMaster,
  },

  {
    path: '/seller',
    name: 'Seller',
    component: Seller,
  },
  {
    path: '/find-cars',
    name: 'FindCars',
    component: Home,
  },
  {
    path: '/landing',
    name: 'Fairpy',
    component: Landing,
  },
  {
    path: '/raffle',
    name: 'Raffle',
    component: Raffle,
  },
  {
    path: '/Landing-page',
    name: 'Landing-page',
    component: Landings,
  },
  {
    path: '/profileInfo',
    name: 'ProfileInfo',
    component: ProfileInfo,
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: Comparison
  },
  {
    path: '/',
    name: 'Home',
    component: Landings,
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
  },
  // {
  //   path: '/details',
  //   name: 'Details',
  //   component: () => import('../views/Details.vue'),
  // },
  {
    path: '/:vin/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
  },
  {
    path: '/:vin/card-details',
    name: 'Card Details',
    component: () => import('../views/CardDetails.vue'),
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/password-reset/:token',
    name: 'Password Reset',
    component: () => import('../views/PasswordResetForm.vue'),
  },
  {
    path: '/investors/password-reset/:token',
    name: 'Password Reset',
    component: () => import('../views/investors/PasswordResetForm.vue'),
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import('../views/Faq.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/terms-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsConditions.vue'),
  },
  {
    path: '/view-rules',
    name: 'View Rules',
    component: () => import('../views/ViewRules.vue'),
  },
  {
    path: '/subscribe-mail',
    name: 'Subscribe Mail',
    component: () => import('../views/SubscribeMail.vue'),
  },
  {
    path: '/wishlist-mail',
    name: 'Wishlist Mail',
    component: () => import('../views/WishlistMail.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    if (to.name == 'Details') {
      return { top: 130 };
    }
    return savedPosition;
  },
});

export default router;
