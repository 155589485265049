<template>
<!--  <div>Available Cars: {{this.deals.length}}</div>-->
  <div class="p-1 border bg-white transition-all shadow-lg-dark hover:shadow-xl relative mt-6">
    <div class="flex-col lg:flex-row flex p-2">
      <div class="w-full lg:w-4/12 ">
        <div class="w-6/12 mt-1 flex">
          <div class="font-semibold">Categories:</div>
          <div class="ml-2">{{ search.categories}}
            <span v-if="!search.categories">None</span>
          </div>
        </div>

        <div class="w-6/12 mt-1 flex">
          <div class="font-semibold">Make:</div>
          <div class="ml-2">{{ search.make }} <span v-if="!search.make">None</span></div>
        </div>

        <div class="w-6/12 mt-1 flex">
          <div class="font-semibold">Model:</div>
          <div class="ml-2">{{ search.model }} <span v-if="!search.model">None</span></div>
        </div>
      </div>

      <div class="w-full lg:w-4/12">

        <div class="mt-1 flex">
          <div class="font-semibold">Odometer:</div>
          <div class="ml-2">{{ search.odometer_min }} - {{ search.odometer_max }}</div>
        </div>

        <div class="mt-1 flex">
          <div class="font-semibold">Year:</div>
          <div class="ml-2">{{ search.from_year }} - {{ search.to_year }}</div>
        </div>

        <div class="mt-1 flex">
          <div class="font-semibold">Budget:</div>
          <div class="ml-2">{{ search.min_budget }} - {{ search.max_budget }}</div>
        </div>
      </div>

      <div class="w-full lg:w-4/12 flex flex-col border-t lg:border-t-0 py-2 mg:mt-0 lg:py-0 lg:border-l border-blue-400">
        <div class="flex justify-end">
          <a href="#" class="text-red-600 text-xs font-semibold float-right" @click="removeSearch(this.searchList.id)">Remove</a>
        </div>
        <div class="flex flex-col justify-center items-center mt-3">
          <div class="pb-4"><span class="text-xs text-gray-600">Saved on: </span>{{dateFormat(this.searchList.created_at, 'dd-MM-yyyy HH:MM:SS')}}</div>
          <button class="bg-blue text-white px-6 py-1.5" @click="gotoSearch(this.searchList)">Continue Search</button>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SearchHistoryList",
  props: {
    searchList: Object,
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      makeObjects: "makes",
      params: "searchParams",
      filters: "filters",
    }),
  },
  mounted() {
    this.search = JSON.parse(this.searchList.filters);
  },
  methods: {
    gotoSearch(searchList) {
      console.log(searchList.min_budget);
      console.log('searchList -----');
      this.params["min_budget"] = this.search.min_budget;
      this.params["max_budget"] = this.search.max_budget;
      this.params["from_year"] = this.search.from_year;
      this.params["to_year"] = this.search.to_year;
      this.params["odometer_min"] = this.search.odometer_min;
      this.params["odometer_max"] = this.search.odometer_max;
      this.params["min_emi"] = this.search.min_emi;
      this.params["max_emi"] = this.search.max_emi;
      this.params["is_price"] = this.search.is_price;
      if (this.search.make == null){
        this.params["make"] = ""
      } else {
        this.params["make"] = this.search.make;
      }

      if (this.search.model == null){
        this.params["model"] = ""
      } else {
        this.params["model"] = this.search.model;
      }

      if (this.search.categories == null){
        this.params["categories"] = ""
      } else {
        this.params["categories"] = this.search.categories;
      }

      if (this.search.car_category == null){
        this.params["car_category"] = ""
      } else {
        this.params["car_category"] = this.search.car_category;
      }
      this.loadDeals({
        ...this.params,
      });


      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },

    removeSearch(id) {
      this.$store.dispatch("removeSearchList", id).then(
          (res) => {
            console.log(res);
            this.$router.go(this.$router.currentRoute)
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    paramSelected(key, value) {
      this.params[key] = value;
    },

    dateFormat(inputDate, format) {
      //parse the input date
      const date = new Date(inputDate);

      console.log(date);
      console.log('-------------------date ');

      //extract the parts of the date
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
console.log(date.toLocaleTimeString())
      //replace the month
      format = format.replace("MM", month.toString().padStart(2, "0"));

      //replace the year
      if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
      } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
      }

      //replace the day
      format = format.replace("dd", day.toString().padStart(2, "0"));

      //replace the month
      format = format.replace("HH", hour.toString().padStart(2, "0"));
      format = format.replace("MM", minutes.toString().padStart(2, "0"));
      format = format.replace("SS", seconds.toString().padStart(2, "0"));

      return format;
    },
  }
}
</script>

<style scoped>

</style>