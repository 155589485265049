<template>
  <TopBar/>
  <NavBar/>
  <div class="container m-auto mb-8">
    <div v-if="this.searchLists.length == 0" class="font-bold text-blue text-2xl flex justify-center items-center" style="height: 60vh">
      <div>No Data Found</div>
    </div>
    <div class="flex justify-center">
      <div class="w-9/12">
        <SearchHistoryList v-for="searchList in this.searchLists" :key="searchList" :searchList="searchList"></SearchHistoryList>

      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import TopBar from "@/components/TopBar";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
// import {mapGetters} from "vuex";
import SearchHistoryList from "@/components/SearchHistoryList";

export default {
  props: {},
  name: "SearchHistory",
  components: {
    SearchHistoryList,
    TopBar,
    NavBar,
    Footer
  },
  data() {
    return {
      searchLists: ''
    }
  },

  mounted() {
    this.getSearchHistory();
  },
  methods: {
    getSearchHistory() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("getSearchHistory", user_id).then(
          (value) => {
            this.searchLists = value.data.data;
            console.log(this.searchLists.length);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },


  }
}
</script>

<style scoped>

</style>