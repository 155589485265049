<template>
  <TopBar />
  <NavBar />
    <Profiles/>
    <Footer />
</template>
<script>
import TopBar from "@/components/TopBar";
import NavBar from "@/components/NavBar";
import Footer from "../components/Footer.vue";
import Profiles from '../components/Profiles.vue'

    export default{
    name: 'ProfileInfo',
    components:{
        TopBar,
        NavBar,
        Footer,
        Profiles
        }
    }
</script>