<template>
  <div class="container justify-center m-auto">
    <div class="flex flex-row justify-center">
      <div class="grid-cols-4 w-4/12">
        <input
            :id="id"
            :type="type"
            :value="modelValue"
            @input="updateInput"
            class="w-full p-1 border-2 border-black-lighter"
        />
      </div>
      <div class="grid-cols-3">
        <button class="border-2 px-6 h-full uppercase flex flex-row items-center justify-center relative font-medium text-white bg-blue border-none lg:ml-6 mt-4 lg:mt-0 lg:py-0 py-3">Search VIN Number</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "searchVinNumber",
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    }
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
}
</script>

<style scoped>

</style>