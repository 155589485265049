<template>

  <TopBar/>
  <div class="container m-auto">

    <div class="flex flex-col lg:flex-row  xl:flex-row mt-4 2xl:flex-row">
      <div class="">

        <Carousel :autoplay="5000" :settings='settings' :breakpoints='breakpoints' v-if="sliders_urls.length > 0">
          <Slide v-for='(gallery, index) in sliders_urls' :key='index'>
            <div class='carousel__item'>
              <div class="">
                <img :src="gallery.src" @click="openModal();currentSlide(index)" class="shadow-cus hover-shadow cursor">

              </div>
            </div>
          </Slide>

          <template #addons="{ slidesCount }">
            <!--            <Pagination/>-->
            <Navigation v-if="slidesCount > 1"/>
          </template>
        </Carousel>

        <div class="flex flex-col mt-8 lg:px-36">
          <h1 class="text-lg font-semibold text-center lg:text-left xl:text-left">{{ headerText }}</h1>
          <p class="mb-8 text-base  text-left px-4 xl:px-0 2xl:px-0">We have an amazing opportunity for you
            to grow
            your wealth via investing in cars. This is a market which has huge untapped potential. Like real estate you
            can grow your wealth in a very safe and secure way using cars. <span class="hidden"> To learn more about how you can money
            <a href="/investors/invest-in-cars" class="font-bold" style="color: #008de9">Click here...</a></span>
          </p>
        </div>


      </div>

      <div class="w-full xl:w-1/2 lg:w-1/2 2xl:w-1/2 content-center hidden">
        <div class="px-4 lg:p-6  xl:px-4 pt-6">
          <p class="text-sm hidden"> Before we take this investment public we are “testing the waters” to ensure there
            is
            sufficient investment demand of no less than $400,000 to justify both the time and cost incurred.
          </p>
          <h1 class="text-lg font-semibold">{{ interestHeaderText }}</h1>

          <div class="flex flex-row  p-2 justify-between">
            <div class="">
              <div class="text-lg">{{ daysRemains }}</div>
              <div class="text-sm">Days Remain</div>
            </div>

            <div class="">
              <div class="text-lg">{{ backers }}</div>
              <div class="text-sm">Backers</div>
            </div>

            <div class="">
              <div class="text-lg">{{ funded }}</div>
              <div class="text-sm">Pledged</div>
            </div>
          </div>

          <div class="mt-2">
            <div class="progress-bar w-full border-black-lighter bg-gray-400 relative">
              <div id="status-bar" class="w-48 h-1" style="background-color: #ec6e1b"></div>
              <div id="status-bar-green" class="absolute right-0 top-0 h-1" style="background-color: green"></div>
            </div>
          </div>

          <div class="mt-2 text-sm flex justify-between">
            <div><b>{{ fundMax }}</b> Goal</div>
            <div class="text-green-600 font-semibold text-sm" v-if="goalReached">Goal Achieved</div>
          </div>

          <div class="flex flex-col mt-4 w-full ">
            <form id="form" @submit.prevent="investorsEnquiry">
              <div class="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                <div class="flex justify-between flex-row lg:flex-col lg:w-6/12 2xl::w-4/12 lg:mr-5">
                  <label class="text-sm">Name</label>
                  <input type="text" class="w-48 lg:w-56 h-8 px-3 outline-none border-1 profile-title-txt"
                         placeholder=""
                         v-model="this.name" name="name" required>
                </div>

                <div class="flex justify-between flex-row lg:flex-col  xl:flex-col lg:w-6/12 2xl::w-4/12 mt-6 lg:mt-0">
                  <label class="text-sm">Phone</label>
                  <input type="number" class="w-48 lg:w-56 h-8 px-3 outline-none border-1 profile-title-txt"
                         placeholder=""
                         v-model="this.mobile_number"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="10" name="mobile_number" required>
                </div>

              </div>
              <div class="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row mt-5">
                <div class="flex justify-between flex-row lg:flex-col lg:w-6/12  2xl::w-4/12 lg:mr-5">
                  <label class="text-sm">I am pledging $</label>
                  <input type="number" class="w-48 lg:w-56 h-8 px-3 outline-none border-1 profile-title-txt"
                         placeholder=""
                         v-model="this.amount" name="amount" required>
                </div>

                <div class="flex justify-between flex-row lg:flex-col lg:w-6/12  2xl::w-4/12 mt-6 lg:mt-0">
                  <label class="text-sm">Email</label>
                  <input type="email" class="w-48 lg:w-56 h-8 px-3 outline-none border-1 profile-title-txt"
                         placeholder=""
                         v-model="this.email" name="email" required>
                </div>

              </div>
              <div class="flex flex-row justify-center">

                <div class="flex flex-row lg:flex-col w-full lg:w-6/12 2xl::w-3/12 mr-2 hidden">
                  <label class="text-sm">Portfolio</label>
                  <select class="w-48 lg:w-56 h-8 px-3 outline-none border-1" v-model="this.selected">
                    <option v-for="select in portfolios" :key="select.id" :value="select.val" class="text-xs">
                      {{ select.val }}
                    </option>
                  </select>
                </div>

                <!--                <div class="flex flex-row lg:flex-col w-full lg:w-6/12  2xl::w-3/12">-->
                <div class="h-8 px-3">
                  <button
                      class="w-56 button bg-blue p-2  text-white text-sm uppercase mt-5 rounded-lg">
                    Submit Pledge
                  </button>
                  <!--                  </div>-->

                </div>
              </div>
            </form>
          </div>

          <div class="mt-10">
            <!--            <h1 class="text-lg font-bold text-center">{{ subHeaderText }}</h1>-->
            <div class="flex flex-row mt-2">
              <div class="p-1">
                <div class="flex flex-row justify-center mb-2">
                  <img src="/images/investors/free_icon_1.svg" class="w-10">
                </div>

                <h3 class="font-bold text-sm text-center">Low Risk</h3>
                <p class="text-xs mt-2 text-center">
                  Extremely low risk investment in auto business
                </p>
              </div>

              <div class="p-1">
                <div class="flex flex-row justify-center mb-2">
                  <img src="/images/investors/high-return.png" class="w-10">
                </div>
                <h3 class="font-bold text-sm text-center">High Return</h3>
                <p class="text-xs mt-2 text-center">
                  Expected returns of 60% - 80% over 3 years
                </p>
              </div>

              <div class="p-1">
                <div class="flex flex-row justify-center mb-2">
                  <img src="/images/investors/small-investment.png" class="w-10">
                </div>
                <h3 class="font-bold text-sm text-center">Small Investment</h3>
                <p class="text-xs mt-2 text-center">
                  Invest any amount greater than or equal to $15,000
                </p>
              </div>

            </div>
          </div>
        </div>

      </div>

      <stripe-checkout></stripe-checkout>

    </div>


    <Modal
        bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
        v-show="isThankYouModalVisible" @close="closeThankModal">


      <template v-slot:body>
        <div v-if="isThankYouModalVisible" class="flex justify-center relative w-full">
          <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 py-12
        px-6 relative">
            <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
              <img class="lg:w-28" src="/images/thankyou-modal-icon.png"/>
            </div>
            <h1 class="text-3xl font-display mt-8 mb-6 text-center lg:px-12 lg:mx-12 px-4 mx-4">
              Thank You
            </h1>
                        <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
                          We will get back to you on this shortly.
                        </p>

            <div class="flex flex-col border-2 border-gray-300 px-6 py-8 hidden">
              <div class="text-lg">10% down payment to block your spot: <span
                  class="font-semibold">${{ this.tenPercentageAmount }}</span></div>
              <div class="flex justify-center mt-6">
                <button @click.prevent="PayNow" class="mr-12 bg-blue uppercase text-white p-3 px-6 block ml-12">
                  Pay Now
                </button>
                <button @click.prevent="PayLater" class="bg-blue uppercase text-white p-3 px-6 block ml-12">
                  Pay Later
                </button>
              </div>
            </div>


          </div>
        </div>
      </template>
    </Modal>

    <div id="myModal" class="modal-slider z-topmost">
      <span class="close cursor" @click="closeModal()">&times;</span>
      <div class="modal-content">

        <div class="container flex justify-center mt">

          <div id="mySlides" class="mySlides" v-for='(gallery, index) in sliders_urls' :key='index'>
            <!--              <div class="numbertext">{{ index }} / {{ galleryItems.length }}</div>-->
            <img :src="gallery.src" class="slider-imagea w-12/12 ">
          </div>
        </div>

        <a class="prev" @click="prevImage(-1)">&#10094;</a>
        <a class="next" @click="nextImage(1)">&#10095;</a>

        <div class="caption-container">
          <p id="caption"></p>
        </div>

        <div class="flex flex-row mt-8">
          <div class="column" v-for='(gallery, index) in sliders_urls' :key='index'>
            <img class="demo cursor h-20" :src="gallery.src" style="width:100%" @click="currentSlide(index)"
                 alt="Nature and sunrise">
          </div>
        </div>

      </div>
    </div>
  </div>

  <Footer/>
</template>

<script>
import Modal from "@/components/Modal";
// import {Carousel, Navigation, Slide, Pagination} from 'vue3-carousel';
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";
import axios from "axios";
import {StripeCheckout} from '@vue-stripe/vue-stripe';
import TopBar from "@/views/investors/components/TopBar";

export default {
  name: "Investors",
  components: {
    TopBar,
    // Pagination,
    Carousel,
    Slide,
    Navigation,
    Modal,
    Footer,
    StripeCheckout
  },
  data: () => ({
    headerText: '',
    subHeaderText: '',
    investRange: '',
    interestHeaderText: '',
    daysRemains: '',
    backers: '',
    funded: '',
    fundMin: '',
    fundMax: '',
    amount: '',
    email: '',
    name: '',
    portfolio: '',
    slideIndex: 1,
    sliders_urls: '',
    goalReached: false,
    mobile_number: '',
    selected: 'Long term rental - 3 yrs',
    portfolios: {
      1: {id: 1, val: 'Short term rental - 1 yr'},
      2: {id: 2, val: 'Long term rental - 3 yrs'},
      3: {id: 3, val: 'Finance - 4 yrs'},
    },
    // galleryItems: '',
    isThankYouModalVisible: false,
    tenPercentageAmount: '',
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
    },
    paymentLink: 'https://buy.stripe.com/5kA031gfV4jUbfO4gg',

  }),
  mounted() {
    this.loadInvestors();
    // this.showSlides(this.slideIndex);
    let vid = document.getElementById("mySlides");
    if (vid) {
      vid.ontimeupdate = function () {
        let percentage = (vid.currentTime / vid.duration) * 100;
        let menu = document.getElementById('status-bar');
        menu.style.width = percentage + "%";
      };
    }
  },
  computed: {
    ...mapGetters({
      investors: "investors",
    }),
  },
  methods: {

    PayLater() {
      const message = new URLSearchParams({
        number: +15025921994,
        // number: +919840431067,
        message: 'Hi FairPy team, we have investor pledge-  ' + this.name + ', opted a PAY LATER of 10% percentage of the pledged amount $' + this.tenPercentageAmount + ' using the link - ' +
            this.paymentLink +
            ' Please contact the investor to assist further, Thanks!'
      });

      axios
          .get(
              `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${message.toString()}`,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                crossDomain: true,
              }
          )

      const customerMessage = new URLSearchParams({
        number: '+1' + this.mobile_number,
        // number: +919840431067,
        message: 'Hi ' + this.name + ', Thanks for the registration! Fairpy welcome you to the investors plan program. Please Pay 10% percentage of the pledged amount $' + this.tenPercentageAmount + ' using the link - ' +
            this.paymentLink +
            ' Thanks!'
      });

      axios
          .get(
              `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${customerMessage.toString()}`,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                crossDomain: true,
              }
          )
    },
    PayNow() {
      this.isThankYouModalVisible = false;
      this.investors["email"] = this.email;
      this.investors["name"] = this.name;
      this.investors["amount"] = this.amount;
      this.investors["mobile_number"] = this.mobile_number;
      this.investors["portfolio"] = this.selected;
      window.location.href = this.paymentLink;

      // console.log('stripe details');
      // console.log(this.$store.state.card.stripe);
      // this.$router.push({name: 'InvestorsPaymentDetails'});
    },

    loadInvestors() {
      const params = {};
      params["id"] = 1;
      this.$store.dispatch("loadInvestorsMaster", params).then(
          (value) => {

            if (value) {
              let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
              });

              let master = value.data.investors;
              this.investRange = master.invest_range;
              this.interestHeaderText = master.interest_header_text;

              const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
              const firstDate = new Date();
              const secondDate = new Date(master.end_date);

              const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
              this.daysRemains = diffDays;
              this.backers = master.backers_count;
              this.funded = formatter.format(master.pledged_amount);
              this.fundMin = formatter.format(master.fund_min);
              this.fundMax = formatter.format(master.fund_max);
              this.sliders_urls = JSON.parse(master.sliders_urls);
              let percentage = (master.pledged_amount / master.fund_max) * 100;
              if (percentage > 100) {
                percentage = 100;
              }
              let statusBar = document.getElementById('status-bar');
              statusBar.style.width = percentage + "%";
              let remainAmount = master.pledged_amount - master.fund_max;

              if (remainAmount > 0) {
                let greenPercentage = (remainAmount / master.fund_max) * 100;
                if (greenPercentage > 100) {
                  greenPercentage = 100;
                }
                let statusBarGreen = document.getElementById('status-bar-green');
                statusBarGreen.style.width = greenPercentage + "%";
              }
              if (parseInt(master.pledged_amount) >= parseInt(master.fund_max)){
                this.goalReached = true;
              }
            }


          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );


    },

    investorsEnquiry() {
      let formData = new FormData();

      formData.append("amount", this.amount);
      formData.append("email", this.email);
      formData.append("name", this.name);
      formData.append('mobile_number', this.mobile_number);
      formData.append("portfolio", this.selected);

      this.tenPercentageAmount = (10 / 100) * this.amount;

      this.$store.dispatch("investorsEnquiry", formData).then(
          (value) => {
            this.isThankYouModalVisible = true;
            console.log(value);
            // console.log('message uncomment')
            let resData = value.data.investors;
            console.log(resData)
            const message = new URLSearchParams({
              number: +15025921994,
              // number: +919750212695,
              // number: +919840431067,
              message: 'Hi FairPy Team, We have an Investors ' + resData.name + ' pitch for an amount of $' + resData.amount + ' from our website. Investor contact email - ' + resData.email + ' . Please reach investor.  Thanks!.',
            });

            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${message.toString()}`,
                    {
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      crossDomain: true,
                    }
                )

            this.$store.dispatch("card/setPaymentIntent", value.data.intent);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );


    },
    openModal() {
      document.getElementById("myModal").style.display = "block";
    },
    closeThankModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
      window.location.reload();
    },
    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },

    prevImage(n) {
      this.showSlides(this.slideIndex += n);
    },
    nextImage(n) {
      console.log(this.slideIndex += n);
      this.showSlides(this.slideIndex += n - 1);
    },

    currentSlide(n) {
      this.showSlides(this.slideIndex = n);
    },

    showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("demo");
      // var captionText = document.getElementById("caption");
      if (n > slides.length) {
        this.slideIndex = 1
      }
      if (n < 1) {
        this.slideIndex = slides.length
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
      // captionText.innerHTML = dots[this.slideIndex - 1].alt;
    }
  },
}
</script>

<style>

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.shadow-cus {
  --tw-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.7);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


.slider-image {
  width: 55vw;
}

.row > .column {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 25%;
}

/* The Modal (background) */
.modal-slider {
  display: none;
  position: fixed;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.95);
}

/* Modal Content */
.modal-content {
  position: relative;
  /*background-color: #fefefe;*/
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

img {
  margin-bottom: -4px;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.carousel__item {
  min-height: 200px;
  /*width: 100%;*/
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  /*border: 5px solid white;*/
  border-radius: 50% !important;
  z-index: 80;
}

.carousel__prev {
  margin-left: 23px !important;
}

.carousel__pagination-button {
  background-color: #efa36d;
}

.carousel__pagination-button--active {
  background-color: #e1650c;
}

</style>