<template>
  <div>
    <button @click="toggleAccordion()" class="flex flex-col items-stretch w-full bg-white border-b-2"
      :aria-expanded="isOpen">
      <div class="relative flex flex-row items-center w-full">
        <div class="flex flex-col items-start flex-grow">
          <div :class="isOpen ? 'text-base text-blacks tiempos' : 'text-blacks'" class="flex flex-row items-center justify-between w-full px-3 md:px-0 lg:px-0 py-3  ">
            <div class="text-base md:text-base lg:text-base text-left tiempos">
              {{ title }}
            </div>
            <span  class="absolute flex items-start md:items-center lg:items-center justify-end w-full pr-3 text-xl md:pr-2 lg:pr-2" :class="{active:isActive}"  @click="toggle">
              {{isActive ? '-' : '+'}}
            </span>
          </div>
        </div>
      </div>
      <div class="relative">
        <div  v-show="isOpen"  class="px-3 pb-2 text-sm font-normal leading-5 tracking-wide text-justify text-blk calibre" >
          <slot>
            
          </slot>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    iconClass: String,
  },
  data() {
    return {
      isOpen: false,
       isHidden: false
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    toggle() {
      this.isActive = this.isActive ? false : true;
      },
  },
};
</script>
<style>
  .tiempos {
    font-family: 'Tiempos Headline', sans-serif;
  }

  .calibre {
    font-family: 'Calibre', sans-serif;
  }

  .text-blue {
    color: #0d213a;
  }

  .text-blacks {
    color: #232323;
  }

  .text-blk {
    color: #191919;
  }
</style>