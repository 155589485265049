<template>
    <div class="w-100 lg:pt-20  relative overflow-hidden">
        <div class="absolute z-0 left-0 right-0 top-0 bottom-0 flex flex-row justify-between items-center">
          <img class="lg:w-auto lg:relative absolute left-0 top-4" src="/images/map-left.png"/>
          <img class="lg:w-auto lg:relative absolute right-0 bottom-4" src="/images/map-right.png"/>
        </div>
        <div class="container mx-auto justify-between px-0 py-6 z-10 relative">
            <SpecialTitle title="Top Car Deals " caption="FariPY Deals" class="lg:px-3 px-5"/>

          <div class="flex  w-full  flex-row justify-end pr-5 pl-5">

            <div class=" lg:w-1/5 rounded-full bg-gray-100 w-full p-1 flex  mb-2 mt-4 relative shadow">
              <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"
                   :class="{ 'left-1/2': this.paymentType, 'left-0': !this.paymentType}">
                <div class="bg-blue rounded-full shadow-sm w-full h-full"></div>
              </div>
              <div @click="toggleFinance"
                   class="w-1/2 text-center py-2 text-sm relative cursor-pointer"
                   :class="{ 'text-white': !this.paymentType }">
                Finance
              </div>
              <div @click="toggleCash"
                   class="py-2 w-1/2 text-sm text-center relative cursor-pointer"
                   :class="{ 'text-white': this.paymentType }">
                Cash
              </div>
            </div>
          </div>

            <div v-if="!isMobile()" class="w-full lg:hidden flex-row justify-center lg:mb-16 flex">
              <button @click="previous()" class="px-4 py-2">
                <img :class="currentIndex == 0?'opacity-30':''" src="/images/icons/left-arrow.png" />
              </button>
              <button :class="currentIndex == slideLimit() ?'opacity-30':''" @click="next()" class="px-4 py-2">
                <img src="/images/icons/right-arrow.png" />
              </button>
            </div>
            <div v-if="!isMobile()" class="w-full hidden lg:block">
<!--              <carousel v-if="forRange(forLimit()) > 0" v-model="currentIndex" :items-to-show="1">-->
<!--                <slide v-for="index in forRange(forLimit())" :key="index">-->
<!--                  <div :class="[slideRows === 1 ? 'grid-rows-1' : 'grid-rows-2', 'grid grid-cols-4 gap-8 lg:px-3 px-5 pb-6']">-->
<!--                    <div v-for="innerIndex in forRange(innerForLimit(index))" v-bind:key="innerIndex">-->
<!--                    <DealItem  v-if="getDealFor(innerIndex, currentIndex)" @click.prevent="gotoDetails(deals[innerIndex + (currentIndex * 4)])" class="w-full" :deal="getDealFor(innerIndex, currentIndex)"/>-->
<!--                    </div>-->
<!--                  </div>-->
<!--              </slide>-->
<!--              </carousel>-->

              <carousel v-if="deals.length > 0" v-model="currentIndex" :items-to-show="4">
                <slide v-for="item in deals" v-bind:key="item.id">
                  <DealItem @click.prevent="gotoDetails(item)" class="w-full" :deal="item" />
                </slide>
              </carousel>

            </div>
            <div v-if="isMobile()" class="w-full lg:hidden lg:pb-6">
              <carousel v-if="deals.length > 0" v-model="currentIndex" :items-to-show="1">
                <slide v-for="item in deals" v-bind:key="item.id">
                  <DealItem @click.prevent="gotoDetails(item)" class="w-full px-5" :deal="item" />
                </slide>
              </carousel>
            </div>
            
            <div class="w-full flex flex-row justify-center lg:mb-16  lg:mt-6 margin-top">
              <button :class="currentIndex == 0?'opacity-30':''" @click="previous()" class="px-4 py-2">
                <img src="/images/icons/left-arrow.png" />
              </button>
              <button :class="currentIndex == slideLimit() ?'opacity-30':''" @click="next()" class="px-4 py-2">
                <img src="/images/icons/right-arrow.png" />
              </button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import DealItem from "./DealItem.vue";
import SpecialTitle from "./SpecialTitle.vue";

export default {
  name: "CarDeals",
  components: {
    SpecialTitle,
    DealItem,
    Carousel,
    Slide,
  },
  data: function () {
    return {
      currentIndex: 0,
      paymentType: true,
      params: {
        make: "",
        model: "",
        series: "",
      },
      deals: [],
      slideRows: 1,
    };
  },
  mounted() {
    this.currentIndex = 0;
    this.loadCars();
  },
  methods: {
    getDealFor(innerIndex, currentIndex){
      return this.deals[innerIndex + (currentIndex * 4)]
    },
    toggleFinance() {
      for (let i = 0; i < this.deals.length; i++) {
        this.deals[i].paymentType = false;
      }
      this.paymentType = !this.paymentType;
    },
    toggleCash() {
      for (let i = 0; i < this.deals.length; i++) {
        this.deals[i].paymentType = true;
      }
      this.paymentType = !this.paymentType;
    },
    gotoDetails(item) {
      this.$store.dispatch("setSelectedDealId", item.id);
      this.$router.push({ name: "Details", params: { vin: item.id } });
    },
    forRange(n){
      return [...Array(n).keys()];
    },
    forLimit() {
      return Math.ceil(this.deals.length / 4);
    },
    innerForLimit(index) {
      if (!this.deals || this.deals.length == 0) {
        return 0;
      }
      return index <= this.forLimit() ? 4 : this.deals.length % 4;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    slideLimit() {
      return (this.isMobile() ? this.deals.length - 1 : this.forLimit());
    },
    next() {
      this.currentIndex++;

      console.log(this.currentIndex, this.slideLimit());

      if (this.currentIndex > this.slideLimit()) {
        this.currentIndex = this.slideLimit();
      }
    },
    previous() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
    },
    loadCars() {
      axios
        .get(
          `https://m2v98plzh2.execute-api.us-west-2.amazonaws.com/Dev/popular-cars`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            crossDomain: true,
          }
        )
        .then((res) => {
          let deals = res.data;
          deals = deals.map(function (deal) {
            return {
              id: deal.VIN,
              title: deal.year_make_model,
              miles: deal.Odometer,
              bid: Math.round(deal.purchase_price),
              // bid: Math.round(deal.fair_bid),
              savings: Math.round(deal.savings),
              finance_savings: Math.round(deal.finance_savings),
              bookValue: Math.round(deal.retail_value),
              image: deal.thumbnail,
              details: deal.car_details,
              date: deal.bid_final_date,
              currentBid: Math.round(deal.kbb),
              type: deal.title,
              pdf: deal.pdf,
              series: deal.Series,
              monthly_payments: Math.round(deal.monthly_payments),
              shipping_fee: deal.shipping_fee,
              down_payment: deal.down_payment,
              sales_tax: deal.sales_tax,
              paymentType: true
            };
          });

          this.deals = deals;
        })
        .catch((error) => {
          console.log(error);
          // Manage errors if found any
        });
    },
  },
};
</script>
<style>

@media (max-width: 640px) {
  
}
</style>