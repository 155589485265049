<template>
  <div class="p-4 border bg-white transition-all shadow-lg-dark hover:shadow-xl mb-8">
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <div class="col-span-1 w-full lg:w-40 cursor-pointer" @click="gotoDetails">
        <img class="object-contain w-full lg:w-40 h-full"
             :src="deal.deal.image"/>
      </div>

      <div class="col-span-2 lg:ml-4">
        <div class="flex flex-row justify-between">
          <h2 class="font-semibold text-left text-lg mb-2 ">{{ deal.deal.title }}</h2>
          <a href="#" class="float-right text-red-600 text-xs uppercase" @click="removeWishlist">Remove</a>
        </div>


        <div class="flex justify-between">
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.miles }}</div>
            <div class="uppercase text-xs">Miles</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.savings }}</div>
            <div class="uppercase text-xs">Savings</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.details }}</div>
            <div class="uppercase text-xs">Details</div>
          </div>

        </div>

        <hr class="my-4 border-t-2 mr-2.5"/>

        <div class="flex justify-between">
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.bookValue }}</div>
            <div class="uppercase text-xs">List Price</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.monthly_payments }}</div>
            <div class="uppercase text-xs">Per Month</div>
          </div>
          <div class="flex flex-col text-center">
            <div class="text-sm font-semibold">{{ deal.deal.down_payment }}</div>
            <div class="uppercase text-xs">Cash Down</div>
          </div>

        </div>


      </div>
      <div class="col-span-1 border-t-2 lg:border-t-0 lg:border-l-2">
        <div class="h-32 grid gap-4 content-center justify-center">
          <Countdown :date="deal.deal.date" :vin="deal.vin"></Countdown>
<!--          <Countdown date="August 15, 2016"></Countdown>-->
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import Countdown from "@/components/Countdown";
export default {
  name: "wishlist",
  components: {
    Countdown
  },
  props: {
    deal: Object,
  },
  mounted() {
  },
  methods: {
    removeWishlist() {
      this.$store.dispatch("removeWishlist", this.deal.id).then(
          (res) => {
           console.log(res);
            this.$router.go(this.$router.currentRoute)
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    gotoDetails() {
      this.$router.push({ name: "Details", params: { vin: this.deal.vin } });
    },
  }
}
</script>

<style scoped>

</style>