<template>
  <div class="w-100 lg:py-24 lg:py-8 relative overflow-hidden bg-gray-lighter">
    <div class="absolute z-0 left-0 right-0 top-0 bottom-0 flex flex-row justify-between items-center">
      <img src="/images/dots.png" class="p-16 pl-0 pt-0 object-contain"/>
      <img src="/images/dots.png" class="p-16 pr-0 pb-0 object-contain"/>
    </div>
    <div class="container mx-auto justify-between lg:flex-row flex-col flex lg:px-3 px-5 py-6 z-10 relative">
      <div class="flex flex-col lg:w-1/2">
        <DefaultTitle :center="!isDesktop" class="lg:mb-16 mb-8">HOW FAIRPY WORKS</DefaultTitle>
        <div class="lg:hidden relative justify-center mb-4">
          <img src="/images/video-bg.png" class="object-cover h-96 w-full">
          <img src="/images/video-thumb-2.png" class="-mt-48 p-6">
          <img @click="openGallery()" src="/images/watch-video.png"
               class="absolute top-1/3 left-0 h-32 -mt-16 cursor-pointer">
        </div>
        <div class="gap-8 lg:grid-cols-2 lg:grid-rows-2 grid-cols-1 grid-rows-4 lg:mb-11 hidden lg:grid">
          <HowItWorkTile
              image="7.png"
              iconClass="icon_size my-auto"
              title="Choose your desired car">
            Find a suitable car from our curated list of super saving cars
          </HowItWorkTile>

          <HowItWorkTile
              image="5.png"
              iconClass="icon_size my-auto"
              title="Reserve your car">
            We procure the car for you directly from wholesale with all the savings
          </HowItWorkTile>

          <HowItWorkTile
              image="6.png"
              iconClass="icon_size my-auto"
              title="Get your car delivered">
            We will deliver the car to your door step
          </HowItWorkTile>

          <HowItWorkTile
              image="8.png"
              iconClass="icon_size my-auto"
              title="Drive it or Return it">
            You have 7 days/250 miles to make your decision on the car. If you don’t like it return it with a full
            refund
          </HowItWorkTile>
        </div>
        <div class="flex lg:hidden flex-col">
          <AppAccordion
              image="7.png"
              iconClass="icon_size my-auto"
              title="Choose your desired car">
            Find a suitable car from our curated list of super saving cars
          </AppAccordion>
          <AppAccordion
              image="5.png"
              iconClass="icon_size my-auto"
              title="Reserve your car">
            We procure the car for you directly from wholesale with all the savings
          </AppAccordion>
          <AppAccordion
              image="6.png"
              iconClass="icon_size my-auto"
              title="Get your car delivered">
            We will deliver the car to your door step
          </AppAccordion>
          <AppAccordion
              image="8.png"
              iconClass="icon_size my-auto"
              title="Drive it or Return it">
            You have 7 days/250 miles to make your decision on the car. If you don’t like it return it with a full
            refund
          </AppAccordion>
        </div>
      </div>
      <div class="lg:flex hidden flex-col w-1/2 relative justify-center">
        <div class="absolute right-0 top-0 bottom-0 left-0">
          <img src="/images/video-bg.png" class="object-contain h-full ml-auto">
        </div>
        <div class="relative p-16 pb-28 mr-auto">
          <img src="/images/video-thumb-2.png">
          <img @click="openGallery()" src="/images/watch-video.png"
               class="absolute top-1/2 right-0 h-32 -mt-16 cursor-pointer">
        </div>
      </div>
    </div>
  </div>
  <div>
    <FsLightbox
        :toggler="toggler"
        :sources="[
'https://www.youtube.com/watch?v=na83SqRHALg',
]"
    />
  </div>
</template>
<script>
import DefaultTitle from "./DefaultTitle.vue";
import HowItWorkTile from "./HowItWorkTile.vue";
import AppAccordion from "./AppAccordion.vue";
import FsLightbox from "fslightbox-vue/v3";

export default {
  name: "HowItWorksSection",
  components: {
    DefaultTitle,
    HowItWorkTile,
    AppAccordion,
    FsLightbox,
  },
  data() {
    return {
      isDesktop: screen.width > 768,
      toggler: false,
    };
  },
  methods: {
    openGallery() {
      this.toggler = !this.toggler;
    },
  },
};
</script>
<style>
.icon_size {
  width: 50px;
  height: 32px;
}
</style>