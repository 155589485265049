<template>
  <div
    v-if="isLoading"
    class="
      fixed
      top-0
      left-0
      bottom-0
      right-0
      bg-white bg-opacity-90
      flex
      justify-center
      items-center
      z-101
    "
  >
    <img
      width="350"
      height="auto"
      src="/images/icons/fairpy-loader.gif"
      alt="Fairpy Loading"
    />
  </div>
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
// import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    // Loading,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.loadMakeModels();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
  watch: {
    isLoading(newValue) {
      console.log("loading....", newValue);
    },
  },
  methods: {
    loadMakeModels() {
      this.$store.dispatch("getMakeModel");
    },
  },
};
</script>

<style></style>
