<template>
  <TopBar />
  <NavBar />

  <div class="w-100 contact-us-banner-bg">
    <div class="container mx-auto lg:px-3 px-5 lg:py-6 py-6 flex flex-col lg:flex-row lg:justify-between">
      <div class="flex flex-col font-bold flex-grow items-center">
        <h2 class="text-white px-6 text-center text-md font-semibold">
         Comparing: Volkswagen Jetta
        </h2>
        <h6 style="font-size: 1rem" class=" text-white mt-1">2017 Models</h6>
      </div>
    </div>
  </div>

  <div class="container mx-auto lg:px-3 px-1 lg:py-6 py-6 flex lg:flex-row md:gap-12 lg:gap-12 gap-2">


    <div class="flex-col text-center justify-items-stretch w-98">
      <div class="grid justify-items-center py-3">
        <img class="h-6 lg:h-12 md:h-12" src="/images/comparison/company-1.png" />
      </div>

      <div class="border-2 justify-center align-center">
        <Carousel>
          <Slide v-for="slide in 3" :key="slide" class="p-0">
            <img class="h-28 md:h-60 lg:h-60 w-full" src="/images/comparison/1.png" />
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
        <div class="text-sm py-3 font-semibold">2017 Volkswagen Jetta R-Line</div>
        <div class="p-2 bg-gray-100 text-sm font-semibold">Price $12,990</div>
       <div class="px-5 mb-32 md:mb-20 lg:mb-20 mt-5">
         <button style="color: #008de9" class="hidden md:block lg:block text-sm w-full p-2 pl-3 pr-3 rounded-md border-2 border-blue text-blue mt-2">
           VIEW WEBSITE
         </button>
         <button style="color: #008de9" class="block md:hidden lg:hidden text-sm w-full p-2 pl-3 pr-3 rounded-md border-2 border-blue text-blue mt-2">
           VIEW
         </button>
       </div>
      </div>
    </div>


    <div class="flex-col text-center  w-98">
      <div class="grid justify-items-center py-3">
        <img class="h-6 lg:h-12 md:h-12" src="/images/comparison/company-2.png" />
      </div>
      <div style="border: 1px solid #008de9" class="border-blue blur-md invert drop-shadow-xl justify-center align-center">
        <Carousel>
          <Slide v-for="slide in 3" :key="slide" class="p-0">
            <img class="h-28 md:h-60 lg:h-60 w-full" src="/images/comparison/2.png" />
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
        <div class="text-sm py-3 font-semibold">2017 Volkswagen Jetta R-Line</div>
        <div class="p-2 bg-gray-100 text-sm font-semibold">Price $10,000</div>
        <div class="p-2 text-sm font-semibold">Retail Value: $12,200</div>
        <div class="p-2 text-sm font-semibold" style="color: #008de9">Savings $2,200</div>
        <div class="md:px-5 lg:px-5 mb-5 mt-2">
          <button  class="text-sm w-full p-2 pl-3 pr-3 rounded-md border-2 bg-blue text-white mt-2">
            BID NOW
          </button>
        </div>
      </div>
    </div>



    <div class="flex-col text-center  w-98">
      <div class="grid justify-items-center py-3">
        <img class="h-6 lg:h-12 md:h-12" src="/images/comparison/company-3.png" />
      </div>
      <div class="border-2 justify-center align-center">
        <Carousel>
          <Slide v-for="slide in 3" :key="slide" class="p-0">
            <img class="h-28 md:h-60 lg:h-60 w-full" src="/images/comparison/3.png" />
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
        <div class="text-sm py-3 font-semibold">2017 Volkswagen Jetta R-Line</div>
        <div class="p-2 bg-gray-100 text-sm font-semibold">Price $12,990</div>
        <div class="px-5 mb-32 md:mb-20 lg:mb-20 mt-5">
          <button style="color: #008de9" class="hidden md:block lg:block text-sm w-full p-2 pl-3 pr-3 rounded-md border-2 border-blue text-blue mt-2">
            VIEW WEBSITE
          </button>
          <button style="color: #008de9" class="block md:hidden lg:hidden text-sm w-full p-2 pl-3 pr-3 rounded-md border-2 border-blue text-blue mt-2">
            VIEW
          </button>
        </div>
      </div>
    </div>

  </div>
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import NavBar from "../components/NavBar.vue";
import Footer from "@/components/Footer";
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: "comparison",
  components: {
    TopBar,
    NavBar,
    Footer,
    Carousel,
    Slide,
    Navigation,
  },
}
</script>

<style scoped>
.carousel__slide{
  padding: 0;
}
.slide-image{
  height: 15rem;
  width: 100%;
}
.company-logo{
  /*width: 100%;*/
  height: 3rem;
}
.carousel__prev, .carousel__next{
  background: #008de900 !important;
  left: 16px !important;
}
.carousel__prev{
  left: 16px !important;
}
</style>