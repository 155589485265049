<template>
  <!--    <div class="relative overflow-hidden w-100 aspect-h-16 aspect-w-58">-->
  <!--      <div class="absolute top-0 bottom-0 left-0 right-0 bg-blue banner-image">-->
  <!--      </div>-->
  <!--    </div>-->


  <div id="home"
       class="relative grid content-end  bg-no-repeat bg-cover bg-banner bg-black-500 lg:bg-blue md:bg-blue md:home-bg lg:home-bg justify-items-center  w-full lg:h-screen md:h-96 lg:h-28 lg:min-h-screen min-h-fit">
    <div class="min-w-full">

      <nav class="absolute top-0 left-0  bottom-0 left-0 right-0 h-0 lg:block">
        <div class="flex">
          <div class="z-10 md:p-5 lg:p-3 p-1 mt-1 lg:mt-0 bg-white cursor-pointer nav-brand-bg w-11/12">
            <div class="flex justify-between">
              <div class="w-10/12 lg:w-6/12 flex items-center">
                <div class="w-7/12 lg:w-6/12 logo flex justify-start lg:justify-center">
                  <a href="/landing-page"><img class="responsive logo-widtha w-20 lg:w-28" src="../assets/logo.png">
                    <!--                    <div id="custom-seekbar" class="">-->
                    <!--                      <span id="custom-seekbars"></span>-->
                    <!--                    </div>-->
                  </a>
                </div>
                <div class="flex justify-between" v-if="loggedIn">
                  <img src="/images/landing-page/notification-icon.png" class="w-9 lg:w-10 p-2 hidden">
                  <div class="relative" v-if="this.wishlistCarsCount > 0" title="Wishlist">
                    <a href="/wishlist-cars">
                      <img src="/images/landing-page/heart-icon.png" class="w-9 lg:w-10 p-2 inline-flex">
                      <span class=" absolute left-5 bottom-4"
                            v-if="this.wishlistCarsCount > 0">
                      <span class="text-xs py-1 px-2 bg-red-600 text-white rounded-full"
                            style="font-size: 9px">{{ this.wishlistCarsCount }}</span>
                    </span>
                    </a>
                  </div>

                  <div class="relative ml-2" v-if="this.searchHistoryCount > 0" title="Search History">
                    <a href="/search_history"><img src="/images/icons/searching-car.png"
                                                   class="w-9 lg:w-10 p-2 inline-flex w-10">
                      <span class="absolute left-6 bottom-4"
                            v-if="this.searchHistoryCount > 0">
                      <span class="text-xs py-1 px-2 bg-red-600 text-white rounded-full"
                            style="font-size: 9px">{{ this.searchHistoryCount }}</span>
                    </span>
                    </a>
                  </div>

                  <!--                  <span class="bg-red-600 text-white rounded-2xl h-4">-->
                  <!--                      <span class="text-xs p-2">{{ this.wishlistCarsCount }}</span>-->
                  <!--                    </span>-->

                  <div class="flex items-center ml-2 hidden lg:flex">
                    <img src="/images/landing-page/user-icon.png" class="p-2" @click="profile" title="Profile">
                    <span class="mt-2" @click="profile">{{ currentUser['first_name'] }}   {{
                        currentUser['last_name']
                      }}</span>
                  </div>
                </div>

                <div id="custom-seekbar" class="">
                  <span id="custom-seekbars"></span>
                </div>
              </div>

              <div class="mr-4 cursor-pointer ">
                <div class="flex flex-row justify-evenly">

                  <div class="z-10 hidden lg:flex xl:flex">
                    <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn text-sm"
                       href="#" style="color: #0d213a" @click="showSignInModal" v-if="!loggedIn"> <i
                        class="icon md1 signin mr-1 signin"></i> Sign In</a>
                  </div>

                  <div class="z-10 hidden lg:flex xl:flex">

                    <a href="/search">
                      <button class="font-normal tracking-wider text-white uppercase btn-bg calibre text-sm">
                        <span style="vertical-align: sub">View All Cars</span>
                      </button>
                    </a>
                  </div>
                  <div class="z-10 hidden lg:flex xl:flex">
                    <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn text-sm"
                       href="/faqs" style="color: #0d213a">Learn
                      More </a>
                  </div>
                  <div @click="setActive"
                       class="lg:mr-3 z-topmost transition-all duration-500  ease-in hover:text-blue md:top-0">
                    <button id="hamburger" class="hamburger app menu-border" @click="hamburger"
                            :class="{ 'hamburger--is-open' :hamburgerOpen }"></button>
                  </div>
                </div>

              </div>

            </div>


          </div>
        </div>
        <div :class="{ active }" class="z-100 hidden fixed ">

          <div class="grid content-start h-screen">
            <div>
              <div
                  class="grid justify-between items-center grid-cols-1 gap-1 md:gap-5 lg:gap-5 md:grid-cols-3 lg:grid-cols-3">
                <div class="grid-cols-1 d-none md:block lg:block ">
                  <div class="h-12 p-2 my-2 topleft-clip "></div>
                </div>
                <div class="flex justify-between mt-3">
                  <div
                      class="flex justify-start pl-5 pt-1 md:pt-2 md:pl-10 lg:pt-2 lg:pl-10 sm:justify-center md:justify-center lg:justify-center">
                    <img class="logo-width" src="../assets/logo.png" alt="">
                  </div>

                  <div @click="setActive"
                       class="flex md:hidden mr-3 z-topmost transition-all duration-500  ease-in hover:text-blue justify-end">
                    <button id="hamburger" class="hamburger-mb app menu-border-mb" @click="hamburger"
                            :class="{ 'hamburger--is-open' :hamburgerOpen }"></button>
                  </div>
                </div>
                <div class="grid-cols-1">
                                    <div @click="setActive"
                                         class="hidden md:flex mr-3 ml-20 z-topmost transition-all duration-500  ease-in hover:text-blue justify-center">
                                      <button id="hamburger" class="hamburger app menu-border" @click="hamburger"
                                              :class="{ 'hamburger--is-open' :hamburgerOpen }"></button>
                                    </div>
                </div>
              </div>
            </div>
            <div>
              <div class="m-auto w-12/12 md:w-9/12 lg:w-9/12 ">
                <div class="grid grid-cols-1 gap-4 py-0 md:pt-14 md:pb-6 md:grid-cols-2 lg:grid-cols-2">
                  <div class="grid-cols-1 mx-8 md:mx-0 lg:mx-0">
                    <ul class="grid w-64 gap-2 m-auto sm:w-auto md:m-auto lg:m-auto px-15 sm:px-0 md:px-0 lg:px-0 md:gap-8 lg:gap-8">
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">01</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos"
                           href="/Landing-page">Home</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">02</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos"
                           href="/about-us">about</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">03</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#how"
                           @click="setActive">how it
                          works</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">04</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#top"
                           @click="setActive">top deals</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">05</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#categories"
                           @click="setActive">categories</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left fairpy-width">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">06</span>
                        <a class="w-40 text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#why"
                           @click="setActive">why
                          choose fairpy</a>
                      </li>
                    </ul>
                  </div>
                  <div class="grid-cols-1 mx-8 md:mx-0 lg:mx-0">
                    <ul class="grid w-64 gap-2 lg:gap-4 m-auto px-15 sm:w-auto md:m-auto lg:m-auto sm:px-0 md:px-0 lg:px-0 md:gap-8 lg:gap-8 ">
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">07</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-3xl text-blue tiempos" href="#finance"
                           @click="setActive">easy
                          finance</a>
                      </li>
                      <!--                    <li class="text-center sm:text-left md:text-left lg:text-left">-->
                      <!--                      <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">08</span>-->
                      <!--                      <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#stats" @click="setActive">stats</a>-->
                      <!--                    </li>-->
                      <!--                    <li class="text-center sm:text-left md:text-left lg:text-left">-->
                      <!--                      <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">09</span>-->
                      <!--                      <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#safety" @click="setActive">safety</a>-->
                      <!--                    </li>-->
                      <li class="text-center sm:text-left md:text-left lg:text-left"
                          v-if="loggedIn && this.wishlistCarsCount > 0">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">08</span>
                        <a class="text-xl uppercase md:text-3xl lg:text-3xl text-blue tiempos" href="/wishlist-cars"
                           @click="setActive">Wishlist Cars</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                          <span v-if="loggedIn && this.wishlistCarsCount == 0">08</span>
                          <span v-if="loggedIn && this.wishlistCarsCount > 0">09</span>
                          <span v-if="!loggedIn">08</span>
                        </span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#faqs"
                           @click="setActive">faqs</a>
                      </li>

                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                             <span v-if="loggedIn && this.wishlistCarsCount == 0">09</span>
                           <span v-if="loggedIn && this.wishlistCarsCount > 0">10</span>
                          <span v-if="!loggedIn">09</span>
                        </span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#foot"
                           @click="setActive">contact</a>
                      </li>

                      <li class="text-center sm:text-left md:text-left lg:text-left"
                          v-if="loggedIn && this.searchHistoryCount > 0">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                         <span v-if="loggedIn && this.searchHistoryCount > 0 && this.wishlistCarsCount == 0">10</span>
                         <span v-if="loggedIn && this.searchHistoryCount > 0 && this.wishlistCarsCount > 0">11</span>
                        </span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="search_history"
                           @click="setActive">Search History</a>
                      </li>

                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                          <span v-if="loggedIn && this.searchHistoryCount > 0 && this.wishlistCarsCount == 0">11</span>
                         <span v-if="loggedIn && this.searchHistoryCount > 0 && this.wishlistCarsCount > 0">12</span>
                        </span>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#" v-if="!loggedIn"
                           @click="showSignInModal">Sign In</a>
                        <a class="text-xl uppercase md:text-3xl lg:text-4xl text-blue tiempos" href="#" v-if="loggedIn"
                           @click.prevent="logout">LOG OUT</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="grid justify-between grid-cols-1 gap-0 lg:gap-5 md:grid-cols-2 lg:grid-cols-2">
                <div class="grid-cols-1"></div>
                <div class="grid-cols-1">
                  <div class="flex justify-end">
                    <div class="h-12 p-2 topright-clip "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav class="absolute top-0 left-0  bottom-0 left-0 right-0 h-0 hidden">
        <div class="flex content-start lg:items-center justify-between ">
          <div
              class="z-99 md:p-5 lg:p-5 p-3 pl-5 bg-white cursor-pointer sm:pl-15 lg:pl-20 md:pl-20 nav-brand-bg sm:w-72 md:w-128 lg:w-128">
            <a href="/landing-page"><img class="responsive logo-width" src="../assets/logo.png">
              <div id="custom-seekbar" class="">
                <span id="custom-seekbars"></span>
              </div>
            </a>
          </div>

          <!--          <div class="flex z-99 mt-4 md:mt-10 lg:mt-2 hidden md:flex lg:flex justify-center">-->
          <!--            <div class="">-->

          <!--              <a href="/search">-->
          <!--                <button class="font-normal tracking-wider text-white uppercase btn-bg calibre"> View All Cars</button>-->
          <!--              </a>-->
          <!--            </div>-->
          <!--            <div class="">-->
          <!--              &lt;!&ndash;      <a href="/search">&ndash;&gt;-->
          <!--              &lt;!&ndash;        <button class="font-normal tracking-wider text-white uppercase bg-blue btn-bg calibre"> Learn More</button>&ndash;&gt;-->
          <!--              &lt;!&ndash;      </a>&ndash;&gt;-->
          <!--              <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn" href="/faqs">Learn-->
          <!--                More </a>-->
          <!--            </div>-->
          <!--          </div>-->


          <div class=" mx-2 cursor-pointer ">
            <div class="flex flex-row justify-between">

              <div class="z-10 hidden lg:flex xl:flex">

                <a href="/search">
                  <button class="font-normal tracking-wider text-white uppercase btn-bg calibre text-sm"> View All
                    Cars
                  </button>
                </a>
              </div>
              <div class="z-10 hidden lg:flex xl:flex">
                <!--      <a href="/search">-->
                <!--        <button class="font-normal tracking-wider text-white uppercase bg-blue btn-bg calibre"> Learn More</button>-->
                <!--      </a>-->
                <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn text-sm"
                   href="/faqs" style="color: #0d213a">Learn
                  More </a>
              </div>
              <!--              <div class="inline-block text-left z-10">-->
              <!--                <div class="mr-8" v-if="!loggedIn">-->
              <!--                  <button type="button" v-if="!loggedIn" @click="showSignInModal"-->
              <!--                          class="inline-flex  mt-3 w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2  hidden md:flex lg:flex xl:flex 2xl:flex"-->
              <!--                          id="menu-button" aria-expanded="true" aria-haspopup="true">-->
              <!--                    <img src="/images/user-white.png" style="width: 20px; margin-right: 10px"> SIGN IN-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="mr-3" v-if="!loggedIn">-->
              <!--                  <button type="button" @click="showSignInModal"-->
              <!--                          class="inline-flex w-full justify-center rounded-md  border-gray-300-->
              <!--                         px-4  text-sm  focus:outline-none-->
              <!--                         flex md:hidden lg:hidden xl:hidden 2xl:hidden"-->
              <!--                          id="menu-button" aria-expanded="true" aria-haspopup="true">-->
              <!--                    <img src="/images/user-white.png" style="width: 30px;">-->
              <!--                  </button>-->
              <!--                </div>-->

              <!--                <div class="mr-3 md:mr-8 lg:mr-8 xl:mr-8  2xl:mr-8 md:mt-3 lg:mt-3 xl:mt-3  2xl:mt-3" v-if="loggedIn">-->
              <!--                  <button type="button" @click="toggleBtn = !toggleBtn"-->
              <!--                          class="inline-flex mr w-full justify-center rounded-sm px-4 py-2 text-sm font-medium-->
              <!--                           focus:ring-2 text-white"-->
              <!--                          id="menu-button" aria-expanded="true" aria-haspopup="true">-->
              <!--                    <img src="/images/user-white.png" style="width: 20px; margin-right: 10px">-->
              <!--                    <span class="hidden md:flex lg:flex xl:flex 2xl:flex text-white uppercase">-->
              <!--                        {{ currentUser['first_name'] }}   {{ currentUser['last_name'] }}-->
              <!--                    </span>-->
              <!--                    <img src="/images/down-arrow.png" style="width: 15px; margin-top: 3px; margin-left: 8px">-->
              <!--                  </button>-->

              <!--                  &lt;!&ndash;                  <div v-if="toggleBtn && loggedIn"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                       class="absolute  z-10 mt-2 w-40 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                       role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <div class="py-1" role="none">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                      <form method="POST" action="#" role="none">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                        <button type="submit" class="text-gray-700 font-bold block w-full px-4 py-2 text-center text-sm"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                                role="menuitem"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                                tabindex="-1" id="menu-item-3" @click.prevent="logout">LOG OUT&ndash;&gt;-->
              <!--                  &lt;!&ndash;                        </button>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                      </form>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--                </div>-->

              <!--              </div>-->

<!--                            <div @click="setActive"-->
<!--                                 class="mr-3 z-101 transition-all duration-500 mt-1 ease-in hover:text-blue right-2 md:right-10 md:top-0">-->
<!--                              &lt;!&ndash;                <button id="hamburger" class="hamburger app" @click="hamburger"&ndash;&gt;-->
<!--                              &lt;!&ndash;                        :class="{ 'hamburger&#45;&#45;is-open' :hamburgerOpen }"></button>&ndash;&gt;-->

<!--                              <button id="hamburger" class="hamburger-mb app menu-border-mb" @click="hamburger"-->
<!--                                      :class="{ 'hamburger&#45;&#45;is-open' :hamburgerOpen }"></button>-->
<!--                            </div>-->
            </div>

          </div>
        </div>
        <div :class="{ active }" class="z-99 hidden fixed ">
          <div class="grid content-start h-screen">
            <div>
              <div class="grid justify-between grid-cols-1 gap-1 md:gap-5 lg:gap-5 md:grid-cols-3 lg:grid-cols-3">
                <div class="grid-cols-1 d-none md:block lg:block ">
                  <div class="h-12 p-2 my-4 topleft-clip "></div>
                </div>
                <div class="grid-cols-1 my-4 ">
                  <div
                      class="flex justify-start pl-5 pt-1 md:pt-2 md:pl-10 lg:pt-2 lg:pl-10 sm:justify-center md:justify-center lg:justify-center">
                    <img class="logo-width" src="../assets/logo.png" alt="">
                  </div>
                </div>
                <div class="grid-cols-1"></div>
              </div>
            </div>
            <div>
              <div class="m-auto w-12/12 md:w-9/12 lg:w-9/12 ">
                <div class="grid grid-cols-1 gap-4 py-0 md:pt-14 md:pb-6 md:grid-cols-2 lg:grid-cols-2">
                  <div class="grid-cols-1 mx-8 md:mx-0 lg:mx-0">
                    <ul class="grid w-64 gap-2 lg:gap-4 m-auto sm:w-auto md:m-auto lg:m-auto px-15 sm:px-0 md:px-0 lg:px-0 md:gap-8 lg:gap-8">
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">01</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos"
                           href="/Landing-page">Home</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">02</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos"
                           href="/about-us">about</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">03</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#how"
                           @click="setActive">how it
                          works</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">04</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#top"
                           @click="setActive">top deals</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">05</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#categories"
                           @click="setActive">categories</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left fairpy-width">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">06</span>
                        <a class="w-40 text-xl uppercase  md:text-4xl lg:text-4xl text-blue tiempos" href="#why"
                           @click="setActive">why
                          choose fairpy</a>
                      </li>
                    </ul>
                  </div>
                  <div class="grid-cols-1 mx-8 md:mx-0 lg:mx-0">
                    <ul class="grid w-64 gap-4 m-auto px-15 sm:w-auto md:m-auto lg:m-auto sm:px-0 md:px-0 lg:px-0 md:gap-8 lg:gap-8 ">
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">07</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#finance"
                           @click="setActive">easy
                          finance</a>
                      </li>
                      <!--                    <li class="text-center sm:text-left md:text-left lg:text-left">-->
                      <!--                      <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">08</span>-->
                      <!--                      <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#stats" @click="setActive">stats</a>-->
                      <!--                    </li>-->
                      <!--                    <li class="text-center sm:text-left md:text-left lg:text-left">-->
                      <!--                      <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">09</span>-->
                      <!--                      <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#safety" @click="setActive">safety</a>-->
                      <!--                    </li>-->
                      <li class="text-center sm:text-left md:text-left lg:text-left" v-if="loggedIn">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">08</span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="/wishlist-cars"
                           @click="setActive">Wishlist Cars</a>
                      </li>
                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                          <span v-if="loggedIn">09</span>
                          <span v-if="!loggedIn">08</span>
                        </span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#faqs"
                           @click="setActive">faqs</a>
                      </li>

                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                          <span v-if="loggedIn">10</span>
                          <span v-if="!loggedIn">09</span>
                        </span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#foot"
                           @click="setActive">contact</a>
                      </li>

                      <li class="text-center sm:text-left md:text-left lg:text-left">
                        <span class="top-0 pr-2 text-gray-400 align-top sm:pr-4 md:pr-5 lg:pr-6 calibre">
                          <span v-if="loggedIn">11</span>
                          <span v-if="!loggedIn">10</span>
                        </span>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#" v-if="!loggedIn"
                           @click="showSignInModal">Sign In</a>
                        <a class="text-xl uppercase md:text-4xl lg:text-4xl text-blue tiempos" href="#" v-if="loggedIn"
                           @click.prevent="logout">LOG OUT</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="grid justify-between grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2">
                <div class="grid-cols-1"></div>
                <div class="grid-cols-1">
                  <div class="flex justify-end">
                    <div class="h-12 p-2 topright-clip "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div class="container m-auto tiempos">
        <div
            class="icon-sec px-4  grid grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 gap-1 flex content-end pb-1 lg:pb-10 xl:pb-10 2xl:pb-10 font-semibold text-xs lg:text-sm xl:text-sm uppercase text-white ">

          <div class="flex flex-col">
            <img src="/images/landing-page/icon-4.png" class="w-6 lg:w-8 xl:w-8 2xl::w-8">
            <h4 class="pt-2 lg:pt-4 xl:pt-4 2xl:pt-4">Choose Car</h4>
          </div>


          <div class="flex flex-col">
            <img src="/images/landing-page/icon-3.png" class="w-6 lg:w-8 xl:w-8 2xl::w-8">
            <h4 class="pt-2 lg:pt-4 xl:pt-4 2xl:pt-4">Reserve It</h4>
          </div>

          <div class="flex flex-col">
            <img src="/images/landing-page/icon-2.png" class="w-6 lg:w-8 xl:w-8 2xl::w-8">
            <h4 class="pt-2 lg:pt-4 xl:pt-4 2xl:pt-4">Get Delivered</h4>
          </div>

          <div class="flex flex-col">
            <img src="/images/landing-page/icon-1.png" class="w-6 lg:w-8 xl:w-8 2xl::w-8">
            <h4 class="pt-2 lg:pt-4 xl:pt-4 2xl:pt-4">Drive it or Return</h4>
          </div>

        </div>
      </div>
    </div>


    <video id="banner-video"
           class="hidden lg:flex absolute top-0 left-0 right-0 md:banner-video lg:banner-video min-h-screen h-full  lg:object-cover object-fit-cover"
           playsinline
           autoplay
           muted
           loop>
      <source src="/images/landing-page/new-banner-video.mp4"
              type="video/mp4">
    </video>

    <video id="banner-video"
           class="flex lg:hidden absolute top-0 left-0 right-0 md:banner-video lg:banner-video h-fit h-full  lg:object-cover object-fit-cover"
           playsinline
           autoplay
           muted
           loop>
      <source src="/images/landing-page/banner-video-mobile.mp4"
              type="video/mp4">
    </video>

    <div class="flex justify-center mb-12 md:mt-5 z-10 md:gap-0 lg:hidden">
      <div class="">
        <a href="/search">
          <button class="font-normal tracking-wider text-white uppercase btn-bg calibre text-xs"> View All Cars</button>
        </a>
      </div>
      <div class="">
        <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn-white text-xs"
           href="/faqs">Learn
          More </a>
      </div>
    </div>
  </div>


  <Modal
      bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-8"
      v-show="isModalVisible"
      @close="closeModal"
  >
    <template v-slot:header>
      <h1 v-if="isSignupModal && !signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Create Your
        FairPY Account</h1>
      <h1 v-if="!isSignupModal && !signupSuccessful && !isForgotPasswordModal"
          class="text-3xl font-display mt-2 mb-10 text-center">Hello, Welcome Back</h1>
      <h1 v-if="!isSignupModal && !signupSuccessful && isForgotPasswordModal"
          class="text-3xl font-display mt-2 mb-10 text-center">Frogot Password</h1>
      <h1 v-if="signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Thank You!</h1>
    </template>

    <template v-slot:body>
      <div class="flex justify-center relative w-full">
        <div
            v-if="!signupSuccessful"
            class="lg:w-1/2 w-full flex flex-col justify-center text-center relative"
        >
          <div
              v-if="!isSignupModal && !isForgotPasswordModal"
              class="flex flex-col w-full"
          >
            <div class="relative mb-8 text-left">
              <input
                  v-model="loginEmail"
                  class="border p-4 w-full pl-12"
                  placeholder="Email"
              />
              <div
                  class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
              >
                <i class="icon email-outline small"></i>
              </div>
            </div>
            <div class="relative mb-8">
              <input
                  v-model="loginPassword"
                  class="border p-4 w-full pl-12"
                  placeholder="Password"
                  type="password"
                  id="password"
              />
              <div
                  class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
              >
                <i class="icon password small"></i>
              </div>
              <div class="absolute right-0 top-0 bottom-0 flex justify-center items-center w-12"
                   @click="showPassword()">
                <i class="icon show-password small" id="password-icon"></i>
              </div>
            </div>
            <div class="relative">
          <span
              v-if="errors['general']"
              class="text-red-700 text-xs leading-tight block mb-8"
          >{{ errors["general"] }}</span
          >
            </div>
            <button
                @click.prevent="signin"
                class="bg-blue uppercase text-white p-4 mb-8"
            >
              Sign In
            </button>
            <a
                @click.prevent="showForgotPassword"
                class="text-blue font-normal text-sm mb-6 cursor-pointer"
            >Forgot Password?</a
            >
            <p class="text-sm text-black-light mb-8">
              Dont have FairPY account?<a
                @click.prevent="showSignUpModal"
                class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
            >Signup Now</a
            >
            </p>
          </div>

          <div
              v-if="isSignupModal && !isForgotPasswordModal"
              class="flex flex-col w-full"
          >
            <div class="relative mb-4 flex items-start">
              <div class="relative mr-1 text-left w-1/2">
                <input
                    id="firstName"
                    v-model="firstName"
                    :class="[
                'border p-4 w-full',
                errors['firstName'] ? 'border-red-700' : '',
              ]"
                    placeholder="First Name"
                />
                <span
                    v-if="errors['firstName']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["firstName"] }}</span
                >
              </div>
              <div class="relative ml-1 w-1/2 text-left">
                <input
                    id="lastName"
                    v-model="lastName"
                    :class="[
                'border p-4 w-full',
                errors['lastName'] ? 'border-red-700' : '',
              ]"
                    placeholder="Last Name"
                />
                <span
                    v-if="errors['lastName']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["lastName"] }}</span
                >
              </div>
            </div>
            <div class="relative mb-4 text-left">
              <input
                  id="email"
                  v-model="email"
                  :class="[
              'border p-4 w-full',
              errors['email'] ? 'border-red-700' : '',
            ]"
                  placeholder="Email"
                  type="text"
              />
              <span
                  v-if="errors['email']"
                  class="text-red-700 text-xs leading-tight block"
              >{{ errors["email"] }}</span
              >
            </div>
            <div class="relative mb-4 flex items-start text-left">
              <div class="relative mr-1 text-left w-1/2">
                <input
                    id="phone"
                    v-model="phone"
                    :class="[
                'border p-4 w-full',
                errors['phone'] ? 'border-red-700' : '',
              ]"
                    placeholder="Phone Number"
                />
                <span
                    v-if="errors['phone']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["phone"] }}</span
                >
              </div>
              <div class="relative ml-1 text-left w-1/2">
                <input
                    id="zip"
                    v-model="zip"
                    :class="[
                'border p-4 w-full',
                errors['zip'] ? 'border-red-700' : '',
              ]"
                    placeholder="Zip Code"
                />
                <span
                    v-if="errors['zip']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["zip"] }}</span
                >
              </div>
            </div>
            <div class="relative mb-4 text-left">
              <input
                  id="password"
                  v-model="password"
                  :class="[
              'border p-4 w-full',
              errors['password'] ? 'border-red-700' : '',
            ]"
                  placeholder="Password"
                  type="password"
              />
              <span
                  v-if="errors['password']"
                  class="text-red-700 text-xs leading-tight block"
              >{{ errors["password"] }}</span
              >
            </div>
            <div class="relative mb-4 text-left">
              <input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  :class="[
              'border p-4 w-full',
              errors['confirmPassword'] ? 'border-red-700' : '',
            ]"
                  placeholder="Confirm Password"
                  type="password"
              />
              <span
                  v-if="errors['confirmPassword']"
                  class="text-red-700 text-xs leading-tight block"
              >{{ errors["confirmPassword"] }}</span
              >
            </div>
            <div class="relative">
          <span
              v-if="errors['general']"
              class="text-red-700 text-xs leading-tight block mb-8"
          >{{ errors["general"] }}</span
          >
            </div>
            <button
                @click.prevent="registerUser"
                class="bg-blue uppercase text-white p-4 mb-8"
            >
              Create My Account
            </button>
            <p class="text-sm text-black-light mb-8">
              Already have a FairPY account?<a
                href="#"
                @click.prevent="showSignInModal"
                class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
            >Sign In</a
            >
            </p>
          </div>

          <div
              v-if="!isSignupModal && isForgotPasswordModal"
              class="flex flex-col w-full"
          >
            <div class="relative mb-8 text-left">
              <input
                  v-model="loginEmail"
                  class="border p-4 w-full pl-12"
                  placeholder="Email"
              />
              <div
                  class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
              >
                <i class="icon email-outline small"></i>
              </div>
            </div>
            <div class="relative">
          <span
              v-if="errors['general']"
              class="text-red-700 text-xs leading-tight block mb-8"
          >{{ errors["general"] }}</span
          >
              <div
                  v-if="messages['info']"
                  class="text-white text-sm bg-green-700 leading-tight block mb-8 p-4"
              >
                {{ messages["info"] }}
              </div>
            </div>
            <button
                @click.prevent="forgotPassword"
                class="bg-blue uppercase text-white p-4 mb-8"
            >
              Reset Password
            </button>
            <a
                @click.prevent="showSignInModal"
                class="text-blue font-normal text-sm mb-6"
            >Back to Login?</a
            >
          </div>

          <div v-if="!isForgotPasswordModal" class="flex items-center mb-8">
            <!--            <hr class="flex-grow"/>-->
            <!--            <p class="px-4 text-sm text-gray-400 font-light">Or connect with</p>-->
            <!--            <hr class="flex-grow"/>-->
          </div>
          <!--          <div v-if="!isForgotPasswordModal" class="flex flex-row mb-8">-->
          <!--            <button class="border border-blue-fb bg-white px-4 py-3 flex items-center justify-center mr-2 flex-grow">-->
          <!--              <img src="/images/fb-btn.png"/>-->
          <!--            </button>-->
          <!--            <GoogleLogin :callback="callbackLogin" prompt auto-login>-->
          <!--              <button @click="googleLogin"-->
          <!--                      class="border border-red-google bg-white px-4 py-3 flex items-center justify-center ml-2 flex-grow">-->
          <!--                <img src="/images/google-btn.png"/>-->
          <!--              </button>-->
          <!--            </GoogleLogin>-->
          <!--          </div>-->
        </div>
        <div
            v-if="signupSuccessful"
            class="lg:w-1/2 w-full flex flex-col justify-center text-center relative"
        >
          <h5 class="text-xl font-display mt-2 mb-10 text-center">
            Your registration is Successfull.
          </h5>
          <button
              @click.prevent="showSignInModal"
              class="bg-blue uppercase text-white p-4 mb-8"
          >
            Login
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>

//
// import {decodeCredential, googleAuthCodeLogin, GoogleLogin} from "vue3-google-login";
import Modal from "@/components/Modal";

export default {
  name: 'Homes',
  props: {
    msg: String
  },
  components: {
    Modal,
    // GoogleLogin,
  },
  data() {
    return {
      toggleBtn: false,
      active: false,
      // hamburgerOpen: false,
      isModalVisible: false,
      isSignupModal: false,
      signupSuccessful: false,
      isForgotPasswordModal: false,
      params: {
        make: "",
        model: "",
        series: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      zip: "",
      password: "",
      confirmPassword: "",
      errors: [],
      loginEmail: "",
      loginPassword: "",
      messages: {},
      wishlistCarsCount: '',
      searchHistoryCount: ''
    };
  },
  computed: {
    // ...mapGetters({
    //   query: "searchQuery",
    // }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.currentUser) {
      this.wishlistCount();
      this.searchHistoryListCount();
    }

    let vid = document.getElementById("banner-video");
    if (vid) {
      vid.ontimeupdate = function () {
        let percentage = (vid.currentTime / vid.duration) * 100;
        let menu = document.getElementById('custom-seekbars');
        menu.style.width = percentage + "%";
      };
    }

    window.addEventListener("resize", this.myEventHandler);

    this.emitter.on("showLogin", (value) => {
      if (value) {
        console.log(value);
      }
      this.showSignInModal();
    });
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },

    profile() {
      this.$router.push("/profileinfo");
    },

    showPassword() {
      let x = document.getElementById("password");
      let icon = document.getElementById("password-icon");
      if (x.type === "password") {
        x.type = "text";
        icon.classList.remove('show-password');
        icon.className = "icon hide-password small";
      } else {
        x.type = "password";
        icon.classList.remove('hide-password');
        icon.className = "icon show-password small";

      }
    },

    hamburger() {
      this.hamburgerOpen = !this.hamburgerOpen;
      const element = document.getElementById("hamburger");
      element.classList.add("hamburger--is-open");
    },
    setActive() {
      this.active = !this.active;
      const element = document.getElementById("hamburger");
      element.classList.remove("hamburger--is-open");
    },
    // closeModal() {
    //   this.isThankYouModalVisible = false;
    //   document.body.classList.remove("hamburger--is-open");
    // },

    // callbackLogin(response) {
    //   console.log(response);
    //   const userData = decodeCredential(response.credential)
    //   console.log('methods section ----', userData);
    // },


    showSignInModal() {
      window.gtag('event', 'sign_in'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      this.signupSuccessful = false;
      document.body.classList.add("overflow-hidden");
    },
    showSignUpModal() {
      window.gtag('event', 'sign_up'); /* google analytic */

      this.isModalVisible = true;
      this.isSignupModal = true;
      this.isForgotPasswordModal = false;
      document.body.classList.add("overflow-hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      document.body.classList.remove("overflow-hidden");
    },
    registerUser() {
      if (!this.validateRegisterForm()) {
        return;
      }
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        zip_code: this.zip,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/register", params).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.signupSuccessful = true;
            this.loading = false;
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              for (const key in error.response.data.errors) {
                this.errors[key] = error.response.data.errors[key].join(", ");
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong!";
            }
            this.signupSuccessful = false;
            this.loading = false;
          }
      );
    },

    showForgotPassword() {
      window.gtag('event', 'forgot_password'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = true;
      document.body.classList.add("overflow-hidden");
    },

    forgotPassword() {
      console.log("here");
      if (!this.validateForgotForm()) {
        return;
      }
      console.log("here ...");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/forgotPassword", this.loginEmail).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.loginEmail = "";
            this.loginPassword = "";
            this.initializeErrors();
            this.messages["info"] =
                "Thank you. An email with password reset link has been sent!";
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },

    signin() {
      if (!this.validateLoginForm()) {
        return;
      }
      const params = {
        email: this.loginEmail,
        password: this.loginPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/login", params).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.closeModal();
            this.loginEmail = "";
            this.loginPassword = "";
            this.toggleBtn = false;
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },
    initializeErrors() {
      this.errors = {};
      this.messages = {};
    },

    validateRegisterForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.firstName) {
        isValid = false;
        this.setInvalidFormField("firstName", "First Name is required");
      }
      if (!this.lastName) {
        isValid = false;
        this.setInvalidFormField("lastName", "Last Name is required");
      }

      if (!this.email || !this.validateEmail(this.email)) {
        isValid = false;
        this.setInvalidFormField("email", "A valid email is required");
      }

      if (!this.phone || !this.validatePhone(this.phone)) {
        isValid = false;
        this.setInvalidFormField("phone", "A valid phone is required");
      }

      if (!this.zip) {
        isValid = false;
        this.setInvalidFormField("zip", "Zip Code is required");
      }

      if (!this.password || this.password.length < 8) {
        isValid = false;
        this.setInvalidFormField(
            "password",
            "Password is required & must be at least 8 characters long"
        );
      }
      if (this.confirmPassword != this.password) {
        isValid = false;
        this.setInvalidFormField(
            "confirmPassword",
            "Confirm Password doesnt match the Password"
        );
      }
      return isValid;
    },

    validateForgotForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail) {
        isValid = false;
        this.setInvalidFormField("general", "Registered email is required");
      }
      return isValid;
    },

    validateLoginForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail || !this.loginPassword) {
        isValid = false;
        this.setInvalidFormField(
            "general",
            "Login email & Password are required"
        );
      }
      return isValid;
    },

    setInvalidFormField(field, message) {
      this.errors[field] = message;
    },

    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
          email
      );
    },
    validatePhone(phone) {
      // eslint-disable-next-line
      return /^\d{8,15}$/.test(phone);
    },


    wishlistCount() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("wishlistCount", user_id).then(
          (res) => {
            this.wishlistCarsCount = res.data.data
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    searchHistoryListCount() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("searchHistoryCount", user_id).then(
          (res) => {
            this.searchHistoryCount = res.data.data
            console.log(this.searchHistoryCount);
            console.log('suucreeeee');
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },


    // async googleLogin() {
    //   console.log("clicked test");
    //   try {
    //     const response = await googleAuthCodeLogin();
    //     console.log("Handle the response", response);
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   console.log("exit");
    // },
  },
}
</script>
<style scoped>

.hamburger-mb.hamburger--is-open:before {
  top: 8px;
  left: 17px;
  background: #0d213a;
  transform: translateY(6px) rotate(135deg);
}

.hamburger-mb.hamburger--is-open:after {
  background: #0d213a;
  transform: translateY(-6px) rotate(45deg);
  bottom: 10px;
  left: 17px;
}

.hamburger-mb {
  width: 55px;
  position: relative;
  display: flex;
  border: 0;
  cursor: pointer;
  pointer-events: all;
  outline: none;
}

.menu-border-mb {
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.hamburger-mb:before {
  content: '';
  position: absolute;
  top: 10px;
  background: #ffffff;
  transition: transform 300ms ease-out;
  left: 16px;
  width: 18px;
  height: 2px !important;
}

.hamburger-mb:after {
  position: absolute;
  top: 20px;
  background: #ffffff;
  bottom: 9px;
  transition: transform 300ms ease-out;
  content: "";
  left: 22px;
  width: 18px;
  height: 2px;
}

.bg-banner {
  background-image: url("/images/landing-page/new-banner.png");
  background-size: cover;
  /*box-shadow: inset 715px -19px 100px -120px #121a3ca6;*/
}

.banner-image {
  background-image: url("/images/landing-page/new-banner.png");
}

/*custom seekbar loader*/
#custom-seekbar {
  cursor: pointer;
  height: 4px;
  overflow: hidden;
  position: absolute;
  width: 91%;
  top: 57px;
  left: 0;
}

#custom-seekbars {
  background-color: #008de9;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0px;
}


#m-seekbar {
  cursor: pointer;
  height: 2px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0;
}

#m-seekbars {
  background-color: #008de9;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0px;
}


/* following rule is for hiding Stack Overflow's console  */
.as-console-wrapper {
  display: none !important;
}

/*end custom seekbar loader*/


.tiempos {
  font-family: 'Tiempos Headline', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif;
}

.text-blue {
  color: #0d213a;
}

.active {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
}

.tiempos {
  font-family: 'Tiempos Headline', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif;
}

.home-bg {
  background-image: url('../assets/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 84vh;
  background-position: center center;
}

.topleft-clip {
  background-color: #008de9;
  width: 200px;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

.topright-clip {
  background-color: #008de9;
  width: 600px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
}

.topleft-clip {
  background-color: #008de9;
  width: 200px;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

.btn-bg {
  background-color: #008de9;
  /*background-color: #0d213a;*/
  height: 35px;
  min-width: 165px;
  text-align: center;
  clip-path: polygon(0% 0, 87% 0, 100% 100%, 15% 100%);
}

.btn-bg:hover {
  background-color: #0163a3;
}


.menu-border-mb {
  background-image: url(/images/landing-page/menu-blue-border.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  min-height: 28px;
  margin-top: -3px;
  display: inline-block;
  min-width: 55px;
  text-align: center;
  color: white;
  line-height: 44px;
}

.menu-border {
  background-image: url(/images/landing-page/menu-blue-border.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  min-height: 35px;
  margin-top: -3px;
  display: inline-block;
  min-width: 55px;
  text-align: center;
  color: white;
  line-height: 44px;
}

.learn-more-btn {
  background-image: url(/images/landing-page/btn-blue-dark.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 165px;
  display: inline-block;
  width: 165px;
  height: 35px;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
}

.learn-more-btn-white {
  background-image: url(/images/landing-page/btn-blue-border.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 165px;
  display: inline-block;
  width: 165px;
  height: 35px;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
}

h1 {
  color: #ffffff;
}

/*button {*/
/*  border: none;*/
/*  background-color: #9370db;*/
/*  border-radius: 2px;*/
/*  height: 2em;*/
/*  color: #ffffff;*/
/*  text-transform: uppercase;*/
/*}*/

/* video styles */
.banner-video {
  width: 100%;
  /*height: 100vh;*/
  object-fit: cover;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
}

.header {
  position: relative;
  height: 100vh;
  text-align: center;
  justify-content: center;
}

.nav-brand-bg {
  clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
}

.text-blue {
  color: #0d213a;
}

.font-size-9 {
  font-size: 9px;
}
@media (max-width: 1023px) {
  .bg-banner{
    height: 55rem;
  }
}
@media (max-width: 767px) {
  #custom-seekbar {
    top: 45px !important;
    width: 96% !important;
  }

  .bg-banner{
    height: 35rem;
  }
  .icon-sec {
    font-size: 8px !important;
  }

  .menu-border {
    min-height: 28px !important;
  }

  .hamburger:before {
    content: '';
    position: absolute;
    left: 18px !important;
    width: 18px !important;
    height: 2px !important;
  }

  .hamburger:after {
    content: '';
    position: absolute;
    left: 22px !important;
    width: 18px !important;
    height: 2px !important;
  }

  .btn-bg {
    min-width: 160px;
    height: 34px !important;
    padding-top: 5px;
  }

  .learn-more-btn {
    background-size: 168px !important;
    width: 184px !important;
    height: 38px !important;
  }

  .learn-more-btn-white {
    background-size: 177px !important;
    width: 184px !important;
    height: 38px !important;
  }

  .topright-clip {
    width: 270px;
    height: 10px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
  }

  .fairpy-width {
    width: 230px;
  }

  .logo-width {
    width: 70px;
    height: 20px;
  }

  .d-none {
    display: none;
  }

  .nav-brand-bg {
    clip-path: polygon(0 0, 93% 0%, 100% 100%, 0% 100%);
    width: 96%;
  }

  .hamburger:before {
    /*background: #ffffff;*/
    top: 9px;
  }

  .hamburger:after {
    /*background: #ffffff;*/
    bottom: 9px;
  }

  /*.hamburger.app {*/
  /*  border: 2px solid #ffffff;*/
  /*  height: 35px;*/
  /*}*/
  .hamburger {
    width: 55px;
  }
}

</style>