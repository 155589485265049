<template>
  <div class="w-100">
    <div class="w-100 shadow">
      <div class="container mx-auto flex flex-col  lg:flex-row justify-between text-xs px-5 lg:px-3 py-3">
        <!--      <div class="flex flex-col lg:flex-row lg:items-center justify-between">-->
        <!--        <div class="flex flex-row justify-between w-full lg:w-auto items-center flex-shrink-0">-->
        <a href="/investors">
          <img class="flex-shrink-0 h-6" src="/images/logo.png"/>
        </a>

        <div class="flex flex-row justify-end text-xs lg:text-sm xl:text-sm mt-4 lg:m-0 xl:m-0" style="color: #008de9">


          <a href="#" class="" @click.prevent="showSignInModal" v-if="!loggedIn">Sign In</a>
          <div class="dropdown inline-block relative" v-if="loggedIn">
            <button
                class="font-semibold  px-4 rounded inline-flex items-center text-blue-dark text-sm font-semibold uppercase">
              <span class="mr-1" v-if="loginData"><i class="icon md2 signin mr-1 signin"
              ></i>
                {{ loginData['first_name'] }}
              </span>
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </button>
            <ul class="dropdown-menu absolute hidden text-gray-400 pt-1 right-0">
              <li class=""><a
                  class="rounded-t bg-gray-200 hover:text-blue-700 py-2 px-4 text-blue-dark font-semibold block whitespace-no-wrap"
                  href="#" @click="logout">Log Out</a></li>
            </ul>
          </div>

          <span class=" h-4 border-l-2 border-blue mx-2" v-if="this.loggedIn"></span>
          <a href="#" class="h-4 border-blue mr-2" @click="agreementModel" v-if="this.loggedIn">Review Document</a>
          <span class=" h-4 border-l-2 border-blue mx-2"></span>
          <a href="/investors/invest-in-cars" class="mr-2 hidden">Invest In Cars</a>
          <span class=" h-4 border-l-2 border-blue mr-2 hidden"></span>
          <a href="/search" class="mr-2">Buy Cars</a>
          <span class=" h-4 border-l-2 border-blue mr-2"></span>
          <a href="/investors/about-us" class="mr-2">About Us</a>
          <span class=" h-4 border-l-2 border-blue mr-2"></span>
          <a href="/investors/faqs" class="mr-2">FAQs</a>
          <span class=" h-4 border-l-2 border-blue mr-2"></span>
          <a href="/investors/contact-us" class="">Contact Us</a>

        </div>
        <!--        </div>-->
        <!--      </div>-->
      </div>
    </div>
    <Modal
        bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-8 z-topmost"
        v-show="isModalVisible"
        @close="closeModal"
    >
      <template v-slot:header>
        <h1 v-if="isSignupModal && !signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Create Your
          FairPY Account</h1>
        <h1 v-if="!isSignupModal && !signupSuccessful && !isForgotPasswordModal && !changePassword && !agreementStatus"
            class="text-3xl font-display mt-2 mb-10 text-center">Hello, Welcome Back</h1>
        <h1 v-if="!isSignupModal && !signupSuccessful && isForgotPasswordModal && !agreementStatus"
            class="text-3xl font-display mt-2 mb-10 text-center">Frogot Password</h1>
        <h1 v-if="signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Thank You!</h1>

        <h1 v-if="!isSignupModal && !signupSuccessful && !isForgotPasswordModal && changePassword && !agreementStatus"
            class="text-3xl font-display mt-2 mb-10 text-center">Change Password</h1>

        <h1 v-if="!isSignupModal && !signupSuccessful && !isForgotPasswordModal && !changePassword && agreementStatus"
            class="text-3xl font-display mt-2 mb-6 text-center">Review Documents</h1>

      </template>


      <template v-slot:body>
        <div class="flex justify-center relative w-full">
          <div v-if="!signupSuccessful && !changePassword && !agreementStatus && !continueBtn"
               class="lg:w-1/2 w-full flex flex-col justify-center text-center relative">
            <div v-if="!isSignupModal && !isForgotPasswordModal && !changePassword" class="flex flex-col w-full">
              <div class="relative mb-8 text-left">
                <input v-model="loginEmail" class="border p-4 w-full pl-12" placeholder="Email"/>
                <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
                  <i class="icon email-outline small"></i>
                </div>
              </div>
              <div class="relative mb-8">
                <input v-model="loginPassword" class="border p-4 w-full pl-12" placeholder="Password" type="password"
                       id="password"
                />
                <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
                  <i class="icon password small"></i>
                </div>
                <div class="absolute right-0 top-0 bottom-0 flex justify-center items-center w-12"
                     @click="showPassword()">
                  <i class="icon show-password small" id="password-icon"></i>
                </div>
              </div>
              <div class="relative">
                <span v-if="errors['general']"
                      class="text-red-700 text-xs leading-tight block mb-8">{{ errors["general"] }}</span>
              </div>
              <button @click.prevent="signin" class="bg-blue uppercase text-white p-4 mb-8">
                Sign In
              </button>
              <a @click.prevent="showForgotPassword"
                 class="text-blue font-normal text-sm mb-6 cursor-pointer">Forgot Password?</a>
              <p class="text-sm text-black-light mb-8 hidden">
                Dont have FairPY investors account?<a
                  @click.prevent="showSignUpModal"
                  class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
              >Signup Now</a
              >
              </p>
            </div>

            <div v-if="isSignupModal && !isForgotPasswordModal && !changePassword" class="flex flex-col w-full">
              <div class="relative mb-4 flex items-start">
                <div class="relative mr-1 text-left w-1/2">
                  <input id="firstName" v-model="firstName"
                         :class="['border p-4 w-full', errors['firstName'] ? 'border-red-700' : '',]"
                         placeholder="First Name"/>
                  <span v-if="errors['firstName']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["firstName"] }}</span>
                </div>
                <div class="relative ml-1 w-1/2 text-left">
                  <input id="lastName" v-model="lastName"
                         :class="['border p-4 w-full', errors['lastName'] ? 'border-red-700' : '',]"
                         placeholder="Last Name"/>
                  <span v-if="errors['lastName']" class="text-red-700 text-xs leading-tight block">{{
                      errors["lastName"]
                    }}</span>
                </div>
              </div>
              <div class="relative mb-4 text-left">
                <input id="email" v-model="email"
                       :class="['border p-4 w-full', errors['email'] ? 'border-red-700' : '',]"
                       placeholder="Email" type="text"/>
                <span
                    v-if="errors['email']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["email"] }}</span
                >
              </div>
              <div class="relative mb-4 flex items-start text-left">
                <div class="relative mr-1 text-left w-1/2">
                  <input
                      id="phone"
                      v-model="phone"
                      :class="[
                'border p-4 w-full',
                errors['phone'] ? 'border-red-700' : '',
              ]"
                      placeholder="Phone Number"
                  />
                  <span
                      v-if="errors['phone']"
                      class="text-red-700 text-xs leading-tight block"
                  >{{ errors["phone"] }}</span
                  >
                </div>
                <div class="relative ml-1 text-left w-1/2">
                  <input
                      id="zip"
                      v-model="zip"
                      :class="[
                'border p-4 w-full',
                errors['zip'] ? 'border-red-700' : '',
              ]"
                      placeholder="Zip Code"
                  />
                  <span
                      v-if="errors['zip']"
                      class="text-red-700 text-xs leading-tight block"
                  >{{ errors["zip"] }}</span
                  >
                </div>
              </div>
              <div class="relative mb-4 text-left">
                <input
                    id="password"
                    v-model="password"
                    :class="[
              'border p-4 w-full',
              errors['password'] ? 'border-red-700' : '',
            ]"
                    placeholder="Password"
                    type="password"
                />
                <span
                    v-if="errors['password']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["password"] }}</span
                >
              </div>
              <div class="relative mb-4 text-left">
                <input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    :class="[
              'border p-4 w-full',
              errors['confirmPassword'] ? 'border-red-700' : '',
            ]"
                    placeholder="Confirm Password"
                    type="password"
                />
                <span
                    v-if="errors['confirmPassword']"
                    class="text-red-700 text-xs leading-tight block"
                >{{ errors["confirmPassword"] }}</span
                >
              </div>
              <div class="relative">
          <span
              v-if="errors['general']"
              class="text-red-700 text-xs leading-tight block mb-8"
          >{{ errors["general"] }}</span
          >
              </div>
              <button
                  @click.prevent="registerInvestors"
                  class="bg-blue uppercase text-white p-4 mb-8"
              >
                Create My Account
              </button>
              <p class="text-sm text-black-light mb-8">
                Already have a FairPY investors account?<a
                  href="#"
                  @click.prevent="showSignInModal"
                  class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
              >Sign In</a
              >
              </p>
            </div>

            <div v-if="!isSignupModal && isForgotPasswordModal && !changePassword" class="flex flex-col w-full">
              <div class="relative mb-8 text-left">
                <input v-model="loginEmail" class="border p-4 w-full pl-12" placeholder="Email"/>
                <div class="absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
                >
                  <i class="icon email-outline small"></i>
                </div>
              </div>
              <div class="relative">
          <span
              v-if="errors['general']"
              class="text-red-700 text-xs leading-tight block mb-8"
          >{{ errors["general"] }}</span
          >
                <div
                    v-if="messages['info']"
                    class="text-white text-sm bg-green-700 leading-tight block mb-8 p-4"
                >
                  {{ messages["info"] }}
                </div>
              </div>
              <button
                  @click.prevent="forgotPassword"
                  class="bg-blue uppercase text-white p-4 mb-8"
              >
                Reset Password
              </button>
              <a
                  @click.prevent="showSignInModal"
                  class="text-blue font-normal text-sm mb-6"
              >Back to Login?</a
              >
            </div>

            <!--      <div v-if="!isForgotPasswordModal" class="flex items-center mb-8">-->
            <!--        <hr class="flex-grow" />-->
            <!--        <p class="px-4 text-sm text-gray-400 font-light">Or connect with</p>-->
            <!--        <hr class="flex-grow" />-->
            <!--      </div>-->
            <!--      <div v-if="!isForgotPasswordModal" class="flex flex-row mb-8">-->
            <!--        <button class="border border-blue-fb bg-white px-4 py-3 flex items-center justify-center mr-2 flex-grow">-->
            <!--          <img src="/images/fb-btn.png" />-->
            <!--        </button>-->
            <!--        <GoogleLogin :callback="callbackLogin" prompt auto-login>-->
            <!--          <button @click="googleLogin"-->
            <!--            class="border border-red-google bg-white px-4 py-3 flex items-center justify-center ml-2 flex-grow">-->
            <!--            <img src="/images/google-btn.png" />-->
            <!--          </button>-->
            <!--        </GoogleLogin>-->
            <!--      </div>-->
          </div>
          <div
              v-if="signupSuccessful"
              class="lg:w-1/2 w-full flex flex-col justify-center text-center relative"
          >
            <h5 class="text-xl font-display mt-2 mb-10 text-center">
              Your registration is Successfull.
            </h5>
            <button
                @click.prevent="showSignInModal"
                class="bg-blue uppercase text-white p-4 mb-8"
            >
              Login
            </button>
          </div>

          <div v-if="continueBtn" class="lg:w-1/2 w-full flex flex-col justify-center text-center relative">
            <div class="relative">
                <span v-if="errors['general']"
                      class="text-red-700 text-xs leading-tight block mb-8">{{ errors["general"] }}</span>
              <div v-if="messages['info']"
                   class="text-white bg-green-700 text-sm leading-tight block mb-8 p-4 text-center">
                <div class="block">{{ messages["info"] }}</div>
                <div class="block">
                  <a class="p-2 inline-block mt-4 px-8 bg-white text-green-700 uppercase" href="#"
                     @click="agreementModel">Continue</a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="changePassword" class="lg:w-1/2 w-full flex flex-col justify-center text-center relative">

            <div class="flex flex-col w-full md:w-full mx-auto">
              <div class="relative mb-8 text-left">
                <input v-model="loginEmail" class="border p-4 w-full pl-12" placeholder="Email"/>
                <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
                  <i class="icon email-outline small"></i>
                </div>
              </div>
              <div class="relative mb-8">
                <input v-model="newPassword" class="border p-4 w-full pl-12" placeholder="Password" type="password"/>
                <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
                  <i class="icon password small"></i>
                </div>
<!--                <div class="absolute right-0 top-0 bottom-0 flex justify-center items-center w-12"-->
<!--                     @click="showPassword()">-->
<!--                  <i class="icon show-password small" id="password-icon"></i>-->
<!--                </div>-->
              </div>
              <div class="relative mb-8">
                <input v-model="confirmPassword" class="border p-4 w-full pl-12" placeholder="Confirm Password"
                       type="password"/>
                <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
                  <i class="icon password small"></i>
                </div>

              </div>
              <button @click.prevent="updatePassword" class="bg-blue uppercase text-white p-4 mb-8">
                Change Password
              </button>
            </div>
          </div>
        </div>

        <div v-if="agreementStatus" class="lg:w-9/12 w-full flex flex-col justify-center text-center relative">
          <div class="flex flex-col w-full md:w-full mx-auto">
            <div class="relative mb-8 text-left">
              <!--              <h2 class="text-xl font-semibold">Review Documents</h2>-->

              <div class="">
                <div class="flex  justify-between mt-4">
                  <div class="form-check-label font-semibold text-gray-800" for="flexCheckDefault">
                    <a href="images/investors/operating_agreement.pdf" target="_blank" class="hover:text-blue-700"> 1. Operating
                      Agreement <span class="font-semibold text-red-700 align-top">*</span></a>
                  </div>

                  <div>
                    <a href="/investors/purchase-agreement" target="_blank"><i class="icon view-document mr-4"
                                                                               id="password-icon" title="View Document"></i></a>
                    <input
                        class="form-check-input  h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox" id="terms" name="terms" v-model="termsState" @change="handleTermsState" v-if="this.investor.agreement_status != 1">
                  </div>
                </div>

                <div class="flex  justify-between mt-2">
                  <div class="form-check-label font-semibold  text-gray-800" for="flexCheckDefault">
                    <a href="/investors/risk-factors" target="_blank" class="hover:text-blue-700"> 2. Risk Factors</a>
                    <div></div>
                  </div>
                  <div>
                    <a href="/investors/risk-factors" target="_blank"><i class="mr-4 icon view-document"
                                                                         id="password-icon" title="View Document"></i></a>
                    <input
                        class="form-check-input  h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox" value="" target="_blank" id="flexCheckDefault" v-if="this.investor.agreement_status != 1">
                  </div>
                </div>


                <div class="flex  justify-between mt-2">

                  <div class="form-check-label font-semibold  text-gray-800">
                    <a href="/investors/offering-memorandum" target="_blank" class="hover:text-blue-700">3.
                      Offering-Memorandum</a>
                  </div>
                  <div>
                    <a href="/investors/offering-memorandum" target="_blank"><i class="mr-4 icon view-document"
                                                                  id="password-icon" title="View Document"></i></a>

                    <input
                        class="form-check-input  h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox" value="" id="flexCheckDefault" v-if="this.investor.agreement_status != 1">
                  </div>
                </div>


<!--                <div class="flex  justify-between mt-2">-->

<!--&lt;!&ndash;                  <div class="form-check-label font-semibold  text-gray-800">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a href="/investors/offering-memorandum" target="_blank" class="hover:text-blue-700">4.&ndash;&gt;-->
<!--&lt;!&ndash;                      Partnership Agreement</a>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  <div>-->
<!--                    <a href="/investors/partnership-agreement" target="_blank"><i class="mr-4 icon view-document"-->
<!--                                                                                id="password-icon" title="View Document"></i></a>-->

<!--                    <input-->
<!--                        class="form-check-input  h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"-->
<!--                        type="checkbox" value="" id="flexCheckDefault" v-if="this.investor.agreement_status != 1">-->
<!--                  </div>-->
<!--                </div>-->



                <hr class="w-full my-3"/>
                <div class="">The Documents generated using the below information</div>
                <hr class="w-full my-3"/>

                <div class="flex">
                  <div class="w-5/12">Full Name</div>
                  <div class="w-5/12">{{ this.loginData.first_name + ' ' + this.loginData.last_name }}</div>
                </div>
                <hr class="w-full my-3"/>
                <div class="flex">
                  <div class="w-5/12">Offering Name</div>
                  <div class="w-5/12">FairReturns Series Jan23</div>
                </div>

                <hr class="w-full my-3"/>
                <div class="flex">
                  <div class="w-5/12">Total Amount</div>
                  <div class="w-5/12">{{ this.totalAmount }}</div>
                </div>
                <p style="color: red" class="for-error terms-error" v-if="termsError">Please review the purchase
                  agreement and agree.</p>
                <div class="flex justify-center mt-6" v-if="this.investor.agreement_status != 1">
                  <button class="w-40 p-2 bg-blue-300 text-white text-center uppercase font-semibold space-x-4"
                          @click="agreeSubmit" v-if="agreeStatus" disabled>Agree
                  </button>
                  <button class="w-40 p-2 bg-blue-700 text-white text-center uppercase font-semibold space-x-4"
                          @click="agreeSubmit" v-if="!agreeStatus">Agree
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from "@/components/Modal";


export default {
  name: "TopBar",
  components: {
    Modal,
  },
  data() {
    return {
      // query: "",
      userData: "",
      isModalVisible: false,
      isSignupModal: false,
      signupSuccessful: false,
      isForgotPasswordModal: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      zip: "",
      password: "",
      confirmPassword: "",
      errors: [],
      loginEmail: "",
      loginPassword: "",
      messages: {},
      loginData: this.$store.state.investorAuth.investors,
      changePassword: false,
      agreementStatus: false,
      agreeStatus: true,
      investorData: '',
      getEnquiry: '',
      totalAmount: '',
      newPassword: "",
      token: "",
      investorId: '',
      termsState: false,
      validated: false,
      continueBtn: false,
      agreeBtn: false,
      investor: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.investorAuth.status.loggedIn;
    },
    termsError() {
      return this.validated && !this.termsState
    }
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    if (this.loggedIn) {
      this.getInvestorEnquiry();
      this.getInvestor();
    }

    this.emitter.on("showLogin", (value) => {
      if (value) {
        console.log(value);
      }
      this.showSignInModal();
    });

  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    showPassword() {
      let x = document.getElementById("password");
      let icon = document.getElementById("password-icon");
      if (x.type === "password") {
        x.type = "text";
        icon.classList.remove('show-password');
        icon.className = "icon hide-password small";
      } else {
        x.type = "password";
        icon.classList.remove('hide-password');
        icon.className = "icon show-password small";

      }
    },

    showSignInModal() {
      window.gtag('event', 'sign_in'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      this.signupSuccessful = false;
      this.agreementStatus = false;
      document.body.classList.add("overflow-hidden");
    },
    showSignUpModal() {
      window.gtag('event', 'sign_up'); /* google analytic */

      this.isModalVisible = true;
      this.isSignupModal = true;
      this.isForgotPasswordModal = false;
      document.body.classList.add("overflow-hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      document.body.classList.remove("overflow-hidden");
    },
    registerInvestors() {
      if (!this.validateRegisterForm()) {
        return;
      }
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        zip_code: this.zip,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };
      // this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("investorAuth/register", params).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.signupSuccessful = true;
            this.loading = false;
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              for (const key in error.response.data.errors) {
                this.errors[key] = error.response.data.errors[key].join(", ");
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong!";
            }
            this.signupSuccessful = false;
            this.loading = false;
          }
      );
    },

    showForgotPassword() {
      window.gtag('event', 'forgot_password'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = true;
      document.body.classList.add("overflow-hidden");
    },

    forgotPassword() {
      if (!this.validateForgotForm()) {
        return;
      }
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("investorAuth/forgotPassword", this.loginEmail).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.loginEmail = "";
            this.loginPassword = "";
            this.initializeErrors();
            this.messages["info"] =
                "Thank you. An email with password reset link has been sent!";
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },

    signin() {
      if (!this.validateLoginForm()) {
        return;
      }
      const params = {
        email: this.loginEmail,
        password: this.loginPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("investorAuth/login", params).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.closeModal();
            this.loginData = data;
            // this.isModalVisible = false;
            this.loginEmail = "";
            this.loginPassword = "";
            this.passwordStatus();
            this.getInvestorEnquiry();
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },

    updatePassword() {
      if (!this.validateForm()) {
        return;
      }
      const params = {
        // token: this.token,
        email: this.loginEmail,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
        id: this.loginData['id'],
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("investorAuth/resetPassword", params).then(
          (data) => {
            console.log(data);
            this.$store.dispatch("setIsLoading", false);
            this.loginEmail = "";
            this.newPassword = "";
            this.confirmPassword = "";
            this.investorId = this.loginData.id;
            this.changePassword = false;
            this.continueBtn = true;
            this.agreementStatus = false;
            this.agreeStatus = true;
            this.messages["info"] = "Password has been reset successfully!";
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },

    isChangePassword() {
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      this.changePassword = true;
    },
    agreementModel() {
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      this.changePassword = false;
      this.continueBtn = false;
      this.agreementStatus = true;
      this.getInvestor();
    },

    passwordStatus() {
      // this.$store.dispatch("setIsLoading", true);
        this.$store.dispatch("investorPasswordStatus", this.loginData['id']).then(
            (data) => {
              const resData = data.data.data;
              if (resData.password_status == 0) {
                this.isChangePassword();
              }
              this.$store.dispatch("setIsLoading", false);

            },
            (error) => {
              this.$store.dispatch("setIsLoading", false);
              if (error.response) {
                console.log(error.response);
                if (error.response.data.message) {
                  this.errors["general"] = error.response.data.message;
                }
                if (!this.errors.general) {
                  this.errors["general"] = error.response.statusText;
                }
              } else {
                console.log(error.request);
                this.errors["general"] = "Sorry! Something went wrong! ";
              }
            }
        );

    },

    getInvestorEnquiry() {
      console.log("here");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("getInvestorEnquiry", this.loginData.id).then(
          (data) => {
            let formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            // console.log('data-------------------------');
            console.log(data.data.enquiry);
            this.getEnquiry = data.data.enquiry;
            this.totalAmount = formatter.format(this.getEnquiry.amount);
            this.$store.dispatch("setIsLoading", false);

          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },


    getInvestor() {
      console.log("here");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("getInvestor", this.loginData.id).then(
          (data) => {
            // console.log('data-------------------------');
            console.log(data.data.investor);
            this.investor = data.data.investor;
            this.$store.dispatch("setIsLoading", false);

          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },

    handleTermsState(e) {
      // console.log(e.target.checked);
      if (e.target.checked){
        this.agreeStatus = false;
        console.log(e.target.checked);
        console.log(this.agreeBtn);

      } else {
        console.log(e.target.checked);
        this.agreeStatus = true;
        console.log(this.agreeBtn);
      }
      this.validated = false;
      // this.agreeBtn = true;
    },

    // agreeSubmit() {
    //   this.validated = true
    // },

    agreeSubmit() {
      this.validated = true
      console.log("here agreement status");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("storeAgreement", this.loginData.id).then(
          (data) => {
            console.log(data);
            this.closeModal();
            this.agreeStatus = false;
            this.getInvestor();
            window.location = "/investors/faqs";
            // this.$store.dispatch("setIsLoading", false);
            // window.location.reload();

          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },


    logout() {
      this.$store.dispatch("investorAuth/logout");
    },

    initializeErrors() {
      this.errors = {};
      this.messages = {};
    },

    validateForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail) {
        this.setInvalidFormField("general", "Email id is required");
        return false;
      }
      if (!this.newPassword) {
        this.setInvalidFormField("general", "Password is required");
        return false;
      }
      if (this.newPassword != this.confirmPassword) {
        this.setInvalidFormField(
            "general",
            "Password & Confirm password must match!"
        );
        return false;
      }
      return isValid;
    },

    validateRegisterForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.firstName) {
        isValid = false;
        this.setInvalidFormField("firstName", "First Name is required");
      }
      if (!this.lastName) {
        isValid = false;
        this.setInvalidFormField("lastName", "Last Name is required");
      }

      if (!this.email || !this.validateEmail(this.email)) {
        isValid = false;
        this.setInvalidFormField("email", "A valid email is required");
      }

      if (!this.phone || !this.validatePhone(this.phone)) {
        isValid = false;
        this.setInvalidFormField("phone", "A valid phone is required");
      }

      if (!this.zip) {
        isValid = false;
        this.setInvalidFormField("zip", "Zip Code is required");
      }

      if (!this.password || this.password.length < 8) {
        isValid = false;
        this.setInvalidFormField(
            "password",
            "Password is required & must be at least 8 characters long"
        );
      }
      if (this.confirmPassword != this.password) {
        isValid = false;
        this.setInvalidFormField(
            "confirmPassword",
            "Confirm Password doesnt match the Password"
        );
      }
      return isValid;
    },

    validateForgotForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail) {
        isValid = false;
        this.setInvalidFormField("general", "Registered email is required");
      }
      return isValid;
    },

    validateLoginForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail || !this.loginPassword) {
        isValid = false;
        this.setInvalidFormField(
            "general",
            "Login email & Password are required"
        );
      }
      return isValid;
    },

    setInvalidFormField(field, message) {
      this.errors[field] = message;
    },

    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
          email
      );
    },
    validatePhone(phone) {
      // eslint-disable-next-line
      return /^\d{8,15}$/.test(phone);
    },
  },
};
</script>
<style>
.icon.md2 {
  width: 15px;
  height: 15px;
  vertical-align: baseline;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media (min-width: 640px) {
  .find-car-btn {
    display: none !important;
  }
}

@media (max-width: 640px) {
  .search-box {
    display: none !important;
  }
}

</style>
