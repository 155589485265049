<template>
  <Home/>
  <Find/>
  <!--    <whole/>-->
  <How/>
  <Top/>
  <Why/>
  <Finance/>
  <!--    <Our/>-->
  <!--    <Safety/>-->
  <Faq/>
  <Foot/>
</template>
<script>
import Home from '../components/Homes.vue'
// import whole from '../components/Whole-worlds.vue'
import How from '../components/How-it-works.vue'
import Top from '../components/Top-car-deals.vue'
import Find from '../components/Find-car.vue'
import Why from '../components/Why-choose.vue'
import Finance from '../components/Finance-option.vue'
// import Our from '../components/Our-stats.vue'
// import Safety from '../components/Safety.vue'
import Faq from '../components/Faq.vue'
import Foot from '../components/Foots.vue'

export default {
  name: 'Landings',
  components: {
    Home,
    // whole,
    How,
    Top,
    Find,
    Why,
    Finance,
    // Our,
    // Safety,
    Faq,
    Foot
  }
}
</script>