<template>
  <div class="w-100">
    <div
      class="container mx-auto flex flex-col lg:flex-row justify-between text-white text-xs px-5 lg:px-3 py-7"
    >
      <div
        class="flex flex-col lg:flex-row lg:items-center items-start flex-grow"
      >
        <div
          class="flex flex-row justify-between w-full lg:w-auto items-center flex-shrink-0"
        >
          <Logo />
          <div class="flex flex-row justify-end lg:hidden">
            <a @click="showLuckyDrawModal" v-if="canShowLuckyDrawButton()" href="#">
              <i class="icon giftbox-outline regular mr-6"></i>
            </a>
            <a @click="toggleSearchMethod" href="#">
              <i class="icon search regular"></i>
            </a>
            <a @click="toggleNavMethod" href="#" class="ml-6">
              <i class="icon menu regular"></i>
            </a>
          </div>
        </div>
        <SearchBox v-show="toggleSearch" classes="relative flex-grow w-full lg:ml-8 lg:mr-32 mt-4 lg:mt-0 search-box"/>
         <HrefButton  v-show="toggleSearch"
          :onClicked="gotoSearch"
          size="default"
          label="Find Your Car"
          class="bg-blue border-none lg:ml-6 mt-4 lg:mt-0 lg:py-0 py-3 w-full find-car-btn"
          icon="find-car"
        />
      </div>
      <div
        v-show="toggleNav"
        class="flex lg:flex-row flex-col lg:items-center items-stretch"
      >
        <HrefButton
          :onClicked="showLuckyDrawModal"
          size="md"
          v-if="canShowLuckyDrawButton()"
          label="Chance To Win A Car!!"
          class="border-red-600 btn-confetti-bg bg-red-600 mr-6 text-white mt-4 lg:mt-0 lg:py-0 py-3"
          icon="giftbox"
        />
        <HrefButton
          v-if="!loggedIn"
          :onClicked="showSignInModal"
          size="md"
          label="Sign In"
          class="border-blue-dark text-blue-dark mt-4 lg:mt-0 lg:py-0 py-3"
          icon="signin"
        />
        <HrefButton
          :onClicked="gotoSearch"
          size="default"
          label="Find Your Car"
          class="bg-blue border-none lg:ml-6 mt-4 lg:mt-0 lg:py-0 py-3"
          icon="find-car"
        />
      </div>
    </div>
<Modal
  bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-8"
  v-show="isModalVisible"
  @close="closeModal"
>
  <template v-slot:header>
    <h1 v-if="isSignupModal && !signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Create Your FairPY Account</h1>
    <h1 v-if="!isSignupModal && !signupSuccessful && !isForgotPasswordModal" class="text-3xl font-display mt-2 mb-10 text-center">Hello, Welcome Back</h1>
    <h1 v-if="!isSignupModal && !signupSuccessful && isForgotPasswordModal" class="text-3xl font-display mt-2 mb-10 text-center">Frogot Password</h1>
    <h1 v-if="signupSuccessful" class="text-3xl font-display mt-2 mb-10 text-center">Thank You!</h1>
  </template>

  <template v-slot:body>
  <div class="flex justify-center relative w-full">
    <div
      v-if="!signupSuccessful"
      class="lg:w-1/2 w-full flex flex-col justify-center text-center relative"
    >
      <div
        v-if="!isSignupModal && !isForgotPasswordModal"
        class="flex flex-col w-full"
      >
        <div class="relative mb-8 text-left">
          <input
            v-model="loginEmail"
            class="border p-4 w-full pl-12"
            placeholder="Email"
          />
          <div
            class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon email-outline small"></i>
          </div>
        </div>
        <div class="relative mb-8">
          <input
            v-model="loginPassword"
            class="border p-4 w-full pl-12"
            placeholder="Password"
            type="password"
            id="password"
          />
          <div class="absolute left-0 top-0 bottom-0 flex justify-center items-center w-12">
            <i class="icon password small"></i>
          </div>
          <div class="absolute right-0 top-0 bottom-0 flex justify-center items-center w-12" @click="showPassword()">
            <i class="icon show-password small" id="password-icon"></i>
          </div>
        </div>
        <div class="relative">
          <span
            v-if="errors['general']"
            class="text-red-700 text-xs leading-tight block mb-8"
            >{{ errors["general"] }}</span
          >
        </div>
        <button
          @click.prevent="signin"
          class="bg-blue uppercase text-white p-4 mb-8"
        >
          Sign In
        </button>
        <a
          @click.prevent="showForgotPassword"
          class="text-blue font-normal text-sm mb-6 cursor-pointer"
          >Forgot Password?</a
        >
        <p class="text-sm text-black-light mb-8">
          Dont have FairPY account?<a
            @click.prevent="showSignUpModal"
            class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
            >Signup Now</a
          >
        </p>
      </div>

      <div
        v-if="isSignupModal && !isForgotPasswordModal"
        class="flex flex-col w-full"
      >
        <div class="relative mb-4 flex items-start">
          <div class="relative mr-1 text-left w-1/2">
            <input
              id="firstName"
              v-model="firstName"
              :class="[
                'border p-4 w-full',
                errors['firstName'] ? 'border-red-700' : '',
              ]"
              placeholder="First Name"
            />
            <span
              v-if="errors['firstName']"
              class="text-red-700 text-xs leading-tight block"
              >{{ errors["firstName"] }}</span
            >
          </div>
          <div class="relative ml-1 w-1/2 text-left">
            <input
              id="lastName"
              v-model="lastName"
              :class="[
                'border p-4 w-full',
                errors['lastName'] ? 'border-red-700' : '',
              ]"
              placeholder="Last Name"
            />
            <span
              v-if="errors['lastName']"
              class="text-red-700 text-xs leading-tight block"
              >{{ errors["lastName"] }}</span
            >
          </div>
        </div>
        <div class="relative mb-4 text-left">
          <input
            id="email"
            v-model="email"
            :class="[
              'border p-4 w-full',
              errors['email'] ? 'border-red-700' : '',
            ]"
            placeholder="Email"
            type="text"
          />
          <span
            v-if="errors['email']"
            class="text-red-700 text-xs leading-tight block"
            >{{ errors["email"] }}</span
          >
        </div>
        <div class="relative mb-4 flex items-start text-left">
          <div class="relative mr-1 text-left w-1/2">
            <input
              id="phone"
              v-model="phone"
              :class="[
                'border p-4 w-full',
                errors['phone'] ? 'border-red-700' : '',
              ]"
              placeholder="Phone Number"
            />
            <span
              v-if="errors['phone']"
              class="text-red-700 text-xs leading-tight block"
              >{{ errors["phone"] }}</span
            >
          </div>
          <div class="relative ml-1 text-left w-1/2">
            <input
              id="zip"
              v-model="zip"
              :class="[
                'border p-4 w-full',
                errors['zip'] ? 'border-red-700' : '',
              ]"
              placeholder="Zip Code"
            />
            <span
              v-if="errors['zip']"
              class="text-red-700 text-xs leading-tight block"
              >{{ errors["zip"] }}</span
            >
          </div>
        </div>
        <div class="relative mb-4 text-left">
          <input
            id="password"
            v-model="password"
            :class="[
              'border p-4 w-full',
              errors['password'] ? 'border-red-700' : '',
            ]"
            placeholder="Password"
            type="password"
          />
          <span
            v-if="errors['password']"
            class="text-red-700 text-xs leading-tight block"
            >{{ errors["password"] }}</span
          >
        </div>
        <div class="relative mb-4 text-left">
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            :class="[
              'border p-4 w-full',
              errors['confirmPassword'] ? 'border-red-700' : '',
            ]"
            placeholder="Confirm Password"
            type="password"
          />
          <span
            v-if="errors['confirmPassword']"
            class="text-red-700 text-xs leading-tight block"
            >{{ errors["confirmPassword"] }}</span
          >
        </div>
        <div class="relative">
          <span
            v-if="errors['general']"
            class="text-red-700 text-xs leading-tight block mb-8"
            >{{ errors["general"] }}</span
          >
        </div>
        <button
          @click.prevent="registerUser"
          class="bg-blue uppercase text-white p-4 mb-8"
        >
          Create My Account
        </button>
        <p class="text-sm text-black-light mb-8">
          Already have a FairPY account?<a
            href="#"
            @click.prevent="showSignInModal"
            class="text-blue font-normal text-sm mb-8 ml-2 cursor-pointer"
            >Sign In</a
          >
        </p>
      </div>

      <div
        v-if="!isSignupModal && isForgotPasswordModal"
        class="flex flex-col w-full"
      >
        <div class="relative mb-8 text-left">
          <input
            v-model="loginEmail"
            class="border p-4 w-full pl-12"
            placeholder="Email"
          />
          <div
            class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon email-outline small"></i>
          </div>
        </div>
        <div class="relative">
          <span
            v-if="errors['general']"
            class="text-red-700 text-xs leading-tight block mb-8"
            >{{ errors["general"] }}</span
          >
          <div
            v-if="messages['info']"
            class="text-white text-sm bg-green-700 leading-tight block mb-8 p-4"
          >
            {{ messages["info"] }}
          </div>
        </div>
        <button
          @click.prevent="forgotPassword"
          class="bg-blue uppercase text-white p-4 mb-8"
        >
          Reset Password
        </button>
        <a
          @click.prevent="showSignInModal"
          class="text-blue font-normal text-sm mb-6"
          >Back to Login?</a
        >
      </div>

<!--      <div v-if="!isForgotPasswordModal" class="flex items-center mb-8">-->
<!--        <hr class="flex-grow" />-->
<!--        <p class="px-4 text-sm text-gray-400 font-light">Or connect with</p>-->
<!--        <hr class="flex-grow" />-->
<!--      </div>-->
<!--      <div v-if="!isForgotPasswordModal" class="flex flex-row mb-8">-->
<!--        <button class="border border-blue-fb bg-white px-4 py-3 flex items-center justify-center mr-2 flex-grow">-->
<!--          <img src="/images/fb-btn.png" />-->
<!--        </button>-->
<!--        <GoogleLogin :callback="callbackLogin" prompt auto-login>-->
<!--          <button @click="googleLogin"-->
<!--            class="border border-red-google bg-white px-4 py-3 flex items-center justify-center ml-2 flex-grow">-->
<!--            <img src="/images/google-btn.png" />-->
<!--          </button>-->
<!--        </GoogleLogin>-->
<!--      </div>-->
    </div>
    <div
      v-if="signupSuccessful"
      class="lg:w-1/2 w-full flex flex-col justify-center text-center relative"
    >
      <h5 class="text-xl font-display mt-2 mb-10 text-center">
        Your registration is Successfull.
      </h5>
      <button
        @click.prevent="showSignInModal"
        class="bg-blue uppercase text-white p-4 mb-8"
      >
        Login
      </button>
    </div>
  </div>
</template>
</Modal>
  </div>
</template>
<script>
// import { googleAuthCodeLogin, GoogleLogin } from "vue3-google-login";
import { mapGetters } from "vuex";
import HrefButton from "./HrefButton.vue";
import Logo from "./Logo.vue";
import Modal from "./Modal.vue";
import SearchBox from "./SearchBox.vue";
import { decodeCredential } from 'vue3-google-login'


export default {
  name: "NavBar",
  components: {
    Logo,
    SearchBox,
    HrefButton,
    Modal,
    // GoogleLogin,
  },
  data() {
    return {
      // query: "",
      userData: "",
      toggleSearch: screen.width > 768,
      toggleNav: screen.width > 768,
      isModalVisible: false,
      isSignupModal: false,
      signupSuccessful: false,
      isForgotPasswordModal: false,
      params: {
        make: "",
        model: "",
        series: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      zip: "",
      password: "",
      confirmPassword: "",
      errors: [],
      loginEmail: "",
      loginPassword: "",
      messages: {},
    };
  },
  computed: {
    ...mapGetters({
      query: "searchQuery",
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);

    this.emitter.on("showLogin", (value) => {
      if (value) {
        console.log(value);
      }
      this.showSignInModal();
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    // trackEvent () {
    //   window.gtag('event', 'sign_in');
    // },
    showPassword(){
      let x = document.getElementById("password");
      let icon = document.getElementById("password-icon");
      if (x.type === "password") {
        x.type = "text";
        icon.classList.remove('show-password');
        icon.className = "icon hide-password small";
      } else {
        x.type = "password";
        icon.classList.remove('hide-password');
        icon.className = "icon show-password small";

      }
    },
    toggleSearchMethod () {
      this.toggleSearch = !this.toggleSearch;
      this.toggleNav = false;
    },
    toggleNavMethod () {
      this.toggleNav = !this.toggleNav;
      this.toggleSearch = false;
    },
    callbackLogin(response) {
      // console.log(response);
      const userData = decodeCredential(response.credential)
      console.log('methods section ----', userData);
    },
    canShowLuckyDrawButton(){
      const routeName = this.$route.name;
      return routeName == 'Raffle';
    },
    showLuckyDrawModal(){
      if(this.$route.name == 'Raffle'){
      this.emitter.emit('showLuckyDraw',true);
      }else{
        this.$router.push('/raffle');
      }
    },
    // async googleLogin() {
    //   console.log("clicked test");
    //   try {
    //     const response = await googleAuthCodeLogin();
    //     console.log("Handle the response", response);
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   console.log("exit");
    // },
    gotoSearch() {
      window.open('/search', '_self')
      // this.loadDeals(this.params);
      // if (this.$route.name != "Search") {
      //   this.$router.push("/search");
      // }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    myEventHandler() {
      this.toggleSearch = screen.width > 768;
      this.toggleNav = screen.width > 768;
    },
    showSignInModal() {
      window.gtag('event', 'sign_in'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      this.signupSuccessful = false;
      document.body.classList.add("overflow-hidden");
    },
    showSignUpModal() {
      window.gtag('event', 'sign_up'); /* google analytic */

      this.isModalVisible = true;
      this.isSignupModal = true;
      this.isForgotPasswordModal = false;
      document.body.classList.add("overflow-hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      this.isSignupModal = false;
      this.isForgotPasswordModal = false;
      document.body.classList.remove("overflow-hidden");
    },
    registerUser() {
      if (!this.validateRegisterForm()) {
        return;
      }
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        zip_code: this.zip,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/register", params).then(
        (data) => {
          console.log(data);
          this.$store.dispatch("setIsLoading", false);
          this.signupSuccessful = true;
          this.loading = false;
        },
        (error) => {
          this.$store.dispatch("setIsLoading", false);
          if (error.response) {
            console.log(error.response);
            if (error.response.data.message) {
              this.errors["general"] = error.response.data.message;
            }
            for (const key in error.response.data.errors) {
              this.errors[key] = error.response.data.errors[key].join(", ");
            }
          } else {
            console.log(error.request);
            this.errors["general"] = "Sorry! Something went wrong!";
          }
          this.signupSuccessful = false;
          this.loading = false;
        }
      );
    },

    showForgotPassword() {
      window.gtag('event', 'forgot_password'); /* google analytic */
      this.isModalVisible = true;
      this.isSignupModal = false;
      this.isForgotPasswordModal = true;
      document.body.classList.add("overflow-hidden");
    },

    forgotPassword() {
      console.log("here");
      if (!this.validateForgotForm()) {
        return;
      }
      console.log("here ...");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/forgotPassword", this.loginEmail).then(
        (data) => {
          console.log(data);
          this.$store.dispatch("setIsLoading", false);
          this.loginEmail = "";
          this.loginPassword = "";
          this.initializeErrors();
          this.messages["info"] =
            "Thank you. An email with password reset link has been sent!";
        },
        (error) => {
          this.$store.dispatch("setIsLoading", false);
          if (error.response) {
            console.log(error.response);
            if (error.response.data.message) {
              this.errors["general"] = error.response.data.message;
            }
            if (!this.errors.general) {
              this.errors["general"] = error.response.statusText;
            }
          } else {
            console.log(error.request);
            this.errors["general"] = "Sorry! Something went wrong! ";
          }
        }
      );
    },

    signin() {
      if (!this.validateLoginForm()) {
        return;
      }
      const params = {
        email: this.loginEmail,
        password: this.loginPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/login", params).then(
        (data) => {
          console.log(data);
          this.$store.dispatch("setIsLoading", false);
          this.closeModal();
          this.loginEmail = "";
          this.loginPassword = "";
        },
        (error) => {
          this.$store.dispatch("setIsLoading", false);
          if (error.response) {
            console.log(error.response);
            if (error.response.data.message) {
              this.errors["general"] = error.response.data.message;
            }
            if (!this.errors.general) {
              this.errors["general"] = error.response.statusText;
            }
          } else {
            console.log(error.request);
            this.errors["general"] = "Sorry! Something went wrong! ";
          }
        }
      );
    },
    initializeErrors() {
      this.errors = {};
      this.messages = {};
    },

    validateRegisterForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.firstName) {
        isValid = false;
        this.setInvalidFormField("firstName", "First Name is required");
      }
      if (!this.lastName) {
        isValid = false;
        this.setInvalidFormField("lastName", "Last Name is required");
      }

      if (!this.email || !this.validateEmail(this.email)) {
        isValid = false;
        this.setInvalidFormField("email", "A valid email is required");
      }

      if (!this.phone || !this.validatePhone(this.phone)) {
        isValid = false;
        this.setInvalidFormField("phone", "A valid phone is required");
      }

      if (!this.zip) {
        isValid = false;
        this.setInvalidFormField("zip", "Zip Code is required");
      }

      if (!this.password || this.password.length < 8) {
        isValid = false;
        this.setInvalidFormField(
          "password",
          "Password is required & must be at least 8 characters long"
        );
      }
      if (this.confirmPassword != this.password) {
        isValid = false;
        this.setInvalidFormField(
          "confirmPassword",
          "Confirm Password doesnt match the Password"
        );
      }
      return isValid;
    },

    validateForgotForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail) {
        isValid = false;
        this.setInvalidFormField("general", "Registered email is required");
      }
      return isValid;
    },

    validateLoginForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail || !this.loginPassword) {
        isValid = false;
        this.setInvalidFormField(
          "general",
          "Login email & Password are required"
        );
      }
      return isValid;
    },

    setInvalidFormField(field, message) {
      this.errors[field] = message;
    },

    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        email
      );
    },
    validatePhone(phone) {
      // eslint-disable-next-line
      return /^\d{8,15}$/.test(phone);
    },
  },
};
</script>
<style>
@media (min-width: 640px) {
  .find-car-btn{
    display: none !important;
  }
}

@media (max-width: 640px) {
  .search-box{
    display: none !important;
  }
}

</style>
