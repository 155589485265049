<template>
  <TopBar/>
  <div class="container m-auto text-justify mb-12 mt-6 lg:mt-12 px-6 lg:px-0 watermark">
    <div class="flex justify-center py-8">
      <h1 class="text-3xl lg:text-4xl font-bold uppercase">Risk Factors</h1>
    </div>

    <p class="italic">
      An investment in our series interests involves risks. In addition to other information contained elsewhere in this
      offering circular, you should carefully consider the following risks before acquiring our interests offered by
      this offering circular. The occurrence of any of the following risks could materially and adversely affect the
      business, prospects, financial condition or results of operations of our company, the ability of our company to
      make cash distributions to the holders of interests and the market price of our interests, which could cause you
      to lose all or some of your investment in our interests. Some statements in this offering circular, including
      statements in the following risk factors, constitute forward-looking statements. See “Cautionary Statement
      Regarding Forward-Looking Statements.”
    </p>

    <h2 class="font-semibold py-4 mt-6 text-2xl">Risks Relating to the Structure, Operation and Performance of our
      Company</h2>

    <h3 class="font-semibold italic">
      The COVID-19 pandemic or the future outbreak of any other highly infectious or contagious diseases could
      materially and adversely impact or cause disruption to our performance, financial condition, results of
      operations, cash flows and ability to pay distributions. Further, the pandemic has caused disruptions in the U.S.
      and global economies and financial markets and created widespread business continuity issues of an as yet unknown
      magnitude and duration.
    </h3>

    <p class="mt-4">
      The impact of the COVID-19 pandemic and measures to prevent its spread could materially negatively impact our
      ability to launch and operate our business and our results of operations, financial condition and liquidity in a
      number of ways, including:
    </p>

    <ul class="mt-4 leading-9" style="list-style-type: disc">
      <li>an inability to sell our series interests resulting in a lack of capital sufficient to acquire and operate our
        series fleet;
      </li>
      <li>a decrease in our series’ revenues as a result of reduced travel caused by regulatory health restrictions or
        the fear of risks
        that travel may cause;
      </li>
      <li>changes in travel preferences may make it less likely that guests would want to stay in the regions where our
        series
        properties are located;
      </li>
      <li>an inability to enforce guests’ contractual obligations and/or limits on our ability to raise short-term
        rental costs due to
        restrictive measures imposed by local, regional or national governmental authorities;
      </li>
      <li>the risk of a prolonged COVID-19 outbreak causing long-term damage to economic conditions, which in turn could
        cause material declines in the fair market value of our series properties, leading to asset impairment charges
        and our inability to sell our series properties; and
      </li>
      <li>the potential inability of our company or our operations manager to maintain adequate staffing for the
        management and
        maintenance of our series fleet due to any future shelter-in-place orders and/or the continued duration or
        expansion of the pandemic.
      </li>
    </ul>

    <p class="mt-4">
      The extent of the COVID-19 pandemic’s effect on our operational and financial performance will depend on future
      developments including the duration, spread and intensity of the outbreak, all of which are uncertain and
      difficult to predict. The adverse impact on our business, results of operations, cash flows and financial
      condition could be material.
    </p>

    <h3 class="font-semibold italic mt-4">
      We are a newly formed entity with no prior operating history, which makes our future performance difficult to
      predict. There is no guarantee that we will be successful in the operation of our company.
    </h3>

    <p class="mt-4">
      Our company recently formed. Our first series was formed in the 4th quarter of 2022 and will commence operations
      upon the closing of the purchase of the property by such series, which will take place in the 4th quarter of 2022.
      We have no prior operating history, and you should consider an investment in our interests in light of the risks,
      uncertainties and difficulties frequently encountered by other newly formed companies with similar objectives. To
      be successful in this market, we and the manager must, among other things:
    </p>

    <ul class="mt-4 leading-9" style="list-style-type: disc">
      <li>identify and acquire real estate assets consistent with our investment strategies;</li>

      <li>increase awareness of our name within the investment products market;</li>

      <li>attract, integrate, motivate and retain qualified personnel to manage our day-to-day operations; and</li>

      <li>build and expand our operations structure to support our business.</li>
    </ul>

    <p>
      We have minimal operating capital and for the foreseeable future will be dependent upon our ability to finance our
      operations from the sale of equity or other financing alternatives. The failure to successfully raise operating
      capital could result in our bankruptcy or other event which would have a material adverse effect on us and our
      investors. There can be no assurance that we will achieve our investment objectives.
    </p>

    <h3 class="font-semibold italic mt-4">An investment in a series offering constitutes only an investment in that
      series and not in our company or directly in any property.</h3>

    <p class="mt-4">
      An investor in an offering will acquire an ownership interest in the series related to that offering and not, for
      the avoidance of doubt, in (i) our company, (ii) any other series, (iii) the manager, or (iv) directly in a
      property associated with the series or any car owned by any other series. This results in limited voting rights of
      the investor, which are solely related to a particular series, and are further limited by the operating agreement,
      described further herein. Investors will have voting rights only with respect to certain matters, primarily
      relating to amendments to the operating agreement that would adversely change the rights of the interest holders
      and removal of the manager for “cause.” The manager thus retains significant control over the management of our
      company, each series and the series properties. Furthermore, because the interests in a series do not constitute
      an investment in our company as a whole, holders of the interests in a series are not expected to receive any
      economic benefit from, or be subject to the liabilities of, the assets of any other series.
    </p>

    <h3 class="font-bold mt-4 italic">
      Each of our company’s series will hold an interest in a cohort of cars, a diversified investment.
    </h3>

    <p class="mt-4">
      We intend for each of our series, either directly or through its wholly-owned subsidiary, to own and operate a a
      cohort of cars. Each series’ return on its investment will depend on the short-term rental revenues generated by
      such cohort and the depreciation of the value of the cars over time. These, in turn, are determined by such
      factors as national and local economic cycles and conditions, financial markets and the economy, competition from
      existing rental car franchises & owners as well as future rental car platforms and government regulation (such as
      tax charges). The value of a car may decline substantially after a series purchases it.
    </p>

    <p class="mt-4">
      Each of our series will own a cohort of cars and as a result of this diversified investment strategy,
      unanticipated capital expenditures could lead to a series’ inability to pay dividends or the loss of your
      investment entirely. Each series’ dividend stream will depend on the short-term rental revenues generated by such
      property and the depreciation of the value of the property over time.
    </p>

    <h3 class="mt-4 italic">There can be no guarantee that our company will reach its funding target from potential
      investors with respect to any series or future proposed series.</h3>

    <p class="mt-4">Due to the start-up nature of our company, there can be no guarantee that our company will reach its
      funding
      target from potential investors with respect to any series or future proposed series. In the event our company
      does not reach a funding target, it may not be able to achieve its investment objectives by acquiring additional
      properties through the issuance of further series interests and monetizing them to generate distributions for
      investors. In addition, if our company is unable to raise funding for additional series, this may impact any
      investors already holding interests as they will not see the benefits which arise from economies of scale
      following the acquisition by other series of additional properties.</p>

    <h3 class="font-semibold italic mt-4">We may not be able to control our operating costs or our expenses may remain
      constant or increase, even if our revenues do not increase, causing our results of operations to be adversely
      affected.</h3>

    <p class="mt-4">Factors that may adversely affect our ability to control operating costs include the need to pay for
      insurance
      and other operating costs, including real estate taxes, which could increase over time; the need periodically to
      repair and renovate our residential properties; the cost of compliance with governmental regulation, including
      zoning, environmental and tax laws; the cost of engaging a property manager, listing our properties on third-party
      sites, and maintaining inventory for furnishing and supplies; the potential for liability under applicable laws;
      interest rate levels; principal loan amounts; and the availability of financing. If our operating costs increase
      as a result of any of the foregoing factors, our results of operations may be adversely affected.</p>
    <p class="mt-4">
      The expense of owning and operating a property is not necessarily reduced when circumstances such as market
      factors and competition cause a reduction in income from a property. As a result, if revenues decline, we may not
      be able to reduce our expenses accordingly. Costs associated with short-term rental investments, such as real
      estate taxes, insurance, property management and listing costs, loan payments and maintenance, generally will not
      be reduced even if a property is not fully occupied or other circumstances cause our revenues to decrease. If we
      are unable to decrease operating costs when demand for our properties decreases and our revenues decline, our
      financial condition, results of operations and our ability to make distributions to our investors may be adversely
      affected.
    </p>

    <h3 class="font-semibold italic mt-4">
      Competition could limit our ability to acquire attractive investment opportunities and increase the costs of those
      opportunities which may adversely affect us, including our profitability, and impede our growth
    </h3>

    <p class="mt-4">
      The car rental market is highly competitive, and we will compete with other entities engaged in car rental
      activities to locate suitable cars to acquire. These competitors will include car rental companies, car sharing
      hosts, dealers & private buyers. Some of these competitors have substantially greater marketing and financial
      resources than we will have and generally may be able to accept more risk than we can prudently manage.
      Competition from these entities may reduce the number of suitable investment opportunities offered to us or
      increase the bargaining power of car owners seeking to sell.
    </p>

    <h3 class="font-semibold italic mt-4">
      Competition may impede our ability to attract or retain guests, which could adversely affect our results of
      operations and cash flow.
    </h3>

    <p class="mt-4">
      Operating rental properties, and short-term rentals in particular, is highly competitive. We will compete based on
      a number of factors that include location, rental rates, security, suitability of a property’s design to
      prospective guests’ needs and the manner in which a property is operated and marketed. The number of competing
      properties could have a material effect on our occupancy levels, rental rates and on the operating expenses of
      certain of our properties. If other developers of similar spaces in our markets offer short-term rentals at prices
      comparable to or less than the prices we offer on the properties we acquire, we may be unable to attract or retain
      guests to book space in our properties, which could adversely affect our results of operations and cash flow.
    </p>

    <h3 class="font-semibold italic mt-4">We may fail to successfully operate acquired properties, which could adversely
      affect us and impede our growth.</h3>

    <p class="mt-4">
      The operations manager’s ability to identify and acquire cars at a good price and successfully manage the fleet
      may be exposed to significant risks. We may be unable to complete an a car purchase after incurring certain
      acquisition-related costs such as Post Sale Inspection. We may also spend more than budgeted to make necessary
      improvements or repairs to acquired cars and may not be able to obtain adequate insurance coverage for some of the
      cars. Any delay or failure to identify, negotiate, finance and consummate such acquisitions in a timely manner and
      on favorable terms, or operate our fleet to meet our financial expectations, could impede our growth and have an
      adverse effect on us, including our financial condition, results of operations, cash flow and the market value of
      our interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      Disruptions in the financial markets or deteriorating economic conditions could adversely impact the short-term
      rental market, which could hinder our ability to implement our business strategy and generate returns to you.
    </h3>

    <p class="mt-4">
      The success of our business is significantly related to general economic conditions and, accordingly, our business
      could be harmed by an economic slowdown. Periods of economic slowdown or recession, significantly rising interest
      rates, declining employment levels, decreasing demand for short-term rentals, or the public perception that any of
      these events may occur, may result in a general decline in acquisition, disposition and short-term rental
      activity, as well as a general decline in the underlying value of any asset, which in turn could result in poor
      economic performance. In such cases, investors may lose the full value of their investment, or may not experience
      any distributions from the series.
    </p>

    <p class="mt-4">
      During an economic downturn, it may also take longer for us to dispose of our fleet or the selling prices may be
      lower than originally anticipated. As a result, the carrying value of our investments may become impaired and we
      could record losses as a result of such impairment or we could experience reduced profitability related to
      declines in car values or rents. Further, as a result of our target leverage, our exposure to adverse general
      economic conditions will be heightened.
    </p>

    <p class="mt-4">
      All the conditions described above could adversely impact our business performance and profitability, which could
      result in our failure to make distributions to our investors and could decrease the value of an investment in us.
    </p>

    <h3 class="font-semibold italic mt-4">
      You may be more likely to sustain a loss on your investment because the manager does not have as strong an
      economic incentive to avoid losses as do managers who have made significant equity investments in their companies.
    </h3>

    <p class="mt-4">
      Because it has not made a significant equity investment in our company, the manager will have little exposure to
      loss in the value of a series’ interests. Without this exposure, our investors may be at a greater risk of loss
      because the manager does not have as much to lose from a decrease in the value of our interests as do those
      managers who make more significant equity investments in their companies.
    </p>

    <h3 class="font-semibold italic mt-4">
      Any adverse changes in the manager’s financial health or our relationship with the manager or its affiliates could
      hinder our operating performance and the return on your investment.
    </h3>

    <p class="mt-4">
      The manager will utilize the manager’s personnel to perform services on its behalf for us. Our ability to achieve
      our investment objectives and to pay distributions to our investors is dependent upon the performance of the
      manager and its affiliates as well as the manager’s team in the identification and acquisition of investments, the
      management of our assets and operation of our day-to-day activities. Any adverse changes in the manager’s
      financial condition or our relationship with the manager could hinder the manager’s ability to successfully manage
      our operations and our properties.
    </p>

    <h3 class="font-semibold italic mt-4">
      Compliance with governmental laws, regulations and rules that are applicable to our short-term rental cars may
      adversely affect our business and growth strategies.
    </h3>

    <p class="mt-4">
      Short-term rental cars are subject to laws, regulations and rules on city, state, country and regional levels.
      While a number of cities have implemented legislation to address short-term rentals, there are many others that
      are not yet explicitly addressing or enforcing short-term rental laws, and could follow suit and enact
      regulations. Compliance with new laws, regulations, government policies, or changes in their interpretations in
      the locations where we operate could entail significant challenges and uncertainties. In the event of any such
      changes, pre-existing bookings may not be honored and current and future listings and bookings could decline
      significantly, and our relationship with our guests could be negatively impacted.
    </p>

    <p class="mt-4">
      Local regulations, including municipal or local ordinances, zoning restrictions and restrictive covenants imposed
      by community developers, may restrict our use of our fleet and may require us to obtain approval from local
      officials or community standards organizations at any time with respect to our fleet, including prior to acquiring
      any of our cars or when undertaking repairs. We cannot assure you that existing regulatory policies will not
      adversely affect us or the timing or cost of any future acquisitions or repairs, or that additional regulations
      will not be adopted that would increase such delays or result in additional costs. Our business and growth
      strategies may be materially and adversely affected by our ability to obtain permits, licenses and zoning
      approvals.
    </p>

    <h2 class="font-semibold text-2xl mt-8">Risks Related to Conflicts of Interest</h2>

    <h3 class="font-semibold italic mt-4">We do not expect to have any employees and we are completely reliant on the
      manager to provide us with investment and advisory services. We expect to benefit from the personnel,
      relationships and experience of the manager’s executive team and other personnel and investors of the manager and
      expect to benefit from the same highly experienced personnel and resources we need for the implementation and
      execution of our investment strategy. Each of our executive officers also serves as an officer of the manager. The
      manager will have significant discretion as to the implementation of our investment and operating policies and
      strategies. Accordingly, we believe that our success will depend to a significant extent upon the efforts,
      experience, diligence, skill and relationships of the executive officers and key personnel of the manager. The
      executive officers and key personnel of the manager will evaluate, negotiate, close and monitor our properties.
      Our success will depend on their continued service.</h3>

    <p class="mt-4">In addition, we offer no assurance that the manager will remain the manager or that we will continue
      to have access to the manager’s principals and professionals. If the operating agreement is terminated and no
      suitable replacement is found to manage us, our ability to execute our business plan will be negatively
      impacted.</p>

    <h3 class="font-semibold italic mt-4">The ability of the manager and its officers and other personnel to engage in
      other business activities, including managing other similar companies, may reduce the time the manager spends
      managing the business of our company and may result in certain conflicts of interest.</h3>

    <p class="mt-4">
      Our officers also serve or may serve as officers or employees of FairPY, Inc., as well as other manager-sponsored
      vehicles, and other companies unaffiliated with the manager. These other business activities may reduce the time
      these persons spend managing our business. Further, if and when there are turbulent conditions in the auto markets
      or distress in the credit markets or other times when we will need focused support and assistance from the
      manager, the attention of the manager’s personnel and executive officers and the resources of the manager may also
      be required by other manager-sponsored vehicles. In such situations, we may not receive the level of support and
      assistance that we may receive if we were internally managed or if we were not managed by the manager. In
      addition, these persons may have obligations to other entities, the fulfillment of which might not be in the best
      interests of us or any of our investors. Our officers and the manager’s personnel may face conflicts of interest
      in allocating sale, financing, leasing and other business opportunities among the real properties owned by the
      various companies and our series.
    </p>

    <h3 class="font-semibold italic mt-4">The terms of the operating agreement make it difficult to end our relationship
      with the manager.</h3>

    <p class="mt-4">
      Under the terms of the operating agreement, holders of interests in each series of our company have the right to
      remove our manager as manager of our company, by a vote of two-thirds of the holders of all interests in each
      series of our company (excluding our manager) voting together, in the event our manager is found by a
      non-appealable judgment of a court of competent jurisdiction to have committed fraud in connection with a series
      of interests or our company. Unsatisfactory financial performance does not constitute grounds to terminate and
      remove the manager under the operating agreement. These provisions make it difficult to end our company’s
      relationship with the manager, even if we believe the manager’s performance is not satisfactory.
    </p>

    <h3 class="font-semibold italic mt-4">
      The operating agreement contains provisions that reduce or eliminate duties (including fiduciary duties) of the
      manager.
    </h3>

    <p class="mt-4">
      The operating agreement provides that the manager, in exercising its rights in its capacity as the manager, will
      be entitled to consider only such interests and factors as it desires, including its own interests, and will have
      no duty or obligation (fiduciary or otherwise) to give any consideration to any interest of or factors affecting
      us or any of our investors and will not be subject to any different standards imposed by our bylaws, or under any
      other law, rule or regulation or in equity. These modifications of fiduciary duties are expressly permitted by
      Delaware law.
    </p>

    <h3 class="font-semibold italic mt-4">There are conflicts of interest among us, the manager and its affiliates.</h3>

    <p class="mt-4">
      Each of our executive officers is an executive officer of the manager. All the agreements and arrangements between
      such parties, including those relating to compensation, are not the result of arm’s-length negotiations. Some of
      the conflicts inherent in our company’s transactions with the manager and its affiliates, and the limitations on
      such parties adopted to address these conflicts, are described below. The manager and its affiliates will try to
      balance our interests with their own. However, to the extent that such parties take actions that are more
      favorable to other entities than us, these actions could have a negative impact on our financial performance and,
      consequently, on distributions to investors and the value of our interests.
    </p>

    <p class="mt-4">
      The operating agreement provides the manager with broad powers and authority which may exacerbate the existing
      conflicts of interest among your interests and those of the manager, its executive officers and its other
      affiliates. Potential conflicts of interest include, but are not limited to, the following:
    </p>


    <ul class="leading-9 mt-4" style="list-style-type: disc">
      <li>the manager or an affiliate of the manager may sell certain properties to various series. The manager will be
        setting the purchase price that a series will pay for such a property, which price may be higher than appraised
        values or comparable property prices;
      </li>

      <li>the manager, its executive officers and its other affiliates may continue to offer other real estate
        investment opportunities, including equity offerings similar to this offering, and may make investments in real
        estate assets for their own respective accounts, whether or not competitive with our business;
      </li>


      <li>the manager, its executive officers and its other affiliates will not be required to disgorge any profits or
        fees or other compensation they may receive from any other business they own separately from us, and you will
        not be entitled to receive or share in any of the profits or fees or other compensation from any other business
        owned and operated by the manager, its executive officers and/or its other affiliates
      </li>

      <li>
        we may engage the manager or affiliates of the manager to perform services at prevailing market rates.
        Prevailing market rates are determined by the manager based on industry standards and expectations of what the
        manager would be able to negotiate with third party on an arm’s length basis; and
      </li>

      <li>
        the manager or affiliates of the manager may provide advances or loans to us and charge reasonable market rates
        of interest, which are determined by the manager based on the manager’s determination of market rates for
        mortgages of a similar character and term at the time of entry into the purchase and sale agreement; and
      </li>

      <li>
        the manager, its executive officers and its other affiliates are not required to devote all of their time and
        efforts to our affairs.
      </li>

    </ul>

    <h3 class="font-semibold italic mt-4">
      We do not have a policy that expressly prohibits our directors, officers, security holders or affiliates from
      having a direct or indirect pecuniary interest in any transaction to which we or any of our subsidiaries has an
      interest or engaging for their own account in business activities of the types conducted by us.
    </h3>

    <p class="mt-4">
      We do not have a policy that expressly prohibits our directors, officers, security holders or affiliates from
      having a direct or indirect pecuniary interest in any asset to be acquired or disposed of by us or any of our
      subsidiaries or in any transaction to which we or any of our subsidiaries are a party or have an interest.
      Additionally, we do not have a policy that expressly prohibits any such persons from engaging for their own
      account in business activities of the types conducted by us. In addition, our management agreement with the
      manager does not prevent the manager and its affiliates from engaging in additional management or investment
      opportunities, some of which could compete with us.
    </p>

    <h3 class="font-semibold italic mt-4">
      The manager’s liability is limited under the operating agreement, and we have agreed to indemnify the manager
      against certain liabilities. As a result, we may experience poor performance or losses for which the manager would
      not be liable.
    </h3>

    <p class="mt-4">
      Pursuant to our company’s operating agreement, the manager will not assume any responsibility other than to render
      the services called for thereunder and not will be responsible for any action of our board of directors in
      following or declining to follow the manager’s advice or recommendations. The manager maintains a contractual, as
      opposed to a fiduciary, relationship with us and our investors. Under the terms of the operating agreement, the
      manager, its officers, investors, members, managers, directors and personnel, any person controlling or controlled
      by the manager and any person providing sub-advisory services to the manager will not be liable to us, any
      subsidiary of ours, our board of directors, or our investors, members or partners or any subsidiary’s investors,
      members or partners for acts or omissions performed in accordance with and pursuant to the operating agreement,
      except by reason of acts or omissions constituting bad faith, willful misconduct, gross negligence, or reckless
      disregard of their duties under the operating agreement. Accordingly, we and our investors will only have recourse
      and be able to seek remedies against the manager to the extent it breaches its obligations pursuant to the
      operating agreement. Furthermore, we have agreed to limit the liability of the manager and to indemnify the
      manager against certain liabilities. We have agreed to reimburse, indemnify and hold harmless the manager, its
      officers, investors, members, managers, directors and personnel, any person controlling or controlled by the
      manager and any person providing sub-advisory services to the manager with respect to all expenses, losses,
      damages, liabilities, demands, charges and claims in respect of, or arising from, acts or omissions of such
      indemnified parties not constituting bad faith, willful misconduct, gross negligence, or reckless disregard of the
      manager’s duties, which have a material adverse effect on us. In addition, we may choose not to enforce, or to
      enforce less vigorously, our rights under the operating agreement because of our desire to maintain our ongoing
      relationship with the manager.
    </p>

    <h2 class="text-2xl font-semibold mt-4">
      Risks Related to U.S. Federal Income Tax Matters
    </h2>

    <h3 class="font-semibold italic mt-4">
      If a series cannot be treated as a corporation for tax purposes, investors may be taxed as partners in a
      partnership
    </h3>

    <p class="mt-4">
      The rule that a separate series of a series LLC is an eligible entity that may elect to be treated as a
      corporation for federal income tax purposes is contained in proposed Treasury Regulations not yet technically in
      force (Prop. Treas. Reg. Section 301.7701- 1(a)(5)) and could be subject to change if and when those proposed
      Treasury Regulations are issued in final form. If such a change were to occur, investors in a series would likely
      be treated as partners in a partnership and would be subject to current federal income tax on their proportional
      share of the income of such series.
    </p>

    <h3 class="font-semibold italic mt-4">
      Legislative or regulatory action with respect to tax laws and regulations could adversely affect our company and
      our investors.
    </h3>

    <p class="mt-4">
      In recent years, numerous legislative, judicial and administrative changes have been made in the provisions of the
      federal income tax laws applicable to investments similar to an investment in the interests. In particular, on
      December 22, 2017, President Trump signed the Tax Cuts and Jobs Act (the “Tax Act”) into law. The Tax Act includes
      major changes to U.S. tax laws and represents the most significant changes to the Internal Revenue Code since
      1986. In addition, recently enacted legislation intended to support the economy during the COVID-19 pandemic, the
      Coronavirus Aid, Relief, and Economic Security Act (the “CARES Act”), made technical corrections, or temporary
      modifications, to certain of the provisions of the Tax Act. Additional changes to the tax laws are likely to
      continue to occur, and we cannot assure investors that any such changes will not adversely affect their taxation,
      the investment in their interests or the market value or the resale potential of our assets. Investors are urged
      to consult with their own tax advisor with respect to the impact of recent legislation, including the Tax Act and
      CARES Act, on their investment in the interests and the status of legislative, regulatory or administrative
      developments and proposals and their potential effect on an investment in the interests.
    </p>


    <h2 class="text-2xl font-semibold mt-4">
      Risks Related to Ownership of our Interests
    </h2>

    <h3 class="font-semibold italic mt-4">
      There is currently no public trading market for our securities.
    </h3>

    <p class="mt-4">
      There is currently no public trading market for any of our series interests, and an active market may not develop
      or be sustained. If an active public trading market for our securities does not develop or is not sustained, it
      may be difficult or impossible for you to resell your interests at any price. Even if a public market does
      develop, the market price could decline below the amount you paid for your interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      If a market ever develops for our interests, the market price and trading volume may be volatile.
    </h3>

    <p class="mt-4">
      If a market develops for our interests, the market price of our interests could fluctuate significantly for many
      reasons, including reasons unrelated to our performance, the series properties or the series, such as reports by
      industry analysts, investor perceptions, or announcements by our competitors regarding their own performance, as
      well as general economic and industry conditions. For example, to the extent that other companies, whether large
      or small, within our industry experience declines in their share prices, the value of our interests may decline as
      well.
    </p>


    <p class="mt-4">
      In addition, fluctuations in operating results of a particular series or the failure of operating results to meet
      the expectations of investors may negatively impact the price of our securities. Operating results may fluctuate
      in the future due to a variety of factors that could negatively affect revenues or expenses in any particular
      reporting period, including vulnerability of our business to a general economic downturn; changes in the laws that
      affect our operations; competition; compensation related expenses; application of accounting standards;
      seasonality; and our ability to obtain and maintain all necessary certifications or licenses to conduct our
      business.
    </p>

    <h3 class="font-semibold italic mt-4">
      There may be state law restrictions on an investor’s ability to sell the interests.
    </h3>

    <p class="mt-4">
      Each state has its own securities laws, often called “blue sky” laws, which (1) limit sales of securities to a
      state’s residents unless the securities are registered in that state or qualify for an exemption from registration
      and (2) govern the reporting requirements for broker-dealers and stockbrokers doing business directly or
      indirectly in the state. Before a security is sold in a state, there must be a registration in place to cover the
      transaction, or it must be exempt from registration. Also, Dalmore must be registered in that state. We do not
      know whether our securities will be registered, or exempt, under the laws of any states. A determination regarding
      registration will be made by broker-dealers, if any, who agree to serve as the market-makers for our interests.
      There may be significant state blue sky law restrictions on the ability of investors to sell, and on purchasers to
      buy, our interests. Investors should consider the resale market for our securities to be limited. Investors may be
      unable to resell their securities, or they may be unable to resell them without the significant expense of state
      registration or qualification.
    </p>

    <h3 class="font-semibold italic mt-4">
      Investors’ limited voting rights restrict their ability to affect the operations of the company or a series.
    </h3>

    <p class="mt-4">
      Our manager has a unilateral ability to amend the operating agreement and the allocation policy in certain
      circumstances without the consent of the investors. The investors only have limited voting rights in respect of
      the series in which they are invested. Investors will therefore be subject to any amendments the manager makes (if
      any) to the operating agreement and allocation policy and also any decision it takes in respect of our company and
      the applicable series, which the investors do not get a right to vote upon. Investors may not necessarily agree
      with such amendments or decisions and such amendments or decisions may not be in the best interests of all of the
      investors as a whole but only a limited number.
    </p>

    <p class="mt-4">
      Furthermore, our manager can only be removed as manager of our company and each series of interests in a very
      limited circumstance, following a non-appealable judgment of a court of competent jurisdiction to have committed
      fraud in connection with our company or a series of interests. Investors would therefore not be able to remove the
      manager merely because they did not agree, for example, with how the manager was operating a series property.
    </p>

    <h3 class="font-semibold italic mt-4">
      This offering is being conducted on a “best efforts” basis and we may not be able to execute our growth strategy
      if we are unable to raise this capital.
    </h3>

    <p class="mt-4">
      We are offering the interests on a “best efforts” basis, and we can give no assurance that all of the offered
      interests will be sold. If you invest in our interests and more than the minimum number of offered interests are
      sold, but less than all of the offered interests are sold, the risk of losing your entire investment will be
      increased. If substantially less than the maximum amount of interests offered are sold, we may be unable to fund
      all the intended uses described in this offering circular from the net proceeds anticipated from this offering
      without obtaining funds from alternative sources or using working capital that we generate. Alternative sources of
      funding may not be available to us at what we consider to be a reasonable cost, and the working capital generated
      by us may not be sufficient to fund any uses not financed by offering net proceeds.
    </p>

    <h3 class="font-semibold italic mt-4">
      The offering price for the interests determined by us may not necessarily bear any relationship to established
      valuation criteria such as earnings, book value or assets that may be agreed to between purchasers and sellers in
      private transactions or that may prevail in the market if and when our interests can be traded publicly.
    </h3>


    <p class="mt-4">
      The price of the interests is a derivative result of the cost that a series is expected to incur in acquiring a
      property. These prices do not necessarily accurately reflect the actual value of the interests or the price that
      may be realized upon disposition of the interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      Funds from purchasers accompanying subscriptions for the interests will not accrue interest while in escrow.
    </h3>

    <p class="mt-4">
      The funds paid by an investor for interests will be held in a non-interest-bearing escrow account until the
      admission of the subscriber as an investor in the applicable series, if such subscription is accepted. Purchasers
      will not have the use of such funds or receive interest thereon pending the completion of the offering. No
      subscriptions will be accepted, and no interests will be sold unless valid subscriptions for the offering are
      received and accepted prior to the termination of the applicable offering. If we terminate an offering prior to
      accepting a subscriber’s subscription, escrowed funds will be returned promptly, without interest or deduction, to
      the proposed investor.
    </p>

    <h3 class="font-semibold italic mt-4">
      Your interest in us will be diluted if and when we issue additional interests in a series, which could reduce the
      overall value of your investment.
    </h3>

    <p class="mt-4">
      Investors do not have preemptive rights to any additional series interests we issue in the future. Under our
      operating agreement, we have authority to issue an unlimited number of additional interests in a series or other
      securities, although, under Regulation A, we are only allowed to sell up to $75 million of interests in any
      12-month period (although we may raise capital in other ways). In particular, our manager is authorized, subject
      to the restrictions of Regulation A and other applicable securities laws, to provide for the issuance of an
      unlimited amount of one or more classes or series of interests, and to designate the number of interests, the
      relative powers, preferences and rights, and the qualifications, limitations or restrictions applicable to each
      class or series thereof in the series designation applicable to such class or series, without investor approval.
      The manager may elect to (i) sell additional interests in our current and future continuous offerings, (ii) issue
      equity interests in private offerings, or (iii) issue interests to our manager, or its successors or assigns, in
      payment of an outstanding fee obligation. In addition, the asset management fee (as described in
      “Management—Management Compensation”) includes the issuance of series interests to the manager. To the extent we
      issue additional equity interests, your percentage ownership interest in us will be diluted. In addition,
      depending upon the terms and pricing of any additional offerings and the value of our investments, you may also
      experience dilution in the book value and fair value of your interests.
    </p>


    <h3 class="font-semibold italic mt-4">
      Any dispute in relation to the operating agreement is subject to the exclusive jurisdiction of the Court of
      Chancery of the State of Delaware, except where federal law requires that certain claims be brought in federal
      courts. The operating agreement, to the fullest extent permitted by applicable law, provides for investors to
      waive their right to a jury trial.
    </h3>
    <p class="mt-4">
      Each investor will covenant and agree not to bring any claim in any venue other than the Court of Chancery of the
      State of Delaware, or if required by federal law, a federal court of the United States, as in the case of claims
      brought under the Exchange Act. Section 27 of the Exchange Act creates exclusive federal jurisdiction over all
      suits brought to enforce any duty or liability created by the Exchange Act or the rules and regulations
      thereunder. As a result, the exclusive forum provision will not apply to suits brought to enforce any duty or
      liability created by the Exchange Act or any other claim for which the federal courts have exclusive jurisdiction.
      Furthermore, Section 22 of the Securities Act creates concurrent jurisdiction for federal and state courts over
      all suits brought to enforce any duty or liability created by the Securities Act or the rules and regulations
      thereunder. As a result, the exclusive forum provisions will not apply to suits brought to enforce any duty or
      liability created by the Securities Act or any other claim for which the federal and state courts have concurrent
      jurisdiction, and investors will not be deemed to have waived our compliance with the federal securities laws and
      the rules and regulations thereunder.
    </p>

    <p class="mt-4">
      If an interest holder were to bring a claim against our company or the manager pursuant to the operating agreement
      and such claim was governed by state law, it would have to bring such claim in the Delaware Court of Chancery. The
      operating agreement, to the fullest extent permitted by applicable law and subject to limited exceptions, provides
      for investors to consent to exclusive jurisdiction to Delaware Court of Chancery and for a waiver of the right to
      a trial by jury, if such waiver is allowed by the court where the claim is brought.
    </p>

    <p class="mt-4">If we opposed a jury trial demand based on the waiver, the court would determine whether the waiver
      was enforceable based on the facts and circumstances of that case in accordance with the applicable state and
      federal law. To our knowledge, the enforceability of a contractual pre-dispute jury trial waiver in connection
      with claims arising under the federal securities laws has not been finally adjudicated by the United States
      Supreme Court. However, we believe that a contractual pre-dispute jury trial waiver provision is generally
      enforceable, including under the laws of the Delaware, which govern the operating agreement, by a federal or state
      court in the State of Delaware, which has exclusive jurisdiction over matters arising under the operating
      agreement. In determining whether to enforce a contractual pre-dispute jury trial waiver provision, courts will
      generally consider whether a party knowingly, intelligently and voluntarily waived the right to a jury trial.</p>

    <p class="mt-4">
      We believe that this is the case with respect to the operating agreement and our interests. It is advisable that
      you consult legal counsel regarding the jury waiver provision before entering into the operating agreement.
      Nevertheless, if this jury trial waiver provision is not permitted by applicable law, an action could proceed
      under the terms of the operating agreement with a jury trial. No condition, stipulation or provision of the
      operating agreement or our interests serves as a waiver by any investor or beneficial owner of our interests or by
      us of compliance with the U.S. federal securities laws and the rules and regulations promulgated thereunder.
      Additionally, our company does not believe that claims under the federal securities laws shall be subject to the
      jury trial waiver provision, and our company believes that the provision does not impact the rights of any
      investor or beneficial owner of our interests to bring claims under the federal securities laws or the rules and
      regulations thereunder.
    </p>

    <p class="mt-4">
      These provisions may have the effect of limiting the ability of investors to bring a legal claim against us due to
      geographic limitations and may limit an investor’s ability to bring a claim in a judicial forum that it finds
      favorable for disputes with us. Furthermore, waiver of a trial by jury may disadvantage an investor to the extent
      a judge might be less likely than a jury to resolve an action in the investor’s favor. Further, if a court were to
      find this exclusive forum provision inapplicable to, or unenforceable in respect of, an action or proceeding
      against us, then we may incur additional costs associated with resolving these matters in other jurisdictions,
      which could materially and adversely affect our business and financial condition.
    </p>


    <p class="mt-4">
      In addition, Section 107 of the JOBS Act also provides that an emerging growth company can take advantage of the
      extended transition period provided in Section 7(a)(2)(B) of the Securities Act for complying with new or revised
      accounting standards. In other words, an emerging growth company can delay the adoption of certain accounting
      standards until those standards would otherwise apply to private companies. We would expect to elect to take
      advantage of the benefits of this extended transition period. Our financial statements may therefore not be
      comparable to those of companies that comply with such new or revised accounting standards.
    </p>

    <p class="mt-4">
      We would expect to take advantage of these reporting exemptions until we are no longer an emerging growth company.
      We would remain an emerging growth company for up to five years, or until the earliest of (i) the last day of the
      first fiscal year in which our total annual gross revenues exceed $1 billion; (ii) the date that we become a large
      accelerated filer as defined in Rule 12b-2 under the Exchange Act, which would occur if the market value of our
      interests that is held by non-affiliates exceeds $700 million as of the last business day of our most recently
      completed second fiscal quarter; or (iii) the date on which we have issued more than $1 billion in non-convertible
      debt during the preceding three-year period.
    </p>

    <p class="mt-4">
      In either case, we will be subject to ongoing public reporting requirements that are less rigorous than Exchange
      Act rules for companies that are not emerging growth companies, and investors could receive less information than
      they might expect to receive from more mature public companies.
    </p>

    <h3 class="font-semibold italic mt-4">
      There may be deficiencies with our internal controls that require improvements, and if we are unable to adequately
      evaluate internal controls, we may be subject to sanctions.
    </h3>

    <p class="mt-4">
      As a Tier 2 issuer, we will not need to provide a report on the effectiveness of our internal controls over
      financial reporting, and we will be exempt from the auditor attestation requirements concerning any such report so
      long as we are a Tier 2 issuer. We are in the process of evaluating whether our internal control procedures are
      effective and, therefore, there is a greater likelihood of undiscovered errors in our internal controls or
      reported financial statements as compared to issuers that have conducted such evaluations.
    </p>

    <h3 class="fa-toggle-on italic mt-4">
      If we are required to register under the Exchange Act, it would result in significant expense and reporting
      requirements that would place a burden on the manager and may divert attention from management of the properties
      by the manager or could cause the manager to no longer be able to afford to run our business.
    </h3>

    <p class="mt-4">
      The Exchange Act requires issuers with more than $10 million in total assets to register its equity securities
      under the Exchange Act if its securities are held of record by more than 2,000 persons or 500 persons who are not
      “accredited investors.” While the operating agreement presently prohibits any transfer that would result in any
      series being held of record by more than 2,000 persons or 500 non-“accredited investors,” there can be no
      guarantee that we will not exceed those limits and the manager has the ability to unilaterally amend the operating
      agreement to permit holdings that exceed those limits. If we are required to register under the Exchange Act, it
      would result in significant expense and reporting requirements that would place a burden on the manager and may
      divert attention from management of the properties by the manager or could cause the manager to no longer be able
      to afford to run our business.
    </p>

    <h3 class="font-semibold italic mt-4">If our company were to be required to register under the Investment Company
      Act or the manager were to be required to register under the Investment Advisers Act, it could have a material and
      adverse impact on the results of operations and expenses of each series and the manager may be forced to liquidate
      and wind up each series or rescind the offerings for any of the series.</h3>
    <p class="mt-4">
      Our company is not registered and will not be registered as an investment company under the Investment Company Act
      of 1940, as amended (the “Investment Company Act”), and the manager is not and will not be registered as an
      investment adviser under the Investment Advisers Act of 1940, as amended (the “Investment Advisers Act”) and the
      interests do not have the benefit of the protections of the Investment Company Act or the Investment Advisers Act.
      Our company and the manager have taken the position that the properties are not “securities” within the meaning of
      the Investment Company Act or the Investment Advisers Act, and thus our company’s assets will consist of less than
      40% investment securities under the Investment Company Act and the manager is not and will not be advising with
      respect to securities under the Investment Advisers Act. This position, however, is based upon applicable case law
      that is inherently subject to judgments and interpretation. If our company were to be required to register under
      the Investment Company Act or the manager were to be required to register under the Investment Advisers Act, it
      could have a material and adverse impact on the results of operations and expenses of each series and the manager
      may be forced to liquidate and wind up each series or rescind the offerings for any of the series or the offering
      for any other series.
    </p>

    <h2 class="font-semibold text-2xl mt-4">
      Risks Related to Conflicts of Interest
    </h2>

    <h3 class="font-semibold italic mt-4">
      We are dependent on the manager and its affiliates and their key personnel who provide services to us through the
      operating agreement, and we may not find a suitable replacement if the operating agreement is terminated, or if
      key personnel leave or otherwise become unavailable to us, which could have a material adverse effect on our
      performance.
    </h3>

    <p class="mt-4">
      We do not expect to have any employees and we are completely reliant on the manager to provide us with investment
      and advisory services. We expect to benefit from the personnel, relationships and experience of the manager’s
      executive team and other personnel and investors of the manager and expect to benefit from the same highly
      experienced personnel and resources we need for the implementation and execution of our investment strategy. Each
      of our executive officers also serves as an officer of the manager. The manager will have significant discretion
      as to the implementation of our investment and operating policies and strategies. Accordingly, we believe that our
      success will depend to a significant extent upon the efforts, experience, diligence, skill and relationships of
      the executive officers and key personnel of the manager. The executive officers and key personnel of the manager
      will evaluate, negotiate, close and monitor our properties. Our success will depend on their continued service.
    </p>

    <p class="mt-4">
      In addition, we offer no assurance that the manager will remain the manager or that we will continue to have
      access to the manager’s principals and professionals. If the operating agreement is terminated and no suitable
      replacement is found to manage us, our ability to execute our business plan will be negatively impacted.
    </p>

    <h3 class="font-semibold italic mt-4">
      The ability of the manager and its officers and other personnel to engage in other business activities, including
      managing other similar companies, may reduce the time the manager spends managing the business of our company and
      may result in certain conflicts of interest.
    </h3>

    <p class="mt-4">
      Our officers also serve or may serve as officers or employees of Arrived Holdings, Inc., as well as other
      manager-sponsored vehicles, and other companies unaffiliated with the manager. These other business activities may
      reduce the time these persons spend managing our business. Further, if and when there are turbulent conditions in
      the real estate markets or distress in the credit markets or other times when we will need focused support and
      assistance from the manager, the attention of the manager’s personnel and executive officers and the resources of
      the manager may also be required by other manager-sponsored vehicles. In such situations, we may not receive the
      level of support and assistance that we may receive if we were internally managed or if we were not managed by the
      manager. In addition, these persons may have obligations to other entities, the fulfillment of which might not be
      in the best interests of us or any of our investors. Our officers and the manager’s personnel may face conflicts
      of interest in allocating sale, financing, leasing and other business opportunities among the real properties
      owned by the various companies and our series.
    </p>

    <h3 class="font-semibold italic mt-4">
      The terms of the operating agreement make it difficult to end our relationship with the manager.
    </h3>

    <p class="mt-4">
      Under the terms of the operating agreement, holders of interests in each series of our company have the right to
      remove our manager as manager of our company, by a vote of two-thirds of the holders of all interests in each
      series of our company (excluding our manager) voting together, in the event our manager is found by a
      non-appealable judgment of a court of competent jurisdiction to have committed fraud in connection with a series
      of interests or our company. Unsatisfactory financial performance does not constitute grounds to terminate and
      remove the manager under the operating agreement. These provisions make it difficult to end our company’s
      relationship with the manager, even if we believe the manager’s performance is not satisfactory.
    </p>

    <h3 class="font-semibold italic mt-4">
      The operating agreement contains provisions that reduce or eliminate duties (including fiduciary duties) of the
      manager.
    </h3>

    <p class="mt-4">
      The operating agreement provides that the manager, in exercising its rights in its capacity as the manager, will
      be entitled to consider only such interests and factors as it desires, including its own interests, and will have
      no duty or obligation (fiduciary or otherwise) to give any consideration to any interest of or factors affecting
      us or any of our investors and will not be subject to any different standards imposed by our bylaws, or under any
      other law, rule or regulation or in equity. These modifications of fiduciary duties are expressly permitted by
      Delaware law.
    </p>

    <h3 class="font-semibold italic mt-4">
      There are conflicts of interest among us, the manager and its affiliates.
    </h3>

    <p class="mt-4">
      Each of our executive officers is an executive officer of the manager. All the agreements and arrangements between
      such parties, including those relating to compensation, are not the result of arm’s-length negotiations. Some of
      the conflicts inherent in our company’s transactions with the manager and its affiliates, and the limitations on
      such parties adopted to address these conflicts, are described below. The manager and its affiliates will try to
      balance our interests with their own. However, to the extent that such parties take actions that are more
      favorable to other entities than us, these actions could have a negative impact on our financial performance and,
      consequently, on distributions to investors and the value of our interests.
    </p>

    <p class="mt-4">
      The operating agreement provides the manager with broad powers and authority which may exacerbate the existing
      conflicts of interest among your interests and those of the manager, its executive officers and its other
      affiliates. Potential conflicts of interest include, but are not limited to, the following:
    </p>

    <ul class="leading-9 mt-4" style="list-style-type: disc">
      <li>the manager or an affiliate of the manager may sell certain properties to various series. The manager will be
        setting the purchase price that a series will pay for such a property, which price may be higher than appraised
        values or comparable property prices;
      </li>
      <li>the manager, its executive officers and its other affiliates may continue to offer other real estate
        investment opportunities, including equity offerings similar to this offering, and may make investments in real
        estate assets for their own respective accounts, whether or not competitive with our business;
      </li>
      <li>the manager, its executive officers and its other affiliates will not be required to disgorge any profits or
        fees or other compensation they may receive from any other business they own separately from us, and you will
        not be entitled to receive or share in any of the profits or fees or other compensation from any other business
        owned and operated by the manager, its executive officers and/or its other affiliates for their own benefit;
      </li>
      <li>we may engage the manager or affiliates of the manager to perform services at prevailing market rates.
        Prevailing market rates are determined by the manager based on industry standards and expectations of what the
        manager would be able to negotiate with third party on an arm’s length basis; and
      </li>
      <li>the manager or affiliates of the manager may provide advances or loans to us and charge reasonable market
        rates of interest, which are determined by the manager based on the manager’s determination of market rates for
        mortgages of a similar character and term at the time of entry into the purchase and sale agreement; and
      </li>
      <li>the manager, its executive officers and its other affiliates are not required to devote all of their time and
        efforts to our affairs.
      </li>
    </ul>

    <h3 class="font-semibold italic mt-4">
      We do not have a policy that expressly prohibits our directors, officers, security holders or affiliates from
      having a direct or indirect pecuniary interest in any transaction to which we or any of our subsidiaries has an
      interest or engaging for their own account in business activities of the types conducted by us.
    </h3>

    <p class="mt-4">
      We do not have a policy that expressly prohibits our directors, officers, security holders or affiliates from
      having a direct or indirect pecuniary interest in any asset to be acquired or disposed of by us or any of our
      subsidiaries or in any transaction to which we or any of our subsidiaries are a party or have an interest.
      Additionally, we do not have a policy that expressly prohibits any such persons from engaging for their own
      account in business activities of the types conducted by us. In addition, our management agreement with the
      manager does not prevent the manager and its affiliates from engaging in additional management or investment
      opportunities, some of which could compete with us.
    </p>

    <h3 class="font-semibold italic mt-4">
      The manager’s liability is limited under the operating agreement, and we have agreed to indemnify the manager
      against certain liabilities. As a result, we may experience poor performance or losses for which the manager would
      not be liable.
    </h3>

    <p class="mt-4">
      Pursuant to our company’s operating agreement, the manager will not assume any responsibility other than to render
      the services called for thereunder and not will be responsible for any action of our board of directors in
      following or declining to follow the manager’s advice or recommendations. The manager maintains a contractual, as
      opposed to a fiduciary, relationship with us and our investors. Under the terms of the operating agreement, the
      manager, its officers, investors, members, managers, directors and personnel, any person controlling or controlled
      by the manager and any person providing sub-advisory services to the manager will not be liable to us, any
      subsidiary of ours, our board of directors, or our investors, members or partners or any subsidiary’s investors,
      members or partners for acts or omissions performed in accordance with and pursuant to the operating agreement,
      except by reason of acts or omissions constituting bad faith, willful misconduct, gross negligence, or reckless
      disregard of their duties under the operating agreement. Accordingly, we and our investors will only have recourse
      and be able to seek remedies against the manager to the extent it breaches its obligations pursuant to the
      operating agreement. Furthermore, we have agreed to limit the liability of the manager and to indemnify the
      manager against certain liabilities. We have agreed to reimburse, indemnify and hold harmless the manager, its
      officers, investors, members, managers, directors and personnel, any person controlling or controlled by the
      manager and any person providing sub-advisory services to the manager with respect to all expenses, losses,
      damages, liabilities, demands, charges and claims in respect of, or arising from, acts or omissions of such
      indemnified parties not constituting bad faith, willful misconduct, gross negligence, or reckless disregard of the
      manager’s duties, which have a material adverse effect on us. In addition, we may choose not to enforce, or to
      enforce less vigorously, our rights under the operating agreement because of our desire to maintain our ongoing
      relationship with the manager.
    </p>

    <h2 class="text-2xl font-semibold mt-8">
      Risks Related to Real Estate Investments Generally
    </h2>

    <h3 class="font-semibold italic mt-4">Our real estate assets will be subject to the risks typically associated with
      real estate.</h3>

    <p class="mt-4">
      Our real estate assets will be subject to the risks typically associated with real estate. The value of real
      estate may be adversely affected by a number of risks, including:
    </p>

    <ul class="leading-9 mt-4" style="list-style-type: disc">
      <li>
        natural disasters such as hurricanes, earthquakes and floods;
      </li>
      <li>acts of war or terrorism, including the consequences of terrorist attacks;
      </li>
      <li>adverse changes in national and local economic and real estate conditions;
      </li>
      <li>an oversupply of (or a reduction in demand for) short-term rentals in the areas where particular properties
        are located and the attractiveness of particular properties to prospective guests;
      </li>
      <li>changes in governmental laws and regulations, fiscal policies and zoning ordinances and the related costs of
        compliance therewith and the potential for liability under applicable laws;
      </li>
      <li>costs of remediation and liabilities associated with environmental conditions affecting properties; and
      </li>
      <li>the potential for uninsured or underinsured property losses.

      </li>
    </ul>

    <p class="mt-4">
      The value of each property is affected significantly by its ability to generate cash flow and net income, which in
      turn depends on the amount of booking revenue that can be generated net of expenses required to be incurred with
      respect to a property. Many expenditures associated with a property (such as operating expenses and capital
      expenditures) cannot be reduced when there is a reduction in rental income from the property.
    </p>

    <p class="mt-4">
      Our acquisitions will be premised on assumptions about occupancy levels and rental rates, and if those assumptions
      prove to be inaccurate, our cash flows and profitability will be reduced. These factors may have a material
      adverse effect on the value that we can realize from our assets.
    </p>

    <h3 class="font-semibold italic mt-4">We face possible risks associated with natural disasters and the physical
      effects of climate change, which could have a material adverse effect on our business, results of operations, and
      financial condition. </h3>

    <p class="mt-4">To the extent climate change causes changes in weather patterns, the coastal destinations where our
      rental properties are located could experience increases in storm intensity and rising sea levels, causing damage
      to our properties that could result in reduced bookings at these properties. Climate change may also affect our
      business by increasing the cost of or eliminating property insurance on terms we find acceptable in areas most
      vulnerable to such events. This could increase operating costs, including the cost of water or energy, and require
      us to expend funds to repair and protect our properties from such events. In addition, changes in federal, state
      and local legislation and regulations designed to address climate change could result in increased capital
      expenditures to improve the energy efficiency of a property that we acquire in order to comply with such
      legislation and regulations. Any of the foregoing could have a material adverse effect on our business, results of
      operations, and financial condition.
    </p>

    <h3 class="font-semibold italic mt-4">
      The performance of any short-term rental is likely to fluctuate significantly due to seasonality based on the
      location of the specific property.
    </h3>

    <p class="mt-4">
      The performance of and rental income generated by any short-term rental property is likely to fluctuate
      significantly during the course of a year, reflecting periods of lower or higher demand based on the location of
      the property. Declines in occupancy rates and revenues during low seasons may occur, while costs and expenses do
      not decline at the same rate, if at all, having a material adverse effect on our profitability, cash flow and
      ability to make distributions to investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      The market in which the company participates is competitive and, if it does not compete effectively, its operating
      results could be harmed.
    </h3>

    <p class="mt-4">
      The company competes with many others engaged in real estate in general and short-term rental operating activities
      in particular, including, but not limited to, individuals, corporations, bank and insurance company investment
      accounts, real estate investment trusts, and private real estate funds. In particular, the company intends to
      cause the property manager to list its short-term rental properties through established third-party rental booking
      platforms, such as Airbnb and Vrbo. The company’s properties will compete with all other properties listed on
      those sites in the vicinity of the company’s properties, as well as other traditional accommodations such as
      hotels that may have built-in client bases and greater resources to withstand economic downturns or off-peak
      vacancies. This market is competitive and rapidly changing. Significant increases in the number of listings for
      short-term rentals in the geographic areas where the company’s properties are located, if not met by a similar
      increase in demand for short-term rentals, is likely to cause downward pressure on rental rates and, potentially,
      the value of the real estate assets. The company expects competition to persist and intensify in the future, which
      could its ability to generate sufficient rental income from its short-term properties or acquire additional
      properties on terms that investors find to be reasonable.
    </p>

    <h3 class="fa-toggle-on italic mt-4">
      The company will be dependent on the efforts of its property manager and the performance, dependability, and
      reliability of the short-term rental platforms through which the property manager lists the series properties.
    </h3>

    <p class="mt-4">
      The company intends to cause the property manager to promote the company’s short-term rental properties through
      third-party rental booking platforms, such as Airbnb and Vrbo, and will rely on these platforms to facilitate
      bookings, communications with renters, and collection of rental amounts and taxes, and to remit those payments
      appropriately. If these platforms were to experience disruptions or fail to meet users’ expectations, our business
      could suffer. Furthermore, if one or more of these platforms were to cease operations or to reject our listings,
      we may have difficulty renting our short-term properties, which could have a material adverse effect on our
      results, profitability and the ability to make distributions to investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      The company may decide to sell a residential property which could conflict with an investor’s interests.
    </h3>

    <p class="mt-4">
      The company may decide to sell any property at any time in accordance with the management rights afforded to the
      manager. Investors will not have a say in this decision. The timing and decision to sell a property may conflict
      with investors’ personal interests, beliefs or theories regarding the real estate market. Further, it is possible
      the sale will not be done at an optimal time. In any case, investors would not have any cause of action against
      the company or the manager for such sales.
    </p>

    <h3 class="font-semibold italic mt-4">We anticipate involvement in a variety of litigation.
    </h3>

    <p class="mt-4">
      We anticipate involvement in a range of legal actions in the ordinary course of business, including disputes
      between the company and guests, challenges to title and ownership rights, and issues with local housing officials
      arising from the condition, maintenance or operation of one or more of our residential properties. These issues
      may escalate into legal action from time to time. These actions can be time consuming and expensive. We cannot
      assure you that we will not be subject to expenses and losses that may adversely affect our operating results.
    </p>

    <h3 class="font-semibold italic mt-4">
      We may be subject to unknown or contingent liabilities related to properties that we acquire for which we may have
      limited or no recourse against the sellers.
    </h3>

    <p class="mt-4">
      Properties that we may acquire in the future may be subject to unknown or contingent liabilities for which we may
      have limited or no recourse against the sellers. Unknown or contingent liabilities might include liabilities for
      clean-up or remediation of environmental conditions, claims of prior tenants or renters, vendors or other persons
      dealing with the acquired properties, tax liabilities and other liabilities whether incurred in the ordinary
      course of business or otherwise. In the future we may enter into transactions with limited representations and
      warranties or with representations and warranties that do not survive the closing of the transactions or that only
      survive for a limited period, in which event we would have no or limited recourse against the sellers of such
      properties. While we expect to usually require the sellers to indemnify us with respect to breaches of
      representations and warranties that survive, such indemnification is often limited and subject to various
      materiality thresholds, a significant deductible or an aggregate cap on losses.
    </p>

    <p class="mt-4">
      As a result, there is no guarantee that we will recover any losses due to breaches by the sellers of their
      representations and warranties. In addition, the total amount of costs and expenses that we may incur with respect
      to liabilities associated with acquired properties may exceed our expectations, which may adversely affect our
      business, financial condition, results of operations and cash flow. Finally, we expect that indemnification
      agreements between us and the sellers will typically provide that the sellers will retain certain specified
      liabilities relating to the properties acquired by us. While the sellers are generally contractually obligated to
      pay all losses and other expenses relating to such retained liabilities, there can be no guarantee that such
      arrangements will not require us to incur losses or other expenses as well.
    </p>

    <h3 class="font-semibold italic mt-4">
      We may not be able to sell our properties at a price equal to, or greater than, the price for which we purchased
      such properties, which may lead to a decrease in the value of our assets.
    </h3>

    <p class="mt-4">
      The value of a property to a potential purchaser may not increase over time, which may restrict our ability to
      sell a property, or if we are able to sell such property, may lead to a sale price less than the price that we
      paid to purchase a property.
    </p>

    <h3 class="font-semibold italic mt-4">
      We may be required to make significant capital expenditures to improve the properties in order to attract guests,
      generate positive cash flow or make short-term rental properties suitable for sale, which could adversely affect
      us, including our financial condition, results of operations and cash flow.
    </h3>

    <p class="mt-4">
      In the event there are adverse economic conditions in the short-term rental market which lead to an increase in
      guest defaults, lower booking rates and less demand for short-term rentals, we may be more inclined to improve the
      properties or provide additional services to our guests in order to compete in a more competitive short-term
      rental environment, all of which could negatively affect our cash flow. If the necessary capital is unavailable,
      we may be unable to make these potentially significant capital expenditures. This could result in decreased
      bookings by guests and our properties remaining vacant, which could adversely affect our financial condition,
      results of operations, cash flow and the market value of our interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      Our dependence on short-term rental revenue may adversely affect us, including our profitability, our ability to
      meet our debt obligations and our ability to make distributions to our investors.
    </h3>

    <p class="mt-4">
      Our income will be primarily derived from short-term rental revenue from residential properties. As a result, our
      performance will depend on our ability to collect rental revenue from guests. Our income and funds for
      distribution would be adversely affected if our guests fail to make payments when due or cause damage to the
      property. These actions could result in the loss of revenue attributable to the terminated rental agreements or
      increased costs to repair the property. The loss of rental revenues from our guests or increased costs to repair
      property may adversely affect us, including our profitability, our ability to meet our debt and other financial
      obligations and our ability to make distributions to our investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      We may engage in development, redevelopment or repositioning activities in the future, which could expose us to
      different risks that could adversely affect us, including our financial condition, cash flow and results of
      operations.
    </h3>

    <p class="mt-4">
      We may engage in development, redevelopment or repositioning activities with respect to properties that we acquire
      as we believe market conditions dictate. If we engage in these activities, we will be subject to certain risks,
      which could adversely affect us, including our financial condition, cash flow and results of operations. These
      risks include, without limitation:
    </p>

    <ul class="leading-9 mt-4" style="list-style-type: disc">
      <li>
        the availability and pricing of financing on favorable terms or at all;
      </li>
      <li>the availability and timely receipt of zoning and other regulatory approvals;
      </li>
      <li>the potential for the fluctuation of short-term rental rates at development and redevelopment properties,
        which may result in our investment not being profitable;
      </li>
      <li>start up, development, repositioning and redevelopment costs may be higher than anticipated;
      </li>
      <li>cost overruns and untimely completion of construction (including risks beyond our control, such as weather,
        labor conditions or material shortages); and
      </li>
      <li>changes in the pricing and availability of buyers and sellers of such properties.
      </li>
    </ul>

    <p class="mt-4">
      These risks could result in substantial unanticipated delays or expenses and could prevent the initiation or the
      completion of development and redevelopment activities, any of which could have an adverse effect on our financial
      condition, results of operations, cash flow, and the market value of our interests and our ability to satisfy our
      debt obligations and to make distributions to our investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      Our properties may be subject to impairment charges.
    </h3>

    <p class="mt-4">
      We will periodically evaluate our real estate investments for impairment indicators. The judgment regarding the
      existence of impairment indicators is based on factors such as market conditions, short-term rental performance
      and legal structure. If we determine that an impairment has occurred, we would be required to make a downward
      adjustment to the net carrying value of a property. Impairment charges also indicate a potential permanent adverse
      change in the fundamental operating characteristics of the impaired property. There is no assurance that these
      adverse changes will be reversed in the future and the decline in the impaired property’s value could be
      permanent.
    </p>

    <h3 class="font-semibold italic mt-4">
      If we fail to diversify our properties, downturns relating to certain geographic regions, industries or business
      sectors may have a more significant adverse impact on our assets and our ability to pay distributions than if we
      had a diversified investment portfolio.
    </h3>

    <p class="mt-4">
      While we intend to diversify our properties by geography, investment size and investment risk, we are not required
      to observe specific diversification criteria. Therefore, our investments may at times be concentrated in a limited
      number of geographic locations, or secured by assets concentrated in a limited number of geographic locations. To
      the extent that our properties are concentrated in limited geographic regions, downturns relating generally to
      such region may result in defaults on a number of our investments within a short time period, which may reduce our
      net income and the value of our interests and accordingly limit our ability to pay distributions to our investors.
    </p>

    <p class="mt-4">
      Any adverse economic or real estate developments in our target markets could adversely affect our operating
      results and our ability to pay distributions to our investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      Joint venture investments could be adversely affected by our lack of sole decision-making authority, our reliance
      on the financial condition of co-venturers and disputes between us and our co-venturers.
    </h3>

    <p class="mt-4">
      We may enter into joint ventures, partnerships and other co-ownership arrangements (including preferred equity
      investments) for the purpose of making investments. In such event, we would not be in a position to exercise sole
      decision-making authority regarding the joint venture. Investments in joint ventures may, under certain
      circumstances, involve risks not present were a third party not involved, including the possibility that partners
      or co-venturers might become bankrupt or fail to fund their required capital contributions. Co-venturers may have
      economic or other business interests or goals which are inconsistent with our business interests or goals, and may
      be in a position to take actions contrary to our policies or objectives. Such investments may also have the
      potential risk of impasses on decisions, such as a sale, because neither we nor the co-venturer would have full
      control over the joint venture. In addition, to the extent our participation represents a minority interest, a
      majority of the participants may be able to take actions which are not in our best interests because of our lack
      of full control. Disputes between us and co-venturers may result in litigation or arbitration that would increase
      our expenses and prevent our officers from focusing their time and effort on our business. Consequently, actions
      by or disputes with co-venturers might result in subjecting properties owned by the joint venture to additional
      risk. In addition, we may in certain circumstances be liable for the actions of our co-venturers.
    </p>

    <h3 class="font-semibold italic mt-4">
      Property taxes could increase due to property tax rate changes or reassessment, which could impact our financial
      condition, results of operations and cash flow.
    </h3>

    <p class="mt-4">
      A series generally will be required to pay state and local taxes on its property. The real property taxes on our
      properties may increase as property tax rates change or as our properties are assessed or reassessed by taxing
      authorities. If the property taxes we pay increase, our financial condition, results of operations, cash flow, the
      value of our interests and our ability to satisfy our principal and interest obligations and to make distributions
      to our investors could be adversely affected.
    </p>

    <h3 class="font-semibold italic mt-4">
      Uninsured losses relating to real property or excessively expensive premiums for insurance coverage, including due
      to the potential non-renewal of the Terrorism Risk Insurance Program (TRIP), could reduce our cash flows and the
      return on our investors’ investments.
    </h3>

    <p class="mt-4">
      There are types of losses, generally catastrophic in nature, such as losses due to wars, acts of terrorism,
      earthquakes, floods, hurricanes, pollution or environmental matters that are uninsurable or not economically
      insurable, or may be insured subject to limitations, such as large deductibles or co-payments. Insurance risks
      associated with such catastrophic events could sharply increase the premiums we pay for coverage against property
      and casualty claims.
    </p>

    <p class="mt-4">
      This risk is particularly relevant with respect to potential acts of terrorism. The Terrorism Risk Insurance
      Program (TRIP), under which the U.S. federal government bears a significant portion of insured losses caused by
      terrorism, was extended until December 31, 2027 pursuant to the Terrorism Risk Insurance Program Reauthorization
      Act of 2019, but there can be no assurance that Congress will act to renew or replace the TRIP following its
      expiration. If the TRIP is not renewed or replaced, terrorism insurance may become difficult or impossible to
      obtain at reasonable costs or at all, which may result in adverse impacts and additional costs to us.
    </p>

    <p class="mt-4">
      Changes in the cost or availability of insurance due to the non-renewal of the TRIP or for other reasons could
      expose us to uninsured casualty losses. If any of our properties incurs a casualty loss that is not fully insured,
      the value of our assets will be reduced by any such uninsured loss, which may reduce the value of our investors’
      investments. In addition, other than any working capital reserve or other reserves we may establish, we have no
      source of funding to repair or reconstruct any uninsured property. Also, to the extent we must pay unexpectedly
      large amounts for insurance, we could suffer reduced earnings that would result in lower distributions to
      investors.
    </p>

    <p class="mt-4">
      Additionally, mortgage lenders insist in some cases that multifamily property owners purchase coverage against
      terrorism as a condition for providing mortgage loans. Accordingly, to the extent terrorism risk insurance
      policies are not available at reasonable costs, if at all, our ability to finance or refinance our properties
      could be impaired. In such instances, we may be required to provide other financial support, either through
      financial assurances or self-insurance, to cover potential losses. We may not have adequate, or any, coverage for
      such losses.
    </p>

    <h3 class="font-semibold italic mt-4">
      Acquiring or attempting to acquire multiple properties in a single transaction may adversely affect our
      operations.
    </h3>

    <p class="mt-4">
      From time to time, we may attempt to acquire multiple properties in a single transaction. Multiple property
      portfolio acquisitions are more complex and expensive than single-property acquisitions, and the risk that a
      portfolio acquisition does not close may be greater than in a single-property acquisition. A seller may require
      that a group of properties be purchased as a package even though we may not want to purchase one or more
      properties in the portfolio. In these situations, if we are unable to identify another person or entity to acquire
      the unwanted properties, we may be required to operate or attempt to dispose of these properties. To acquire
      multiple properties in a single transaction we may be required to accumulate a large amount of cash. We would
      expect the returns that we earn on such cash to be less than the ultimate returns in real property and, therefore,
      accumulating such cash could reduce the funds available for distributions to our investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      Our targeted investments may include condominium interests. Condominium interests are subject to special risks
      that may reduce your return on investment.
    </h3>
    <p class="mt-4">
      Our targeted investments may include condominium interests, which is a type of common ownership interest. Common
      ownership interests are subject to special risks that may reduce your return on investment. For example, common
      ownership interests are governed by associations in which we, as a condominium unit owner, have a vote. We may be
      outvoted by the other members of the condominium respecting matters that materially impact the management,
      appearance, safety or financial soundness of the dwelling or of the association.
    </p>

    <p class="mt-4">
      The value of common ownership interests may be decreased by the default of other interest holders on their
      homeowners association, or HOA, fees or similar fees. If enough holders default on their fees, the HOA’s liquidity
      and net worth may decrease dramatically. If the HOA or board is forced to foreclose on any delinquent interests
      representing the condominium interests, a lowered value realized at the foreclosure sale may adversely impact the
      market value of every other unit.
    </p>

    <p class="mt-4">
      We, as a common ownership interest owner, will also be required to pay HOA fees. If we default in our payment, we
      may be obligated to pay financial penalties or, in severe circumstances, our unit may be foreclosed on by the
      board or the HOA. If the board or HOA is mismanaged or if the applicable property suffers from neglect or deferred
      maintenance, HOA fees may increase, which may reduce our cash flow from operations and your ability to receive
      distributions
    </p>

    <h3 class="font-semibold italic mt-4">
      Real estate investments are relatively illiquid and may limit our flexibility.
    </h3>

    <p class="mt-4">
      Real estate investments are relatively illiquid, which may tend to limit our ability to react promptly to changes
      in economic or other market conditions. Our ability to dispose of assets in the future will depend on prevailing
      economic and market conditions. Our inability to sell our properties on favorable terms or at all could have an
      adverse effect on our sources of working capital and our ability to satisfy our debt obligations. In addition,
      real estate can at times be difficult to sell quickly at prices we find acceptable. When we sell any of our
      assets, we may recognize a loss on such sale.
    </p>


    <h3 class="font-semibold italic mt-4">
      The failure of any bank in which we deposit our funds could reduce the amount of cash we have available to pay
      distributions to our investors and make additional investments.
    </h3>

    <p class="mt-4">
      We intend to diversify our cash and cash equivalents among several banking institutions in an attempt to minimize
      exposure to any one of these entities. However, the Federal Deposit Insurance Corporation, or FDIC, only insures
      amounts up to $250,000 per depositor per insured bank. We expect to have cash and cash equivalents and restricted
      cash deposited in certain financial institutions in excess of federally insured levels. If any of the banking
      institutions in which we have deposited funds ultimately fails, we may lose our deposits over $250,000.
    </p>

    <h3 class="font-semibold italic mt-4">
      The occurrence of a cyber incident, or a deficiency in our cyber security, could negatively impact our business by
      causing a disruption to our operations, a compromise or corruption of our confidential information, or damage to
      our business relationships, all of which could negatively impact our financial results.
    </h3>

    <p class="mt-4">
      We collect and retain certain personal information provided by our series investors and guests who may stay in the
      properties owned by the series. While we expect to implement a variety of security measures to protect the
      confidentiality of this information and periodically review and improve our security measures, we can provide no
      assurance that we will be able to prevent unauthorized access to this information. A cyber incident is considered
      to be any adverse event that threatens the confidentiality, integrity, or availability of our information
      resources. More specifically, a cyber incident is an intentional attack or an unintentional event that can include
      gaining unauthorized access to systems to disrupt operations, corrupt data, or steal confidential information. As
      our reliance on technology has increased, so have the risks that could directly result from the occurrence of a
      cyber incident including operational interruption, damage to our relationships with our investors and guests, and
      private data exposure, any of which could negatively impact our reputation and financial results.
    </p>


    <h3 class="font-semibold italic mt-4">
      Potential development and construction delays and resultant increased costs and risks may hinder our operating
      results and decrease our net income.
    </h3>

    <p class="mt-4">
      From time to time we may acquire unimproved real property or properties that are under development or
      construction. Investments in such properties will be subject to the uncertainties associated with the development
      and construction of real property, including those related to re-zoning land for development, environmental
      concerns of governmental entities and community groups and our builders’ ability to build in conformity with
      plans, specifications, budgeted costs and timetables. If a builder fails to perform, we may resort to legal action
      to rescind the purchase or the construction contract or to compel performance. A builder’s performance may also be
      affected or delayed by conditions beyond the builder’s control. We may incur additional risks when we make
      periodic progress payments or other advances to builders before they complete construction. These and other
      factors can result in increased costs of a project or loss of our investment. In addition, we must rely on
      short-term rental income and expense projections and estimates of the fair market value of residential property
      upon completion of construction when agreeing upon a purchase price at the time we acquire the property. If our
      projections are inaccurate, we may pay too much for a property, and the return on our investment could suffer.
    </p>

    <h3 class="font-semibold italic mt-4">
      Inflation may adversely affect our financial condition and results of operations.
    </h3>

    <p class="mt-4">
      An increase in inflation could have an adverse impact on any floating rate mortgages or credit facilities we may
      enter into and general and administrative expenses, as these costs could increase at a rate higher than our rental
      and other revenue.
    </p>

    <h3 class="font-semibold italic mt-4">
      Costs imposed pursuant to governmental laws and regulations may reduce our net income and the cash available for
      distributions to our investors.
    </h3>

    <p class="mt-4">
      Real property and the operations conducted on real property are subject to federal, state and local laws and
      regulations relating to protection of the environment and human health. We could be subject to substantial
      liability in the form of fines, penalties or damages for noncompliance with these laws and regulations. Even if we
      are not subject to liability, other costs, which we would undertake to avoid or mitigate any such liability, such
      as the cost of removing or remediating hazardous or toxic substances could be substantial. These laws and
      regulations generally govern wastewater discharges, air emissions, the operation and removal of underground and
      above-ground storage tanks, the use, storage, treatment, transportation and disposal of solid and hazardous
      materials, the remediation of contamination associated with the release or disposal of solid and hazardous
      materials, the presence of toxic building materials and other health and safety-related concerns.
    </p>

    <p class="mt-4">
      Some of these laws and regulations may impose joint and several liability on the owners or operators of real
      property for the costs to investigate or remediate contaminated properties, regardless of fault, whether the
      contamination occurred prior to purchase, or whether the acts causing the contamination were legal. The condition
      of properties at the time we buy them, operations in the vicinity of our properties, such as the presence of
      underground storage tanks, or activities of unrelated third parties may affect our properties.
    </p>

    <p class="mt-4">
      The presence of hazardous substances, including hazardous substances that have not been detected, or the failure
      to properly manage or remediate these substances, may hinder our ability to sell, rent or pledge such property as
      collateral for future borrowings. Any material expenditures, fines, penalties or damages we must pay will reduce
      our ability to make distributions to our investors and may reduce the value of your investment.
    </p>

    <p class="mt-4">
      In addition, when excessive moisture accumulates in buildings or on building materials, mold growth may occur,
      particularly if the moisture problem remains undiscovered or is not addressed over a period of time. Some molds
      may produce airborne toxins or irritants. Concern about indoor exposure to mold has been increasing, as exposure
      to mold may cause a variety of adverse health effects and symptoms, including allergic or other reactions. As a
      result, the presence of significant mold at any of our projects could require us to undertake a costly remediation
      program to contain or remove the mold from the affected property or development project, which would adversely
      affect our operating results.
    </p>

    <p class="mt-4">
      Environmental laws also may impose liens on property or restrictions on the manner in which property may be used
      or businesses may be operated, and these restrictions may require substantial expenditures or prevent us or our
      property manager and its assignees from operating such properties. Some of these laws and regulations have been
      amended so as to require compliance with new or more stringent standards as of future dates. Compliance with new
      or more stringent laws or regulations or stricter interpretation of existing laws may require us to incur material
      expenditures. Future laws, ordinances or regulations may impose material environmental liability.
    </p>

    <h3 class="font-semibold italic mt-4">
      The costs of defending against claims of environmental liability, of complying with environmental regulatory
      requirements, of remediating any contaminated property, or of paying personal injury or other damage claims could
      reduce our cash available for distributions to our investors.
    </h3>

    <p class="mt-4">
      Under various federal, state and local environmental laws, ordinances and regulations, a current or previous real
      property owner or operator may be liable for the cost of removing or remediating hazardous or toxic substances,
      including asbestos-containing materials and lead-based paint, on, under or in such property. These costs could be
      substantial. Such laws often impose liability whether or not the owner or operator knew of, or was responsible
      for, the presence of such hazardous or toxic substances. Third parties may seek recovery from real property owners
      or operators for personal injury or property damage associated with exposure to released hazardous substances and
      governments may seek recovery for natural resource damage. The costs of defending against claims of environmental
      liability, of complying with environmental regulatory requirements, of remediating any contaminated property, or
      of paying personal injury, property damage or natural resource damage claims could materially adversely affect our
      business, assets or results of operations and, consequently, reduce the amounts available for distribution to our
      investors.
    </p>

    <h3 class="font-semibold italic mt-4">
      Costs associated with complying with the Americans with Disabilities Act and similar laws (including but not
      limited to the Fair Housing Amendments Act of 1988 and the Rehabilitation Act of 1973) may decrease cash available
      for distributions to our investors.
    </h3>

    <p class="mt-4">
      Our properties may be subject to the Americans with Disabilities Act of 1990, as amended, or the ADA. Under the
      ADA, all places of public accommodation are required to comply with federal requirements related to access and use
      by disabled persons. The Fair Housing Amendments Act of 1988 requires apartment communities first occupied after
      March 13, 1991 to comply with design and construction requirements for disabled access. For projects receiving
      federal funds, the Rehabilitation Act of 1973 also has requirements regarding disabled access. If one or more of
      our properties that we acquire are not in compliance with such laws, then we could be required to incur additional
      costs to bring the property into compliance. We cannot predict the ultimate amount of the cost of compliance with
      such laws. Noncompliance with these laws could also result in the imposition of fines or an award of damages to
      private litigants. Substantial costs incurred to comply with such laws, as well as fines or damages resulting from
      actual or alleged noncompliance with such laws, could adversely affect us, including our future results of
      operations and cash flows.
    </p>

    <h3 class="font-semibold italic mt-4">
      Declines in the market values of the properties we invest in may adversely affect periodic reported results of
      operations and credit availability, which may reduce earnings and, in turn, cash available for distribution to our
      investors.
    </h3>

    <p class="mt-4">
      Some of the assets we invest in may be classified for accounting purposes as “available-for-sale.” These
      investments will be carried at estimated fair value and temporary changes in the market values of those assets
      will be directly charged or credited to investors’ equity without impacting net income on the income statement.
      Moreover, if we determine that a decline in the estimated fair value of an available-for-sale asset falls below
      its amortized value and is not temporary, we will recognize a loss on that asset on the income statement, which
      will reduce our earnings in the period recognized.
    </p>

    <p class="mt-4">
      A decline in the market value of the assets we invest in may adversely affect us particularly in instances where
      we have borrowed money based on the market value of those assets. If the market value of those assets declines,
      the lender may require us to post additional collateral to support the loan. If we were unable to post the
      additional collateral, we may have to sell assets at a time when we might not otherwise choose to do so. A
      reduction in credit available may reduce our earnings and, in turn, cash available for distribution to our
      investors.
    </p>

    <p class="mt-4">
      Further, credit facility providers may require us to maintain a certain amount of cash reserves or to set aside
      unlevered assets sufficient to maintain a specified liquidity position, which would allow us to satisfy our
      collateral obligations. As a result, we may not be able to leverage our assets as fully as we would choose, which
      could reduce our return on equity. If we are unable to meet these contractual obligations, our financial condition
      could deteriorate rapidly.
    </p>

    <p class="mt-4">
      Market values of our investments may decline for a number of reasons, such as changes in prevailing market rates,
      increases in defaults, increases in voluntary prepayments for those investments that we have that are subject to
      prepayment risk, widening of credit spreads and downgrades of ratings of the securities by ratings agencies.
    </p>

    <h3 class="font-semibold italic mt-4">
      A prolonged economic slowdown, a lengthy or severe recession or declining real estate values could harm our
      operations.
    </h3>

    <p class="mt-4">
      Our investments may be susceptible to economic slowdowns or recessions, which could lead to financial losses in
      our investments and a decrease in revenues, net income and assets. An economic slowdown or recession, in addition
      to other non-economic factors such as an excess supply of properties, could have a material negative impact on the
      values of, and the cash flows from, residential real estate properties, which could significantly harm our
      revenues, results of operations, financial condition, business prospects and our ability to make distributions to
      our investors. In addition, possible future declines in booking rates and rental incomes may result in decreased
      cash flows from our rental properties.
    </p>

    <h3 class="font-semibold italic mt-4">
      Deficiencies in our internal controls over financial reporting could adversely affect our ability to present
      accurately our financial statements and could materially and adversely affect us, including our business,
      reputation, results of operations, financial condition and liquidity.
    </h3>

    <p class="mt-4">
      Effective internal controls over financial reporting are necessary for us to accurately report our financial
      results. There can be no guarantee that our internal controls will be effective in accomplishing all control
      objectives all of the time. As we grow our business, our internal controls will become more complex, and we may
      require significantly more resources to ensure our internal controls remain effective. Deficiencies, including any
      material weakness, in our internal control over financial reporting could result in misstatements of our results
      of operations that could require a restatement, failing to meet our reporting obligations and causing investors to
      lose confidence in our reported financial information. These events could materially and adversely affect us,
      including our business, reputation, results of operations, financial condition and liquidity.
    </p>

    <h2 class="text-2xl font-semibold mt-4">Risks Related to U.S. Federal Income Tax Matters</h2>

    <h3 class="font-semibold italic mt-4">
      If a series cannot be treated as a corporation for tax purposes, investors may be taxed as partners in a
      partnership.
    </h3>

    <p class="mt-4">
      The rule that a separate series of a series LLC is an eligible entity that may elect to be treated as a
      corporation for federal income tax purposes is contained in proposed Treasury Regulations not yet technically in
      force (Prop. Treas. Reg. Section 301.7701- 1(a)(5)) and could be subject to change if and when those proposed
      Treasury Regulations are issued in final form. If such a change were to occur, investors in a series would likely
      be treated as partners in a partnership and would be subject to current federal income tax on their proportional
      share of the income of such series.
    </p>

    <h3 class="font-semibold italic mt-4">
      Legislative or regulatory action with respect to tax laws and regulations could adversely affect our company and
      our investors
    </h3>

    <p class="mt-4">
      In recent years, numerous legislative, judicial and administrative changes have been made in the provisions of the
      federal income tax laws applicable to investments similar to an investment in the interests. In particular, on
      December 22, 2017, President Trump signed the Tax Cuts and Jobs Act (the “Tax Act”) into law. The Tax Act includes
      major changes to U.S. tax laws and represents the most significant changes to the Internal Revenue Code since
      1986. In addition, recently enacted legislation intended to support the economy during the COVID-19 pandemic, the
      Coronavirus Aid, Relief, and Economic Security Act (the “CARES Act”), made technical corrections, or temporary
      modifications, to certain of the provisions of the Tax Act. Additional changes to the tax laws are likely to
      continue to occur, and we cannot assure investors that any such changes will not adversely affect their taxation,
      the investment in their interests or the market value or the resale potential of our assets. Investors are urged
      to consult with their own tax advisor with respect to the impact of recent legislation, including the Tax Act and
      CARES Act, on their investment in the interests and the status of legislative, regulatory or administrative
      developments and proposals and their potential effect on an investment in the interests.
    </p>

    <h2 class="font-semibold text-2xl mt-6">Risks Related to Ownership of our Interests</h2>

    <h3 class="font-semibold italic mt-4">
      There is currently no public trading market for our securities.
    </h3>

    <p class="mt-4">
      There is currently no public trading market for any of our series interests, and an active market may not develop
      or be sustained. If an active public trading market for our securities does not develop or is not sustained, it
      may be difficult or impossible for you to resell your interests at any price. Even if a public market does
      develop, the market price could decline below the amount you paid for your interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      If a market ever develops for our interests, the market price and trading volume may be volatile.
    </h3>

    <p class="mt-4">
      If a market develops for our interests, the market price of our interests could fluctuate significantly for many
      reasons, including reasons unrelated to our performance, the series properties or the series, such as reports by
      industry analysts, investor perceptions, or announcements by our competitors regarding their own performance, as
      well as general economic and industry conditions. For example, to the extent that other companies, whether large
      or small, within our industry experience declines in their share prices, the value of our interests may decline as
      well.
    </p>

    <p class="mt-4">
      In addition, fluctuations in operating results of a particular series or the failure of operating results to meet
      the expectations of investors may negatively impact the price of our securities. Operating results may fluctuate
      in the future due to a variety of factors that could negatively affect revenues or expenses in any particular
      reporting period, including vulnerability of our business to a general economic downturn; changes in the laws that
      affect our operations; competition; compensation related expenses; application of accounting standards;
      seasonality; and our ability to obtain and maintain all necessary certifications or licenses to conduct our
      business.
    </p>

    <h3 class="font-semibold italic mt-4">
      There may be state law restrictions on an investor’s ability to sell the interests.
    </h3>

    <p class="mt-4">
      Each state has its own securities laws, often called “blue sky” laws, which (1) limit sales of securities to a
      state’s residents unless the securities are registered in that state or qualify for an exemption from registration
      and (2) govern the reporting requirements for broker-dealers and stockbrokers doing business directly or
      indirectly in the state. Before a security is sold in a state, there must be a registration in place to cover the
      transaction, or it must be exempt from registration. Also, Dalmore must be registered in that state. We do not
      know whether our securities will be registered, or exempt, under the laws of any states. A determination regarding
      registration will be made by broker-dealers, if any, who agree to serve as the market-makers for our interests.
      There may be significant state blue sky law restrictions on the ability of investors to sell, and on purchasers to
      buy, our interests. Investors should consider the resale market for our securities to be limited. Investors may be
      unable to resell their securities, or they may be unable to resell them without the significant expense of state
      registration or qualification.
    </p>

    <h3 class="font-semibold italic mt-4">
      Investors’ limited voting rights restrict their ability to affect the operations of the company or a series.
    </h3>

    <p class="mt-4">
      Our manager has a unilateral ability to amend the operating agreement and the allocation policy in certain
      circumstances without the consent of the investors. The investors only have limited voting rights in respect of
      the series in which they are invested. Investors will therefore be subject to any amendments the manager makes (if
      any) to the operating agreement and allocation policy and also any decision it takes in respect of our company and
      the applicable series, which the investors do not get a right to vote upon. Investors may not necessarily agree
      with such amendments or decisions and such amendments or decisions may not be in the best interests of all of the
      investors as a whole but only a limited number.
    </p>

    <p class="mt-4">
      Furthermore, our manager can only be removed as manager of our company and each series of interests in a very
      limited circumstance, following a non-appealable judgment of a court of competent jurisdiction to have committed
      fraud in connection with our company or a series of interests. Investors would therefore not be able to remove the
      manager merely because they did not agree, for example, with how the manager was operating a series property.
    </p>

    <h3 class="font-semibold italic mt-4">
      This offering is being conducted on a “best efforts” basis and we may not be able to execute our growth strategy
      if we are unable to raise this capital.
    </h3>

    <p class="mt-4">
      We are offering the interests on a “best efforts” basis, and we can give no assurance that all of the offered
      interests will be sold. If you invest in our interests and more than the minimum number of offered interests are
      sold, but less than all of the offered interests are sold, the risk of losing your entire investment will be
      increased. If substantially less than the maximum amount of interests offered are sold, we may be unable to fund
      all the intended uses described in this offering circular from the net proceeds anticipated from this offering
      without obtaining funds from alternative sources or using working capital that we generate. Alternative sources of
      funding may not be available to us at what we consider to be a reasonable cost, and the working capital generated
      by us may not be sufficient to fund any uses not financed by offering net proceeds.
    </p>

    <h3 class="font-semibold italic mt-4">
      The offering price for the interests determined by us may not necessarily bear any relationship to established
      valuation criteria such as earnings, book value or assets that may be agreed to between purchasers and sellers in
      private transactions or that may prevail in the market if and when our interests can be traded publicly.
    </h3>

    <p class="mt-4">
      The price of the interests is a derivative result of the cost that a series is expected to incur in acquiring a
      property. These prices do not necessarily accurately reflect the actual value of the interests or the price that
      may be realized upon disposition of the interests.
    </p>

    <h3 class="font-semibold italic mt-4">
      Funds from purchasers accompanying subscriptions for the interests will not accrue interest while in escrow.
    </h3>

    <p class="mt-4">
      The funds paid by an investor for interests will be held in a non-interest-bearing escrow account until the
      admission of the subscriber as an investor in the applicable series, if such subscription is accepted. Purchasers
      will not have the use of such funds or receive interest thereon pending the completion of the offering. No
      subscriptions will be accepted, and no interests will be sold unless valid subscriptions for the offering are
      received and accepted prior to the termination of the applicable offering. If we terminate an offering prior to
      accepting a subscriber’s subscription, escrowed funds will be returned promptly, without interest or deduction, to
      the proposed investor.
    </p>

    <h3 class="font-semibold italic mt-4">
      Your interest in us will be diluted if and when we issue additional interests in a series, which could reduce the
      overall value of your investment.
    </h3>

    <p class="mt-4">Investors do not have preemptive rights to any additional series interests we issue in the future.
      Under our operating agreement, we have authority to issue an unlimited number of additional interests in a series
      or other securities, although, under Regulation A, we are only allowed to sell up to $75 million of interests in
      any 12-month period (although we may raise capital in other ways). In particular, our manager is authorized,
      subject to the restrictions of Regulation A and other applicable securities laws, to provide for the issuance of
      an unlimited amount of one or more classes or series of interests, and to designate the number of interests, the
      relative powers, preferences and rights, and the qualifications, limitations or restrictions applicable to each
      class or series thereof in the series designation applicable to such class or series, without investor approval.
      The manager may elect to (i) sell additional interests in our current and future continuous offerings, (ii) issue
      equity interests in private offerings, or (iii) issue interests to our manager, or its successors or assigns, in
      payment of an outstanding fee obligation. In addition, the asset management fee (as described in
      “Management—Management Compensation”) includes the issuance of series interests to the manager. To the extent we
      issue additional equity interests, your percentage ownership interest in us will be diluted. In addition,
      depending upon the terms and pricing of any additional offerings and the value of our investments, you may also
      experience dilution in the book value and fair value of your interests.</p>

    <h3 class="font-semibold italic mt-4">
      Any dispute in relation to the operating agreement is subject to the exclusive jurisdiction of the Court of
      Chancery of the State of Delaware, except where federal law requires that certain claims be brought in federal
      courts. The operating agreement, to the fullest extent permitted by applicable law, provides for investors to
      waive their right to a jury trial.
    </h3>

    <p class="mt-4">
      Each investor will covenant and agree not to bring any claim in any venue other than the Court of Chancery of the
      State of Delaware, or if required by federal law, a federal court of the United States, as in the case of claims
      brought under the Exchange Act. Section 27 of the Exchange Act creates exclusive federal jurisdiction over all
      suits brought to enforce any duty or liability created by the Exchange Act or the rules and regulations
      thereunder. As a result, the exclusive forum provision will not apply to suits brought to enforce any duty or
      liability created by the Exchange Act or any other claim for which the federal courts have exclusive jurisdiction.
      Furthermore, Section 22 of the Securities Act creates concurrent jurisdiction for federal and state courts over
      all suits brought to enforce any duty or liability created by the Securities Act or the rules and regulations
      thereunder. As a result, the exclusive forum provisions will not apply to suits brought to enforce any duty or
      liability created by the Securities Act or any other claim for which the federal and state courts have concurrent
      jurisdiction, and investors will not be deemed to have waived our compliance with the federal securities laws and
      the rules and regulations thereunder.
    </p>

    <p class="mt-4">
      If an interest holder were to bring a claim against our company or the manager pursuant to the operating agreement
      and such claim was governed by state law, it would have to bring such claim in the Delaware Court of Chancery. The
      operating agreement, to the fullest extent permitted by applicable law and subject to limited exceptions, provides
      for investors to consent to exclusive jurisdiction to Delaware Court of Chancery and for a waiver of the right to
      a trial by jury, if such waiver is allowed by the court where the claim is brought.
    </p>

    <p class="mt-4">
      If we opposed a jury trial demand based on the waiver, the court would determine whether the waiver was
      enforceable based on the facts and circumstances of that case in accordance with the applicable state and federal
      law. To our knowledge, the enforceability of a contractual pre-dispute jury trial waiver in connection with claims
      arising under the federal securities laws has not been finally adjudicated by the United States Supreme Court.
      However, we believe that a contractual pre-dispute jury trial waiver provision is generally enforceable, including
      under the laws of the Delaware, which govern the operating agreement, by a federal or state court in the State of
      Delaware, which has exclusive jurisdiction over matters arising under the operating agreement. In determining
      whether to enforce a contractual pre-dispute jury trial waiver provision, courts will generally consider whether a
      party knowingly, intelligently and voluntarily waived the right to a jury trial.
    </p>

    <p class="mt-4">
      We believe that this is the case with respect to the operating agreement and our interests. It is advisable that
      you consult legal counsel regarding the jury waiver provision before entering into the operating agreement.
      Nevertheless, if this jury trial waiver provision is not permitted by applicable law, an action could proceed
      under the terms of the operating agreement with a jury trial. No condition, stipulation or provision of the
      operating agreement or our interests serves as a waiver by any investor or beneficial owner of our interests or by
      us of compliance with the U.S. federal securities laws and the rules and regulations promulgated thereunder.
      Additionally, our company does not believe that claims under the federal securities laws shall be subject to the
      jury trial waiver provision, and our company believes that the provision does not impact the rights of any
      investor or beneficial owner of our interests to bring claims under the federal securities laws or the rules and
      regulations thereunder.
    </p>

    <p class="mt-4">
      These provisions may have the effect of limiting the ability of investors to bring a legal claim against us due to
      geographic limitations and may limit an investor’s ability to bring a claim in a judicial forum that it finds
      favorable for disputes with us. Furthermore, waiver of a trial by jury may disadvantage an investor to the extent
      a judge might be less likely than a jury to resolve an action in the investor’s favor. Further, if a court were to
      find this exclusive forum provision inapplicable to, or unenforceable in respect of, an action or proceeding
      against us, then we may incur additional costs associated with resolving these matters in other jurisdictions,
      which could materially and adversely affect our business and financial condition.
    </p>
  </div>
  <PageTop/>
  <Footer/>
</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import Footer from "@/components/Footer";
import PageTop from "@/views/investors/components/PageTop";

export default {
  name: "RiskFactors",
  components: {PageTop, TopBar, Footer}
}
</script>

<style scoped>

</style>