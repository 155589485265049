<template>
  <div class="w-full flex flex-col">
    <label class="uppercase text-xs mb-2 text-black-lighter" v-if="placeholderAsLabel">{{placeholder}}</label>
    <div class="relative select-wrapper w-full border bg-white text-sm flex-grow">
    <select @change="valueChanged" name="make" class="w-full border-0 bg-white p-2 outline-none h-full">
      <option v-if="!placeholderAsLabel && placeholder" value="" class="bg-white">{{ placeholder }}</option>
      <!-- <option v-if="selectedValue" :selected="true" :value="selectedValue['value']">{{ selectedValue['label'] }}</option> -->
      <option :value="option['value']" :selected="isSelected(option)" v-for="(option, index) in selectOptions" :key="index">{{ option['label'] }}</option>
    </select>
    <div class="absolute pointer-events-none right-0 top-0 bottom-0 flex items-center flex-row mr-3">
      <i class="icon xs select-dropdown"></i>
    </div>
  </div>
  </div>
  
</template>
<script>
export default {
  props: {
    placeholder: String,
    options: Array,
    value: String,
    placeholderAsLabel: Boolean,
    selectedValue: Object,
  },
  data() {
    return {
      selectOptions: [],
    };
  },
  methods: {
    isSelected(option) {
      return (
        this.value == option["value"] || (option["default"] && !this.value)
      );
    },
    valueChanged(event) {
      const value = event.target.value;
      this.$emit("valueChange", value);
      this.$emit("input", value);
    },
    prepareOptions() {
      this.tempOptions = [];
      for (const option of this.options) {
        if (typeof option === "string") {
          this.tempOptions.push({
            label: option,
            value: option,
          });
        } else {
          this.tempOptions.push(option);
        }
      }
      this.selectOptions = [...this.tempOptions];
    },
    checkSelectedValue() {
      if (this.selectedValue && !this.hasOption(this.selectedValue)) {
        this.selectOptions = [
          ...this.selectOptions,
          {
            value: this.selectedValue["value"],
            label: this.selectedValue["label"],
          },
        ];
      }
    },
    hasOption(selectedOption) {
      for (const option of this.selectOptions) {
        if (`${selectedOption["value"]}` === `${option["value"]}`) {
          return true;
        }
      }
    },
  },
  watch: {
    options() {
      this.prepareOptions();
    },
    selectedValue() {
      this.checkSelectedValue();
    },
  },
  mounted() {
    this.prepareOptions();
  },
};
</script>
<style scoped>
img {
  height: 3rem;
}
</style>
