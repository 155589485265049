<template lang="">
  <transition name="bounce">

    <div
         class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-40 flex justify-center items-center z-100 overflow-y-scroll">
      <div :class="bodyClass"
           class="modal flex flex-col items-center relative z-auto max-w-2mxl">
        <button type="button"
                class="absolute top-4 right-4 border-0 text-white text-2xl p-0 bg-transparent z-10"
                @click="close">
          <a class="close"
             href="#">×</a>
        </button>
        <div class="relative bg-blue">
          <div class="relative">
            <img src="/images/lucky-draw-desktop-banner.png"
                 class="absolute left-0 right-0 top-0 bottom-0">
            <div class="w-full py-4 px-8 relative">
              <h1 class="font-bold text-stroke-blue text-center text-white text-4xl mt-4">
                A chance to win a CAR !!! For FREE ...
              </h1>

              <div class="mt-52 text-center mb-4">
                <p class="font-display font-extrabold text-2xl text-white">Register today to get your free raffle
                  ticket.</p>
                <p class="font-display font-semibold text-white">
                  This raffle is sponsored by FairPY <br/> "The smartest way to buy a pre-owned vehicle"
                </p>
              </div>
              <div class="">
                <div class="relative mb-4 grid grid-cols-3 grid-rows-1 gap-6">
                  <div class="">
                    <input id="name"
                           v-model="name"
                           :class="['border text-white bg-blue placeholder-white bg-transparent px-2 py-2 pb-2 w-full text-sm', errors['name'] ? 'border-red-700' : '',]"
                           placeholder="Name" />
                    <span v-if="errors['name']"
                          class="text-red-700 text-xs leading-tight block">{{ errors["name"] }}</span>
                  </div>

                  <div class="">
                    <input id="phoneNumber"
                           v-model="phoneNumber"
                           :class="['border text-white bg-blue placeholder-white bg-transparent px-2 py-2 pb-2 w-full text-sm', errors['phoneNumber'] ? 'border-red-700' : '',]"
                           placeholder="Phone Number" />
                    <span v-if="errors['lastName']"
                          class="text-red-700 text-xs leading-tight block">{{ errors["phoneNumber"] }}</span>
                  </div>

                  <div class="">
                    <input id="email"
                           v-model="email"
                           :class="['border text-white bg-blue placeholder-white bg-transparent px-2 py-2 pb-2 w-full text-sm', errors['email'] ? 'border-red-700' : '',]"
                           placeholder="Email" />
                    <span v-if="errors['email']"
                          class="text-red-700 text-xs leading-tight block">{{ errors["email"] }}</span>
                  </div>
                </div>

                <div class="checkbox flex flex-row items-start justify-between">
                  <div class="">
                    <label class="checkbox-container">
                      <input name="subcribe"
                             checked
                             type="checkbox"><span class="checkmark"></span>Subscribe to hot car deals & Updates
                    </label>

                    <label class="checkbox-container">
                      <input name="terms"
                             type="checkbox">
                      <span class="checkmark"></span>I agree to the <a style="text-decoration: underline;"
                         target="_blank"
                         href="/terms-conditions">terms & conditions</a>
                    </label>
                  </div>
                  <div class="">
                    <button @click.prevent="submitLuckyDraw" class="text-blue-dark bg-white px-14 py-1 mt-2">SUBMIT</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex px-8 py-4 w-full items-center justify-between bg-white bg-opacity-80">
            <div class="">
              <Logo height="2rem"
                    class="" />
            </div>
            <div class="">
              <div class="flex flex-row items-center">
                <router-link to="/view-rules" target="_blank">
                <div class="flex flex-row items-center leading-none text-xs text-blue cursor-pointer mr-3">
                  <i class="icon lg rules-white mr-2 flex-shrink-0"></i> View Rules >
                </div>
                </router-link>
                <div class="flex flex-row items-center leading-none text-xs text-blue ">
                  <i class="icon lg ends-white mr-2 flex-shrink-0"></i> Ends By 10 July '22
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>
<script>
  import Logo from './Logo.vue';
  export default {
    props: {
      bodyClass: String,
      showModalBg: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Logo
    },
    methods: {
      close() {
        this.$emit("close");
      },
      submitLuckyDraw() {
        this.$emit("success");
      },
    },
    data() {
      return {
        name: "",
        phoneNumber: "",
        email: "",
        errors: "",
      };
    },
  };
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.25s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.25s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media only screen and (min-width: 768px) {
    .bg-image {
      width: 52%;
      /* The width is 100%, when the viewport is 800px or smaller */
    }
  }

  .checkbox {
    color: #ffffff;
    font-size: 10px;
    font-weight: 200;
    line-height: 30px;
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 8px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #0785d8;
    border: 1px #ffffff solid;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input~.checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked~.checkmark {
    background-color: #0785d8;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 3px;
    top: 1px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }

  *::placeholder {
    /* modern browser */
    color: #ffffff;
    font-size: 10px !important;
    font-weight: 200 !important;
  }

  .checkbox .left {
    display: inline-block;
  }

  .checkbox .right {
    float: right;
  }
</style>