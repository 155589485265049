<template lang="">
  <transition name="bounce">

    <div
         class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-60 flex justify-center items-center z-topmost overflow-y-scroll">
      <button type="button"
              class="absolute top-4 right-4 border-0 p-0 bg-transparent z-10"
              @click="close">
        <i class="icon close small"></i>
      </button>
      <div class="absolute bg-blue rounded-lg max-h-mobile-popup left-0 right-0 bottom-0 overflow-y-auto">
        <div class="modal shadow-xl flex flex-col items-start relative z-auto">

          <img class="absolute top-0 left-0 right-0 z-0"
               src="/images/lucky-draw-mobile-banner.png" />
          <div class="w-full">
            <div class="relative flex flex-col items-center w-full p-4">
              <h2 class="text-2.5xl text-white font-black text-center text-stroke-blue">A chance to win a CAR
                !!!!<br />For FREE...</h2>

              <p class="font-display text-2.5xl text-white text-center mt-40 leading-none font-bold">
                Register today to get your free raffle ticket
              </p>
              <p class="font-display mt-4 text-center text-white font-semibold">
                This raffle is sponsored by FairPY<br/>"The smartest way to buy a pre-owned vehicle"
              </p>

              <div class="relative w-full mb-4 mt-6">
                <input class="border text-white border-white bg-transparent placeholder-white p-3 px-4 w-full"
                       v-model="name"
                       placeholder="Name" />
              </div>
              <div class="relative w-full mb-4">
                <input class="border text-white border-white bg-transparent placeholder-white p-3 px-4 w-full"
                       v-model="phone"
                       placeholder="Phone" />
              </div>
              <div class="relative w-full mb-4">
                <input class="border text-white border-white bg-transparent placeholder-white p-3 px-4 w-full"
                       v-model="email"
                       placeholder="Email" />
              </div>
              <div class="relative mt-4 w-full">
                <label class="text-white leading-none flex flex-row items-start justify-start w-full ">
                  <input id="default-checkbox"
                         type="checkbox"
                         value="1"
                         class="w-4 h-4 text-blue bg-transparent rounded border-white mr-2">
                  <div>Subscribe to hot car deals & Updates</div>
                </label>
              </div>
              <div class="relative mt-4 mb-4 w-full">
                <label class="text-white leading-none flex flex-row items-start justify-start w-full">
                  <input id="default-checkbox"
                         type="checkbox"
                         value="1"
                         class="w-4 h-4 text-blue bg-transparent rounded border-white mr-2">
                  I agree to the&nbsp;<a @click.prevent="showTerms"
                     class="underline"
                     href="#"> Terms & Conditions</a>
                </label>
              </div>
              <button @click.prevent="submitLuckyDraw"
                      class="bg-white uppercase text-blue p-3 w-full block mt-4">
                Submit
              </button>
              <div class="mt-8 flex flex-row w-full items-center">
                <div class="w-1/2">
                  <div @click.prevent="showRules"
                       class="flex flex-row items-center text-white leading-none text-sm">
                    <i class="icon above-xl rules-mobile mr-3 flex-shrink-0"></i> View Rules
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="flex flex-row items-center text-white leading-none text-sm">
                    <i class="icon above-xl ends-mobile mr-3 flex-shrink-0"></i> Ends By 10 July '22
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white text-center w-full flex items-center justify-center p-3">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import Logo from '../components/Logo.vue';

  export default {
    props: {
      bodyClass: String,
      showModalBg: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Logo
    },
    methods: {
      close() {
        this.$emit("close");
      },
      submitLuckyDraw() {
        this.$emit("success");
      },
      showTerms() {
        this.emitter.emit("showTerms", 'terms');
        return;
      },
      showRules() {
        this.emitter.emit("showTerms", 'rules');
        return;
      }
    },
    data() {
      return {
        name: '',
        phone: '',
        email: '',
      };
    },
  };
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.25s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.25s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>