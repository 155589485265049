<template lang="">
    <div class="bg-white flex flex-col lg:h-64 h-48 justify-end relative rounded-md overflow-hidden lg:p-6 p-3 group">
          <div :style="{ backgroundImage: 'url(/images/lifestyle/' + image + ')', backgroundPosition:'center' }" class="absolute left-0 top-0 right-0 bottom-0 z-0 bg-no-repeat bg-cover transform group-hover:scale-110 transition-all"/>
          <div class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-b from-transparent via-transparent to-blue-dark z-10"></div>
          <div class="flex flex-col relative z-20 items-center">
            <h2 class="text-white lg:text-xl text-base uppercase font-display font-extrabold text-center">{{title}}</h2>
            <p class="text-white font-light text-center lg:text-base text-xs">
                  {{caption}}
            </p>
          </div>
    </div>
</template>
<script>
export default {
  props: {
    image: String,
    title: String,
    caption: String,
  },
};
</script>
<style lang="">
</style>