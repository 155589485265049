<template>
  <div class="w-100 bg-blue-dark">
    <div
      class="
        container
        mx-auto
        flex-col
        p-3
        lg:p-0 lg:flex-row
        justify-end
        text-white text-xs
        font-display
        flex
      "
    >
      <a
        id="toggle-button"
        href="#"
        @click="toggle = !toggle"
        class="text-right pr-5 mr-3 relative lg:hidden"
        >Support & Contact <i class="arrow-down absolute top-1 right-0"></i
      ></a>
      <ul v-if="toggle" class="flex lg:flex lg:flex-row flex-col">
        <li v-if="loggedIn && this.searchHistoryCount > 0" class="">
          <a v-if="loggedIn" class="p-3 cursor-pointer block hover:bg-blue" href="/search_history">
            Search History
          </a>
        </li>
        <li v-if="loggedIn && this.wishlistCarsCount > 0">
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/wishlist-cars">
            Wishlist cars
          </a>
        </li>
        <li class="">
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/about-us">
            About
          </a>
        </li>
        <li>
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/faqs">
            FAQs
          </a>
        </li>
        <li>
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/contact-us">
            Contact Us
          </a>
        </li>
        <li v-if="loggedIn" class="p-3 cursor-pointer hover:bg-blue">
         <a href="/profileinfo"> {{ `Welcome ${currentUser.first_name} ${currentUser.last_name}` }}</a>
        </li>
        <li v-if="loggedIn">
          <a
            class="p-3 cursor-pointer block hover:bg-blue"
            href="#"
            @click.prevent="logout"
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <div
      @click="disableSlide ? scrollTo('updates-section') : toggleSubscribe()"
      class="
        text-white
        cursor-pointer
        bg-blue-dark
        text-xs
        lg:p-3
        p-2
        pt-1
        rounded-full
        transform
        -rotate-90
        origin-right
        side-leaf
        fixed
        z-50
        right-0
        top-72
      "
    >
      <div class="px-6 pr-12 lg:mb-8 mb-4">
        Know Updates. <span class="font-bold">Subscribe</span>
      </div>
    </div>
    <div
      @click="disableSlide ? scrollTo('questions-section') : toggleChat()"
      class="
        text-white
        cursor-pointer
        bg-blue
        text-xs
        lg:p-3
        p-2
        pt-1
        rounded-full
        transform
        -rotate-90
        origin-right
        side-leaf
        fixed
        z-50
        right-0
        top-32
        hidden
      "
      :class="disableSlide ? '' : 'intercom_link'"
    >
      <div class="px-6 lg:mb-8 mb-4">
        Have question? <span class="font-bold">Ask Us!</span>
      </div>
    </div>

    <transition v-if="toggle" name="subscribe-enter">
      <div
        :class="[showSubscribe ? 'right-0' : '-right-128']"
        class="
          fixed
          bottom-20
          shadow-regular
          w-98
          z-100
          transition-all
          duration-300
        "
      >
        <div class="relative">
          <SubscribeLeafForm />
          <div
            :class="
              showSubscribe
                ? 'opacity-100 top-10 -rotate-90'
                : 'opacity-0 top-0 -rotate-120'
            "
            class="
              text-white
              cursor-pointer
              bg-blue-dark
              text-xs
              p-3
              rounded-t-full
              transform
              origin-top-right
              w-64
              h-10
              side-leaf
              absolute
              z-99
              -left-72.5
              select-none
              custom-transition
            "
          >
            <div class="px-6 pb-4 text-center relative">
              Know Updates. <span class="font-bold">Subscribe</span>
              <i
                @click="toggleSubscribe()"
                class="icon leaf-close absolute -top-7"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition v-if="toggle" name="chat-enter">
      <div
        :class="[showChat ? 'right-0' : '-right-128']"
        class="
          fixed
          bottom-20
          shadow-regular
          w-96
          z-100
          transition-all
          duration-150
        "
      >
        <div class="relative">
          <div class="max-h-80vh overflow-y-scroll">
            <ChatLeafForm />
          </div>
          <div
            :class="
              showChat
                ? 'opacity-100 top-10 -rotate-90'
                : 'opacity-0 top-0 -rotate-120'
            "
            class="
              text-white
              cursor-pointer
              bg-blue
              text-xs
              p-3
              rounded-t-full
              custom-transition
              transform
              origin-top-right
              w-64
              h-10
              side-leaf
              absolute
              z-99
              -left-72.5
              select-none
            "
          >
            <div class="px-6 pb-4 text-center relative">
              Have question? <span class="font-bold">Ask Us!</span>
              <i
                @click="toggleChat()"
                class="icon dark-leaf-close absolute -top-7"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition v-if="!toggle" name="subscribe-enter">
      <div
        :class="[showSubscribe ? 'top-0' : 'top-full']"
        class="
          fixed
          left-0
          w-full
          h-full
          bg-black-full bg-opacity-30
          shadow-regular
          z-100
          transition-all
          duration-300
          p-5
          flex
          items-center
        "
      >
        <div class="relative">
          <SubscribeLeafForm />
          <div
            :class="
              showSubscribe
                ? 'opacity-100 -top-10 rotate-0'
                : 'opacity-0 top-10 -rotate-12'
            "
            class="
              text-white
              cursor-pointer
              bg-blue-dark
              text-sm
              p-3
              rounded-t-full
              transform
              origin-top-right
              w-64
              h-10
              side-leaf
              absolute
              z-99
              left-0
              select-none
              custom-transition
            "
          >
            <div class="px-3 pb-4 text-center relative">
              Know Updates. <span class="font-bold">Subscribe</span>
            </div>
          </div>
          <i
            @click="toggleSubscribe()"
            class="icon white-close md absolute -top-7 right-0"
          ></i>
        </div>
      </div>
    </transition>

    <transition v-if="!toggle" name="chat-enter">
      <div
        :class="[showChat ? 'top-0' : 'top-full']"
        class="
          fixed
          left-0
          w-full
          top-0
          h-full
          bg-black-full bg-opacity-30
          shadow-regular
          z-100
          transition-all
          duration-300
          flex
          pt-20
        "
      >
        <div class="relative flex-grow">
          <div class="overflow-y-scroll h-full">
            <ChatLeafForm />
          </div>
          <div
            :class="
              showChat
                ? 'opacity-100 -top-10 rotate-0'
                : 'opacity-0 top-10 -rotate-12'
            "
            class="
              text-white
              cursor-pointer
              bg-blue
              text-sm
              p-3
              rounded-t-full
              custom-transition
              transform
              origin-top-right
              w-64
              h-10
              side-leaf
              absolute
              z-99
              -left-0
              select-none
            "
          >
            <div class="px-3 pb-4 text-center relative">
              Have question? <span class="font-bold">Ask Us!</span>
            </div>
          </div>
          <i
            @click="toggleChat()"
            class="icon white-close md absolute -top-7 right-0"
          ></i>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ChatLeafForm from "./ChatLeafForm.vue";
import SubscribeLeafForm from "./SubscribeLeafForm.vue";

export default {
  props: {
    disableSlide: Boolean,
  },
  components: {
    SubscribeLeafForm,
    ChatLeafForm,
  },
  data() {
    return {
      toggle: screen.width > 768,
      showSubscribe: false,
      showChat: false,
      wishlistCarsCount: '',
      searchHistoryCount: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.currentUser) {
      this.wishlistCount();
      this.searchHistoryListCount();
    }
    window.addEventListener("resize", this.myEventHandler);

    this.emitter.on("showChat", (value) => {
      if (value) {
        console.log(value);
      }
      // this.showChat = value;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.toggle = screen.width > 768;
    },
    scrollTo(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    toggleSubscribe() {
      this.showSubscribe = !this.showSubscribe;
      this.showChat = this.showSubscribe ? false : this.showChat;
    },
    toggleChat() {
      // this.showChat = !this.showChat;
      // this.showSubscribe = this.showChat ? false : this.showSubscribe;
    },

    logout() {
      this.$store.dispatch("auth/logout");
    },

    wishlistCount() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("wishlistCount", user_id).then(
          (res) => {
            this.wishlistCarsCount = res.data.data
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    searchHistoryListCount() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("searchHistoryCount", user_id).then(
          (res) => {
            this.searchHistoryCount = res.data.data
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
  },
};
</script>
<style>
.custom-transition {
  -webkit-transition: opacity 150ms ease-out 300ms, top 150ms ease-out 300ms,
    transform 150ms ease-out 400ms;
  -moz-transition: opacity 150ms ease-out 300ms, top 150ms ease-out 300ms,
    transform 150ms ease-out 400ms;
  -o-transition: opacity 150ms ease-out 300ms, top 150ms ease-out 300ms,
    transform 150ms ease-out 400ms;
  transition: opacity 150ms ease-out 300ms, top 150ms ease-out 300ms,
    transform 150ms ease-out 400ms;
}
</style>