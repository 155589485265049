<template lang="">
  <div
    class="p-4 flex flex-col bg-white lg:w-4/12 my-auto mt-6 pt-8 lg:mt-0"
  >
    <form class="">
      <SelectInput class="mb-6" placeholder="Any Make" :options="makes"
            @valueChange="modelsForMake" />
      <SelectInput class="mb-6" placeholder="Any Model" :options="models"
            @valueChange="seriesForModel" />

      <div
        class="relative select-wrapper w-full border p-2 flex flex-col bg-white mb-3 text-sm"
      >
        <div class="flex flex-row w-full justify-between items-center mb-1">
          <label>Odometer</label>
          <div class="flex flex-row items-center flex-grow-0">
            <input @keyup="minOdoValueChange(odometerValue[0])" class="w-20 text-right border px-2 py-1 rounded" name="min_budget" v-model="odometerValue[0]" />
            <div>&nbsp;-&nbsp;</div>
            <input @keyup="maxOdoValueChange(odometerValue[1])" class="w-20 text-right border px-2 py-1 rounded" name="max_budget" v-model="odometerValue[1]" />
          </div>
        </div>
        <vue-slider
          class="mx-2"
          :interval="1000"
          :min="0"
          :max="100000"
          v-model="odometerValue"
        ></vue-slider>
        <div class="flex flex-row justify-between">
          <label>0 mi</label>
          <label>100,000 mi</label>
        </div>
      </div>

      <div
        class="relative select-wrapper w-full border p-2 flex flex-col bg-white mb-3 text-sm"
      >
        <div class="flex flex-row w-full justify-between items-center mb-1">
          <label>Year</label>
          <div class="flex flex-row items-center flex-grow-0">
            <input @keyup="minYearValueChange(yearValue[0])" class="w-20 border text-right px-2 py-1 rounded" name="min_budget" v-model="yearValue[0]" />
            <div>&nbsp;-&nbsp;</div>
            <input @keyup="maxYearValueChange(yearValue[1])" class="w-20 border text-right px-2 py-1 rounded" name="max_budget" v-model="yearValue[1]" />
          </div>
        </div>
        <vue-slider
        class="mx-2"
          :step="1"
          :min="2008"
          :max="2022"
          v-model="yearValue"
        ></vue-slider>
        <div class="flex flex-row justify-between">
          <label>2008</label>
          <label>2022</label>
        </div>
      </div>
      <div class="rounded-full bg-gray-100 w-full p-1 flex items-center mb-2 mt-4 relative">
        <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex" 
            :class="{ 'left-1/2': isPrice, 'left-0': !isPrice}">
            <div class="bg-white rounded-full shadow-sm w-full h-full"></div>
        </div>
          <div @click="togglePrice" class="w-1/2 text-center py-2 text-sm relative cursor-pointer" :class="{ 'text-blue':isPrice }">
            Finance
          </div>
          <div @click="togglePrice" class="py-2 w-1/2 text-sm text-center relative cursor-pointer" :class="{ 'text-blue':!isPrice }">
            Cash
          </div>
      </div>
      <div
        v-if="isPrice"
        class="relative select-wrapper w-full p-2 flex flex-col bg-white mb-3 text-sm"
      >
        <div class="flex flex-row w-full justify-between items-center mb-1">
          <label>Your Budget</label>
          <div class="flex flex-row items-center flex-grow-0">
            <div class="relative">
              <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
              <input class="w-20 border pr-2 py-1 pl-4 rounded text-right" @keyup="minValueChange(value[0])" name="min_budget" v-model="value[0]" />
              </div>
            <div>&nbsp;-&nbsp;</div>
            <div class="relative">
              <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
              <input class="w-20 border pr-2 py-1 pl-4 rounded text-right" @keyup="maxValueChange(value[1])" name="max_budget" v-model="value[1]" />
            </div>
          </div>
        </div>
        <vue-slider
          class="mx-2"
          :interval="500"
          :min="1000"
          :max="200000"
          v-model="value"
        ></vue-slider>
        <div class="flex flex-row justify-between">
          <label>$1,000</label>
          <label>$200,000</label>
        </div>
      </div>

      <div
        v-if="!isPrice"
        class="relative select-wrapper w-full p-2 flex flex-col bg-white mb-3 text-sm"
      >
        <div class="flex flex-row w-full justify-between items-center mb-1">
          <label>Monthly Budget</label>
          <div class="flex flex-row items-center flex-grow-0">
            <div class="relative">
              <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
              <input class="w-20 border pr-2 py-1 pl-4 rounded text-right" @keyup="minEmiValueChange(emiValue[0])" name="min_emi_budget" v-model="emiValue[0]" />
              </div>
            <div>&nbsp;-&nbsp;</div>
            <div class="relative">
              <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
              <input class="w-20 border pr-2 py-1 pl-4 rounded text-right" @keyup="maxEmiValueChange(emiValue[1])" name="max_emi_budget" v-model="emiValue[1]" />
            </div>
          </div>
        </div>
        <vue-slider
          class="mx-2"
          :interval="100"
          :min="0"
          :max="2000"
          v-model="emiValue"
        ></vue-slider>
        <div class="flex flex-row justify-between">
          <label>$0</label>
          <label>$2,000</label>
        </div>
      </div>
      <button
        type="button"
        @click="gotoSearch"
        class="bg-blue text-white w-full p-2 py-3 text-sm uppercase mt-3 mb-2"
      >
        Search Cars
      </button>
    </form>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import { mapGetters } from "vuex";
import SelectInput from "./SelectInput.vue";

export default {
  components: {
    SelectInput,
    VueSlider,
  },
  computed: {
    ...mapGetters({
      makeObjects: "makes",
      filters: "filters",
      defaultParams: "defaultParams",
    }),
  },

  data() {
    return {
      makes: [],
      models: [],
      series: [],
      years: [
        "2020 & Above",
        "2018 & Above",
        "2016 & Above",
        "2014 & Above",
        "2010 & Above",
        "Below 2010",
      ],
      params: {
        make: "",
        model: "",
        series: "",
      },
      value: [5000, 15000],
      yearValue: [2010, 2018],
      odometerValue: [1000, 50000],
      emiValue: [0, 2000],
      isPrice: false,
    };
  },
  methods: {
    togglePrice() {
      this.isPrice = !this.isPrice;
    },
    minValueChange(value) {
      if (value < 1000 || isNaN(value)) {
        this.value[0] = 1000;
      }
      // if (value > this.value[1]) {
      //   this.value[0] = this.value[1];
      // }
      this.value = [...this.value];
    },
    maxValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.value[1] = 200000;
      }
      // if (value < this.value[0]) {
      //   this.value[1] = this.value[0];
      // }
      this.value = [...this.value];
    },
    minEmiValueChange(value) {
      if (value < 0 || isNaN(value)) {
        this.emiValue[0] = 0;
      }
      // if (value > this.emiValue[1]) {
      //   this.emiValue[0] = this.emiValue[1];
      // }
      this.emiValue = [...this.emiValue];
    },
    maxEmiValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.emiValue[1] = 2000;
      }
      // if (value < this.emiValue[0]) {
      //   this.emiValue[1] = this.emiValue[0];
      // }
      this.emiValue = [...this.emiValue];
    },
    minYearValueChange(value) {
      if (value < 2008 || isNaN(value)) {
        this.yearValue[0] = 2008;
      }
      // if (value > this.yearValue[1]) {
      //   this.yearValue[0] = this.yearValue[1];
      // }
      this.yearValue = [...this.yearValue];
    },
    maxYearValueChange(value) {
      if (value > 2022 || isNaN(value)) {
        this.yearValue[1] = 2022;
      }
      // if (value < this.yearValue[0]) {
      //   this.yearValue[1] = this.yearValue[0];
      // }
      this.yearValue = [...this.yearValue];
    },
    minOdoValueChange(value) {
      if (value < 0 || isNaN(value)) {
        this.odometerValue[0] = 0;
      }
      // if (value > this.odometerValue[1]) {
      //   this.odometerValue[0] = this.odometerValue[1];
      // }
      this.odometerValue = [...this.odometerValue];
    },
    maxOdoValueChange(value) {
      if (value > 100000 || isNaN(value)) {
        this.odometerValue[1] = 100000;
      }
      // if (value < this.odometerValue[0]) {
      //   this.odometerValue[1] = this.odometerValue[0];
      // }
      this.odometerValue = [...this.odometerValue];
    },
    gotoSearch() {
      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.loadDeals(this.params);
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    modelsForMake(makeName) {
      this.params.make = makeName;
      this.models = [];
      this.series = [];
      if (!makeName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      this.models = makeObj.models.map((item) => item.name);
    },
    seriesForModel(modelName) {
      this.params.model = modelName;
      this.series = [];
      if (!modelName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      const model = makeObj.models.find(
        (item) => item.name == this.params.model
      );
      this.series = model.series.map((item) => item.name);
    },
    seriesSelected(seriesName) {
      this.params.series = seriesName;
    },
    prepareMakes() {
      this.makes = this.makeObjects.map((item) => item.name);
    },
    setParams(value) {
      if (value["make"]) {
        this.modelsForMake(value["make"]);
      }
      if (value["model"]) {
        this.seriesForModel(value["model"]);
      }
      if (value["series"]) {
        this.seriesSelected(value["series"]);
      }

      if (!this.value) {
        this.value = [1000, 100000];
      }

      if (value["min_budget"] && value["max_budget"]) {
        this.value = [value["min_budget"], value["max_budget"]];
      }
      if (value["min_emi"] && value["max_emi"]) {
        this.emiValue = [value["min_emi"], value["max_emi"]];
      }

      if (!this.yearValue) {
        this.yearValue = [2008, 2021];
      }

      if (value["from_year"] && value["to_year"]) {
        this.yearValue = [value["from_year"], value["to_year"]];
      }

      if (!this.odometerValue) {
        this.odometerValue = [1000, 50000];
      }
      if (value["odometer_min"] && value["odometer_max"]) {
        this.odometerValue = [value["odometer_min"], value["odometer_max"]];
      }
      if (value["is_price"]) {
        this.isPrice = value["is_price"];
      }
    },
  },
  mounted() {
    this.params = { ...this.defaultParams };
    this.setParams(this.params);
    this.prepareMakes();
  },

  watch: {
    makeObjects() {
      this.prepareMakes();
    },
    defaultParams(value) {
      this.params = { ...value };
      this.setParams(this.params);
    },
  },
};
</script>
<style src="../assets/slider-theme.css"></style>
