<template>
    <div class="bg-white shadow-lg p-4 flex lg:flex-col flex-row">
        <div class="w-12 h-12 mr-2 flex items-center">
          <img :src="'/images/icons/'+image" :class="iconClass" />
        </div>
        <div>
          <h2 class="text-black-light text-lg font-bold lg:my-4 mb-2">{{title}}</h2>
          <p class="text-black-lighter lg:text-base text-sm">
            <slot></slot>
          </p>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    image: String,
    title: String,
    iconClass: String,
  },
};
</script>
<style lang="">
</style>