<template>
  <div class="" v-if="expire < 0">
    <button class="button text-red-600 border-2 border-red-600 border-solid px-2 uppercase font-semibold">Sold Out</button>
  </div>
  <div class="flex flex-row text-white bg-blue rounded-2xl p-1 ml-3" v-if="expire > 0">
    <div class="block text-center">
      <p class="digit text-lg">{{ days }}</p>
      <p class="text text-xs px-2">Days</p>
    </div>
    <div class="text-white border-l-2 h-4  mt-1"></div>
    <div class="block text-center">
      <p class="digit text-lg">{{ hours }}</p>
      <p class="text text-xs px-2">Hours</p>
    </div>
    <div class="text-white border-l-2 h-4  mt-1"></div>
    <div class="block text-center">
        <p class="digit text-lg">{{ minutes }}</p>
      <p class="text text-xs px-2">Minutes</p>
    </div>
    <div class="text-white border-l-2 h-4  mt-1"></div>
    <div class="block text-center">
      <p class="digit text-lg">{{ seconds }}</p>
      <p class="text text-xs px-2">Seconds</p>
    </div>
  </div>
  <div class="flex flex-row justify-center" v-if="expire > 0">
    <button class="p-2 bg-green-500 px-6 text-white text-sm uppercase" @click="gotoDetails">Click More</button>
  </div>
</template>
<script>
export default {

  props : {
    date : {
      type: Number,
      coerce: str => Math.trunc(Date.parse(str) / 1000)
    },
    vin: {
      type: Number
    },
  },
  data() {
    return {
      now: Math.trunc((new Date()).getTime() / 1000),
      // dates: Math.trunc((new Date(this.date)).getTime() / 1000),
      dates: '',
      difference: '',
      getDate: '',
    }
  },
  mounted() {

    var dateFormat = new Date(this.date);
    dateFormat.setHours(dateFormat.getHours() + 71); // timestamp
    dateFormat.setMinutes(dateFormat.getMinutes() + 59); // timestamp
    dateFormat.setSeconds(dateFormat.getSeconds() + 59); // timestamp
    this.getDate = new Date(dateFormat).getTime(); // Date object
    this.dates = Math.trunc((new Date(this.getDate)).getTime() / 1000),
        console.log(dateFormat);
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000);
      // console.log(this.now );
    },1000);
  },
  methods:{
    gotoDetails() {
      this.$router.push({ name: "Details", params: { vin: this.vin } });
    },
  },
  computed: {

    seconds() {
      return (this.dates - this.now) % 60;
    },

    minutes() {
      return Math.trunc((this.dates - this.now) / 60) % 60;
    },

    hours() {
      return Math.trunc((this.dates - this.now) / 60 / 60) % 24;
    },

    days() {
      return Math.trunc((this.dates - this.now) / 60 / 60 / 24);
    },

    expire() {
      let difference = +this.dates - +this.now;
      return difference;
    },
  }
}
</script>
<style>

/*.block {*/
/*  display: flex;*/
/*  flex-direction: column;*/

/*}*/
.text {
/*  color: #1abc9c;*/
  font-size: 8px;
/*  font-family: 'Roboto Condensed', serif;*/
/*  font-weight: 400;*/
/*  margin-top:10px;*/
/*  margin-bottom: 10px;*/
/*  text-align: center;*/
}
/*.digit {*/
/*  !*color: #ecf0f1;*!*/
/*  font-size: 15px;*/
/*  font-weight: 100;*/
/*  font-family: 'Roboto', serif;*/
/*  text-align: center;*/
/*}*/
</style>