<template>
  <LuckDrawMobile v-show="isModalVisible"
                  @success="showThankYouModal"
                  @close="closeModal"></LuckDrawMobile>
  <LuckyDrawMobileThankYou v-show="isThankyouModalVisible"
                           @close="closeThankYouModal"></LuckyDrawMobileThankYou>
  <LuckyDrawMobileInfoModal v-show="isTermsVisible"
                            @close="closeTermsModal"
                            type="terms"></LuckyDrawMobileInfoModal>
  <LuckyDrawMobileInfoModal v-show="isRulesVisible"
                            @close="closeRulesModal"
                            type="rules"></LuckyDrawMobileInfoModal>
</template>

<script>
import LuckDrawMobile from "./LuckDrawMobile.vue";
import LuckyDrawMobileInfoModal from "./LuckyDrawMobileInfoModal.vue";
import LuckyDrawMobileThankYou from "./LuckyDrawMobileThankYou.vue";
  export default {
    name: "LuckyDrawMobileHolder",
    components: {
      LuckDrawMobile,
      LuckyDrawMobileThankYou,
      LuckyDrawMobileInfoModal
    },
    data() {
      return {
        isModalVisible: false,
        isThankyouModalVisible: false,
        isTermsVisible: false,
        isRulesVisible: false,
      };
    },
    mounted() {
      window.setTimeout(() => {
        this.showLuckyDrawMobile();
      }, 1000);

      this.emitter.on("showTerms", (value) => {
        if (value == 'terms') {
          this.showTermsModal();
        } else if (value == 'rules') {
          this.showRulesModal();
        }
      });

      this.emitter.on("showLuckyDraw", (value) => {
        if (value)
          this.showLuckyDrawMobile();
      });
    },
    methods: {
      closeModal() {
        this.isModalVisible = false;
        document.body.classList.remove("overflow-hidden");
      },
      closeTermsModal() {
        this.isTermsVisible = false;
        document.body.classList.remove("overflow-hidden");
      },
      closeRulesModal() {
        this.isRulesVisible = false;
        document.body.classList.remove("overflow-hidden");
      },

      showLuckyDrawMobile() {
        this.isModalVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      showTermsModal() {
        this.isTermsVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      showRulesModal() {
        this.isRulesVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      showThankYouModal() {
        this.closeModal();
        this.isThankyouModalVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      closeThankYouModal() {
        this.isThankyouModalVisible = false;
        document.body.classList.remove("overflow-hidden");
      }
    },
  };
</script>

<style></style>