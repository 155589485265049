import axios from "axios";
import config from "@/config";
const API_URL = config.hostname + '/api/investorAuth/';

class AuthService {
  login(investors) {
    return axios.post(API_URL + 'login', investors).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('investors', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  forgotPassword(email) {
    return axios
      .post(API_URL + 'forgot-password', { email: email })
      .then((response) => {
        return response.data;
      });
  }
  resetPassword(data) {
    return axios.post(API_URL + 'update-password', data).then((response) => {
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem('investors');
  }
  register(investors) {
    return axios.post(API_URL + 'register', investors);
  }
}
export default new AuthService();
