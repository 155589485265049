<template>
  <div>
    <button
      @click="toggleAccordion()"
      class="bg-white shadow-lg p-4 flex flex-col w-full items-center mb-4"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${id}`"
    >
      <div class="flex flex-row w-full items-center">
        <div
          v-if="image"
          class="w-12 h-12 mr-6 flex items-center flex-shrink-0"
        >
          <img :src="'/images/icons/' + image" :class="iconClass" />
        </div>
        <div class="flex flex-col items-start flex-grow">
          <div class="flex flex-row justify-between items-center w-full">
            <h2 class="text-black-light text-lg font-bold flex-grow text-left">
              {{ title }}
            </h2>
            <svg
              class="w-3 transition-all duration-200 transform flex-shrink-0"
              :class="{
                'rotate-180': isOpen,
                'rotate-0': !isOpen,
              }"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 10"
              aria-hidden="true"
            >
              <path
                d="M15 1.2l-7 7-7-7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="">
        <p
          v-show="isOpen"
          :id="`collapse${id}`"
          class="text-black-lighter text-sm text-left mt-4"
        >
          <slot></slot>
        </p>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    iconClass: String,
  },
  data() {
    return {
      isOpen: false,
      id: null,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
