<template>
  <section class=" border-t-1">
    <div class="relative w-full dots">
      <div class="z-50 m-auto w-12/12 md:w-10/12 lg:w-10/12">
        <form @submit.prevent="updateProfile">
          <div class="flex justify-between py-10 m-3 md:m-0 lg:m-0">
            <div>
              <h4 class="relative text-xl font-bold md:text-2xl lg:text-2xl text-blue-dark pro-underline"> Profile
                Information</h4>
            </div>
            <div>
              <button
                  class="px-6 py-2 text-xs text-white uppercase md:py-3 lg:py-3 md:text-sm lg:text-sm bg-blue "
              >Update Information
              </button>
            </div>
          </div>
          <div class="relative grid grid-cols-1 gap-10 mb-10 md:grid-cols-3 lg:grid-cols-3 ">
            <div class="col-span-2 p-5 border">
              <h3 class="mb-5 text-lg font-semibold profile-title-txt">Persolnal Info</h3>
              <div class="grid w-full grid-cols-2 gap-5 mb-6">
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">First Name</h5>
                  <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="John"
                         v-model="this.firstName" name="firstName"
                         :class="['border p-4 w-full', errors['firstName'] ? 'border-red-700' : '',]">
                  <span v-if="errors['firstName']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["firstName"] }}</span>
                </div>
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Last Name</h5>
                  <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="Doe" v-model="this.lastName" name="lastName"
                         :class="['border p-4 w-full', errors['lastName'] ? 'border-red-700' : '',]">
                  <span v-if="errors['lastName']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["lastName"] }}</span>

                </div>
              </div>
              <div class="w-full mb-6">
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Email Address</h5>
                <input type="email" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="Jhonbaskear3189@gmail.com" v-model="email" name="email"
                       :class="['border p-4 w-full', errors['email'] ? 'border-red-700' : '',]" disabled>
                <span v-if="errors['email']" class="text-red-700 text-xs leading-tight block">{{
                    errors["email"]
                  }}</span>

              </div>
              <div class="grid w-full grid-cols-2 gap-5 mb-6">
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Phone Number</h5>
                  <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="+1 917-634-888" v-model="phoneNumber" name="phoneNumber"
                         :class="['border p-4 w-full', errors['phoneNumber'] ? 'border-red-700' : '',]">
                  <span v-if="errors['phoneNumber']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["phoneNumber"] }}</span>

                </div>
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Alternate Phone Number</h5>
                  <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="+1 917-634-888" v-model="alternativeNumber" name="alternativeNumber"
                         :class="['border p-4 w-full', errors['alternativeNumber'] ? 'border-red-700' : '',]">
                  <span v-if="errors['alternativeNumber']" class="text-red-700 text-xs leading-tight block">{{
                      errors["alternativeNumber"]
                    }}</span>

                </div>
              </div>
            </div>
            <div class="p-5 border">
              <h3 class="text-lg font-semibold profile-title-txt">Profile Picture</h3>
              <div class="flex items-center justify-center h-97">
                                <div class="image-wrapper z-100" :style="{ 'background-image': `url(${imagePreview})` }">
                                  <input class="image-select" name="image" @change="uploadImage" accept="image/*" type="file">
                                </div>
                <div class="z-0 flex items-center justify-center thumnail">
                  <img class="" src="/images/thumbnail.png" alt="">
                </div>
              </div>
            </div>
          </div>


          <div class="grid grid-cols-1 gap-10 mb-10 md:grid-cols-2 lg:grid-cols-2">
            <div class="p-5 border">
              <div v-for="(field, counter) in physicalAddress.slice(0, 2)" v-bind:key="counter"
                   class="physical-address">

                <button v-if="removeForm" class="float-right danger button font-extrabold text-red-700"
                        :class="{'remove-btn': counter}" @click="deleteAddress(counter)">Remove
                </button>

                <h3 class="mb-5 text-lg font-semibold profile-title-txt">Physical Address</h3>

                <div class="w-full mb-6">
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Address Line 1 </h5>
                  <input type="text" v-model="field.address1"
                         class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="103 Charles St,"
                  required>
                </div>

                <div class="w-full mb-6">
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Address Line 2</h5>
                  <input type="text" v-model="field.address2"
                         class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="Basement Front Address" required>
                </div>

                <div class="w-full mb-6">
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">City</h5>
                  <input type="text" v-model="field.city"
                         class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                         placeholder="Bergenfeild" required>
                </div>

                <div class="grid w-full grid-cols-2 gap-5 mb-6">
                  <div>
                    <h5 class="pb-2 text-sm font-semibold text-blue-dark">State</h5>
                    <input type="text" v-model="field.state"
                           class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="John" required>
                  </div>

                  <div>
                    <h5 class="pb-2 text-sm font-semibold text-blue-dark">Zip Code</h5>
                    <input type="text" v-model="field.zipcode"
                           class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="Doe" required>
                  </div>

                </div>

                <div class="w-full mb-6">
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Country</h5>
                  <div class="relative pro-select">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         class="absolute flex-shrink-0 w-5 h-12 ml-3 transition-all duration-200 transform z-100 right-3 fill-gray-50"
                         :class="{ 'rotate-180': isOpen,  'rotate-0': !isOpen,   }" viewBox="0 0 20 20" fill="#8a8a8a">
                      <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"/>
                    </svg>
                    <select v-model="field.country" @change="selectCounty($event)"
                            class="w-full h-12 px-3 outline-none border-1 profile-title-txt" id="">
                      <option value="United States">United States</option>
                      <option value="India">India</option>
                      <option value="China">China</option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="mb-0">
                <p class="text-sm cursor-pointer text-blue-dark" @click="addAddress">+ Add another physical address</p>
              </div>

            </div>


            <div class="p-5 border">
              <h3 class="mb-5 text-lg font-semibold profile-title-txt">Mailing Address</h3>
              <div class="w-full mb-6">
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Address Line 1</h5>
                <input type="text" v-model="mailingAddress.address1"
                       class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="" required>
                <span v-if="errors['address1']"
                      class="text-red-700 text-xs leading-tight block">{{ errors["address1"] }}</span>

              </div>
              <div class="w-full mb-6">
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Address Line 2</h5>
                <input type="text" v-model="mailingAddress.address2"
                       class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="" required>
                <span v-if="errors['address2']"
                      class="text-red-700 text-xs leading-tight block">{{ errors["address2"] }}</span>

              </div>
              <div class="w-full mb-6">
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">City</h5>
                <input type="text" v-model="mailingAddress.city"
                       class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="" required>
                <span v-if="errors['city']"
                      class="text-red-700 text-xs leading-tight block">{{ errors["city"] }}</span>

              </div>
              <div class="grid w-full grid-cols-2 gap-5 mb-6">
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">State</h5>
                  <input type="text" v-model="mailingAddress.state"
                         class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="" required>
                  <span v-if="errors['state']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["state"] }}</span>

                </div>
                <div>
                  <h5 class="pb-2 text-sm font-semibold text-blue-dark">Zip code</h5>
                  <input type="text" v-model="mailingAddress.zipcode"
                         class="w-full h-12 px-3 outline-none border-1 profile-title-txt" placeholder="" required>
                  <span v-if="errors['zipcode']"
                        class="text-red-700 text-xs leading-tight block">{{ errors["zipcode"] }}</span>

                </div>
              </div>
              <div class="w-full mb-6">
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Country</h5>
                <div class="relative pro-select">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="absolute flex-shrink-0 w-5 h-12 ml-3 transition-all duration-200 transform z-100 right-3 fill-gray-50"
                       :class="{ 'rotate-180': isOpen,  'rotate-0': !isOpen,   }" viewBox="0 0 20 20" fill="#8a8a8a">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                  <select @change="selectCountry($event)" v-model="mailingAddress.country"
                          class="w-full h-12 px-3 outline-none border-1 profile-title-txt">
                    <option value="United States">United States</option>
                    <option value="India">India</option>
                    <option value="China">China</option>
                  </select>
                </div>
              </div>
              <div class="flex items-center mb-0">
                <input type="checkbox" class="mr-3 indeterminate:bg-gray-300" v-model="copyAdd" name=""
                       @change="copyAddress($event)" true-value="1" false-value="false">
                <span class="text-sm text-blue-dark">Use my physical address as my mailing address</span>
              </div>
            </div>
          </div>
          <div class="flex justify-between py-10 m-3 md:m-0 lg:m-0">
            <div>
              <h4 class="text-sm font-thin text-blue-dark"> Logout from account</h4>
            </div>
            <div>
              <button
                  class="px-10 py-2 text-xs text-gray-400 uppercase transition bg-gray-300 md:py-3 lg:py-3 hover:ease-in hover:text-gray-100 md:text-sm lg:text-sm hover:bg-gray-400 "
              >Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <div class="fixed right-0 flex items-center origin-right transform -rotate-90 top-2">
          <a href="#" class="relative h-16 px-10 pt-2 text-sm text-white bg-blue-dark bor-left ">Know Updates. <b>Subscribe</b></a>
          <a href="#" class="relative h-16 px-8 pt-2 pr-10 text-sm text-white bg-blue bor-right right-8">Have Question.
            <b>Ask Us!</b></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


// import axios from "axios";
// import config from "@/config";


export default {
  name: 'Profiles',
  data() {
    return {
      isOpen: false,
      firstName: "",
      lastName: "",
      email: "kmkrishna@gmail.com",
      phoneNumber: "",
      alternativeNumber: "",
      errors: [],
      message: "",
      copyAdd: "",
      removeForm: false,
      selectedFile: null,
      image: null,
      imagePreview: null,
      showPreview: false,
      profile: [],
      physicalAddress: [
        {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          country: 'United States'
        }
      ],
      mailingAddress:
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            country: 'United States'
          },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/');
    } else {
      this.loadProfile();
    }
  },

  methods: {
    addAddress() {
      this.physicalAddress.push({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: ''
      })
      this.removeForm = true;
    },
    deleteAddress(counter) {
      this.physicalAddress.splice(counter, 1);
    },
    selectCounty(event) {
      console.log(event.target.value)
    },
    selectCountry(event) {
      console.log(event.target.value)
    },

    copyAddress(event) {
      console.log(event)
      if (this.copyAdd == 1) {
        this.mailingAddress.address1 = this.physicalAddress[0].address1;
        this.mailingAddress.address2 = this.physicalAddress[0].address2;
        this.mailingAddress.city = this.physicalAddress[0].city;
        this.mailingAddress.state = this.physicalAddress[0].state;
        this.mailingAddress.zipcode = this.physicalAddress[0].zipcode;
        this.mailingAddress.country = this.physicalAddress[0].country;
      } else {
        this.mailingAddress.address1 = "";
        this.mailingAddress.address2 = "";
        this.mailingAddress.city = "";
        this.mailingAddress.state = "";
        this.mailingAddress.zipcode = "";
        this.mailingAddress.country = "";
      }

    },

    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    uploadImage(e) {

      this.selectedFile = e.target.files[0];

      let reader = new FileReader();
      reader.addEventListener("load", function () {
        this.showPreview = true;
        this.imagePreview = reader.result;
      }.bind(this), false);
      if (this.selectedFile) {
        if (/\.(jpe?g|png|gif)$/i.test(this.selectedFile.name)) {
          reader.readAsDataURL(this.selectedFile);
        }
      }
    },

    validateForm() {
      this.formAttempt++;
      this.errors = {};
      let valid = true;
      if (!this.firstName.trim()) {
        this.errors["firstName"] = "First Name is required";
        valid = false;
      }
      if (!this.lastName.trim()) {
        this.errors["lastName"] = "Last Name is required";
        valid = false;
      }
      if (!this.email || !this.validateEmail(this.email)) {
        valid = false;
        this.errors["email"] = "A valid Email is required";
      }

      if (!this.phoneNumber) {
        valid = false;
        this.errors["phoneNumber"] = "A valid Phone is required";
      }

      if (!this.alternativeNumber) {
        valid = false;
        this.errors["alternativeNumber"] = "A valid Phone is required";
      }

      // if (this.physicalAddress[0].address1 == '') {
      //   valid = false;
      //   this.errors['address1'] = "A valid address is required";
      // }
      // if (this.physicalAddress[1]){
      //   if (this.physicalAddress[1].address1 == '') {
      //     valid = false;
      //     this.errors['address1'] = "A valid address is required";
      //   }
      // }
      return valid;
    },

    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
          email
      );
    },
    validatePhone(phone) {
      // eslint-disable-next-line
      return /^\d{8,15}$/.test(phone);
    },

    loadProfile() {
      const params = {};
      params["user_id"] = this.currentUser.id;
      this.$store.dispatch("loadProfile", params).then(
          (value) => {

            if (value) {
              console.log('fail')
              console.log(profile)
              let profile = value.data.profile;
              this.firstName = profile.first_name;
              this.lastName = profile.last_name;
              this.email = profile.email;
              this.alternativeNumber = profile.alternative_phone;
              this.phoneNumber = profile.phone;
              this.imagePreview = profile.photo_url;
              this.physicalAddress = profile.physical_address;
              if (profile.mailing_address) {
                let mailingAddress = JSON.parse(profile.mailing_address);
                let maddress = mailingAddress[0];
                this.mailingAddress.address1 = maddress.address1;
                this.mailingAddress.address2 = maddress.address2;
                this.mailingAddress.city = maddress.city;
                this.mailingAddress.state = maddress.state;
                this.mailingAddress.zipcode = maddress.zipcode;
              }

              const physicalAddress = JSON.parse(profile.physical_address);
              if (physicalAddress) {
                this.physicalAddress = null
                this.physicalAddress = physicalAddress;
              }
              console.log(value.data.profile);
            }


          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );


    },

    updateProfile() {
      if (!this.validateForm()) {
        return;
      }

      let test = document.getElementsByClassName("physical-address").onclick = function () {
        if (test) {
          alert('error please fill all fields!');
        }
      };

      let formData = new FormData();

      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("email", this.email);
      formData.append("phone", this.phoneNumber);
      formData.append("alternative_number", this.alternativeNumber);
      formData.append("physical_address", JSON.stringify(this.physicalAddress));
      formData.append("mailing_address", JSON.stringify(this.mailingAddress));
      formData.append("image", this.selectedFile);
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phoneNumber,
        alternative_number: this.alternativeNumber,
        physical_address: this.file,
        mailing_address: this.mailingAddress,
        image: this.selectedFile,
      };
      console.log(params);
      this.$store.dispatch("updateProfile", formData).then(
          (value) => {
            console.log(value.data);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
  },
}
</script>
<style>
.h-97 {
  height: 97%;
}

.border-t-1 {
  border-top: 1px solid #ececec;
}

.border {
  border: 1px solid #f1f1f1;
}

.border-1 {
  border: 1px solid #d8d8d8;
}

.currentColor {
  fill: #ccc !important;
}

.pro-underline::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 90px;
  height: 1px;
  background: #008de9;
}

.profile-title-txt {
  color: #333;
}

input::placeholder {
  color: rgb(114, 114, 114);
  font-size: small;
}

.dots::before {
  z-index: -1;
  content: '';
  position: absolute;
  left: 0;
  top: 7%;
  width: 100px;
  height: 400px;
  background-image: url(/images/dots.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.dots::after {
  z-index: -1;
  content: '';
  position: absolute;
  right: 0;
  bottom: 7%;
  width: 100px;
  height: 400px;
  background-image: url(/images/dots.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: block;
  cursor: pointer;
  background-image: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-select {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  opacity: 0;
  cursor: pointer;
}

.thumnail {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  cursor: pointer;
}

.thumnail:focus img {
  opacity: 0;
}


.bor-right {
  border-radius: 40px 50px 0px 0px;
}

.bor-left {
  border-radius: 40px 0px 0px 0px;
}
</style>