<template>
  <div ref="cardInfo" class="w-100">
    <div class="flex w-full justify-start items-center">
      <h3 class="lg:text-xl text-lg lg:font-semibold font-medium flex-grow">
        Credit Card
      </h3>
      <img class="h-4 ml-10 lg:hidden" src="/images/credit-cards.png" />
    </div>
    <div class="flex w-full justify-between mt-4 mb-10">
      <p class="text-black-lighter text-xs">
        Safe money transfer using your bank account Visa, Maestro, Discover,
        American Express
      </p>
      <img class="h-6 ml-10 hidden lg:block" src="/images/credit-cards.png" />
    </div>
    <div class="w-full hidden">
      <div
        v-if="!stripeError && stripeValidationError"
        class="text-red-700 text-sm mb-3"
      >
        {{ stripeValidationError }}
      </div>
      <div v-if="stripeError" class="text-red-700 text-sm mb-3">
        {{ stripeError }}
      </div>
    </div>
    <div class="flex w-full flex-col mb-8">
      <label class="text-xs font-semibold mb-2">CREDIT CARD NUMBER</label>
      <div class="relative">
        <div
          id="card-number"
          class="border p-4 w-full"
          :class="stripeCardError ? 'border-red-700' : ''"
        ></div>
      </div>
      <div v-if="stripeCardError" class="text-red-700 text-sm mt-2">
        {{ stripeCardError }}
      </div>
    </div>
    <div class="flex w-full flex-row mb-8">
      <div class="flex flex-grow flex-col mr-4">
        <label class="text-xs font-semibold mb-2">CVV NUMBER</label>
        <div class="relative">
          <div
            id="card-cvc"
            class="border p-4 w-full"
            :class="stripeCvvError ? 'border-red-700' : ''"
          ></div>
          <div
            class="
              absolute
              right-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon cvv regular"></i>
          </div>
        </div>
        <div v-if="stripeCvvError" class="text-red-700 text-sm mt-2">
          {{ stripeCvvError }}
        </div>
      </div>
      <div class="flex flex-grow flex-col ml-4">
        <label class="text-xs font-semibold mb-2">EXPIRY DATE</label>
        <div class="relative">
          <div
            id="card-expiry"
            class="border p-4 w-full"
            :class="stripeExpiryError ? 'border-red-700' : ''"
          ></div>
        </div>
        <div v-if="stripeExpiryError" class="text-red-700 text-sm mt-2">
          {{ stripeExpiryError }}
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col mb-6">
      <label class="text-xs font-semibold mb-2">NAME ON CARD</label>
      <div class="relative">
        <input class="border p-4 w-full" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      toggle: screen.width > 768,
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationError: "",
      stripeExpiryError: "",
      stripeCvvError: "",
      stripeCardError: "",
    };
  },
  computed: {
    stripeError() {
      return this.$store.state.card.stripeError;
    },
  },
  mounted() {
    this.$refs.cardInfo.scrollIntoView({ behavior: "smooth" });
    window.addEventListener("resize", this.myEventHandler);
    this.stripe = this.$store.state.card.stripe;
    this.createAndMountFormElements();

    window.gtag('event', 'payment_details'); /* google analytic */
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.toggle = screen.width > 768;
    },
    createAndMountFormElements() {
      var elements = this.stripe.elements();

      this.cardNumberElement = elements.create("cardNumber");
      this.cardNumberElement.mount("#card-number");
      this.$store.dispatch("card/setCard", this.cardNumberElement);

      this.cardExpiryElement = elements.create("cardExpiry");
      this.cardExpiryElement.mount("#card-expiry");

      this.cardCvcElement = elements.create("cardCvc");
      this.cardCvcElement.mount("#card-cvc");

      this.cardNumberElement.on("change", (event) => {
        this.setValidationError(event, "card");
      });
      this.cardExpiryElement.on("change", (event) => {
        this.setValidationError(event, "expiry");
      });
      this.cardCvcElement.on("change", (event) => {
        this.setValidationError(event, "cvv");
      });
    },
    setValidationError(event, type) {
      let message = event.error ? event.error.message : "";
      if (!type) {
        this.stripeValidationError = message;
      } else if (type == "card") {
        this.stripeCardError = message;
      } else if (type == "cvv") {
        this.stripeCvvError = message;
      } else if (type == "expiry") {
        this.stripeExpiryError = message;
      }
    },
  },
};
</script>
<style>
</style>