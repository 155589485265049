<template lang="">
  <div class="bg-white z-100 relative">
    <div class="p-5 pb-16 bg-gradient-to-br from-blue-dark to-blue-medium">
      <div><i class="icon xl steering-smiley"></i></div>
      <h1 class="font-display text-4xl text-white font-extrabold my-2">Hi</h1>
      <p class="text-white text-sm mb-3">
        We help you to find a suitable car from our curated list of super saving
        cars
      </p>
    </div>
    <div class="p-5 px-6">
      <div class="bg-white shadow-regular p-5 -mt-16 rounded mb-5">
        <p class="font-bold text-black-light">Start a conversation</p>
        <div class="flex justify-between mt-3">
          <p class="text-black-lighter">Our usual reply time</p>
          <p class="text-black-full flex items-center">
            <i class="icon reply-time small mr-2"></i>A few mins
          </p>
        </div>
        <input class="border p-4 w-full mt-6" placeholder="Name *" />
        <input class="border p-4 w-full mt-4" placeholder="Email *" />
        <button
          class="bg-blue uppercase text-white p-4 px-8 mt-4 text-sm w-full"
        >
          Start Conversation
        </button>
      </div>

      <div class="bg-white shadow-regular p-5 rounded mb-5">
        <p class="font-bold text-black-light">Search for help from our FAQs</p>
        <div class="relative mt-4">
          <input
            placeholder="Search questions"
            class="border border-blue rounded-md p-3 flex-grow w-full pl-7"
            type="text"
            name="search"
          />
          <div
            class="absolute left-0 top-0 bottom-0 flex items-center justify-center flex-row w-7"
          >
            <i class="icon small search-questions"></i>
          </div>
        </div>
        <div class="mt-5">
          <p class="text-gray-medium text-sm mb-2">Suggested FAQs</p>

          <FaqListSimple/>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import FaqListSimple from "./FaqListSimple.vue";
export default {
  components: {
    FaqListSimple,
  },
};
</script>
<style lang=""></style>
