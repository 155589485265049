<template>
  <NavBar/>
  <div class="container m-auto">
    <div class="flex justify-center">
      <h1 class="p-3 font-semibold text-2xl uppercase">Investors</h1>
    </div>

    <div class="border-gray-100">
      <div class="flex justify-center">
        <div class="w-8/12">
          <form @submit.prevent="updateMaster">
            <div class="flex flex-col gap-10">
              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Header Text</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.headerText" name="header_text">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Sub Header Text</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.subHeaderText" name="sub_header_text">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Invest Range</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.investRange" name="invest_range">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Interest Header Text</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.interestHeaderText" name="interest_header_text">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Days Remains</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.daysRemains" name="days_remains">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Backers</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.backers" name="backers">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Funded</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.funded" name="funded">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Fund Minimum</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.fundMin" name="fund_min">
              </div>

              <div>
                <h5 class="pb-2 text-sm font-semibold text-blue-dark">Fund Maximum</h5>
                <input type="text" class="w-full h-12 px-3 outline-none border-1 profile-title-txt"
                       placeholder="John"
                       v-model="this.fundMax" name="fund_max">
              </div>

              <div class="flex justify-center mb-28">
                <button class="button p-3 bg-blue text-white uppercase w-98">Submit</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBarLogoOnly";

export default {
  name: "InvestorsMaster",
  components: {NavBar},
  data() {
    return {
      headerText: '',
      subHeaderText: '',
      investRange: '',
      interestHeaderText: '',
      daysRemains: '',
      backers: '',
      funded: '',
      fundMin: '',
      fundMax: '',

    }
  },
  mounted() {
    this.loadInvestors()
  },
  methods: {
    loadInvestors() {
      const params = {};
      params["id"] = 1;
      this.$store.dispatch("loadInvestorsMaster", params).then(
          (value) => {

            if (value) {
              console.log('fail')
              let master = value.data.investors;
              this.headerText = master.header_text;
              this.subHeaderText = master.sub_header_text;
              this.investRange = master.invest_range;
              this.interestHeaderText = master.interest_header_text;
              this.daysRemains = master.days_remains;
              this.backers = master.backers;
              this.funded = master.funded;
              this.fundMin = master.fund_min;
              this.fundMax = master.fund_max;
              // this.lastName = profile.last_name;

            }


          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );


    },
    updateMaster() {
      let formData = new FormData();

      formData.append("id", '1');
      formData.append("header_text", this.headerText);
      formData.append("sub_header_text", this.subHeaderText);
      formData.append("invest_range", this.investRange);
      formData.append("interest_header_text", this.interestHeaderText);
      formData.append("days_remains", this.interestHeaderText);
      formData.append("backers", this.backers);
      formData.append("funded", this.funded);
      formData.append("fund_min", this.fundMin);
      formData.append("fund_max", this.fundMax);


      this.$store.dispatch("investorsMaster", formData).then(
          (value) => {
            console.log(value.data);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            // this.toast.error(message);
            console.log(message);
          }
      );
    }
  }
}
</script>

<style scoped>

</style>