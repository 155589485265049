<template>
  <div
      class="relative overflow-hidden w-100 banner-bg aspect-h-50 md:aspect-h-36 lg:aspect-h-36 aspect-h-50 aspect-w-64">
    <div class="absolute top-0 bottom-0 left-0 right-0 bg-blue bg-banner bg-position">
      <!--      <video class="h-full lg:w-full lg:object-cover object-fit-cover"-->
      <!--          playsinline-->
      <!--             autoplay-->
      <!--             muted-->
      <!--             loop>-->
      <!--        <source src="/images/LandingPageVideo.mp4"-->
      <!--                type="video/mp4">-->
      <!--      </video>-->
      <!--<div class="h-full lg:w-full lg:object-cover object-fit-cover">-->
      <!--  <img src="/images/home-banner.jpg" class="h-full lg:w-full lg:object-fill object-fit-fill"/>-->

      <div class="relative flex flex-col font-display font-bold flex-grow banner-text">
        <h5
            class="text-blue-light uppercase lg:text-2xl sm:text-xs text-xl text-1">The Smartest Way To</h5>
        <h1 class="text-white uppercase lg:text-4xl text-2xl font-extrabold text-2 pt-4 lg:pb-6 pb-3"> Purchase a <span
            class="whitespace-nowrap">Pre-Owned</span> Vehicle </h1>
        <p class="text-white font-normal text-3"><span class="line-break">We have a passion for providing the</span>
          <span> fairest deals for customers</span>
        </p>
        <div class="flex" style="margin-top: 20px">
          <a href="/search"
             class="m-button justify-center px-5 py-3 mr-3 text-center text-white rounded shadow-md bg-blue border-blue lg:px-10 lg:w-64 lg:text-xl">
            View All Cars </a>
          <a href="/faqs"
             class="m-button learn-more-btn justify-center px-5 py-3 ml-3 text-center text-white rounded shadow-md bg-blue border-blue lg:px-10 lg:w-64 lg:text-xl">
            Learn More </a>
        </div>
        <!-- Mobile View -->
        <section id="categories" class="mb-0 md:hidden">
          <!--    <h1 class="pt-5 text-2xl tracking-wider text-center uppercase md:py-2 lg:py-2 text-blue tiempos sm:text-3xl md:text-4xl">-->
          <!--      find car by category</h1>-->
          <div class="mt-16">

            <div class="grid  grid-cols-6 gap-2 ">

              <div
                  v-for="(category, index) in filters['categories']" :key="index"
                  @click="gotoCategory(category.value)"
                  class="box-size bg-blue text-white shadow-gap flex flex-wrap justify-center  p-1  border-black md:gap-0 lg:gap-0 md:p-2 lg:p-2 min-w-300">
                <h6 class="category text-white text-14 sm-text-10 tracking-wider uppercase">
                  {{ category.label }}</h6>
              </div>

            </div>
          </div>
        </section>

        <!-- Desktop View-->
        <section id="categories" class="mb-0 mt-32 hidden md:block lg:block ">
          <!--    <h1 class="pt-5 text-2xl tracking-wider text-center uppercase md:py-2 lg:py-2 text-blue tiempos sm:text-3xl md:text-4xl">-->
          <!--      find car by category</h1>-->
          <div class="p-5 m-auto mt-3 w-12/12 sm:w-11/12 md:w-11/12 lg:w-11/12 sm:my-4 md:my-6 lg:my-6">

            <div class="grid grid-cols-2 gap-2 md:gap-3 lg:gap-3 md:grid-cols-3 lg:grid-cols-6 ">

              <div  v-for="(category, index) in filters['categories']" :key="index"
                    @click="gotoCategory(category.value)"
                    class="bg-white cursor-pointer shadow-gap grid grid-cols-1 justify-items-center gap-2 p-2 border border-black md:gap-0 lg:gap-0 md:p-2 lg:p-2 min-w-300">
                <img :src="require(`@/assets/${category.image}`)" class="img-width" alt="">
                <h5 class="m-auto text-sm text-14 sm-text-10 tracking-wider uppercase  text-blue">
                  {{ category.label }}</h5>
              </div>

            </div>
          </div>
        </section>

      </div>
    </div>



<!--    <div class="container relative flex items-end justify-center w-full h-full px-5 py-12 mx-auto lg:px-3 m-sec">-->
<!--      <div class="flex flex-col items-start w-full font-bold font-display lg:flex-grow lg:mb-44">-->
<!--        <div class="flex">-->
<!--          <a href="/search"-->
<!--             class="m-button justify-center px-5 py-3 mr-3 text-center text-white rounded shadow-md bg-blue border-blue lg:px-10 lg:w-64 lg:text-xl">-->
<!--            View All Cars </a>-->
<!--          <a href="/faqs"-->
<!--             class="m-button justify-center px-5 py-3 ml-3 text-center text-white rounded shadow-md bg-blue border-blue lg:px-10 lg:w-64 lg:text-xl">-->
<!--            Learn More </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


  </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: "Banner",
  computed: {
    ...mapGetters({
      defaultParams: "defaultParams",
      filters: "filters",
    }),
  },
  components: {},
  methods: {
    gotoCategory(category) {
      this.params['car_category'] = category;
      this.loadDeals(this.params);
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
  },
  mounted() {
    this.params = { ...this.defaultParams };
    // this.setParams(this.params);
  },
  watch: {
    defaultParams(value) {
      this.params = { ...value };
      this.setParams(this.params);
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 820px) {
  .bg-banner{
    box-shadow: inset 435px -19px 69px -120px #121a3ca6 !important;
  }
  .banner-text{
    top: 138px !important;
    padding: 12px !important;
    line-height: 1rem !important;
  }
  .text-3{
    font-size: 13px;
  }
}
.img-width{
  width: 90px;
  height: 35px;
}
.bg-banner{
  background-image: url("/images/desktop_banner.png");
  background-size: cover;
  box-shadow: inset 715px -19px 100px -120px #121a3ca6;
}

@media only screen and (max-width: 600px) {
  .bg-position {
    background-position: -125px 0;
  }

  .bg-banner {
    box-shadow: inset 290px -19px 69px -120px #121a3ca6 !important;
  }

  .banner-text {
    top: 40px !important;
    padding: 12px !important;
    line-height: 1rem !important;
  }

  .text-1 {
    font-size: 0.6rem;
  }

  .text-2 {
    font-size: 0.6rem;
    padding: 0 !important;
  }

  .text-3 {
    font-size: 0.6rem;
  }

  .m-button {
    font-size: 10px;
    line-height: 22px;
    padding: 5px 10px 5px 10px;
  }

  .m-sec {
    padding-top: 12rem;
    align-items: center;
  }

  .line-break {
    display: block;
  }
}

.banner-text {
  top: 210px;
  padding: 20px;
}

.learn-more-btn {
  border: 1px solid #ffffff;
  background-color: transparent;
}

.car_icon {
  width: 1.3rem;
  height: 0.9rem;
  display: none;
}

.category {
  font-size: 8px;
}

.box-size {
  width: 60px;
  border-radius: 3px;
}
</style>