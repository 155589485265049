<template>
  <section  id="faqs" class=" sm:pt-0 md:pt-0 lg:pt-0 sm:my-10 md:mt-28 lg:mt-28 ">
   <div class="container m-auto">
    <h1 class="py-5 text-xl tracking-wider text-center uppercase md:py-5 text-blue tiempos sm:text-3xl">Frequenty asked questions</h1>
    <div class="lg:flex  md:justify-evenly lg:justify-evenly relative p-4 md:pt-10 lg:pt-10">
      <div class="lg:w-2/4 mr-0 lg:mr-20 xl:mr-20 2xl:mr-20">
        <div class=" " v-for="(faq, index) in faqs" :key="index">
          <FaqsAccordian class="w-full mb-1 pt-3 pb-3" :title="faq.question">
            <template v-if="!Array.isArray(faq.answer)">
              <div v-html="nl2br(faq.answer)"></div>
            </template>
            <template v-if="Array.isArray(faq.answer)">
              <ol class="pl-6 list-decimal list-outside">
                <li
                    v-for="(answer, answerIndex) in faq.answer"
                    :key="answerIndex"
                    class="mb-2">
                  <span class="relative left-2">{{ answer }}</span>
                </li>
              </ol>
            </template>
          </FaqsAccordian>
        </div>
      </div>
        <div class="lg:w-2/4">
        <div class=" " v-for="(faq, index) in faqsList" :key="index">
          <FaqsAccordian class="w-full mb-1 pt-3 pb-3" :title="faq.question">
            <template v-if="!Array.isArray(faq.answer)">
              <div v-html="nl2br(faq.answer)"></div>
            </template>
            <template v-if="Array.isArray(faq.answer)">
              <ol class="pl-6 list-decimal list-outside">
                <li
                    v-for="(answer, answerIndex) in faq.answer"
                    :key="answerIndex"
                    class="mb-2">
                  <span class="relative left-2">{{ answer }}</span>
                </li>
              </ol>
            </template>
          </FaqsAccordian>
        </div>
      </div>
    </div>
   </div>
  </section>
</template>
<script>
import FaqsAccordian from "./FaqComponent.vue";


export default {
  name: 'Faq',
  components: {
    FaqsAccordian,
  },
  data() {
    return {
      faqs: [
        {
          question: "Who are you?",
          answer:
              "We provide the public access to dealer-only auctions in order to save them money on their car purchases. Buying cars at wholesale prices saves our customers a lot of money on their car purchases by eliminating the high markup price that the local dealerships charge.",
        },
        {
          question: "What auctions do you provide access to?",
          answer:
              "We provide an online platform to purchase used vehicles from the major auctions in the USA. The vehicles in our inventory come from Manheim, Adesa, Copart, and IAAI which encompasses 70-80% of the auto-auction market in North America. ",
        },
        {
          question: "What is the registration policy?",
          answer:
              "You do not need to register to search our vehicle inventory from all the major auctions. You must first register to bid on and/or buy any car on our site. Registration is free and there is no membership requirement. Once you have registered, you can save vehicles that you are interested in into your List.",
        },
        {
          question: "Do I need a deposit to bid?",
          answer:
              "You will then need to choose a payment method and pay a deposit of 600 dollars or 10% of the Bid Price of the vehicle you intend to bid on prior to placing a bid.",
        },
        {
          question: "What differentiates you?",
          answer:
              "We are a customer-centric organization who has aligned our process with our customer success in mind.\n\nWe provide a seamless platform that is easy to use and navigate. We provide access to all the major auctions in the country, accounting for 80% of the market.\n\nWe are very transparent and list all the fees (Auction Fee, FairPY Fee, Documentation Fee) and Costs (Transportation Cost, Repair Cost Estimate) the customers will incur. We also give you an estimate of the sale taxes due. \n\nWe use this information to help you set a Fair Bid that allows you to calculate exactly how much you will be saving. To protect our customers, we will only list vehicles with title present, with a good condition report (above > 3.5), and without significant damage.\n\nWe notify our customer if they are making a bid that is significantly higher than the Fair Bid to try to protect them from potentially losing on making a good saving. We are only interested in a win-win situation for both us and our customers. Lastly, we do not allow our customer to bid higher than the current market value of the vehicle.\n\nWe do not charge an annual membership fee to use our services. We charge a transaction fee that is very affordable and competitive.\n\nWe streamline the process by providing an easy affordable transport solution to deliver the vehicle in order to save our customer time and money. We give our customers a 7-day return policy to allow them to make the right purchase that meets their needs. ",
        },
      ],
      faqsList: [
        {
          question: "What are the accepted payment methods?",
          answer:
              "The Deposit payment can be made by Credit Card, Debit Card, Zelle, or by sending a Wire Transfer for the amount. However, payment for the purchase of any vehicle (including applicable Fees) must be made by Wire Transfer only. No other payment methods are accepted.",
        },
        {
          question: "When are you launching?",
          answer:
              "We plan to launch nationwide on Jan, 2022. Initially, we will be providing a platform for auction purchase for our customers.",
        },
        {
          question: "Do you provide financing?",
          answer:
              "As of now, we do not provide financing. We hope to provide fair financing solutions to our customers in the future.",
        },
        {
          question: "What is the process of purchasing a car?",
          answer: [
            "You can search our vehicle inventory from all the major auctions. Our inventory is updated daily.",
            "Review the condition of the vehicle well before bidding.",
            "You need to register to start bidding",
            "Pay a deposit to set your purchasing power. A minimum deposit of $600 is required or 10% of the estimated bid price you are willing to bid (whichever one is larger)",
            "You can bid on the vehicle you choose once your deposit is received. Once you place a bid it is binding and cannot be canceled or retracted",
            "You need to make the payment when an auction is won. We will send you an email and phone notification. The email will detail all the steps required to finish the sale and will include the invoice and instructions on making payment. We only accept payment for vehicles by bank wire transfer",
            "We then arrange to ship your car from any auction location to you. We provide a recommended transportation solution that is very affordable to save you time and money. You can arrange your own transport at your own risk",
            "You can finalize the ownership process via our website prior to the 7 days return policy deadline. Documents will be overnight shipped to your address after the finalize ownership notification",
          ],
        },
        {
          question: "What is the delivery policy?",
          answer:
              "We offer a simple and affordable shipping solution to our customers to save them the hassle of having to find their own solution. We advise our customers to use the recommended shipping solution to avoid storage fees and potential damage to the vehicle during the transportation process. Customers do have the option of arranging their own delivery solution. However, the vehicle cannot be released until payment is made in full. Only then will we notify the Auction ClearingHouse that the car can be picked up.",
        },
      ]
    };
  },
  methods: {
    nl2br(str, is_xhtml) {
      var breakTag =
          is_xhtml || typeof is_xhtml === "undefined" ? "<br " + "/>" : "<br>";

      return (str + "").replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          "$1" + breakTag + "$2"
      );
    },
  },
}
</script>
<style scoped>
.tiempos {
  font-family: 'Tiempos Headline', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif!important;
}

.text-blue {
  color: #0d213a;
}

.text-blacks {
  color: #232323;
}

.text-blk {
  color: #191919;
}

.shadow {
  box-shadow: 0 0 5px 1px #d5d5d5;
}

.min-w-300 {
  min-width: 280px;
}


</style>