<template lang="">
  <transition name="bounce">
    <div
         class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-60 flex justify-center items-center z-topmost overflow-y-scroll">
      <div class="absolute bg-white rounded-lg max-h-mobile-popup left-0 right-0 bottom-0 overflow-y-auto">
        <div class="modal shadow-xl flex flex-col items-start relative p-5">
          <div class="flex flex-row items-center">
            <button @click.prevent="close"><i class="icon back mr-4"></i></button>
            <h1 class="text-lg font-semibold">{{ type == 'terms' ? 'Terms & Conditions' : 'Rules' }}</h1>
          </div>
          <div class="w-full">
            <div class="relative flex flex-col items-center w-full p-4">
              <ul class="list-disc pl-4 font-light" v-if="type == 'terms'">
                <li class="mb-5">The email & phone number should be entered correctly to be part of the raffle</li>
                <li class="mb-5">Raffile participants should follow all rules to win the car</li>
                <li class="mb-5">Raffile participant should be 16 years or older and a U.S resident</li>
                <li class="mb-5">If the Raffle participant is the winner then the car will be registered in the name as
                  given during registration</li>
                <li class="mb-5">Same person cannot register multiple times</li>
                <li class="mb-5">Raffle participant cannot share the raffle ticket with someone else</li>
              </ul>
              <ul class="list-disc pl-4 font-light" v-if="type != 'terms'">
                <li class="mb-5">Enter your legal name, phone number & email</li>
                <li class="mb-5">Click on the link sent to your phone & watch the video in its entirety</li>
                <li class="mb-5">Enter the code given at the end of the video to complete your registration</li>
                <li class="mb-5">Increase your chance of winning by sharing the video (get an additional raffle ticket for free)</li>
                <li class="mb-5">Upon completion of registration free raffle ticket will be sent your email</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: {
      type: String,
    },
    components: {},
    methods: {
      close() {
        this.$emit("close");
      },
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.25s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.25s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: translate(0,100%);
      opacity: 0;
    }

    50% {
      transform: translate(0,50%);
    }

    100% {
      transform: translate(0,0);
      opacity: 1;
    }
  }
</style>