<template>
  <div class="w-100 shadow">
    <div class="container mx-auto flex flex-col  lg:flex-row justify-between text-xs px-5 lg:px-3 py-3">
      <!--      <div class="flex flex-col lg:flex-row lg:items-center justify-between">-->
      <!--        <div class="flex flex-row justify-between w-full lg:w-auto items-center flex-shrink-0">-->
      <a href="/investors">
        <img class="flex-shrink-0 h-6" src="/images/logo.png"/>
      </a>

      <div class="flex flex-row justify-end text-xs lg:text-sm xl:text-sm mt-4 lg:m-0 xl:m-0" style="color: #008de9">
        <a href="/investors/invest-in-cars" class="mr-2">Invest In Cars</a>
        <span class=" h-4 border-l-2 border-blue mr-2"></span>
        <a href="/investors/buy-cars" class="mr-2">Buy Cars</a>
        <span class=" h-4 border-l-2 border-blue mr-2"></span>
        <a href="/investors/about-us" class="mr-2">About Us</a>
        <span class=" h-4 border-l-2 border-blue mr-2"></span>
        <a href="/investors/contact-us" class="">Contact Us</a>
      </div>
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="container m-auto">

    <div class="flex flex-col lg:flex-row  xl:flex-row 2xl:flex-row mt-16 mb-16">
      <!--      <div class="w-full px-4 lg:0 xl:w-1/2 lg:w-1/2 2xl:w-1/2">-->

      <!--        <div class="flex flex-col">-->
      <!--          <div class="flex flex-row items-center">-->
      <!--&lt;!&ndash;            <div class="w-72 ">&ndash;&gt;-->
      <!--              <div class="w-96 px-10 border-2  border-blue-medium rounded-2xl bg-white">-->
      <!--                <div class="flex flex-row text-sm py-2 text-center">-->
      <!--                  <img src="/images/investors/icons/1.svg" class="mr-1" width="50px">-->
      <!--                  <h2>Investor Pledges</h2>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--&lt;!&ndash;            </div>&ndash;&gt;-->
      <!--            <div class="text-xs ml-4">-->
      <!--              <p>Investor pledges to buy 7500 shares worth $7500-->
      <!--                potentially valued at $9375* in over 4 years-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="flex flex-row items-center mt-8">-->
      <!--            <div class="w-80 px-12 border-2 border-blue-medium rounded-2xl bg-white">-->
      <!--              <div class="flex flex-row text-sm text-center py-2 px-4">-->
      <!--                <img src="/images/investors/icons/2.svg" class="mr-1" width="50px">-->
      <!--                <h2>Customer Pledges</h2>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="text-xs ml-4">-->
      <!--              <p>Customer makes $2500 down payment and pledges to pay $9375 over 4 years</p>-->
      <!--            </div>-->
      <!--          </div>-->


      <!--          <div class="flex flex-row items-center mt-8">-->
      <!--            <div class="w-98 px-8 border-2 border-blue-medium rounded-2xl bg-white">-->
      <!--              <div class="flex flex-row text-sm text-center py-2">-->
      <!--                <img src="/images/investors/icons/3.svg" class="mr-1" width="50px">-->
      <!--                <h2>FairPY Auction Purchase</h2>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="text-xs ml-4">-->
      <!--              <p>FairPY buys the car at $10k from Auction on behalf of-->
      <!--                investor (75% ownership) & customer (25% ownership)-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--        <div class="flex flex-row items-center mt-8">-->
      <!--          <div class="w-96 px-4 border-2 border-blue-medium rounded-2xl bg-white">-->
      <!--            <div class="flex flex-row text-sm text-center py-2 ">-->
      <!--              <img src="/images/investors/icons/4.svg" class="mr-1" width="50px">-->
      <!--              <h2>Investor sells car with Markup-->
      <!--              </h2>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="text-xs ml-4">-->
      <!--            <p>Investor sells 75% ownership ($7500) to the customer at a Markup ($9375) over 4 years-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--        <div class="flex flex-row items-center mt-8">-->
      <!--          <div class="w-64 px-4 border-2 border-blue-medium rounded-2xl bg-white">-->
      <!--            <div class="flex flex-row text-sm text-center py-2">-->
      <!--              <img src="/images/investors/icons/5.svg" class="mr-1" width="50px">-->
      <!--              <h2>Customer makes payment-->
      <!--              </h2>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="text-xs ml-4">-->
      <!--            <p>Customer makes $196 monthly payment over 4 years-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--        <div class="flex flex-row items-center mt-8">-->
      <!--          <div class="w-80 px-8 border-2 border-blue-medium rounded-2xl bg-white">-->
      <!--            <div class="flex flex-row text-sm text-center py-2">-->
      <!--              <img src="/images/investors/icons/2.svg" class="mr-1" width="50px">-->
      <!--              <h2>Investor Reinvests-->
      <!--              </h2>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="text-xs ml-4">-->
      <!--            <p>Investor reinvests $196 back into FairPY to buy additional shares (Principal $156-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--      </div>-->


      <div class="w-full px-4 lg:0 xl:w-1/2 lg:w-1/2 2xl:w-1/2">

        <div class="flex flex-col">
          <div class="flex flex-row items-center">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/1.svg" class="w-20">
              </div>
            </div>
            <div class="ml-6">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">Investor Pledges</h2>
              <p class="text-xs">Investor pledges to buy 7500 shares worth $7500
                potentially valued at $9375* in over 4 years
              </p>
            </div>
          </div>

          <div class="flex flex-row items-center mt-8">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/2.svg" class="w-20">
              </div>
            </div>
            <div class="ml-4">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">Customer Pledges</h2>
              <p class="text-xs">Customer makes $2500 down payment and pledges to pay $9375 over 4 years
              </p>
            </div>
          </div>

          <div class="flex flex-row items-center mt-8">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/3.svg" class="w-20">
              </div>
            </div>
            <div class="ml-8">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">FairPY Auction Purchase</h2>
              <p class="text-xs">FairPY buys the car at $10k from Auction on behalf of
                investor (75% ownership) & customer (25% ownership)
              </p>
            </div>
          </div>


          <div class="flex flex-row items-center mt-8">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/4.svg" class="w-20">
              </div>
            </div>
            <div class="ml-4">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">Investor sells car with Markup</h2>
              <p class="text-xs">Investor sells 75% ownership ($7500) to the customer at a Markup ($9375) over 4 years
              </p>
            </div>
          </div>


          <div class="flex flex-row items-center mt-8">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/5.svg" class="w-20">
              </div>
            </div>
            <div class="">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">Customer makes payment</h2>
              <p class="text-xs">Customer makes $196 monthly payment over 4 years
              </p>
            </div>
          </div>


          <div class="flex flex-row items-center mt-8">
            <div class="w-28">
              <div class="text-sm py-2 text-center">
                <img src="/images/investors/icons/reinvest.svg" class="w-20">
              </div>
            </div>
            <div class="m-0.5">
              <h2 class="text-lg mb-2 font-semibold" style="color: #008de9">Investor Reinvests</h2>
              <p class="text-xs">Investor reinvests $196 back into FairPY to buy additional shares (Principal $156
              </p>
            </div>
          </div>


        </div>
      </div>

      <div class="w-full xl:w-1/2 lg:w-1/2 2xl:w-1/2 content-center">
        <div class="px-2 mt-8 md:px-4 lg:px-8 xl:px-12 ">
          <table class="w-full text-xs investor-table">
            <tr class="text-xs">
              <th></th>
              <th>Month 1</th>
              <th>Month 2</th>
              <th>.......</th>
              <th>Month 48</th>
            </tr>
            <tr>
              <td># of Shares</td>
              <td>7500</td>
              <td>7548</td>
              <td>......</td>
              <td></td>
            </tr>
            <tr>
              <td>Purchase Price</td>
              <td>$7500</td>
              <td>-</td>
              <td>.......</td>
              <td></td>
            </tr>
            <tr>
              <td>Potential Value</td>
              <td>$9375</td>
              <td>$9435</td>
              <td>......</td>
              <td></td>
            </tr>
          </table>
        </div>


      </div>

    </div>


  </div>

  <Footer/>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "InvestInCars",
  components: {
    Footer
  }
}
</script>

<style scoped>

.rcorners2 {
  border-radius: 25px;
  border: 2px solid #008de9;
  padding: 12px;
  width: 300px;
  height: 90px;
}


.investor-table td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.investor-table tr:nth-child(even) {
  background-color: rgba(206, 230, 245, 0.72);
}

.investor-table tr:nth-child(odd) {
  background-color: rgba(238, 242, 245, 0.72);
}

.investor-table tr:nth-child(1) {
  background-color: #008de9;
  color: #ffffff;
}
</style>