<template>
  <div class="">
    <div class="relative flex flex-col p-1 bg-white border shadow hover:shadow-lg lg:h-full lg:justify-between">
      <div class="absolute top-2 left-2">
        <p class="px-2 py-1 text-xs text-white rounded-sm bg-blue bg-opacity-90">Sample Photo</p>
      </div>
      <img class="flex-shrink-0 w-full h-44 object-cover" :src="deal.image"/>
      <div class="flex flex-col p-4 text-black-light">
        <h2 class="font-semibold text-left">{{ deal.title }}</h2>
        <p class="overflow-hidden text-sm text-left text-black-lighter overflow-ellipsis whitespace-nowrap">
          {{ deal.series }}
        </p>
        <div class="flex flex-row items-center py-2 pt-1 text-sm">
          <i class="mr-2 icon small miles"></i> {{ deal.miles }} miles
        </div>
        <hr/>
        <div class="flex flex-row items-center justify-between mt-3">
          <div class="relative flex-grow px-3 py-2 mr-12 leading-3 text-left text-white bg-blue"
               v-if="deal.paymentType">
            <p class="text-xl font-semibold">${{ deal.bid.toLocaleString() }}</p>
            <!--              <span class="mt-1 text-xs">Fair Bid</span>-->
            <span class="mt-1 text-xs">Purchase Price</span>
            <div style="right:-31px;" class="absolute top-0 bottom-0 w-8 cut-svg"></div>
          </div>

          <div class="relative flex-grow px-3 py-2 mr-8 leading-3 text-left text-white bg-blue"
               v-if="!deal.paymentType">
            <p class="text-sm"><span class="text-xl font-semibold">${{ deal.monthly_payments.toLocaleString() }}</span>
              <span class="text-xs">/mo</span></p>
            <span class="mt-1" style="font-size: 10px">48 months</span>
            <div style="right:-31px;" class="absolute top-0 bottom-0 w-8 cut-svg"></div>
          </div>


          <div class="leading-3 text-right">
            <p>
              <span v-if="!deal.paymentType"> ${{ deal.finance_savings.toLocaleString() }}</span>
              <span v-if="deal.paymentType"> ${{ deal.savings.toLocaleString() }}</span>
            </p>
           <span class="mt-1 text-xs text-black-lighter">Savings*</span>
          </div>
        </div>
        <div class="mt-2 text-xs text-black-lighter">
          Retail Value = ${{ deal.bookValue.toLocaleString() }}*
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deal: Object,
  },
};
</script>
<style scoped>
</style>