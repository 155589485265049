import AuthService from "@/views/investors/services/auth.service";

const investors = JSON.parse(localStorage.getItem('investors'));
const investorsInitialState = investors
    ? {status: {loggedIn: true}, investors}
    : {status: {loggedIn: false}, investors: null};
export const investorAuth = {
    namespaced: true,
    state: investorsInitialState,
    actions: {
        login({commit}, investors) {
            return AuthService.login(investors).then(
                (investors) => {
                    commit('loginSuccess', investors);
                    return Promise.resolve(investors);
                },
                (error) => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        forgotPassword({commit}, email) {
            commit('logout');
            return AuthService.forgotPassword(email);
        },
        resetPassword({commit}, data) {
            // commit('logout');
            console.log(commit);
            return AuthService.resetPassword(data);
        },
        register({commit}, investors) {
            console.log('test register');
            return AuthService.register(investors).then(
                (response) => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                (error) => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

    },
    mutations: {
        loginSuccess(state, investors) {
            state.status.loggedIn = true;
            state.investors = investors;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.investors = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.investors = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
    },
};
