<template>
  <TopBar />
  <DefaultBanner
      title="Frequently Asked Questions"
      :class="['lg:faq-banner-bg faq-banner-mobile-bg']"
  />
  <div class="container mx-auto p-5">
  <div class="mt-5 text-4xl font-semibold">{{this.category.title}}</div>
    <div class="mt-2 text-base">{{this.category.description}}</div>

   <div class="mt-10">
     <div v-for="(faq, index) in faqs" :key="index">
       <FaqsAccordian class="w-full mb-5" :title="faq.questions">
         <template v-if="!Array.isArray(faq.content)">
           <div v-html="nl2br(faq.content)"></div>
         </template>
         <template v-if="Array.isArray(faq.content)">
           <ol class="pl-6 list-decimal list-outside">
             <li
                 v-for="(answer, answerIndex) in faq.content"
                 :key="answerIndex"
                 class="mb-2"
             >
               <span class="relative left-2">{{ answer }}</span>
             </li>
           </ol>
         </template>
       </FaqsAccordian>
     </div>
   </div>
  </div>

<Footer/>

</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import DefaultBanner from "@/components/DefaultBanner";
import FaqsAccordian from "@/components/FaqsAccordian";
import Footer from "@/components/Footer";
export default {
  name: "FaqCategory",
  components: {
    TopBar,
    DefaultBanner,
    FaqsAccordian,
    Footer
  },
  data(){
    return{
      categoryId: this.$route.params.id,
      faqs: '',
      category: ''
    }
  },
  mounted() {
    this.faqCategory()
  },
  methods:{
    faqCategory() {
      this.$store.dispatch("investorsFaqs", this.categoryId).then(
          (res) => {
            this.faqs = res.data.list;
            this.category = res.data.category;
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    nl2br(str, is_xhtml) {
      var breakTag =
          is_xhtml || typeof is_xhtml === "undefined" ? "<br " + "/>" : "<br>";

      return (str + "").replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          "$1" + breakTag + "$2"
      );
    },
  }
}
</script>

<style scoped>

</style>