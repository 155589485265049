<template>
    <section id="foot" class="p-4 mt-10 sm:mt-10 md:mt-25 lg:mt-25 foot-bg" >
      <div class="p-5 m-auto w-12/12 sm:w-12/12 md:w-11/12 lg:w-11/12">
          <div class="flex justify-center pb-10">
            <a href="#"> <img class="" src="@/assets/logo-white.png" alt=""> </a>
          </div>
          <div class="grid grid-cols-1 pb-2 md:pb-4 lg:pb-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"> 
            <div class="flex items-center justify-center gap-2 pb-2 md:pb-0 sm:gap-2 md:gap-2 lg:gap-2 sm:justify-start md:justify-start lg:justify-center">
              &nbsp; <span class="mb-0 text-base font-thin md:mb-0 lg:mb-0 md:text-sm lg:text-sm add-txt-color calibre fa-w-500"> Fair PY Inc.</span>
            </div>
           <div class="flex items-center justify-center gap-1 pb-2 md:pb-0 sm:gap-2 md:gap-2 lg:gap-2 sm:justify-start md:justify-start lg:justify-center">
               <img src="@/assets/location.png" alt="">&nbsp; <span class="text-sm cus-font font-thin add-txt-color calibre ">
              880 SW 15th St #3, Forest Lake
              MN 55025, USA </span>
            </div>
             <div class="flex items-center justify-center gap-1 pb-2 md:pb-0 sm:gap-2 md:gap-2 lg:gap-2 sm:justify-start md:justify-start lg:justify-center">
               <img src="@/assets/Phone.png" alt="">&nbsp; <span class="text-sm cus-font font-thin add-txt-color calibre"> 817-357-8462</span>
            </div>
             <div class="flex items-center justify-center gap-1 pb-0 md:pb-0 sm:gap-2 md:gap-2 lg:gap-2 sm:justify-start md:justify-start lg:justify-center">
               <img src="@/assets/mail.png" alt="">&nbsp; <span class="text-sm cus-font font-thin add-txt-color calibre"> admin@fairpy.com </span>
            </div>
          </div>
           <div class="flex flex-wrap justify-center pb-0 my-5 md:pb-5 gap-y-6 sm:gap-y-3 md:gap-y-3 lg:gap-y-0 ">
                <div>
                  <div class="outside-1 ">
                    <div class="inside-1">
                      <input type="email" v-model="email" name="email" class="text-lg font-normal  tracking-wider bg-transparent border-0 outline-none px-10 captilize pr-14 w-full outline-0 text-gray calibre" placeholder="Enter Email ID">
                    </div>
                    </div>
                  </div>
                   <div>
              <a @click.prevent="subscribeToEmail" class="flex-wrap font-light cus-font tracking-wider uppercase text-gray calibre learn-more-btn" href="#">subscribe newsletter </a>
            </div> 
                  
            </div>
            <div class="flex flex-wrap justify-center gap-2 pb-6 sm:gap-3 md:gap-5 lg:gap-5 gap-y-0 sm:gap-y-3 md:gap-y-3 lg:gap-y-0">
                <div>
                  <a target="_blank" href="https://www.facebook.com/gofairpy/"> <img src="@/assets/facebook.png" alt=""></a>
                </div>
                  <div>
                  <a target="_blank" href="https://www.instagram.com/gofairpy/"><img src="@/assets/instagram.png" alt=""></a>
                </div>
              <div>
                <a target="_blank" href="https://www.instagram.com/gofairpy/"><img src="@/assets/twitter.png" alt=""></a>
              </div>
            </div>
            <div class="flex justify-center gap-5 ">
                 <span class="text-sm font-thin add-txt-color calibre">© 2022 FairPY. All rights reserved </span>
            </div>
      </div>
    </section>


  <transition name="bounce" v-show="isThankYouModalVisible" @close="closeModal">
    <div
        class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-40 flex justify-center items-center z-100 overflow-y-scroll">
      <div class="thankyou-modal modal bg-white shadow-xl flex flex-col items-center relative z-auto">
        <button type="button" class="absolute top-4 right-4 border-0 p-0 bg-transparent z-10" @click="closeModal">
          <i class="icon close small"></i>
        </button>
        <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
          <img class="lg:w-36 fuel-icon" src="/images/thankyou-popup.png"/>
        </div>

        <div v-if="isThankYouModalVisible" class="flex justify-center relative w-full">
          <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 py-12 px-6
        relative">

            <h1 class="tn-text text-3xl font-display  text-center mt-10 ">
              Thank you!
            </h1>
            <h1 class="tn-text text-3xl font-display  text-center ">
              You're Subscribed
            </h1>
            <p class="text-content text-sm text-gray-medium mt-6">
              A confirmation has been sent to you email ID. You will be the first to know about new vehicles, special
              bid offers etc.. Stay tuned.
            </p>
            <h3 class="follow font-display">FOLLOW US</h3>

            <div class="w-full flex flex-row justify-center items-center text-center relative">
              <a href="https://www.facebook.com/gofairpy/"><img class="fb-icon" src="/images/icons/fb-gray.png"/></a>
              <a href="https://twitter.com/gofairpy"> <img class="fb-icon" src="/images/icons/twitter-gray.png"/></a>
              <a href="#"><img class="fb-icon" src="/images/icons/google-gray.png"/></a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </transition>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: 'Foot',
  props: {
    msg: String
  },
  data() {
    return {
      email: "",
      isThankYouModalVisible: false,
    };
  },
  setup() {
    const toast = useToast();
    return {toast};
  },
  methods: {
    isValidEmail(email) {
      const re =
          /* eslint-disable-next-line */
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },
    subscribeToEmail() {
      if (!this.email || this.email.trim() == "") {
        this.toast.warning("Email is required");
        return;
      } else if (!this.isValidEmail(this.email)) {
        this.toast.warning("Invalid Email Provided");
        return;
      }
      this.$store.dispatch("subscribeLetter", this.email).then(
          (value) => {
            console.log(value);
            // this.toast.success("Thank you for Subscribing.");
            this.$store.dispatch("subscribeMail", this.email).then(
                (data) => {
                  console.log(data);
                  this.isThankYouModalVisible = true;
                  document.body.classList.add("overflow-hidden");
                }, (error) => {
                  this.toast.error("Sorry! Something went wrong");
                  console.log(error);
                }
            )
            this.email = "";
          },
          (error) => {
            this.toast.error("Sorry! Something went wrong");
            console.log(error);
          }
      );
    },

    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isModalVisible = false;
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    close() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>
.cus-font{
  font-size: 0.8rem;
}
.add-txt-color{
  color: #dce1e5;
}
  .foot-bg{
    background-image: url(../assets/footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .learn-more-btn{
    background-image: url(../assets/btn-trans.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 58px;
    display: inline-block;
    min-width: 265px;
    text-align: center;
    color: white;
    line-height: 50px;

}
  .outside-1 {
    position: relative;
    width: 420px;
    height: 50px;
    background-color: #fff;
    clip-path: polygon(0% 0, 92% 0, 100% 100%, 7% 100%);
  }

  .inside-1 {
    line-height: 45px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 4px;
    right: 4px;
    bottom: 2px;
    background-color: rgb(149 149 149 / 46%);
    clip-path: polygon(0% 0, 92% 0, 100% 100%, 7% 100%);
  }
  input::placeholder{
    color: #191919;
    font-size: medium;
  }

    @media (max-width: 640px) { 
    .outside-1{
      width: 320px!important;
    
    }
    .inside-1{
      text-align: center;
      width: 320px!important;
    }

 }


@media screen and (max-width: 760px) {
  .object-custom-position {
    object-position: 0px -100px !important;
  }

  .thankyou-modal {
    width: 100% !important;
  }

  .fuel-icon {
    width: 40% !important;
  }
}

.thankyou-modal {
  width: 40%;
}

.fuel-icon {
  position: absolute;
  top: -70px;
  margin-left: 50px;
}

.tn-text {
  color: #0d213a;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.text-content {
  color: #666666;
  font-size: 11px;
}

.fb-icon {
  width: 55px;
  padding: 10px;
}

.follow {
  color: #4d4d4d;
  margin-top: 26px;
  font-size: 14px;
  font-weight: 600;
}

.object-custom-position {
  object-position: 0px 0px;
}
</style>
