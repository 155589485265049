<template lang="">
    <div :class="['flex', 'w-full', 'font-display', 'relative', 'flex-col', center ? 'items-center' : '', center ? 'text-center' : '']">
      <h1 :class="['lg:text-4xl','text-3xl', light ? 'text-white' : 'text-blue-dark', 'font-extrabold', 'uppercase', 'relative', 'z-10']">
        <slot></slot>
      </h1>
      <div :class="['h-px', lineClass ? lineClass : 'bg-blue-dark', 'w-24', 'mt-4']"></div>
    </div>
</template>
<script>
export default {
  props: {
    lineClass: String,
    titleClass: String,
    light: Boolean,
    center: Boolean,
  },
};
</script>
<style scoped>
img {
  height: 3rem;
}
</style>