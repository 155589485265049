<template>
  <div>
    <button
      @click="toggleAccordion()"
      class="flex flex-col items-stretch w-full bg-white border"
      :aria-expanded="isOpen"
    >
      <div class="flex flex-row items-center w-full">
        <div class="flex flex-col items-start flex-grow">
          <div :class="isOpen ? 'bg-blue text-white' : 'text-black'" class="flex flex-row items-center justify-between w-full px-3 py-3 lg:px-6 ">
            <div class="text-lg font-bold text-left rounded-sm">
              {{ title }}
            </div>
            <svg  class="flex-shrink-0 w-3 ml-3 transition-all duration-200 transform " :class="{ 'rotate-180': isOpen,  'rotate-0': !isOpen,   }"  fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 10"
              aria-hidden="true"
            >
              <path   d="M15 1.2l-7 7-7-7"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  />
            </svg>
          </div>
        </div>
      </div>
      <div class="">
        <div  v-show="isOpen"  class="px-6 pt-4 pb-8 text-base text-left text-black-lighter" >
          <slot>
            
          </slot>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    iconClass: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
