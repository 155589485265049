<template lang="">
  <div class="w-100 banner-bg">
    <div
      class="container mx-auto lg:px-3 px-5 py-12 pt-32 flex flex-col lg:flex-row lg:justify-between"
    >
      <div class="flex flex-col font-display font-bold flex-grow">
        <h5 class="text-blue-light uppercase lg:text-2xl text-xl">
          The Smartest Way To
        </h5>
        <h1
          class="text-white uppercase lg:text-5xl text-3xl font-extrabold pt-4 lg:pb-6 pb-3"
        >
          Purchase a <span class="whitespace-nowrap">Pre-Owned</span> Vehicle
        </h1>
        <p class="text-white font-normal">
          We have a passion for providing the
          <span class="text-blue-light">fairest deals for customers</span>
        </p>
        <div class="p-8 pl-0 mt-20 banner-car lg:block hidden">
          <img src="/images/car.png" />
        </div>
      </div>

      <div class="flex flex-col justify-center items-end">
        <a href="/" class="bg-blue rounded border-blue px-10 py-3 w-64 text-center justify-center text-white mr-6 text-xl mb-4"> View All Cars </a>
        <a href="/faqs" class="bg-white rounded border-white px-10 py-3 w-64 text-center justify-center text-blue mr-6 text-xl mb-4"> Learn More </a>
      </div>

      <div class="block lg:hidden">
        <div class="pt-8 banner-car">
          <img src="/images/car.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",
  components: {},
};
</script>
<style lang=""></style>
