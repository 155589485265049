<template lang="">
<div class="w-100">
    <div class="w-100 bg-blue-dark">
        <div class="container mx-auto flex flex-row justify-between text-white text-xs lg:px-3 px-5 py-6">
            <div class="flex flex-row items-center flex-grow">
               <div class="lg:w-3/12 w-1/2">
                  <Logo :isLight="true"/>
               </div>
               <div class="lg:flex hidden flex-row items-start w-3/12">
                 <i class="icon h-5 w-5 map mr-2"></i>
                 <p class="text-white">
                    880 SW 15th St #3, Forest Lake<br>
                    MN 55025, USA
                 </p>
               </div>
               <div class="lg:flex hidden flex-col items-start w-3/12">
               <div class="flex flex-row">
                  <i class="icon small phone mr-2 items-center"></i>
                  <p class="text-white">
                    817-357-8462
                  </p>
                </div>
                <div class="flex flex-row items-center mt-2">
                  <i class="icon small email mr-2"></i>
                  <p class="text-white">
                    admin@fairpy.com
                  </p>
                </div>
               </div>
               <div class="flex flex-row justify-end lg:w-3/12 w-1/2">
                 <a href="https://www.facebook.com/gofairpy/" target="_blank"><img src="/images/icons/fb.png"/></a>
                 <a class="ml-2" href="https://twitter.com/gofairpy" target="_blank"><img src="/images/icons/twitter.png"/></a>
                 <a class="ml-2" href="https://www.instagram.com/gofairpy/" target="_blank"><img src="/images/icons/insta.png"/></a>
               </div>
            </div>
        </div>
    </div>
    <div class="w-100 bg-blue-darker">
        <div class="container mx-auto flex lg:flex-row flex-col justify-between text-white text-xs px-3 py-4">
          <div class="text-gray-300 py-2">
            © Copyright 2021  FairPY.  All Right Reserved
          </div>
          <div class="text-gray-300 flex flex-row items-center">
            <a href="" class="mr-3 py-2">Privacy Policy</a> |
            <a href="" class="ml-3 py-2">Terms and Conditions</a>
          </div>
        </div>
    </div>
    </div>
</template>
<script>
import Logo from "./Logo.vue";
export default {
  name: "NavBar",
  components: {
    Logo,
  },
};
</script>
<style lang="">
</style>