<template>
  <!-- <GeoLocation v-show="geolocation" /> -->
  <TopBar :disableSlide="true" />
  <NavBar />
  <Banner />
  <CarDeals />
  <HowItWorks />
  <LifeStyle />
  <HaveQuestion />
  <GetUpdates />
  <Footer />
</template>

<script>
  import Banner from "../components/Banner.vue";
import CarDeals from "../components/CarDeals.vue";
import Footer from "../components/Footer.vue";
// import GeoLocation from "../components/GeoLocation.vue";
import GetUpdates from "../components/GetUpdates.vue";
import HaveQuestion from "../components/HaveQuestion.vue";
import HowItWorks from "../components/HowItWorks.vue";
import LifeStyle from "../components/LifeStyle.vue";
import NavBar from "../components/NavBar.vue";
import TopBar from "../components/TopBar.vue";
  export default {
    name: "Home",
    components: {
      // GeoLocation,
      TopBar,
      NavBar,
      Banner,
      CarDeals,
      HowItWorks,
      LifeStyle,
      HaveQuestion,
      GetUpdates,
      Footer,
    },
    data() {
      return {
        toggleMobile: false,
      };
    },
    mounted() {
      window.addEventListener("resize", this.myEventHandler);
      this.myEventHandler();
    },
    methods: {
      isMobile() {
        return window.innerWidth <= 768;
      },
      myEventHandler() {
        this.toggleMobile = this.isMobile();
      },
    },
  };
</script>

<style></style>