<template lang="">
  <transition name="bounce">

    <div
         class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-60 flex justify-center items-center z-topmost overflow-y-scroll">
      <button type="button"
              class="absolute top-4 right-4 border-0 p-0 bg-transparent z-10"
              @click="close">
        <i class="icon close small"></i>
      </button>
      <div class="absolute bg-blue rounded-lg max-h-mobile-popup left-0 right-0 bottom-0 overflow-y-auto">
        <div class="modal shadow-xl flex flex-col items-start relative z-auto">

          <img class="absolute top-0 left-0 right-0 z-0"
               src="/images/lucky-draw-thankyou-mobile-banner.png" />
          <div class="w-full">
            <div class="relative flex flex-col items-center w-full p-4">
              <h2 class="text-2.5xl text-white font-black text-center text-stroke-blue mt-5">Thank You</h2>

              <p class="font-display text-2.5xl text-white text-center mt-40 leading-none font-extrabold">
                Thank you for registering with us. We will send you the free raffle ticket shortly
              </p>
              <p class="font-display mt-4 text-center text-white font-semibold">
                This raffle is sponsored by FairPY <br/>"The smartest way to buy a pre-owned vehicle"
              </p>

              
              <button @click.prevent="close"
                      class="bg-white uppercase text-blue p-3 w-full block my-8">
                Okay
              </button>
            </div>
            <div class="bg-white text-center w-full flex items-center justify-center p-3">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import Logo from './Logo.vue';

  export default {
    props: {
      bodyClass: String,
      showModalBg: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Logo
    },
    methods: {
      close() {
        this.$emit("close");
      },
      submitLuckyDraw() {

      }
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.25s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.25s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>