<template>
  <LuckyDraw v-show="isModalVisible"
             @success="showThankYouModal"
             @close="closeModal" />
  <LuckyDrawThankYou v-show="isThankyouModalVisible"
                     @close="closeThankYouModal" />
</template>

<script>
  import LuckyDraw from "./LuckyDraw.vue";
import LuckyDrawThankYou from "./LuckyDrawThankYou.vue";
  export default {
    name: "LuckyDrawDesktopHolder",
    components: {
      LuckyDraw,
      LuckyDrawThankYou
    },
    data() {
      return {
        isModalVisible: false,
        isThankyouModalVisible: false
      };
    },
    mounted() {
      window.setTimeout(() => {
        this.showLuckyDraw();
      }, 1000);

      this.emitter.on("showLuckyDraw", (value) => {
        if (value)
          this.showLuckyDraw();
      });
    },
    methods: {
      closeModal() {
        this.isModalVisible = false;
        document.body.classList.remove("overflow-hidden");
      },
      showLuckyDraw() {
        this.isModalVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      showThankYouModal() {
        this.closeModal();
        this.isThankyouModalVisible = true;
        document.body.classList.add("overflow-hidden");
      },
      closeThankYouModal() {
        this.isThankyouModalVisible = false;
        document.body.classList.remove("overflow-hidden");
      }
    },
  };
</script>

<style></style>