<template lang="">
    <a href="" @click.prevent="clicked" class="border-2 px-6 h-full uppercase flex flex-row items-center justify-center relative font-medium">
       <i v-if="icon" :class="['icon',size ? size : 'small','signin', size != 'small'?'mr-4':'mr-2',icon]"></i>{{ label }}
    </a>
</template>
<script>
export default {
  props: {
    icon: String,
    label: String,
    size: String,
    onClicked: Function,
  },
  mounted() {},
  methods: {
    clicked() {
      this.onClicked();
    },
  },
};
</script>
<style scoped>
</style>