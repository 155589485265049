<template>
  <!-- <GeoLocation v-show="geolocation"/> -->
  <LuckyDrawDesktopHolder v-if="!toggleMobile && isRafflePage()"/>
  <LuckyDrawMobileHolder v-if="toggleMobile && isRafflePage()"></LuckyDrawMobileHolder>
  <TopBar :disableSlide="true"/>
  <NavBar/>
  <VideoBanner/>
<!--  <Find/>-->
  <CarDeals/>
  <HowItWorks/>
<!--  <LifeStyle/>-->
  <HaveQuestion/>
  <GetUpdates/>
  <Footer/>
</template>

<script>
// import GeoLocation from "@/components/GeoLocation";
import CarDeals from "../components/CarDeals.vue";
import Footer from "../components/Footer.vue";
import GetUpdates from "../components/GetUpdates.vue";
import HaveQuestion from "../components/HaveQuestion.vue";
import HowItWorks from "../components/HowItWorks.vue";
// import LifeStyle from "../components/LifeStyle.vue";
import LuckyDrawDesktopHolder from "../components/LuckyDrawDesktopHolder.vue";
import LuckyDrawMobileHolder from "../components/LuckyDrawMobileHolder.vue";
import NavBar from "../components/NavBar.vue";
import TopBar from "../components/TopBar.vue";
import VideoBanner from "../components/VideoBanner.vue";
// import Find from '../components/Find-car.vue'

export default {
  name: "Home",
  components: {
    // GeoLocation,
    TopBar,
    NavBar,
    VideoBanner,
    // Find,
    CarDeals,
    HowItWorks,
    // LifeStyle,
    HaveQuestion,
    GetUpdates,
    Footer,
    LuckyDrawMobileHolder,
    LuckyDrawDesktopHolder
  },
  data() {
    return {
      toggleMobile: false,
      geolocation: false,
      getLocation: ''
    };
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
    // console.log('My Current Location');
    this.getLocation = localStorage.getItem('location');
    // console.log(this.getLocation);
    if (this.getLocation) {
      this.geolocation = false;
      document.body.classList.remove("overflow-hidden");
    } else {
      this.geolocation = true;
    }
  },

  methods: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    isRafflePage() {
      return this.$route.name == 'Raffle';
    },
    myEventHandler() {
      this.toggleMobile = this.isMobile();
    },
  }
};
</script>

<style></style>