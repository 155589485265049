import axios from 'axios';
import config from '../config';

const API_URL = config.hostname + '/api/';

class CommonService {
  contact(data) {
    return axios.post(API_URL + 'contact', data);
  }
  profile(data) {
    return axios.post(API_URL + 'profile', data);
  }
}
export default new CommonService();
