<template lang="">
  <div class="flex items-center mb-8">
    <div class="mr-4 flex-shrink-0">
      <img class="" :src="image" />
    </div>
    <div class="flex flex-col">
      <h5 class="font-bold text-black-light mb-2">{{ title }}</h5>
      <p v-if="content" class="text-black-lighter">
        {{content}}
      </p>
      <slot v-if="!content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image: String,
    title: String,
    content: String,
  },
};
</script>
<style lang=""></style>
