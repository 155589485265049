<template lang="">
  <div class="w-100">
    <div
      class="container mx-auto lg:px-3 px-5 lg:py-16 py-12 flex flex-col lg:flex-row lg:justify-between"
    >
      <div class="flex flex-col font-bold flex-grow items-center">
        <h2
          class="text-white px-6 text-center text-3xl font-semibold"
        >
          {{title}}
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Default Banner",
  components: {},
  props: {
    title: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang=""></style>
