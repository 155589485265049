<template lang="">
    <div class="w-100 banner-bg">
        <div class="container mx-auto lg:px-3 px-5 py-12 flex flex-col lg:flex-row lg:justify-between">
          <div class="flex flex-col font-display font-bold flex-grow">
              <h5 class="text-blue-light uppercase lg:text-2xl text-xl">The Smartest Way To</h5>
              <h1 class="text-white uppercase lg:text-5xl text-3xl font-extrabold pt-4 lg:pb-6 pb-3">
                Purchase a <span class="whitespace-nowrap">Pre-Owned</span> Vehicle
              </h1>
              <p class="text-white font-normal">
                We have a passion for providing the <span class="text-blue-light">fairest deals for customers</span>
              </p>
              <div class="p-8 pl-0 mt-20 banner-car lg:block hidden">
                <img src="/images/car.png"/>
              </div>
          </div>
          <BannerSearchForm />
          <div class="block lg:hidden">
            <div class="pt-8 banner-car">
                <img src="/images/car.png"/>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
import BannerSearchForm from "./BannerSearchForm.vue";

export default {
  name: "Banner",
  components: {
    BannerSearchForm,
  },
};
</script>
<style lang="">
</style>