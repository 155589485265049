<template>
  <TopBar/>
  <div class="container mx-auto mt-10 lg:px-3 px-5 watermark-sample">
    <h1 class="text-center font-semibold text-3xl my-6">PARTNERSHIP AGREEMENT </h1>

    <div class="mt-12">
      This Partnership Agreement (the "Agreement") is made and entered into this (the "Effective Date"). The Partners in
      this Agreement are as follows:
    </div>

    <ul class="mt-4 leading-9 ml-8" style="list-style-type: disc">
      <li>[name of Partner 1]</li>
      <li>[name of Partner 2]</li>
    </ul>

    <div class="mt-4">The Partners of this Agreement agree to the following:</div>

    <h2 class="mt-4 uppercase font-semibold text-xl">I. Name</h2>
    <div class="mt-2">This Partnership will be known as [legal name] (the "Partnership").</div>

    <h2 class="mt-6 font-semibold text-xl uppercase">II. THE PARTNERSHIP</h2>

    <ul class="leading-9 mt-2 ml-8" style="list-style-type: disc">
      <li>The Partners wish to become legal partners in business.</li>
      <li>The terms and conditions of their Partnership will be outlined in this Agreement.</li>
      <li>If the Agreement is executed, the Partnership will be in effect on [date].</li>
      <li>The Partnership will only be terminated as outlined in this Agreement.</li>
      <li>The Partnership's primary place of business will be 4443 Zahir Ct Irving TX 75062</li>
      <li>The Partnership will be governed under the laws of the state of Delaware</li>
      <li>The Partnership's primary purpose is to invest in Cars which will generate passive rental income. Supported
        by a managing LLC
      </li>
    </ul>

    <h2 class="font-semibold uppercase text-xl mt-6" style="list-style-type: disc">III. CONTRIBUTIONS</h2>
    <div class="mt-1">The Partners will make an initial contribution to the Partnership as follows:</div>
    <ul class="mt-4 leading-9 ml-8" style="list-style-type: disc" >
      <li>[insert name of Partner 1]: $[insert amount]</li>
      <li>[insert name of Partner 2]: $[insert amount]</li>
    </ul>

    <div class="mt-4">
      Contributions will be submitted no later than Dec 31st 2022. All capital contributions are final unless all
      partners give written consent of withdrawal. All contributions will be deposited into FairPY Business Account and
      transferred into the Partnership LLC once its setup.
    </div>

    <h2 class="mt-4 font-semibold text-xl mt-6">
      IV. INTEREST AND AUTHORITY
    </h2>
    <div class="mt-2">The Partners' ownership interest in the Partnership will be as follows:</div>

    <ul class="mt-2 leading-7 ml-8" style="list-style-type: disc">
      <li>[name of Partner 1]: [percentage]%</li>
      <li>name of Partner 2]: [percentage]%</li>
    </ul>

    <div class="mt-4">
      The Partners' authority will be defined by the following unless otherwise stated in the Agreement: Any decisions
      requiring a contract or otherwise will require a unanimous vote by all Partners.
    </div>

    <div class="mt-2">
      The Partnership qualifies and agrees to opt-out of appointing a partnership representative under 26 U.S. Code §
      6221.
    </div>

    <h2 class="mt-6 font-semibold text-2xl">V. COSTS</h2>
    <div class="mt-2">One-time Costs:</div>

    <ul class="mt-4 leading-9" style="list-style-type: disc">
      <li>Car Purchase Costs
        <ul class="leading-7 ml-12" style="list-style-type: circle">
          <li>Car Auction Price</li>
          <li>Wholesale buyer fee</li>
          <li>Purchase Insurance (this is needed to return the car)</li>
          <li>Shipping Fee if any (plan to buy most of the cars from Texas)</li>
          <li>FairPY Buyer Fee - $199</li>
        </ul>
      </li>
      <li>
        Car Setup Costs
        <ul class="leading-7 ml-12" style="list-style-type: circle">
          <li>Post Sale Inspection</li>
          <li>Detailing</li>
          <li>Minor Repairs</li>
          <li>Tracker Setup</li>
          <li>Tolls Setup</li>
          <li>State Inspection</li>
          <li>Gap Insurance Setup</li>
          <li>Photography</li>
          <li>Registration, Title & Sales Tax</li>
        </ul>
      </li>

      <li>Car Sales Costs
        <ul class="leading-7 ml-12" style="list-style-type: circle">
          <li>3rd party/Auction Seller Fee</li>
          <li>Mailing costs</li>
          <li>FairPY Sales Fee - $199</li>
        </ul>
      </li>

      <li>Monthly variable costs:
        <ul class="leading-7 ml-12" style="list-style-type: circle">
          <li>FairPY Management Fee (30% of Rental Earnings)</li>
          <li>Delivery Charges</li>
          <li>Reimbursements (Incidentals, Tolls, Gas)</li>
          <li>Major Repairs</li>
        </ul>
      </li>

      <li>Monthly Fixed Costs
        <ul class="leading-7 ml-12" style="list-style-type: circle">
          <li>Gap Insurance</li>
          <li>Tracker Costs (~$20 per month)</li>
        </ul>
      </li>
    </ul>

    <div class="mt-2">
      Car Purchase & Car Setup Costs (Asset Costs) will all be added to calculate the cost of asset. Car Sales Costs
      will be subtracted from Car Sales Price when the cars are sold. The total Asset Costs of all vehicles should be no
      less than 95% of the funds raised ($475,000). 5% will be in the Bank Account as a buffer for any unforeseen car
      related expenses.
    </div>

    <div class="mt-2">
      Monthly costs will be calculated at the end of the month by combining monthly variable & fixed costs
    </div>

    <div class="mt-2">The Partners will share monthly costs according to the following percentages:</div>

    <ul class="mt-2 leading-7" style="list-style-type: disc">
      <li>[name of Partner 1]: [percentage]%</li>
      <li>[name of Partner 2]: [percentage]%</li>
    </ul>

    <h2 class="font-semibold text-2xl mt-4">
      VI. PROFITS
    </h2>

    <div class="mt-2">
      The Partners will share the net profits of the Partnership according to the following percentages:
    </div>

    <ul class="leading-7 mt-2" style="list-style-type: disc">
      <li>[name of Partner 1]: [percentage]%</li>
      <li>[name of Partner 2]: [percentage]%</li>
    </ul>

    <div class="mt-4">
      The Profits will be accounted for by FairPY INC and distributed at the end of the month according to the above
      percentages after the costs of the Partnership have been deduced from Total Earnings (Rental Earnings &
      Reimbursements) according to the above cost percentages.
    </div>

    <h2 class="font-semibold  text-2xl mt-6">
      VII. FAIRPY Management Fee
    </h2>

    <div class="mt-2">
      FairPY will charge 30% of Rental Earnings & FairPY is responsible for all of the following
    </div>
    <ul class="leading-9 mt-2" style="list-style-type: decimal">
      <li><b>120 point inspection</b> –Inspect every single car to ensure if it meets all our standards for rentals</li>
      <li><b>Return/Repair</b> – Post inspection, make a decision of whether to return the car or repair the car and
        rent or just list it on rental platforms. This decision is based on the optimal ROI
      </li>

      <li><b>Gap Insurance</b> – Work with commercial Period X (Gap) Insurance companies to add insurance to the car
      </li>
      <li><b>State Inspection</b> – Every car in the state of Texas needs to undergo a state inspection. If the state
        inspection fails then FairPY will determine between returning the car back to the auction or fixing the issue
        and bringing it back to state inxpection
      </li>
      <li><b>Registration & Title</b> – Register the car with FairPY as the owner and individual investors in the Lien
      </li>
      <li><b>Car Staging</b> - Clean/Detail the car and get ready of photoshoot</li>
      <li><b>Toll Tag</b> – Apply for toll tags and configure in carsync to maintain receipts for tolls</li>
      <li><b>Tracker/Remote lock/unlock</b> – Install car tracker with remote lock & unlock feature</li>
      <li><b>Car Rental Listing </b> – List cars in various (Turo, Getaround,Hyrecar) platforms</li>
      <li><b>Car Calendar Syncing </b> – Sync calendars between car sharing platforms</li>
      <li><b>Rental Pricing</b> – Price cars rentals based on demand, days of week & trip duration</li>
      <li><b>Cleaning</b> – Clean car before every car check-in</li>
      <li><b>Delivery</b> – Deliver cars to customer based on customer requests. Focus will be on delivery to DFW
        Airport,
        Pickup the car from DFW Airport short term parking or from customer location
      </li>
      <li><b>Checkin/Checkout</b> – Take pictures, enter odometer, fuel level & verify customer license</li>
      <li><b>Car Parking</b> – Park the cars with all needed securities to prevent car breakins when not in use</li>
      <li><b>Invoice incidentals</b> (Gas, Tolls, Late fee etc) – Invoice the customer on incidentals immediately after
        or
        within a week of customer checkout
      </li>
      <li><b>Insurance Claim Processing</b> – In case of accidents work with Turo to process claims</li>
      <li><b>Car Damages/Thefts</b> – Work with Gap Insurance to get recover car damages & theft costs. FairPY will try
        to
        identify the location of the car using its tracker in case of theft. Car break-ins are not preventable, FairPY
        will use all its available resources to deter car breakins
      </li>
      <li><b>Oil Change</b> – Change oil every 5000 miles and or as needed</li>
      <li><b>Tires Rotation & Alignment</b> – Rotate tires and ensure vehicle alignment. Vehicle alignment should be
        checked
        every 6 months
      </li>
      <li><b> Repairs</b> – Minor car repairs below $200 will be done by FairPY, Major car repairs will be dealt with
        case by
        case basis. FairPY will decide the right course of action based on returns for investors. Following are the
        possible course of actions 1. Fix the car, Charge every investor equally & list it back on the car sharing
        platform 2. Fix the car, Charge every investor equally, sell it in Retail/Auction market & purchase another car
        for the same value 3. Sell the car as is in auction market & purchase another car for the same value
      </li>

    </ul>

    <h2 class="font-semibold text-2xl mt-6">VIII. ACCOUNTING</h2>

    <ul class="leading-9" style="list-style-type: disc">
      <li>FairPY will internally maintain an individual distribution account. FairPY will keep accurate and complete
        books of account for all accounts related to the Partnership.
      </li>
      <li>Any Partner, whether majority or minority, will be allowed to review all books of account upon request.</li>
      <li>Mid January of each year FairPY will generate K1 to each of the partners. K1 will include taxable income which
        will include the loss from depreciation of the vehicles)
      </li>
      <li>Each Partner will be responsible for his or her own taxes on any distributions made.</li>
      <li>Accounting records will be kept on a cash basis.</li>
      <li>The fiscal year will be complete on the last day of Dec of each year. All Partners will present their position
        on the state of the Partnership within two weeks of the completion of each fiscal year.
      </li>
    </ul>

    <h2 class="font-semibold text-2xl mt-6">IX. NEW PARTNERS</h2>
    <div class="mt-2">
      The Partnership will amend this agreement to include new partners upon the written and unanimous vote of all
      Partners.
    </div>

    <div class="mt-2">
      The name of the Partnership may be amended if a new Partner is added to the Partnership upon the written and
      unanimous vote of all Partners
    </div>

    <h2 class="font-semibold text-2 mt-6">X. WITHDRAWAL OR DEATH</h2>

    <div class="mt-2">
      The Partners hereby reserve the right to withdraw from the Partnership at the end of the fiscal year. Should a
      Partner withdraw from the Partnership because of choice or death, the remaining Partners will have the option to
      buy out the remaining shares of the Partnership. Should the Partners agree to buy out the shares, the shares will
      be transferred to the respective Partners. The Partners agree to utilize FairPY to assess the market value of the
      remaining shares. FairPY assessment will be based on MMR (KBB equivalent of Wholesale) and the condition of the
      car. The Partners will have 15 days to decide if they want to buy the remaining shares together and disperse them
      equally. If all Partners do not agree to buy the shares, individual Partners will then have the right to buy the
      shares individually. If more than one Partner requests to buy the remaining shares, the shares will be split
      equally among those Partners wishing to purchase the shares. Should all Partners agree by unanimous vote, the
      Partnership may choose to allow a non-Partner to buy the shares thereby replacing the previous Partner.
    </div>

    <div class="mt-2">
      If no individual Partner(s) finalizes a purchase agreement by 30 days, the Partnership will be dissolved.
    </div>

    <div class="mt-2">
      The name of the Partnership may be amended upon the written and unanimous vote of all Partners if a Partner is
      successfully bought out.
    </div>

    <h2 class="font-semibold text-2xl mt-6">
      XI. DISSOLUTION
    </h2>

    <div class="mt-4">
      Should the Partnership be dissolved by unanimous vote, the Partnership will be liquidated by selling all the cars
      back in local auction, and the debts will be paid. All remaining funds after debts have been paid will be
      distributed based on the percentage of ownership interest outlined in this Agreement.
    </div>

    <div class="font-semibold text-2xl mt-6">
      XII. AMENDMENTS
    </div>

    <ul class="leading-7 ml-8" style="list-style-type: disc;">
      <li>Amendments may be made hereto upon the unanimous and written consent of all Partners.</li>
      <li>Amendments must be expressly written and have the original signatures of all Partners.</li>
      <li>All amendments, notices, requests, claims, demands, and other communications between the parties shall be in
        writing. All such written communications shall be given (i) by delivery in person, (ii) by a nationally
        recognized next day courier service, (iii) by first-class, registered or certified mail, postage prepaid, (iv)
        by facsimile or (v) by electronic mail to the addresses of the parties specified in this Agreement or such other
        addresses specified in writing. All notices shall be effective upon (i) receipt by the party to which the
        written communication is given, or (ii) on the 5th day following mailing, whichever occurs first.
      </li>
    </ul>

    <div class="mt-6">
      <span class="font-semibold text-xl">IN WITNESS WHEREOF, </span>
      <span class="font-semibold text-lg">this Agreement has been executed and delivered in the manner prescribed by law as of the Effective Date first written above.</span>
    </div>

    <div class="flex flex-row mb-12">
      <div>By: <input type="text" class="w-6/12  lg:w-8/12 border-b-2 border-black-light focus:outline-none">
      </div>
      <div>Date : <input type="text" class="w-6/12  lg:w-8/12 border-b-2 border-black-light focus:outline-none"></div>
    </div>

  </div>

  <Footer/>
</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import Footer  from "@/components/Footer";
export default {
  name: "PartnershipAgreement",
  components: {TopBar, Footer}
}
</script>

<style scoped>

</style>