<template>
  <div id="updates-section" class="w-100 lg:py-24 lg:py-16 relative overflow-hidden">
    <div class="container mx-auto items-center flex-row flex lg:px-3 px-6 py-6 z-10 relative">
      <div class="lg:flex flex-col w-1/2 hidden">
        <img src="/images/updates-image.png"/>
      </div>
      <div class="flex flex-col lg:w-1/2 relative justify-center">
        <DefaultTitle lineClass="bg-blue" class="lg:mb-16 mb-8">GET ALL THE UPDATES</DefaultTitle>
        <div class="flex flex-col lg:hidden mb-8">
          <img src="/images/updates-image.png"/>
        </div>
        <p class="font-bold text-xl text-black-light mb-8">
          Subscribe to know about the upcoming Offers
        </p>
<!--        <p class="font-light text-black-lighter">-->
<!--          Dont miss to know about any new arrivals or any new auctions which is going to happen.-->
<!--          <br><br>-->
<!--          FariPY wants you to get updated always and not to miss any of your dream car.-->
<!--        </p>-->
        <p class="font-light text-black-lighter">
          Don’t miss out on new arrivals and deals.
          <br><br>
          FairPY will keep you updated on your dream cars.
        </p>
        <div class="flex lg:flex-row flex-col lg:items-stretch items-start w-full mt-8">
          <input v-model="email" placeholder="Enter Email Address" class="border mr-6 p-2 w-full flex-grow" type="text"
                 name="search" id="email-subscribe"/>
          <button @click.prevent="subscribeToEmail"
                  class="bg-blue text-white h-full p-3 px-9 text-sm uppercase lg:mt-0 mt-4">Subscribe
          </button>
        </div>
      </div>
    </div>
  </div>


  <transition name="bounce" v-show="isThankYouModalVisible" @close="closeModal">
    <div
        class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-40 flex justify-center items-center z-100 overflow-y-scroll">
      <div class="thankyou-modal modal bg-white shadow-xl flex flex-col items-center relative z-auto">
        <button type="button" class="absolute top-4 right-4 border-0 p-0 bg-transparent z-10" @click="closeModal">
          <i class="icon close small"></i>
        </button>
        <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
          <img class="lg:w-36 fuel-icon" src="/images/thankyou-popup.png"/>
        </div>

        <div v-if="isThankYouModalVisible" class="flex justify-center relative w-full">
          <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 py-12
        px-6 relative">

            <h1 class="tn-text text-3xl font-display  text-center mt-10 ">
              Thank you!
            </h1>
            <h1 class="tn-text text-3xl font-display  text-center ">
              You're Subscribed
            </h1>
            <p class="text-content text-sm text-gray-medium mt-6">
              A confirmation has been sent to you email ID. You will be the first to know about new vehicles, special
              bid offers etc.. Stay tuned.
            </p>
            <h3 class="follow font-display">FOLLOW US</h3>

            <div class="w-full flex flex-row justify-center items-center text-center relative">
              <a href="https://www.facebook.com/gofairpy/"><img class="fb-icon" src="/images/icons/fb-gray.png"/></a>
              <a href="https://twitter.com/gofairpy"> <img class="fb-icon" src="/images/icons/twitter-gray.png"/></a>
              <a href="#"><img class="fb-icon" src="/images/icons/google-gray.png"/></a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </transition>


</template>


<script>
import { useToast } from "vue-toastification";
import DefaultTitle from "./DefaultTitle.vue";

export default {
  components: {
    DefaultTitle,
  },
  data() {
    return {
      email: "",
      remainingInterval: 30,
      isThankYouModalVisible: false,
    };
  },
  setup() {
    const toast = useToast();
    return {toast};
  },
  methods: {
    isValidEmail(email) {
      const re =
          /* eslint-disable-next-line */
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },
    subscribeToEmail() {
      if (!this.email || this.email.trim() == "") {
        this.toast.warning("Email is required");
        return;
      } else if (!this.isValidEmail(this.email)) {
        this.toast.warning("Invalid Email Provided");
        return;
      }
      this.$store.dispatch("subscribeLetter", this.email).then(
          (value) => {
            console.log(value);
            // this.toast.success("Thank you for Subscribing.");
            this.$store.dispatch("subscribeMail", this.email).then(
                (data) => {
                  console.log(data);
                  this.isThankYouModalVisible = true;
                  window.gtag('event', 'subscription/thank_you'); /* google analytic */
                  document.body.classList.add("overflow-hidden");
                }, (error) => {
                  this.toast.error("Sorry! Something went wrong");
                  console.log(error);
                }
            )
            this.email = "";
          },
          (error) => {
            this.toast.error("Sorry! Something went wrong");
            console.log(error);
          }
      );
    },

    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isModalVisible = false;
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    close() {
      this.$emit("close");
    },
    toggleKelly() {
      this.showKelly = !this.showKelly;
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 760px) {
  .object-custom-position {
    object-position: 0px -100px !important;
  }

  .thankyou-modal {
    width: 100% !important;
  }

  .fuel-icon {
    width: 40% !important;
  }
}

.thankyou-modal {
  width: 40%;
}

.fuel-icon {
  position: absolute;
  top: -70px;
  margin-left: 50px;
}

.tn-text {
  color: #0d213a;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.text-content {
  color: #666666;
  font-size: 11px;
}

.fb-icon {
  width: 55px;
  padding: 10px;
}

.follow {
  color: #4d4d4d;
  margin-top: 26px;
  font-size: 14px;
  font-weight: 600;
}

.object-custom-position {
  object-position: 0px 0px;
}
</style>