<template>
  <section id="finance" class="w-full mb-10  mt-36 sm:mt-0 md:mt-0 lg:mt-0 sm:mb-10 md:mb-20 lg:mb-20 finance-bg">
    <div class="m-size m-auto">
            <div class="grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              <div class="relative col-span-1 bottom-80 sm:bottom-0 md:bottom-0 lg:bottom-0">
                    <div class="relative bottom-0 grid w-auto gap-4 p-4 m-4 sm:bottom-0 md:bottom-0 sm:top-0 md:top-28 lg:top-28 sm:relative md:py-8 md:px-8 lg:px-8 sm:w-64 md:w-80 lg:w-96 play-video-bg">
                      <h1 class="text-2xl font-light text-white uppercase md:text-3xl lg:text-3xl tiempos fin-line">No interest finance</h1>
                      <p class="pb-3 text-base leading-6 tracking-wider text-white md:text-xs lg:text-xs font-extralight f-w-100 calibre">
                        Our payment plans are interest free and hence the best in the market. We will price match the payment plans if you can find a similar car for less total cost (down payment + payment plans). We require atleast a 650 credit score the payment plans don’t change based on your credit score so no need to haggle again
                      </p>
                      <a href="/faqs" class="w-auto text-sm font-light tracking-wider text-white uppercase shadow-md play-btn calibre"> know more</a>
                    </div>
              </div>
              <div class="relative grid col-span-2">
                  <div class="relative grid justify-center w-full p-5 m-auto md:justify-end lg:justify-end md:top-28 lg:top-28 bottom-72 sm:bottom-0 md:bottom-0 lg:bottom-0 align-items-center">
                    <h6 class="pb-5 text-xl text-center text-white uppercase sm:text-center md:text-left lg:text-left tiempos">we are happy to help you</h6>
                    <div class="flex flex-wrap  md:flex-col lg:flex-row xl:flex-row gap-y-4 sm:gap-y-3 md:gap-y-3 lg:gap-y-3 md:pr-1 lg:pr-10">
                             <div class="flex justify-center w-full sm:w-auto md:w-auto lg:w-auto">
                            <div class="outside-1">
                              <div class="inside-1">
                                <input type="text" name="phone_number" v-model.number="phone_number"   @keypress="isNumber($event)"  class="px-10 text-lg font-normal tracking-wider bg-transparent border-0 outline-none pr-14 w-80 outline-0 text-gray calibre" placeholder="Enter Phone Number">
                              </div>
                              </div>
                        </div>
                     <div class="flex justify-center w-full sm:w-auto md:w-auto lg:w-auto">
                    <a class="flex-wrap font-normal tracking-wider uppercase text-gray calibre learn-more-btn " href="#" @click.prevent="sendEnquiry">submit </a>
                  </div>
                    </div>
                  </div>
              </div>
            </div>
    </div>
  </section>


  <Modal :showModalBg="false"
         bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
         v-show="isThankYouModalVisible" @close="closeModal">

    <template v-slot:body>
      <div v-if="isThankYouModalVisible" class="flex justify-center relative w-full">
        <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 py-12
        px-6 relative">
          <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
            <img class="lg:w-36" src="/images/thankyou-modal-icon.png"/>
          </div>
          <h1 class="text-3xl font-display mt-8 mb-6 text-center lg:px-12 lg:mx-12 px-4 mx-4">
            Thank You
          </h1>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
            We will get back to you on this shortly.
          </p>
          <button @click.prevent="closeModal" class="bg-blue uppercase text-white p-3 px-6 block">
            Continue
          </button>
        </div>
      </div>
    </template>
  </Modal>

</template>

<script>
import {useToast} from "vue-toastification";
import Modal from "@/components/Modal";

export default {
  name: 'Finance',
  props: {
    msg: String
  },
  components: {
    Modal,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return{
      isThankYouModalVisible: false,
      phone_number: "",
    }
  },
  methods: {
    isNumber(evt, allowDot = true) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (allowDot) {
        keysAllowed.push(".");
      }
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    sendEnquiry() {
      if (!this.phone_number) {
        this.toast.warning("Sorry! Phone Number is required");
        return;
      }

      this.$store.dispatch("SendEnquiry", this.phone_number).then(
          (value) => {
            console.log(value);
            // this.toast.success("Thank you for Enquiry.");
            this.phone_number = "";
            this.isThankYouModalVisible = true;
          },
          (error) => {
            this.toast.error("Sorry! Something went wrong");
            console.log(error);
          }
      );
    },
    closeModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
  },
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .object-custom-position {
    object-position: 0px -100px !important;
  }
}

.object-custom-position {
  object-position: 0px 0px;
}
.finance-bg{
  background-image: url(../assets/finance-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}



.play-video-bg{
  background: #1b1d1f;
}
.play-btn {
  background-color: #008de9;
  height: 50px;
  max-width: 190px;
  text-align: center;
  line-height: 55px;
  clip-path: polygon(0% 0, 87% 0, 100% 100%, 15% 100%);
  box-shadow: 1px 1px 1px 1px  #191919;
}

.fin-line{
  line-height: 45px;
}

.play-btn:hover {
  background-color: #0163a3;
}
.fin-input{
  clip-path: polygon(0% 0, 87% 0, 100% 100%, 15% 100%);
}
.outlines{
  clip-path: polygon(0% 0, 87% 0, 100% 100%, 15% 100%);

}
.learn-more-btn{
  background-image: url(/images/fin-btn.png);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  min-height: 50px;
  display: inline-block;
  min-width: 220px;
  text-align: center;
  color: white;
  line-height: 50px;

}
.outside-1 {
  position: relative;
  width: 370px;
  height: 50px;
  background-color: #fff;
  clip-path: polygon(0% 0, 91% 0, 100% 100%, 9% 100%);
}

.inside-1 {
  line-height: 45px;
  text-align: center;
  position: absolute;
  top: 2px;
  left: 4px;
  right: 4px;
  bottom: 2px;
  background-color: rgb(149 149 149 / 46%);
  clip-path: polygon(0% 0, 91% 0, 100% 100%, 9% 100%);
}
input::placeholder{
  color: #585858;
  font-size: small;
}

@media (max-width: 640px) {
  .outside-1{
    width: 320px!important;
  }
  .finance-bg{
    background-size: cover;
    background-position: center;
    max-height: 350px;
    padding: 0;
  }
  .mt-36 {
    margin-top: 10rem;
  }
  .play-btn{
    max-width: 100%;
    clip-path: polygon(0% 0, 90% 0, 100% 100%, 10% 100%);
  }
}
@media only screen and (min-width: 1280px) {
  .m-size {width: 1170px}
}
</style>
