<template>
  <section id="why"    class="mt-0 sm:mt-10 md:mt-10 lg:mt-10 md:mb-10 lg:mb-10 ">
    <div class="ml-0 lg:ml-24 xl:ml-24">
    <div class="p-0 pr-0 my-2 ml-0 md:pr-0 lg:pr-0 ">
      <div class="grid grid-cols-1 sm:gap-4 md:gap-4 lg:gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3">
        <div class="col-span-1 p-5">
          <h1
              class="md:py-10 lg:py-10 pb-6 text-2xl tracking-wider uppercase text-start md:pb-7 md:mt-5 text-blue tiempos sm:text-3xl md:text-4xl">
            why to choose fairpy</h1>
          <p class="pb-5 text-base md:text-sm lg:text-sm 2xl:text-xl text-start text-des calibre">Consumers are often faced with
            conflicting data that prevent them from making smart purchases. The Auto industry offers expensive options
            that not only decrease consumers’ net value but also increase their debt burden.</p>
          <p class="text-base pb-7 md:text-sm lg:text-sm 2xl:text-xl text-start text-des calibre">Instead, we believe in maximizing
            the value of our customers by providing them the best deals available in the market. We have a passion for
            saving for our customers – their savings is our success.</p>
          <div class="flex justify-center md:justify-start lg:justify-start">
            <a href="/faqs"> <button
                    class="px-5 2xl:px-10 mb-5 text-sm 2xl:text-2xl font-light tracking-wider text-white uppercase shadow-md md:mb-0 lg:mb-0 md:w-auto play-btn w-52 lg:w-52 2xl:w-80 2xl:h-16 calibre">
              know more</button></a>
          </div>

        </div>
        <div class="col-span-2 ">
          <div class="relative top-0 car py-28 2xl:py-44 sm:top-10 md:top-10 lg:top-10">
          </div>
          <div class="absolute w-full top-car-deals bottom-30">
            <carousel class="my-carosel top-car-deals" :itemsToShow="1.30"
                      :breakpoints="breakpoints"
                      :transition="300"
                      :autoplay="3000"
                      :nav="true">
              <slide v-for="slide in cars"
                     :key="slide">
                <div class="relative ">
                  <div class="grid justify-center bg-white shadow hover:shadow py-14 w-60 h-60 lg:w-52 lg:h-52 2xl:w-96 2xl:h-96 min-w-250s min-w-270s">
                    <img class="h-20 2xl:h-36 pb-5 m-auto"
                         :src="slide.image"
                         alt="">
                    <h5 class="w-56 font-light text-center uppercase text-sm 2xl:text-xl text-blacks tiempos">{{slide.text}}</h5>
                  </div>
                </div>
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </div>

        </div>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
  import {
Carousel,
Navigation,
Slide
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

  export default {
    name: 'Why',
    components: {
      Carousel,
      Slide,
      Navigation,
    },
    data: () => ({
      cars: [{
          image: '/images/fairness.png',
          text: 'Fairness for all'
        },
        {
          image: '/images/Win.png',
          text: 'Striving for Win-Win'
        },
        {
          image: '/images/Finding.png',
          text: 'Finding Creative Solutions'
        },
        {
          image: '/images/Obssesed.png',
          text: 'Obssesed about Customer Success'
        },
        {
          image: '/images/Perfect.png',
          text: 'Perfect Assesment'
        },

        {
          image: '/images/Sustainable.png',
          text: 'Sustainable'
        },

      ],

      settings: {
        itemsToShow: 1,
        itemsToScroll: 3,
        snapAlign: 'start',
      },

      breakpoints: {
        700: {
          itemsToShow: 2,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 3,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    }),

  }
</script>
<style scoped>

/* ---- carousel style ----- */

.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}


.carousel__slide--next .finance-wrapper {
  min-height: 170px !important;
  max-height: 170px !important;
}
.carousel__slide--prev .finance-wrapper{
  min-height: 170px !important;
  max-height: 170px !important;
}
/* ---- end carousel style ----- */


  .tiempos {
    font-family: 'Tiempos Headline', sans-serif;
  }

  .calibre {
    font-family: 'Calibre', sans-serif;
  }

  .text-blue {
    color: #0d213a;
  }

  .txt-size {
    font-size: 15px;
  }

  .finance-wrapper {
    min-height: 238px;

  }

  .shadow {
    box-shadow: 0px 1px 3px 0px #d8d8d8;

  }

  .top-car-deals {
    position: relative;
    bottom: 20px;
  }

  .top-car-deals::after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 40%;
    height: 90%;
    background-image: url('../assets/Gradient1.png');
    background-repeat: no-repeat;
    background-position: right;
    z-index: 0;
  }

  .carousel__slide {
    padding: 15px 5px;
  }

  .carousel__prev--in-active,
  .carousel__next--in-active {
    display: none;
  }


  .min-w-250 {
    min-width: 250px;
  }

  .text-blacks {
    color: #232323;
  }

  .play-btn {
    background-color: #008de9;
    /*height: 40px;*/
    /*min-width: 190px;*/
    text-align: center;
    line-height: 40px;
    clip-path: polygon(0% 0, 87% 0, 100% 100%, 15% 100%);
    box-shadow: 1px 1px 1px 1px #191919;
  }

  .play-btn:hover {
    background-color: #0163a3;
  }

  .car {
    /*padding: 7rem 0;*/
    background-image: url(../assets/cars.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

  }


  @media (max-width: 640px) {
    .car {
      min-height: 275px !important;
      background-image: url(../assets/cars.png);
      background-position: center;
      background-size: 450px;
      object-fit: cover;
      background-repeat: no-repeat;
      position: relative;
      top: 170px;
    }

    .car {
      background-position: 51%;
    }

    .top-car-deals::after {
      display: none;
    }

    .top-car-deals {
      position: relative;
      bottom: 130px;
    }

    /*.play-btn {*/
    /*  min-width: 250px;*/
    /*}*/

    .shadow{
      box-shadow: 0 0 10px 3px #d8d8d8;
    }
    .min-w-270 {
      min-width: 270px;
    }
  }
</style>