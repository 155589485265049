import axios from 'axios';
import {createStore} from 'vuex';
import config from '../config';
import {auth} from './auth.module';
import {card} from './card.module';
import {investorAuth} from "@/views/investors/store/auth.module";

const mutations = {
    setBidValue(state, bidValue) {
        state.bidValue = bidValue;
    },
    setDeals(state, deals) {
        state.deals = deals;
    },
    setMake(state, make) {
        state.make = [...make];
    },
    setSelectedDeal(state, deal) {
        state.selectedDeal = {...deal};
    },
    setSelectedDealId(state, dealId) {
        state.selectedDealId = dealId;
    },
    setIsLoading(state, loading) {
        state.isLoading = loading;
    },
    setSearchParams(state, params) {
        state.searchParams = {...params};
    },
    setSearchedParams(state, params) {
        state.searchedParams = {...params};
    },
    setSearchQuery(state, query) {
        state.searchQuery = query;
    },
};

const actions = {
    setBidValue: ({commit}, bidValue) => {
        commit('setBidValue', bidValue);
    },
    setSelectedDeal: ({commit}, deal) => commit('setSelectedDeal', deal),
    setSelectedDealId: ({commit}, id) => commit('setSelectedDealId', id),
    setSearchParams: ({commit}, params) => commit('setSearchParams', params),
    fetchDeals: ({commit}, params) => {
        commit('setSearchParams', {...params});
        commit('setSearchedParams', params);
        params['fair_userid'] = 'hasnath@fairpy.com';
        params['finance'] = params.is_price ? 'False' : 'True';
        params['max_monthly'] = params['max_emi'];
        params['min_monthly'] = params['min_emi'];
        delete params.max_emi;
        delete params.min_emi;
        const jsonParams = encodeURI(JSON.stringify(params));
        commit('setIsLoading', true);
        axios
            .get(
                `https://uibk63par7.execute-api.us-west-2.amazonaws.com/default/sortcars?search=${jsonParams}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                }
            )
            .then((res) => {
                commit('setIsLoading', false);
                let deals = res.data;
                deals = deals.map(function (deal) {
                    let formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    });
                    return {
                        id: deal.VIN,
                        title: deal.year_make_model,
                        miles: deal.Odometer,
                        // bid: Math.round(deal.fair_bid),
                        bid: formatter.format(deal.purchase_price),
                        savings: formatter.format(deal.savings),
                        finance_savings: formatter.format(deal.finance_savings),
                        bookValue: formatter.format(deal.retail_value),
                        image: deal.thumbnail,
                        details: deal.car_details,
                        date: deal.bid_final_date,
                        currentBid: Math.round(deal.kbb),
                        type: deal.title,
                        car_details: deal.car_details,
                        pdf: deal.pdf,
                        series: deal.Series,
                        monthly_payments: formatter.format(deal.monthly_payments),
                        shipping_fee: deal.shipping_fee,
                        down_payment: formatter.format(deal.down_payment),
                        sales_tax: deal.sales_tax,
                    };
                });
                commit('setDeals', deals);
            })
            .catch((error) => {
                commit('setIsLoading', false);
                console.log(error);
            });
    },
    fetchDealsWithLastParams: ({getters, dispatch}) => {
        dispatch('fetchDeals', {...getters.searchedParams});
    },
    searchDealsByText: ({commit}, query) => {
        commit('setSearchQuery', query);
        commit('setIsLoading', true);
        axios
            .get(
                `https://htkhw5fv76.execute-api.us-west-2.amazonaws.com/default/searchtext?search_cars=${query}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                }
            )
            .then((res) => {
                commit('setIsLoading', false);
                let deals = res.data;
                deals = deals.map(function (deal) {
                    let formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    });
                    return {
                        id: deal.VIN,
                        title: deal.year_make_model,
                        miles: deal.Odometer,
                        // bid: Math.round(deal.fair_bid),
                        bid: formatter.format(deal.purchase_price),
                        savings: formatter.format(deal.savings),
                        finance_savings: formatter.format(deal.finance_savings),
                        bookValue:formatter.format(deal.retail_value),
                        image: deal.thumbnail,
                        details: deal.car_details,
                        date: deal.bid_final_date,
                        currentBid: Math.round(deal.kbb),
                        type: deal.title,
                        car_details: deal.car_details,
                        pdf: deal.pdf,
                        series: deal.Series,
                        monthly_payments: formatter.format(deal.monthly_payments),
                        shipping_fee: deal.shipping_fee,
                        down_payment: formatter.format(deal.down_payment),
                        sales_tax: deal.sales_tax,
                    };
                });
                commit('setDeals', deals);
            })
            .catch((error) => {
                commit('setIsLoading', false);
                console.log(error);
            });
    },

    searchDealsByLifestyle: ({commit}, query) => {
        commit('setIsLoading', true);
        axios
            .get(
                `https://66p1op490e.execute-api.us-west-2.amazonaws.com/default/lifestyle-cars?lifestyle_cars=${query}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                }
            )
            .then((res) => {
                commit('setIsLoading', false);
                let deals = res.data;
                deals = deals.map(function (deal) {
                    return {
                        id: deal.VIN,
                        title: deal.year_make_model,
                        miles: deal.Odometer,
                        // bid: Math.round(deal.fair_bid),
                        bid: Math.round(deal.purchase_price),
                        savings: Math.round(deal.savings),
                        finance_savings: Math.round(deal.finance_savings),
                        bookValue: Math.round(deal.retail_value),
                        image: deal.thumbnail,
                        details: deal.car_details,
                        date: deal.bid_final_date,
                        currentBid: Math.round(deal.kbb),
                        type: deal.title,
                        pdf: deal.pdf,
                        series: deal.Series,
                        monthly_payments: deal.monthly_payments,
                        shipping_fee: deal.shipping_fee,
                        down_payment: deal.down_payment,
                        sales_tax: deal.sales_tax,
                    };
                });
                commit('setDeals', deals);
            })
            .catch((error) => {
                commit('setIsLoading', false);
                console.log(error);
            });
    },

    getMakeModel: ({commit}) => {
        commit('setIsLoading', true);
        axios
            .get(
                `https://kep8duqcx2.execute-api.us-west-2.amazonaws.com/default/makemodelload`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                }
            )
            .then((res) => {
                commit('setIsLoading', false);
                let makes = res.data;
                let makeObjs = [];
                for (const make in makes) {
                    let makeObj = {};
                    makeObj['name'] = make;
                    makeObj['models'] = [];
                    for (const model in makes[make]) {
                        let modelObj = {};
                        modelObj['name'] = model;
                        modelObj['series'] = [];

                        for (const series of makes[make][model]) {
                            modelObj['series'].push({name: series});
                        }

                        makeObj['models'].push(modelObj);
                    }
                    makeObjs.push(makeObj);
                }
                commit('setMake', makeObjs);
            })
            .catch((error) => {
                commit('setIsLoading', false);
                console.log(error);
            });
    },

    fetchSelectedDeal: ({commit}, id) => {
        const vin = id || store.state.selectedDealId;
        if (!vin) {
            return;
        }
        commit('setIsLoading', true);
        axios
            .get(
                `https://wewziy66mb.execute-api.us-west-2.amazonaws.com/default/details-cars?details_vin=${vin}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                }
            )
            .then((res) => {
                commit('setIsLoading', false);
                const deal = res.data;
                const currentDeal = {
                    id: deal.VIN,
                    title: deal.year_make_model,
                    series: deal.Series,
                    miles: deal.Odometer,
                    // bid: Math.round(deal.fair_bid),
                    bid: Math.round(deal.purchase_price),
                    savings: Math.round(deal.savings),
                    finance_savings: Math.round(deal.finance_savings),
                    bookValue: Math.round(deal.retail_value),
                    image: deal.thumbnail,
                    details: deal.car_details,
                    date: deal.bid_final_date,
                    currentBid: Math.round(deal.kbb),
                    type: deal.title,
                };
                for (const x in deal) {
                    if (!currentDeal[x]) {
                        currentDeal[x] = deal[x];
                    }
                }
                commit('setSelectedDeal', currentDeal);
                commit('setSelectedDealId', currentDeal.id);
            })
            .catch((error) => {
                commit('setIsLoading', false);
                console.log(error);
            });
    },

    subscribeLetter: ({commit}, email) => {
        const params = {
            subscribe_email: email,
            fair_userid: 'hasnath@fairpy.com',
        };
        const jsonParams = encodeURI(JSON.stringify(params));
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://kzr8s6eg4m.execute-api.us-west-2.amazonaws.com/default/subscribe-cars?search=${jsonParams}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    subscribeMail: ({commit}, email) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(
                    config.hostname + `/api/subscribe-mail?email=${email}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    generalEnquiry: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        // console.log(auth.state);
        const phone = params['phone'];
        const vin = params['vin'];
        const model = params['model'];
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/general/enquiry', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
            const message = new URLSearchParams({
                number: +15025921994,
                message: 'Hi FairPy Team, We have an General enquiry form our website. ' +
                    ' Here is the car details: Model- ' + model + ' & VIN #- ' + vin +
                    '\n' +
                    'This is the customer contact number -' + phone + ' . Please reach customer. ' +
                    '\n' +
                    ' Thanks!.',
            });

            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${message.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });

        });

    },

    financeEnquiry: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        // console.log(auth.state);
        const phone = params['phone'];
        const vin = params['vin'];
        const model = params['model'];
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/finance/enquiry', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
            const message = new URLSearchParams({
                number: +15025921994,
                message: 'Hi FairPy Team, We have an finance enquiry form our website. ' +
                    ' Here is the car details: Model- ' + model + ' & VIN #- ' + vin + '. ' +
                    '\n' +
                    'This is the customer contact number -' + phone + ' . Please reach customer.' +
                    '\n' +
                    ' Thanks!.',
            });

            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${message.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });

        });

    },

    promoCodeValidate: ({commit}, promocode) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/promo-code-validate?code=${promocode}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    saveSearch: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        const jsonParams = {
            filters: params,
            user_id: auth.state.user.id,
        };
        params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/save-customer-search', jsonParams, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    searchHistoryCount: ({commit}, user_id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + '/api/search-history-count/' + user_id, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    getSearchHistory: ({commit}, user_id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/search-history/` + user_id, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    removeSearchList: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/remove-search-history?id=${id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    sendOtpInvestors: ({commit}, params) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/investors/otp', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },


    sendOtp: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/bid/otp', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    resendOtp: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/bid/otp/resend', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    verifyOtp: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/bid/otp/verify', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    setIsLoading: ({commit}, value) => {
        commit('setIsLoading', value);
    },

    setFavourite: ({commit}, deal) => {
        const params = new URLSearchParams({
            fair_userid: 'hasnath@fairpy.com',
            wishlist_vin: deal.vin,
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://2bk8d8f2yg.execute-api.us-west-2.amazonaws.com/default/wishlist-cars?${params.toString()}`,
                    {
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    setWishlistCars: ({commit}, params) => {
        params['user_id'] =  auth.state.user.id
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/wishlist-cars/store', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    loadWishlist: ({commit}, user_id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/load-wishlist-cars?user_id=${user_id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    wishlistCount: ({commit}, user_id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/wishlist-cars-count?user_id=${user_id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },


    removeWishlist: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/remove-wishlist-cars?id=${id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    SendEnquiry: ({commit}, phone) => {

        const params = new URLSearchParams({
            number: +15025921994,
            message: 'Hi FairPy Team, We have an finance enquiry form our website. This is the customer contact number -' + phone + ' . Please reach customer.  Thanks!.',
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${params.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    paymentStatus: ({commit}, res) => {
        const params = new URLSearchParams({
            number: +15025921994,
            message: 'Hi FairPy Team,\n' +
                '\n' +
                'We have a ' + res['customer_name'] + ', successfully done a CC hold transaction of $' + res['fair_bid'] + ' on ' + res['date'] + '. Here is the car details: Model- ' + res['model_name'] + ' & VIN #- ' + res['vin'] +
                '\n' +
                'Thanks!',
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${params.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    successPaymentMail: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        // console.log(auth.state);
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/success-payment-mail', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    investorsPaymentStatus: ({commit}, res) => {
        const params = new URLSearchParams({
            number: +15025921994,
            // number: +919750212695,
            message: 'Hi' + res['name'] + ', Thanks for the payment of $' + res['amount'] + '. Fairpy welcome you to the investors plan program.  Please email us if you have any questions or concerns regarding your credit card hold @ admin@fairpy.com or call us @ 817-357-8462. Thanks!',
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${params.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });

          // Investors Message
            const paramsInvestors = new URLSearchParams({
                // number: +15025921994,
                number: res['mobile_number'],
                message: 'Thank You,\n' +
                    '\n' +
                    'We have a ' + res['name'] + ', successfully payment. transaction of $' + res['amount'] + ' Phone : ' + res['mobile_number'] +
                    '\n' +
                    'Thanks!',
            });
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${paramsInvestors.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },



    updateProfile: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/update-profile', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    loadProfile: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + '/api/load-profile/' + params['user_id'], {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    loadInvestorsMaster: ({commit}, params) => {
        params['id'] = 1;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + '/api/investors-master/' + params['id'], {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    investorsMaster: ({commit}, params) => {
        if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/investors-master', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    investorsEnquiry: ({commit}, params) => {
        // if (auth.state.user) params['user_id'] = auth.state.user.id;
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/investors-enquiry', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        // Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
// console.log('message uncomment')
//             const message = new URLSearchParams({
//                 number: +15025921994,
//                 message: 'Hi FairPy Team, We have an Investors pitch for an amount of $' +  + 'in form our website. Investor contact email -' +  + ' . Please reach investor.  Thanks!.',
//             });
//
//             axios
//                 .get(
//                     `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${message.toString()}`,
//                     {
//                         headers: {
//                             Accept: 'application/json',
//                             'Content-Type': 'application/json',
//                         },
//                         crossDomain: true,
//                     }
//                 )
//                 .then((res) => {
//                     commit('setIsLoading', false);
//                     resolve(res);
//                 })
//                 .catch((error) => {
//                     commit('setIsLoading', false);
//                     reject(error);
//                 });

        });
    },

    investorPasswordStatus: ({commit}, investorId) => {
        console.log('password status');
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/password-status?investor_id=${investorId}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    getInvestorEnquiry: ({commit}, investorId) => {
        console.log('test');
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/get-enquiry?investor_id=${investorId}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    getInvestor: ({commit}, id) => {
        console.log('test');
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/get-investor?id=${id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    investorsFaqCategories: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/faq-categories`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    investorsFaqs: ({commit}, categoryId) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/faq-list/` + categoryId, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    storeAgreement: ({commit}, investorId) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/investors/agreement-status?investor_id=${investorId}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },

    storeBuyers: ({commit}, params) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .post(config.hostname + '/api/store-buyers', params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.state.user.accessToken}`,
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    buyersMessage: ({commit}, customerInfo) => {
        const params = new URLSearchParams({
            number: +15025921994,
            message: 'Hi FairPy team, we have a customer ' + customerInfo.name + ' contact # ' + customerInfo.phone + ' applied promo code ' + customerInfo.promo_code.toUpperCase() + ' for the Car ' + customerInfo.model + ', VIN # ' + customerInfo.vin +
                '\n' +
                'Please reach customer. Thanks!',
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${params.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    teamMessage: ({commit}, customerInfo) => {
        const params = new URLSearchParams({
            number: +16125082154,
            message: 'Hi FairPy team (John), we have a customer ' + customerInfo.name + ' contact # ' + customerInfo.phone + ' applied promo code ' + customerInfo.promo_code.toUpperCase() + ' for the Car ' + customerInfo.model +
                '\n' +
                'Please reach customer. Thanks!',
        });
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(
                    `https://q1igcsxf82.execute-api.us-west-2.amazonaws.com/default/sendSmsTwillio?${params.toString()}`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        crossDomain: true,
                    }
                )
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });
    },

    getPromoCode: ({commit}, promocode) => {
        return new Promise((resolve, reject) => {
            commit('setIsLoading', true);
            axios
                .get(config.hostname + `/api/get-promo-code?customer_id=${promocode.customer_id}&vin=${promocode.vin}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                })
                .then((res) => {
                    commit('setIsLoading', false);
                    resolve(res);
                })
                .catch((error) => {
                    commit('setIsLoading', false);
                    reject(error);
                });
        });

    },



};

const getters = {
    bidValue: (state) => {
        return state.bidValue;
    },
    deals: (state) => {
        return state.deals;
    },
    selectedDeal: (state) => {
        return state.selectedDeal;
    },
    selectedDealId: (state) => {
        return state.selectedDealId;
    },
    makes: (state) => {
        return state.make;
    },
    filters: (state) => {
        return state.filters;
    },
    investors: (state) => {
        return state.investors;
    },
    isLoading: (state) => {
        return state.isLoading;
    },
    authToken: (state) => {
        return state.authToken;
    },
    searchParams: (state) => {
        return state.searchParams;
    },
    searchedParams: (state) => {
        return state.searchedParams;
    },
    defaultParams: (state) => {
        return state.defaultParams;
    },
    searchQuery: (state) => {
        return state.searchQuery;
    },
};

const store = createStore({
    state() {
        return {

            isLoading: false,
            selectedDeal: null,
            selectedDealId: null,
            bidValue: 0,
            deals: [],
            authToken:
                'eyJraWQiOiJMbEJEbklBS2JEUVlpNzFHWlhNMU0zTFlMU0Izemo0aW9OQXNneXlcL0RGdz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxNDlkNGU1Ni1iMDlkLTQ0ZjYtYjNjNy0xNDlkNzA3YTFjMDYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfeTJXeDRFRzNwIiwiY29nbml0bzp1c2VybmFtZSI6Imxpbmdlc2gtYXQtZmFpcnB5LmNvbSIsIm9yaWdpbl9qdGkiOiJkYjQyODNhYS02MjBmLTQ1Y2UtYmViZS00YTlkNTE1NDU4MzAiLCJhdWQiOiIzcW8zc2c1dmM1YzllZWlraHUxYzlqNmRraSIsImV2ZW50X2lkIjoiNDBhYWU0NmEtZjA0Ny00MWJkLTg5ZDUtOTEyODA…X3RpbWUiOjE2NDMwMjk5NTEsImV4cCI6MTY0NDM0NTkyNCwiaWF0IjoxNjQ0MzQyMzI0LCJqdGkiOiI4YTFmMDY3My03NzU4LTQ1MDctODNjYi0zMjc3MDhlYzllYzQiLCJlbWFpbCI6Ikxpbmdlc2hAZmFpcnB5LmNvbSJ9.DD6kVgWc-u6US-JGaFeQy12qULwUuaBZLrrnxalQpv0G2wE-nGKDuKhG9CEG2-PKwd7hS4vt1U7jFpLQDwwoLPT6ITf1NDWjFYHfvfV9A5r52AD3Cj0NcBBqOyavCB00f7pPQ-XsxBCYByd_Sm9jtjdOc9W-Obwj3IRo2IRasRukl35XyTr5D2Z-qAC_h6Exh85xH8WUhRKW3UxaHOo8ydJ_ArAqDsvD6iuaEfiFGTFj7vvJnhmQq9rGrOU5CHvhtMQNL4WtZQW_-upAInL3yYbvNAifeyi8DAzsPhal-vIKQCLqMWi0MAaoCikHY4nb7nQPlIGoVL3jhEtGWeunIQ',
            make: [],
            investors: {
                name: '',
                email: '',
                mobile_number: '',
                amount: '',
                portfolio: '',
            },
            filters: {
                odometers: [
                    {label: 'Below 2500 miles', value: '0-2500'},
                    {label: '2500 to 10000 miles', value: '2500-10000'},
                    {label: '10000 to 25000 miles', value: '10000-25000'},
                    {label: '25000 to 50000 miles', value: '25000-50000'},
                    {label: '50000 to 75000 miles', value: '50000-75000'},
                    {label: '0 to 100000 miles', value: '0-100000', default: 'true'},
                ],
                years: [
                    {label: '2008 & Above', value: '2008-2022', default: 'true'},
                    {label: 'Below 2010', value: '2008-2010'},
                    {label: '2010 & Above', value: '2010-2022'},
                    {label: '2014 & Above', value: '2014-2022'},
                    {label: '2016 & Above', value: '2016-2022'},
                    {label: '2018 & Above', value: '2018-2022'},
                    {label: '2020 & Above', value: '2020-2022'},
                ],
                title: [
                    {label: 'All', value: 'All', default: 'true'},
                    {label: 'Salvage', value: 'Salvage'},
                    {label: 'Clean', value: 'Clean'},
                ],
                bodyStyle: [
                    {label: 'All', value: 'All', default: 'true'},
                    {label: 'Sedan', value: 'Sedan'},
                    {label: 'Hatchback', value: 'Hatchback'},
                    {label: 'Van', value: 'Van'},
                    {label: 'Coupe', value: 'Coupe'},
                ],
                transmission: [
                    {label: 'All', value: 'All', default: 'true'},
                    {label: 'Auto', value: 'Auto'},
                    {label: 'Manual', value: 'Manual'},
                ],
                fuelType: [
                    {label: 'All', value: 'All', default: 'true'},
                    {label: 'Gas', value: 'Gas'},
                    {label: 'Electric', value: 'Electric'},
                    {label: 'Diesel', value: 'Diesel'},
                    {label: 'Hybrid', value: 'Hybrid'},
                ],
                range: [
                    {label: '0 - 50 miles', value: '0-50'},
                    {label: '50 to 100 miles', value: '50-100'},
                    {label: '100 to 250 miles', value: '100-250'},
                    {label: '250+ miles', value: '250-2500', default: 'true'},
                ],
                color: [
                    {label: 'Black', value: 'Black'},
                    {label: 'White', value: 'White'},
                    {label: 'Grey', value: 'Grey'},
                    {label: 'Silver', value: 'Silver'},
                    {label: 'All', value: 'All', default: 'true'},
                ],
                sortCars: [
                    {label: 'Price', value: 'Price'},
                    {label: 'Savings', value: 'Savings'},
                    {label: 'Odometer', value: 'Odometer'},
                    {label: 'Recommended', value: 'Recommended', default: 'true'},
                ],
                categories: [
                    {label: "SUVs", image: "suv.png", value: "SUVs"},
                    {label: "Sedans", image: "sedan.png", value: "Sedans"},
                    {label: "Trucks", image: "trucks.png", value: "Trucks"},
                    {label: "Minivans", image: "mini-vans.png", value: "Minivans"},
                    {label: "FULL-SUV", image: "full-suv.png", value: "FULL-SUV"},
                    {label: "Coupes", image: "coupes.png", value: "Coupes"}
                ]
            },
            searchParams: {
                make: '',
                model: '',
                series: '',
                sort_cars: 'Recommended',
                from_year: '2008',
                to_year: '2022',
                min_budget: '1000',
                max_budget: '200000',
                min_emi: '0',
                max_emi: '2000',
                title: 'All',
                odometer_min: '1000',
                odometer_max: '100000',
                bodystyle: 'All',
                transmission: 'All',
                fuel_type: 'All',
                zipcode: '',
                distance_range: '0-50',
                color: 'All',
                fair_userid: 'hasnath@fairpy.com',
                is_price: true,
                car_category: '',
            },
            searchedParams: {
                make: '',
                model: '',
                series: '',
                sort_cars: 'Recommended',
                from_year: '2008',
                to_year: '2022',
                min_budget: '1000',
                max_budget: '200000',
                min_emi: '0',
                max_emi: '2000',
                title: 'All',
                odometer_min: '1000',
                odometer_max: '100000',
                bodystyle: 'All',
                transmission: 'All',
                fuel_type: 'All',
                zipcode: '',
                distance_range: '0-50',
                color: 'All',
                fair_userid: 'hasnath@fairpy.com',
                is_price: true,
                car_category: '',
            },
            defaultParams: {
                make: '',
                model: '',
                series: '',
                sort_cars: 'Recommended',
                from_year: '2008',
                to_year: '2022',
                min_budget: '1000',
                max_budget: '200000',
                odometer_min: '1000',
                odometer_max: '100000',
                min_emi: '0',
                max_emi: '2000',
                title: 'All',
                bodystyle: 'All',
                transmission: 'All',
                fuel_type: 'All',
                zipcode: '',
                distance_range: '0-50',
                color: 'All',
                fair_userid: 'hasnath@fairpy.com',
                is_price: true,
                car_category: '',
            },
            searchQuery: '',
        };
    },
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: {
        auth,
        card,
        investorAuth
    },
});

export default store;
