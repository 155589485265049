<template lang="">
  <transition name="bounce">
    <div
         class="modal-backdrop fixed top-0 left-0 right-0 bottom-0 bg-black-full bg-opacity-40 flex justify-center items-center z-100 overflow-y-scroll">
      <div :class="bodyClass"
           class="modal flex flex-col items-center relative z-auto max-w-2mxl">
        <button type="button"
                class="absolute top-4 right-4 border-0 text-white text-2xl p-0 bg-transparent z-10"
                @click="close">
          <a class="close"
             href="#">×</a>
        </button>
        <div class="relative bg-blue">
          <div class="relative">
            <img src="/images/lucky-draw-desktop-banner.png"
                 class="absolute left-0 right-0 top-0 bottom-0">

            <div class="relative w-full px-8 py-4">
              <h2 class="text-2.5xl text-white font-black text-center text-stroke-blue mt-5">Thank You</h2>

              <p class="font-display text-2.5xl text-white text-center mt-48 leading-none font-extrabold">
                Thank you for registering with us.<br/> We will send you the free raffle ticket shortly
              </p>
              <p class="font-display mt-4 text-center text-white font-semibold">
                This raffle is sponsored by FairPY <br/>"The smartest way to buy a pre-owned vehicle"
              </p>

              <div class="w-full text-center">
              <button @click.prevent="close"
                      class="bg-white uppercase text-blue p-3 inline-block mx-auto my-8 px-16">
                Okay
              </button>
              </div>
            </div>

          </div>
          <div class="flex px-8 py-4 w-full items-center justify-center bg-white bg-opacity-80">
            <div class="">
              <Logo height="2rem"
                    class="" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </transition>



</template>
<script>
  import Logo from './Logo.vue';

  export default {
    props: {
      bodyClass: String,
      showModalBg: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Logo
    },
    methods: {
      close() {
        this.$emit("close");
      },
      submitLuckyDraw() {

      }
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.25s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.25s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>