const initialState = {
  cardNumber: null,
  cvv: null,
  expiry: null,
  nameOnCard: null,
  stripe: null,
  stripeError: null,
  paymentIntent: null,
};
export const card = {
  namespaced: true,
  state: initialState,
  actions: {
    setCard({ commit }, cardNumber) {
      commit('setCard', cardNumber);
    },
    setStripe({ commit }, stripe) {
      commit('setStripe', stripe);
    },
    setStripeError({ commit }, error) {
      commit('setStripeError', error);
    },
    setPaymentIntent({ commit }, intent) {
      commit('setPaymentIntent', intent);
    },
  },
  mutations: {
    setCard(state, cardNumber) {
      state.cardNumber = cardNumber;
    },
    setStripe(state, stripe) {
      state.stripe = stripe;
    },
    setStripeError(state, error) {
      state.stripeError = error;
    },
    setPaymentIntent(state, intent) {
      state.paymentIntent = intent;
    },
  },
};
