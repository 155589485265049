<template>
  <section id="categories" class="mb-0 sm:mb-10 md:mb-10 mt-2 md:mt-10 lg:mb-10 ">
<!--    <h1 class="pt-5 text-2xl tracking-wider text-center uppercase md:py-2 lg:py-2 text-blue tiempos sm:text-3xl md:text-4xl">-->
<!--      find car by category</h1>-->
    <div class="p-2 m-auto  sm:my-4 md:my-6 lg:my-6 justify-center">

      <div class="grid grid-cols-3 gap-2 md:gap-3 lg:gap-3 md:grid-cols-3 lg:grid-cols-6 ">

          <div  v-for="(category, index) in filters['categories']" :key="index"
                @click="gotoCategory(category.value)" class="grid grid-cols-1 justify-items-center gap-2 p-2 cursor-pointer  h-16 md:h-20 lg:h-20 md:gap-0 lg:gap-0 md:p-2 lg:p-2">
            <img :src="require(`@/assets/${category.image}`)" class="img-widtha w-20 2xl:w-30" alt="">
            <h5 class="m-auto text-14 sm-text-10 tracking-wider uppercase  text-blue tiempos mt-0 2xl:mt-1">
              {{ category.label }}</h5>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Find',
  props: {
    msg: String
  },
  computed: {
    ...mapGetters({
      defaultParams: "defaultParams",
      filters: "filters",
    }),
  },
  methods: {
    gotoCategory(value) {
      this.params['car_category'] = value;
      this.loadDeals(this.params);
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
  },
  mounted() {
    this.params = { ...this.defaultParams };
    // this.setParams(this.params);
  },
  watch: {
    defaultParams(value) {
      this.params = { ...value };
      this.setParams(this.params);
    },
  },

}
</script>

<style scoped>
@media (max-width: 640px) {
  .img-width{
    width: 70px !important;
    height: 30px !important;
  }
  .sm-text-10{
    font-size: 10px !important;
  }
}
.text-14{
  font-size: 14px;
}
.img-width{
  width: 95px;
  height: 50px;
}
.tiempos {
  font-family: 'Tiempos Headline', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif;
}

.text-blue {
  color: #0d213a;
}

.shadow {
  box-shadow: 0 0 5px 1px #d5d5d5;
}

.min-w-300 {
  width: 100%;
  min-height: 90px;
}

.border {
  border: 1px solid rgb(46, 46, 46);
}
</style>
