<template>
    <div :class="classes">
        <input autocomplete="off" v-model="query" placeholder="Search cars" class="border p-3 w-full flex-grow text-black-light" type="text" name="search" id="search-box"/>
        <button @click.prevent="gotoSearch" class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
            <i class="icon small search"></i>
        </button>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    classes: String,
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapGetters({
      searchQuery: "searchQuery",
    }),
  },
  methods: {
    gotoSearch() {
      this.loadDeals();
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals() {
      this.$store.dispatch("searchDealsByText", this.query);
    },
  },
  mounted() {
    this.query = this.searchQuery;
  },
};
</script>
<style scoped>
</style>