import axios from 'axios';
import config from '../config';
const API_URL = config.hostname + '/api/auth/';

class AuthService {
  login(user) {
    return axios.post(API_URL + 'login', user).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  forgotPassword(email) {
    return axios
      .post(API_URL + 'forgot-password', { email: email })
      .then((response) => {
        return response.data;
      });
  }
  resetPassword(data) {
    return axios.post(API_URL + 'reset-password', data).then((response) => {
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return axios.post(API_URL + 'register', user);
  }
}
export default new AuthService();
