<template>
    <div class="text-center w-full font-display relative lg:mb-16">
      <h1 class="lg:text-4xl text-sm font-extrabold uppercase relative z-10 r-top">{{ title }}</h1>
      <div class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-0">
        <span class="transform lg:scale-300 lg:text-4xl text-5xl font-extrabold opacity-5 -mt-2">{{ caption }}</span>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    title: String,
    caption: String,
  },
};
</script>
<style scoped>
img {
  height: 3rem;
}

</style>