<template>
  <div class="w-100 shadow">
    <div
      class="container mx-auto flex flex-col lg:flex-row justify-between text-white text-xs px-5 lg:px-3 py-7">
      <div class="flex flex-col lg:flex-row lg:items-center items-start flex-grow">
        <div class="flex flex-row justify-start w-full lg:w-auto items-center flex-shrink-0">
          <Logo />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "./Logo.vue";
export default {
  name: "NavBar",
  components: {
    Logo,
  },
  data() {
    return {};
  },
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
<style lang=""></style>
