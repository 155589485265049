<template>
  <div>
    <div class="container mx-auto mt-10 lg:px-3 px-5 watermark">

      <div class="flex flex-col  lg:flex-row  lg:justify-between gap-3">
        <div class="flex flex-col">
          <h1 class="text-3xl font-semibold uppercase">{{this.details.name}}</h1>
          <div class="flex flex-col">
            <a href="mailto:{{this.details.email}}" class="text-xs text-gray-900 mt-3">{{this.details.email}}</a>
            <a href="tel:{{this.details.mobile_number}}" class="text-xs text-gray-900 mt-2">{{this.details.mobile_number}}</a>
          </div>
        </div>

        <div class="flex items-center">
          <div class="text-lg font-semibold">
            {{this.details.portfolio}}
          </div>
        </div>

        <div class="flex flex-col">

          <div class="text-lg">Pledging Amount</div>
          <div class="text-4xl font-semibold">
            ${{this.details.amount}}
          </div>
        </div>

      </div>


      <div class="w-full mb-10">
<!--        <p class="text-black-lighter text-xs my-8 leading-5">-->
<!--          To reserve the car, we put a hold on your credit card for 10% of the purchase price or down payment.  We will let you know the status of your purchase within the next 24 to 48 hours. If we are able to procure the car from our wholesale partner we ask you to wire the down payment and thereafter we release your credit card hold and start the shipping process. If we are unsuccessful in procuring the car from our wholesale partners we release the credit card hold immediately.-->
<!--        </p>-->
        <p class="text-black-lighter text-xs my-8 leading-5">
          Please email us if you have any questions or concerns regarding your credit card hold @ <a class="underline text-blue" href="mailto:admin@fairpy.com">admin@fairpy.com </a> or call us @ <a class="underline text-blue" href="tel:817-357-8462">817-357-8462</a>
        </p>
        <hr />
      </div>

      <div class="w-full flex flex-row mt-6 mb-16">
        <div class="w-7/12 items-stretch flex pr-8">
          <div class="border flex-grow p-6">
            <CreditCardForm />
          </div>
        </div>
        <div class="w-5/12 items-stretch flex pl-8">
          <div class="border flex-grow p-6 flex flex-col">
            <h3 class="text-xl font-semibold mb-14">Summary</h3>
            <div class="flex flex-col w-full text-black-lighter flex-grow">
              <div class="flex justify-between items-center text-sm mb-8">
                <p>
                  Pledging Amount
                </p>
                <p class="font-semibold text-black-light">
                {{this.details.amount}}
                </p>
              </div>
              <div class="flex justify-between items-center text-sm mb-8">
                <p>Other Charges</p>
                <p class="font-semibold text-black-light">$0.00</p>
              </div>
              <hr />
              <div class="flex justify-between items-center text-sm mb-8 mt-8">
                <p class="text-black-light">Total Amount</p>
                <p class="font-semibold text-black-light text-2xl">
                  {{this.details.amount}}
                </p>
              </div>
              <div class="flex flex-col items-center mb-6 flex-grow justify-end">
                <button  @click.prevent="pay"
                    class="bg-blue uppercase text-white p-4 w-full lg:block hidden"
                >
                  PAY NOW
                </button>
                <a
                    class="mt-6 cursor-pointer text-sm text-black-lighter"
                    href=""
                >
                  Go Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
        :showModalBg="true"
        bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
        v-show="isThankYouModalVisible"
        @close="closeModalAndGotoHome"
    >
      <template v-slot:header>
        <div></div>
      </template>

      <template v-slot:body>
        <div class="flex justify-center relative w-full">
          <div
              class="
            w-full
            flex flex-col
            justify-center
            items-center
            text-center
            lg:px-12
            py-12
            px-6
            relative
          "
          >
            <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
              <img class="lg:w-36" src="/images/thankyou-modal-icon.png" />
            </div>
            <h1
                class="
              text-3xl
              font-display
              mt-8
              mb-6
              text-center
              lg:px-12 lg:mx-12
              px-4
              mx-4
            "
            >
              Thank You
            </h1>
            <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
              Your Bid has been Placed Successfully.
            </p>
            <button
                @click.prevent="closeModalAndGotoHome"
                class="bg-blue uppercase text-white p-3 px-6 block"
            >
              Continue
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CreditCardForm from "@/components/CreditCardForm";
import Modal from "@/components/Modal";
export default {
  name: "InvestorsPaymentDetails",
  components: {
    CreditCardForm,
    Modal
  },
  data () {
    return {
      token: null,
      stripe: null,
      details: '',
      isThankYouModalVisible: false,
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    // console.log(this.email);
    // console.log('test successfull');
    this.details = this.$store.getters.investors;
  },
  created () {
    console.log('state', this.$store);
    this.$store.getters.Data;
  },
  methods: {
    myEventHandler() {
      this.toggle = screen.width > 768;
    },
    async pay() {
      console.log(this.$store.state.card.cardNumber);
      console.log(this.$store.state.card.paymentIntent);

      this.$store.dispatch("setIsLoading", true);

      let name = this.$store.state.auth.user.first_name;
      name += " ";
      name += this.$store.state.auth.user.last_name;

      this.$store.state.card.stripe
          .confirmCardPayment(
              this.$store.state.card.paymentIntent.client_secret,
              {
                payment_method: {
                  card: this.$store.state.card.cardNumber,
                  billing_details: {
                    name: name,
                  },
                },
              }
          )
          .then((result) => {
            this.$store.dispatch("setIsLoading", false);
            if (result.error) {
              // Show error to your customer (for example, insufficient funds)
              console.log(result, result.error);
              this.toast.error(result.error.message);
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === "succeeded" ||
                  (result.paymentIntent.capture_method === 'manual' && result.paymentIntent.status === 'requires_capture')) {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                this.showThankYouModal();

                const params = {};
                params["name"] = this.details['name'];
                params["phone"] = this.details['phone'];
                params["email"] = this.details['email'];
                params["portfolio"] = this.details['portfolio'];
                params["amount"] = this.details['amount'];

                this.$store.dispatch("investorsPaymentStatus", params).then(
                    (value) => {
                      console.log(value);
                      this.isThankYouModalVisible = true;
                    },
                    (error) => {
                      this.toast.error("Sorry! Something went wrong");
                      console.log(error);
                    }
                );


              }
            }
          });
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        document.getElementById("card-error").innerHTML = error.message;
        return;
      }
      console.log(token);
    },

    showThankYouModal() {
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    closeModal() {
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    closeModalAndGotoHome() {
      this.closeModal();
      this.$router.push({
        name: "Home",
      });
    },
  }
}
</script>

<style scoped>

</style>