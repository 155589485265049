<template>
  <TopBar/>
  <div class="container m-auto text-justify mt-12 px-4 lg:px-0 watermark">
    <div class="flex justify-center">
      <h1 class="text-xl lg:text-3xl font-semibold">FairReturns Series Jan23, a Series of FairReturns, LLC</h1>
    </div>
    <div class="flex justify-around mb-4">
      <div>Subscription Agreement to subscribe for FairReturns Series Jan23, a Series of FairReturns, LLC</div>
    </div>

    <div class="mt-12">
      <div class="flex justify-center">
        <div class="w-full lg:w-8/12 text-sm lg:text-base">
          <div class="flex flex-row">
            <div class="w-6/12 font-semibold">Legal name of Purchaser</div>

            <input type="text" class="w-6/12  lg:w-5/12 h-12 ml-8 border-b-2 border-black-light focus:outline-none" disabled v-model="this.getEnquiry.name">

          </div>

          <div class="flex flex-row mt-10">
            <div class="w-6/12 font-semibold">Number of FairReturns Series Jan23, a
              Series of FAIRRETURNS, LLC Interests subscribed for
            </div>
            <input type="text" v-model="this.numberOfShare" disabled
                   class="w-6/12 lg:w-5/12 h-12 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>

          <div class="flex flex-row mt-6">
            <div class="w-6/12 font-semibold ">Aggregate Price of FairReturns Series
              Jan23, a Series of FAIRRETURNS, LLC $15,000 Interests subscribed for
            </div>
            <input type="text" v-model="this.amount" disabled
                   class="w-6/12  lg:w-5/12 h-12 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>
      </div>


      <div class="flex flex-col text-center mt-12">
        <div class="font-semibold">SUBSCRIPTION AGREEMENT</div>
        <div class="font-bold text-2xl">FAIRRETURNS SERIES JAN23, A SERIES OF FAIRRETURNS, LLC</div>
      </div>

      <div class="flex flex-col mt-16">
        <div>FairPY, Inc</div>
        <div>FAIRRETURNS, LLC</div>
        <div>3414 Swan Ln</div>
        <div>Irving TX 75062</div>
      </div>

      <div class="text-lg mt-8 mb-4">Ladies and Gentlemen:</div>

      <div class="font-semibold text-xl mb-3">1. Subscription</div>

      <div class="mt-2"><b>1.1.</b> The undersigned (the “Purchaser”), intending to be legally bound, hereby irrevocably
        agrees to purchase from FairReturns Series Jan23, a Series of FAIRRETURNS, LLC , a Delaware series limited
        liability company (the “Company”), the number of Interests in FairReturns Series Jan23, a Series of FairReturns,
        LLC (the “Series Jan23 Interests”) set forth on the front of this Subscription Agreement at a purchase price of
        $1000 per Series Jan23 Interest for the aggregate purchase price set forth on the front page hereto (the
        “Subscription Price”), and on the terms and conditions of the Limited Liability Company Agreement governing the
        Company, dated Nov 15, 2022, as amended from time to time (the “Operating Agreement”), a copy of which the
        Purchaser has received and read. This subscription is submitted to FairPY, Inc., the managing member of the
        Company and of FairReturns Series Jan23, a Series of FAIRRETURNS, LLC (the “Manager”) by the Purchaser in
        accordance with and subject to the terms and conditions described in this Subscription Agreement, relating to
        the exempt oﬀering by the Company (the “Offering”) up to a number of Series Jan23 Interests for a maximum
        aggregate
        interests and gross proceeds of $500,000 (“Maximum Offering Amount ”).
      </div>

      <div class="mt-4">
        <b>1.2.</b> The Purchaser understands that the Series Jan23
        Interests are being offered pursuant to an oﬀering circular, dated Nov 26, 2022 (the <b>“Offering Circular”</b>)
        By executing this Subscription Agreement, the Purchaser acknowledges that the Purchaser has received this
        Subscription Agreement, copies of the Offering Circular and, the exhibits thereto, and any other information
        required by the Purchaser to make an investment decision.
      </div>

      <div class="mt-4">
        <b>1.3.</b> The initial closing of the Oﬀering (the <span class="font-bold">“Closing”</span>) will occur on the
        earliest to occur of
        (i) the date subscriptions for the Maximum Oﬀering Amount have been accepted, (ii) a date determined by the
        Manager in its sole discretion or (iii) the date one week prior to two months after the oﬀering begins.
        Additionally, any closing following such initial closing will occur on the earliest to occur of (i) the date
        subscriptions for the maximum number of securities have been accepted, (ii) a date determined by the manager in
        its sole discretion and (iii) the date that is two months after the prior closing. If an initial Closing has not
        occurred, the Oﬀering shall be terminated upon the earliest to occur of (i) the date immediately following the
        date one week prior to two months after the oﬀering begins or (ii) any date on which the manager elects to
        terminate the oﬀering for a particular series in its sole discretion (the <span class="font-bold">“Termination Date”</span>).
      </div>

      <div class="mt-6">
        <div class="font-semibold text-xl mb-2">2. Payment.</div>
        Concurrent with the execution hereof, the Purchaser authorizes FairPY INC, a Delaware corporation, to request
        the Subscription Price from the Purchaser’s bank. FairPY INC shall maintain all such funds for the Purchaser’s
        benefit in a segregated non-interest-

        bearing account until the earliest to occur of: (i) the Closing, (ii) the rejection of such subscription or
        (iii) the Termination Date.
      </div>

      <div class="mt-6">
        <div class="font-semibold text-xl mb-3">3. Termination of Offering or Rejection of Subscription .</div>
        <p>
          <span class="font-semibold mt-4">3.1.</span> In the event that the Company does not affect an initial Closing
          on or
          before the Termination Date,
          FairPY INC will refund promptly the Subscription Price paid by the Purchaser, without deduction, oﬀset or
          interest accrued thereon and this Subscription Agreement shall thereafter be of no further force or effect.
        </p>
      </div>

      <div class="mt-4">
        <span class="font-semibold">3.2. </span>The Purchaser understands and agrees that the Manager, in its sole
        discretion, reserves the right to accept
        or reject this or any other subscription for Series Jan23 Interests, for any reason or no reason,
        notwithstanding prior receipt by the Purchaser of notice of acceptance of this subscription. If the Manager
        rejects a subscription (which decision is in its sole discretion), FairPY INC will return promptly the rejected
        Subscription Price to the Purchaser without deduction, oﬀset or interest accrued thereon. If this subscription
        is rejected this Subscription Agreement shall thereafter be of no further force or effect.
      </div>

      <div class="mt-6">
        <div class="font-semibold text-xl mb-3">4. Acceptance of Subscription</div>
        <p>
          At the Closing, if the Manager accepts this subscription in whole or in part, the Company shall execute and
          deliver to the Purchaser a counterpart executed copy of this Subscription Agreement and release the
          Subscription Price (or applicable portion thereof if such subscription is only accepted in part) to the
          Company for the beneﬁt of FairReturns Series Jan23, a Series of FAIRRETURNS, LLC . The Company shall have no
          obligation hereunder until the Company shall execute and deliver to the Purchaser an executed copy of this
          Subscription Agreement, and until the Purchaser shall have executed and delivered to the Manager this
          Subscription Agreement and a substitute Form W-9 (if applicable) and shall have deposited the Subscription
          Price in accordance with this Agreement. The Purchaser understands and agrees that this subscription is made
          subject to the condition that the Series Jan23 Interests to be issued and delivered on account of this
          subscription will be issued only in the name of and delivered only to the Purchaser. Eﬀective upon the
          Company’s execution of this Subscription Agreement, the Purchaser shall be a member of the Company, and the
          Purchaser agrees to adhere to and be bound by, the terms and conditions of the Operating Agreement as if the
          Purchaser were a party to it (and grants to the Manager the power of attorney described therein).
        </p>
      </div>

      <div class="mt-6">
        <div class="font-semibold text-xl mb-3">5. Representations and Warranties, Acknowledgments, and Agreements .
        </div>
        <p>
          The Purchaser hereby acknowledges, represents, warrants and agrees to and with the Company, FairReturns Series
          Jan23, a Series of FAIRRETURNS, LLC and the Manager as follows:
        </p>
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.1.</span> The Purchaser is aware that an investment in the Series Jan23 Interests
        involves a signiﬁcant degree of
        risk, and has received and carefully read the Oﬀering Circular and, in particular, the “Risk Factors” section
        therein. The Purchaser understands that the Company is subject to all the risks applicable to early-stage
        companies, whether or not set forth in such “Risk Factors.” The Purchaser acknowledges that no representations
        or warranties have been made to it or to its advisors or representatives with respect to the business or
        prospects of the Company, FairReturns Series Jan23 , or their financial condition.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.2.</span> The oﬀering and sale of the Series Jan23 Interests has not been
        registered
        under the Securities Act of 1933, as amended (the <b>“Securities Act”</b>), or any state securities laws. The
        Purchaser
        understands that the oﬀer and sale of the Series Jan23 Interests are intended to be exempt from
        registration under the Securities Act, by virtue of Tier 2 of Regulation A thereof, based, in part, upon the
        representations, warranties and agreements of the Purchaser contained in this Subscription Agreement, including,
        without limitation, the investor qualiﬁcation (<b>“Investor Qualiﬁcation and Attestation”</b>) immediately
        following
        the signature page of this Subscription Agreement. The Purchaser is purchasing the Series Jan23 Interests for
        its own account for investment purposes only and not with a view to or intent of resale or distribution thereof
        in violation of any applicable securities laws, in whole or in part.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.3.</span> The Purchaser, agrees he/she is a “Qualified Purchaser”. The Purchaser
        agrees to promptly provide the
        Manager and their respective agents with such other information as may be reasonably necessary for them to
        confirm the Qualified Purchaser status of the Purchaser.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.4.</span> The Purchaser acknowledges that the Purchaser’s responses to the
        investor
        qualiﬁcation questions are
        complete and accurate as of the date hereof.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.5.</span> The Purchaser acknowledges that neither the SEC nor any state securities
        commission or other regulatory
        authority has passed upon or endorsed the merits of the oﬀering of the Series Jan23 Interests.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.6.</span> In evaluating the suitability of an investment in the Series Jan23
        Interests, the Purchaser has not relied
        upon any representation or information (oral or written) other than as set forth in the Offering Circular, the
        Operating Agreement and this Subscription Agreement.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.7.</span> Except as previously disclosed in writing to the Company, the Purchaser
        has taken no action that would give
        rise to any claim by any person for brokerage commissions, ﬁnders’ fees or the like relating to this
        Subscription Agreement or the transactions contemplated hereby and the Purchaser shall be solely liable for any
        such fees and shall indemnify the Company with respect thereto pursuant to Section 6.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.8.</span> The Purchaser, together with its advisors, if any, has such knowledge
        and experience in ﬁnancial, tax, and
        business matters, and investments in securities, so as to enable it to utilize the Oﬀering Circular to evaluate
        the merits and risks of an investment in the Series Jan23 Interests and the Company and to make an informed
        investment decision with respect thereto.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.9.</span> The Purchaser is not relying on the Company, the Manager, the Broker or
        any of their respective employees
        or agents with respect to the legal, tax, economic and related considerations of an investment in the Series
        Jan23 Interests, other than with respect to the opinion of legality of legal counsel provided at Exhibit 12.1 to
        the Oﬀering Circular, and the Purchaser has relied on the advice of, or has consulted with, only its own
        advisors, if any, whom the Purchaser has deemed necessary or appropriate in connection with its purchase of the
        Series Jan23 Interests.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.10.</span> No consent, approval, authorization or order of any court, governmental
        agency or body or arbitrator
        having jurisdiction over the Purchaser or any of the Purchaser’s aﬃliates is required for the execution of this
        Subscription Agreement or the performance of the Purchaser’s obligations hereunder,
        including, without limitation, the purchase of the Series Jan23 Interests by the Purchaser.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.11.</span> The Purchaser has adequate means of providing for such Purchaser’s
        current ﬁnancial needs and foreseeable
        contingencies and has no need for liquidity of its investment in the Series Jan23 Interests for an indefinite
        period of time.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.12.</span> The Purchaser (a) if a natural person, represents that the Purchaser
        has reached the age of 21 (or 18 in
        states with such applicable age limit) and has full power and authority to execute and deliver this Subscription
        Agreement and all other related agreements or certiﬁcates and to carry out the provisions hereof and thereof; or
        (b) if a corporation, partnership, or limited liability company or other entity, represents that such entity was
        not formed for the speciﬁc purpose of acquiring the Series Jan23 Interests, such entity is duly organized,
        validly existing and in good standing under the laws of the state of its organization, the consummation of the
        transactions contemplated hereby is authorized by, and will not result in a violation of state law or its
        charter or other organizational documents, such entity has full power and authority to execute and deliver this
        Subscription Agreement and all other related agreements or certificates and to carry out the provisions hereof
        and thereof and to purchase and hold the Series Jan23 Interests, the execution and delivery of this Subscription
        Agreement has been duly authorized by all necessary action, this Subscription Agreement has been duly executed
        and delivered on behalf of such entity and is a legal, valid and binding obligation of such entity; or (c) if
        executing this Subscription Agreement in a representative or ﬁduciary capacity, represents that it has full
        power and authority to execute and deliver this Subscription Agreement in such capacity and on behalf of the
        subscribing individual, ward, partnership, trust, estate, corporation, or limited liability company or
        partnership, or other entity for whom the Purchaser is executing this Subscription Agreement, and such
        individual, partnership, ward, trust, estate, corporation, or limited liability company or partnership, or other
        entity has full right and power to perform pursuant to this Subscription Agreement and make an investment in the
        Company, and represents that this Subscription Agreement constitutes a legal, valid and binding obligation of
        such entity. The execution and delivery of this Subscription Agreement will not violate or be in conﬂict with
        any order, judgment, injunction, agreement or controlling document to which the Purchaser is a party or by which
        it is bound.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.13.</span> Any power of attorney of the Purchaser granted in favor of the Manager
        contained in the Operating
        Agreement has been executed by the Purchaser in compliance with the laws of the state, province or jurisdiction
        in which such agreements were executed.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.14.</span> If an entity, the Purchaser has its principal place of business or, if
        a natural person, the Purchaser has
        its primary residence, in the jurisdiction (state and/or country) set forth in the “Investor Qualiﬁcation and
        Attestation”. The Purchaser ﬁrst learned of the oﬀer and sale of the Series Jan23 Interests in the state listed
        in the “Investor Qualiﬁcation and Attestation”, and the Purchaser intends that the securities laws of that state
        shall govern the purchase of the Purchaser’s Series Jan23 Interests.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.15.</span> The Purchaser is either (a) a natural person resident in the United
        States, (b) a partnership, corporation
        or limited liability company organized under the laws of the United States, (c) an estate of which any executor
        or administrator is a U.S. person, (d) a trust of which any trustee is a U.S. person, (e) an agency or branch of
        a foreign entity located in the United States, (f) a non-discretionary account or similar account (other than an
        estate or trust) held by a dealer or other ﬁduciary for the beneﬁt or account of a U.S. person, or (g) a
        partnership or corporation organized or incorporated under the laws of a foreign jurisdiction that was formed by
        a U.S. person principally for the purpose of investing in securities not registered under the Securities Act,
        unless it is organized or incorporated, and owned, by accredited investors who are not natural persons, estates
        or trusts. The Purchaser is not (i) a discretionary account or similar account (other than an estate or trust)
        held for the beneﬁt or account of a non-U.S. person by a dealer or other professional ﬁduciary organized,
        incorporated, or (if an individual) resident in the United States, (ii) an estate of which any professional
        ﬁduciary acting as executor or administrator is a U.S. person if an executor or administrator of the estate who
        is not a U.S. person has sole or shared investment discretion with respect to the assets of the estate and the
        estate is governed by foreign law, (iii) a trust of which any professional ﬁduciary acting as trustee is a U.S.
        person, if a trustee who is not a U.S. person has sole or shared investment discretion with respect to the trust
        assets and no beneﬁciary of the trust (and no settlor if the trust is revocable) is a U.S. person, (iv) an
        employee beneﬁt plan established and administered in accordance with the law of a country other than the United
        States and customary practices and documentation of such country, or (v) an agency or branch of a U.S. person
        located outside the United States that operates for valid business reasons engaged in the business of insurance
        or banking that is subject to substantive insurance or banking regulation, respectively, in the jurisdiction
        where located.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.16.</span> Any information which the Purchaser has heretofore furnished or is
        furnishing herewith to the Company is
        true, complete and accurate and may be relied upon by the Manager, the Company and the Broker, in particular, in
        determining the availability of an exemption from registration under federal and state securities laws in
        connection with the Oﬀering. The Purchaser further represents and warrants that it will notify and supply
        corrective information to the Company immediately upon the occurrence of any change therein occurring prior to
        the Company’s issuance of the Series Jan23 Interests.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.17.</span> The Purchaser is not, nor is it acting on behalf of, a “beneﬁt plan
        investor” within the meaning of 29
        C.F.R. § 2510.3-101(f)(2), as modiﬁed by Section 3(42) of the Employee Retirement Income Security Act of 1974
        (such regulation, the “Plan Asset Regulation”, and a beneﬁt plan investor described in the Plan Asset
        Regulation, a “ Beneﬁt Plan Investor ”). For the avoidance of doubt, the term Beneﬁt Plan Investor includes all
        employee beneﬁt plans subject to Part 4, Subtitle B, Title I of ERISA, any plan to which Section 4975 of the
        Internal Revenue Code applies and any entity, including any insurance company general account, whose underlying
        assets constitute “plan assets”, as deﬁned under the Plan Asset Regulation, by reason of a Benefit Plan
        Investor’s investment in such entity
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.18.</span> The Purchaser is satisﬁed that the Purchaser has received adequate
        information with respect to all matters
        which it or its advisors, if any, consider material to its decision to make this investment.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.19.</span> Within ﬁve (5) days after receipt of a written request from the
        Manager, the Purchaser will provide such
        information and deliver such documents as may reasonably be necessary to comply with any and all laws and
        ordinances to which the Company is subject.
      </div>

      <div class="mt-4">
        <span class="font-semibold">5.20.</span> THE FAIRRETURNS SERIES JAN23, A SERIES OF FAIRRETURNS, LLC INTERESTS
        OFFERED HEREBY HAVE NOT BEEN
        REGISTERED UNDER THE SECURITIES ACT, OR ANY STATE SECURITIES LAWS AND ARE BEING OFFERED AND SOLD IN RELIANCE ON
        EXEMPTIONS FROM THE REGISTRATION REQUIREMENT OF SAID ACT AND SUCH LAWS. THE FAIRRETURNS SERIES JAN23, A SERIES
        OF FAIRRETURNS, LLC INTERESTS ARE SUBJECT TO RESTRICTIONS ON TRANSFERABILITY AND RESALE AND MAY NOT BE
        TRANSFERRED OR RESOLD EXCEPT AS PERMITTED BY THE OPERATING AGREEMENT.

        <p>
          THE SERIES JAN23 INTERESTS HAVE NOT BEEN APPROVED OR DISAPPROVED BY THE SEC, ANY STATE SECURITIES COMMISSION
          OR ANY OTHER REGULATORY AUTHORITY, NOR HAVE ANY OF THE FOREGOING AUTHORITIES PASSED UPON OR ENDORSED THE
          MERITS OF THIS OFFERING OR THE ACCURACY OR ADEQUACY OF THE MEMORANDUM OR THIS SUBSCRIPTION AGREEMENT. ANY
          REPRESENTATION TO THE CONTRARY IS UNLAWFUL.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">5.21.</span> The Purchaser should check the Oﬃce of Foreign Assets Control
          (“OFAC”) website at
          http://www.treas.gov/ofac before making the following representations. The Purchaser represents that the
          amounts invested by it in the Company in the Oﬀering were not and are not directly or indirectly derived from
          activities that contravene federal, state or international laws and regulations, including anti-money
          laundering laws and regulations. Federal regulations and Executive Orders administered by OFAC prohibit, among
          other things, the engagement in transactions with, and the provision of services to, certain foreign
          countries, territories, entities and individuals. The lists of OFAC prohibited countries, territories, persons
          and entities can be found on the OFAC website at http://www.treas.gov/ofac. In addition, the programs
          administered by OFAC (the “OFAC Programs ”) prohibit dealing with individuals, including specially designated
          nationals, specially designated narcotics traﬃckers and other parties subject to OFAC sanctions and embargo
          programs, or entities in certain countries regardless of whether such individuals or entities appear on the
          OFAC lists. Furthermore, to the best of the Purchaser’s knowledge, none of: (a) the Purchaser; (b) any person
          controlling or controlled by the Purchaser; (c) if the Purchaser is a privately-held entity, any person having
          a beneﬁcial interest in the Purchaser; or (d) any person for whom the Purchaser is acting as agent or nominee
          in connection with this investment is a country, territory, individual or entity named on an OFAC list, or a
          person or entity prohibited under the OFAC Programs. Please be advised that the Company may not accept any
          amounts from a prospective investor if such prospective investor cannot make the representation set forth in
          this paragraph. The Purchaser agrees to promptly notify the Company should the Purchaser become aware of any
          change in the information set forth in these representations. The Purchaser understands and acknowledges that,
          by law, the Company may be obligated to “freeze the account” of the Purchaser, either by prohibiting
          additional subscriptions from the Purchaser, declining any redemption requests and/or segregating the assets
          in the account in compliance with governmental regulations, and the Company may also be required to report
          such action and to disclose the Purchaser’s identity to OFAC. The Purchaser further acknowledges that the
          Company may, by written notice to the Purchaser, suspend the redemption rights, if any, of the Purchaser if
          the Company reasonably deems it necessary to do so to comply with anti-money laundering regulations applicable
          to the Company or any of the Company’s other service providers. These individuals include specially designated
          nationals, specially designated narcotics traffickers and other parties subject to OFAC sanctions and embargo
          programs.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">5.22.</span> To the best of the Purchaser’s knowledge, none of: (a) the Purchaser;
          (b) any person controlling or
          controlled by the Purchaser; (c) if the Purchaser is a privately-held entity, any person having a beneﬁcial
          interest in the Purchaser; or (d) any person for whom the Purchaser is acting as agent or nominee in
          connection with this investment is a senior foreign political ﬁgure,or an immediate family member or close
          associate of a senior foreign political ﬁgure. A “senior foreign political ﬁgure” is a senior oﬃcial in the
          executive, legislative, administrative, military or judicial branches of a foreign government (whether elected
          or not), a senior oﬃcial of a major foreign political party, or a senior executive of a foreign
          government-owned corporation. In addition, a “senior foreign political ﬁgure” includes any corporation,
          business or other entity that has been formed by, or for the beneﬁt of, a senior foreign political ﬁgure.
          “Immediate family” of a senior foreign political ﬁgure typically includes the ﬁgure’s parents, siblings,
          spouse, children and in-laws. A ”close associate” of a senior foreign political ﬁgure is a person who is
          widely and publicly known to maintain an unusually close relationship with the senior foreign political ﬁgure,
          and includes a person who is in a position to conduct substantial domestic and international financial
          transactions on behalf of the senior foreign political figure.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">5.23.</span> If the Purchaser is aﬃliated with a non-U.S. banking institution (a
          “Foreign Bank ”), or if the
          Purchaser receives deposits from, makes payments on behalf of, or handles other ﬁnancial transactions related
          to a Foreign Bank, the Purchaser represents and warrants to the Company that: (a) the Foreign Bank has a ﬁxed
          address, other than solely an electronic address, in a country in which the Foreign Bank is authorized to
          conduct banking activities; (b) the Foreign Bank maintains operating records related to its banking
          activities; (c) the Foreign Bank is subject to inspection by the banking authority that licensed the Foreign
          Bank to conduct banking activities; and (d) the Foreign Bank does not provide banking services to any other
          Foreign Bank that does not have a physical presence in any country and that is not a regulated affiliate.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">5.24.</span> Each of the representations and warranties of the parties hereto set
          forth in this Section 5 and made as
          of the date hereof shall be true and accurate as of the Closing applicable to the subscription made hereby as
          if made on and as of the date of such Closing.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">6. Indemnification.</div>
        <p>
          The Purchaser agrees to indemnify and hold harmless the Company, FairReturns Series Jan23, a Series of
          FAIRRETURNS, LLC , the Manager and their respective oﬃcers, directors, employees, agents, members, partners,
          control persons and aﬃliates (each of which shall be deemed third party beneﬁciaries hereof) from and against
          all losses, liabilities, claims, damages, costs, fees and expenses whatsoever (including, but not limited to,
          any and all expenses incurred in investigating, preparing or defending against any litigation commenced or
          threatened) based upon or arising out of any actual or alleged false acknowledgment, representation or
          warranty, or misrepresentation or omission to state a material fact, or breach by the Purchaser of any
          covenant or agreement made by the Purchaser herein or in any other document delivered in connection with this
          Subscription Agreement. Notwithstanding the foregoing, no representation, warranty, covenant or acknowledgment
          made herein by the Purchaser shall be deemed to constitute a waiver of any rights granted to it under the
          Securities Act or state securities laws.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">7. Irrevocability; Binding Eﬀect.</div>

        <p>The Purchaser hereby acknowledges and agrees that the subscription hereunder is irrevocable by the Purchaser,
          except as required by applicable law, and that this Subscription Agreement shall survive the death or
          disability of the Purchaser and shall be binding upon and inure to the beneﬁt of the parties and their heirs,
          executors, administrators, successors, legal representatives, and permitted assigns. If the Purchaser is more
          than one person, the obligations of the Purchaser hereunder shall be joint and several and the agreements,
          representations, warranties, and acknowledgments herein shall be deemed to be made by and be binding upon each
          such person and such person’s heirs, executors, administrators, successors, legal representatives, and
          permitted assigns.</p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">8. Modiﬁcation.</div>
        <p>
          This Subscription Agreement shall not be modiﬁed or waived except by an instrument in writing signed by the
          party against whom any such modification or waiver is sought.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">
          9. Assignability.
        </div>
        <p>
          This Subscription Agreement and the rights, interests and obligations hereunder are not transferable or
          assignable by the Purchaser and the transfer or assignment of the Series Jan23 Interests shall be made only in
          accordance with all applicable laws and the Operating Agreement. Any assignment contrary to the terms hereof
          shall be null and void and of no force or effect.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">
          10. Applicable Law and Jurisdiction
        </div>
        <p>
          This Subscription Agreement and the rights and obligations of the Purchaser arising out of or in connection
          with this Subscription Agreement, the Operating Agreement and the Oﬀering Circular shall be construed in
          accordance with and governed by the internal laws of the State of Texas without regard to principles of
          conﬂict of laws. The Purchaser (a) irrevocably submits to the non-exclusive jurisdiction and venue of the
          state and federal courts sitting in Dallas, TX, in any action arising out of this Subscription Agreement, the
          Operating Agreement and the Offering Circular and (b) consents to the service of process by mail.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">
          11. Use of Pronouns.
        </div>
        <p>
          All pronouns and any variations thereof used herein shall be deemed to refer to the masculine, feminine,
          neuter, singular or plural as the identity of the person or persons referred to may require.
        </p>
      </div>

      <div class="mt-4">
        <div class="font-semibold text-xl mb-3">
          12. Miscellaneous.
        </div>
        <p>
          <span class="font-semibold">12.1.</span> Sections 15.1 (Addresses and Notices) and 15.2 (Further Action) of
          the Operating Agreement are deemed
          incorporated into this Subscription Agreement.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">12.2.</span> This Subscription Agreement, together with the Operating Agreement,
          constitutes the entire agreement
          between the Purchaser and the Company with respect to the subject matter hereof and supersedes all prior oral
          or written agreements and understandings, if any, relating to the subject matter hereof. The terms and
          provisions of this Subscription Agreement may be waived, or consent for the departure therefrom granted, only
          by a written document executed by the party entitled to the beneﬁts of such terms or provisions.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">12.3.</span> The covenants, agreements, representations and warranties of the
          Company and the Purchaser made, and the
          indemniﬁcation rights provided for, in this Subscription Agreement shall survive
          the execution and delivery hereof and delivery of the Series
          Jan23
          Interests, regardless of any
          investigation made by or on behalf of any party, and shall survive delivery of any payment for the
          Subscription Price.
        </p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">12.4.</span> Except to the extent otherwise described in the Oﬀering Circular,
          each of the parties hereto shall pay
          its own fees and expenses (including the fees of any attorneys, accountants or others engaged by such party)
          in connection with this Subscription Agreement and the transactions contemplated hereby whether or not the
          transactions contemplated hereby are consummated.
        </p>
      </div>

      <div class="mt-4">
        <p><span class="font-semibold">12.5.</span> This Subscription Agreement may be executed in one or more
          counterparts each of which shall be deemed
          an original (including signatures sent by facsimile transmission or by email transmission of a PDF scanned
          document or other electronic signature), but all of which shall together constitute one and the same
          instrument.</p>
      </div>

      <div class="mt-4">
        <p><span class="font-semibold">12.6.</span> Each provision of this Subscription Agreement shall be considered
          separable and, if for any reason any
          provision or provisions hereof are determined to be invalid or contrary to applicable law, such invalidity or
          illegality shall not impair the operation of or aﬀect the remaining portions of this Subscription
          Agreement.</p>
      </div>

      <div class="mt-4">
        <p>
          <span class="font-semibold">12.7.</span> Paragraph titles are for descriptive purposes only and shall not
          control or alter the meaning of this
          Subscription Agreement as set forth in the text.
        </p>
      </div>

      <div class="mt-4">
        <p><span class="font-semibold">12.8.</span> Words and expressions which are used but not deﬁned in this
          Subscription Agreement shall have the
          meanings given to them in the Operating Agreement.</p>
      </div>

      <div class="mt-4 font-semibold">
        SIGNATURE PAGE TO THE SUBSCRIPTION AGREEMENT
        FAIRRETURNS, LLC

      </div>

      <div class="mt-4 font-semibold text-xl text-center my-2">
        FAIRRETURNS SERIES JAN23, A SERIES OF FAIRRETURNS, LLC
      </div>

      <div class="mt-4 font-semibold">
        The Purchaser hereby elects to subscribe under the Subscription Agreement
        for the number and price of the FairReturns Series Jan23, a Series of Arrived
        STR, LLC Interests stated on the front page of this Subscription Agreement and executes the Subscription
        Agreement.

      </div>

      <div class="my-4">
        <div class="flex flex-col lg:flex-row">
          <div class="w-full lg:w-6/12 mb-4 lg:mb-0">
            <span>Date :</span>
            <input type="text" v-model="this.currentDate"
                   class="w-9/12 lg:w-4/12 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
          <div class="w-full lg:w-4/12">
            <div class="flex flex-col">
              <input type="text" v-model="this.getEnquiry.name" disabled
                     class=" h-6 border-b-2 border-black-light focus:outline-none">
              <label>Print Name of Purchaser</label>
            </div>

            <div class="flex flex-col mt-2">
              <div class="flex flex-row">
                <span>By: </span>
                <input type="text" class="w-full h-6 border-b-2 border-black-light focus:outline-none">
              </div>
              <label>Signature of Authorized Signatory</label>
            </div>

            <div class="flex flex-col mt-2">
              <input type="text" class=" h-6 border-b-2 border-black-light focus:outline-none">
              <label>Name of Authorized Signatory (if an entity)</label>
            </div>

            <div class="flex flex-col mt-2">
              <input type="text" class=" h-6 border-b-2 border-black-light focus:outline-none">
              <label>Title of Authorized Signatory (if an entity)</label>
            </div>


          </div>

        </div>
      </div>

      <div class="mb-6">
        <div class="font-semibold">Accepted:</div>
        <div class="flex">
          <label>Date: </label>
          <input type="text" v-model="this.currentDate" disabled
                 class=" h-6 ml-8 border-b-2 border-black-light focus:outline-none">
        </div>
      </div>

      <div class="mb-6">
        <div class="font-semibold text-xl">FairReturns Series Jan23, a Series of FAIRRETURNS, LLC</div>
        <div class="font-semibold">By: FairPY, Inc., as managing member</div>
      </div>

      <div class="mb-12 leading-8">
        <div class="flex">
          <label class="w-12">By: </label>
          <input type="text" class=" h-6 ml-8 border-b-2 border-black-light focus:outline-none">
        </div>

        <div class="flex">
          <label class="w-12">Name: </label>
          <input type="text" v-model="this.loginData.name"
                 class="h-6 ml-8 border-b-2 border-black-light focus:outline-none">
        </div>

        <div class="flex">
          <label class="w-12">Title: </label>
          <input type="text" class=" h-6 ml-8 border-b-2 border-black-light focus:outline-none">
        </div>
      </div>

      <div class="flex justify-center font-semibold">
        INVESTOR QUALIFICATION AND ATTESTATION
      </div>

      <div class="flex font-semibold mt-6">
        <div class="lg:ml-32">INVESTOR INFORMATION</div>
      </div>

      <div class="flex flex-col">
        <!--        <div class="w-11/12">-->
        <div class="flex lg:justify-center mt-4">
          <div class="w-4/12">Name</div>
          <div class="w-8/12 lg:w-5/12">
            <input type="text" v-model="this.getEnquiry.name" disabled
                   class=" lg:w-96 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>
        <div class="flex lg:justify-center mt-4">
          <div class="w-4/12">Date of Birth</div>
          <div class="w-8/12 lg:w-5/12">
            <input type="text"
                   class="lg:w-96 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>

        <div class="flex lg:justify-center mt-4">
          <div class="w-4/12">Address</div>
          <div class="w-8/12 lg:w-5/12">
            <input type="text"
                   class="lg:w-96 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>

        <div class="flex lg:justify-center mt-4">
          <div class="w-4/12">Phone Number</div>
          <div class="w-8/12 lg:w-5/12">
            <input type="text" v-model="this.getEnquiry.mobile_number" disabled
                   class="lg:w-96 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>

        <div class="flex lg:justify-center mt-4 mb-12">
          <div class="w-4/12">Email</div>
          <div class="w-8/12 lg:w-5/12">
            <input type="text" v-model="this.getEnquiry.email" disabled
                   class="lg:w-96 h-6 ml-8 border-b-2 border-black-light focus:outline-none">
          </div>
        </div>
        <!--        </div>-->

      </div>


    </div>
  </div>
  <PageTop/>
  <Footer/>
</template>

<script>
import TopBar from "@/views/investors/components/TopBar";
import Footer from "@/components/Footer";
import PageTop from "@/views/investors/components/PageTop";

export default {
  name: "PurchaseAgreement",
  components: {
    TopBar,
    PageTop,
    Footer
  },
  data() {
    return {
      loginData: this.$store.state.investorAuth.investors,
      getEnquiry: '',
      numberOfShare: '',
      amount: '',
      currentDate: ''
    }
  },
  mounted() {

    const objToday = new Date(),
        dayOfWeek = objToday.getDate(),
        months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
        curMonth = months[objToday.getMonth()],
        curYear = objToday.getFullYear();
    this.currentDate = curMonth + ' ' + dayOfWeek + ', ' + curYear;
    this.getInvestorEnquiry();
  },

  methods: {
    getInvestorEnquiry() {
      console.log("here");
      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("getInvestorEnquiry", this.loginData['id']).then(
          (data) => {
            let formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            this.getEnquiry = data.data.enquiry;
            this.numberOfShare = this.getEnquiry.amount / 1000;
            this.amount = formatter.format((this.getEnquiry.amount));
            this.$store.dispatch("setIsLoading", false);
          },
          (error) => {
            this.$store.dispatch("setIsLoading", false);
            if (error.response) {
              console.log(error.response);
              if (error.response.data.message) {
                this.errors["general"] = error.response.data.message;
              }
              if (!this.errors.general) {
                this.errors["general"] = error.response.statusText;
              }
            } else {
              console.log(error.request);
              this.errors["general"] = "Sorry! Something went wrong! ";
            }
          }
      );
    },
  }
}
</script>

<style scoped>

</style>