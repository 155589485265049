<template>
  <TopBar/>
  <NavBar/>
  <div class="w-100 lg:pt-4 relative overflow-hidden">
    <div
        class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-center
        items-center
      "
    >
      <img
          class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
          src="/images/map-full.png"
      />
      <img
          class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
          src="/images/map-right.png"
      />
    </div>
    <div class="container mx-auto lg:px-3 px-5 py-6 z-10 relative">
      <div class="flex lg:flex-row flex-col w-full justify-center">
        <div class="md:w-full lg:w-10/12 lg:pr-12 lg:mt-0 lg:mt-4 ">
          <div class="lg:px-6 lg:py-2">

            <div class="">
              <wishlist v-for="deal in this.deals" :key="deal" :deal="deal"></wishlist>

            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>

</template>

<script>
import TopBar from "@/components/TopBar";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Wishlist from "@/components/wishlist";

export default {
  name: "WishListCars",
  components: {
    Wishlist,
    TopBar,
    NavBar,
    Footer,
  },
  data: () => ({
    deals: '',
    items: [],
  }),
  mounted() {
    this.loadWishlist();
  },
  methods: {
    loadWishlist() {
      let user_id = this.$store.state.auth.user.id;
      this.$store.dispatch("loadWishlist", user_id).then(
          (res) => {
            this.deals = res.data.data
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
  },
}
</script>

<style scoped>

</style>