<template>
<!--  <section id="top" class="relative z-0 mt-52 md:mt-12 lg:mt-12 ">-->
  <section id="top" class="z-0 mt-0 md:mt-12 lg:mt-12 ">
    <div class="ml-0 lg:ml-24 xl:ml-24">
    <h1
        class="pt-5 text-2xl tracking-wider text-center uppercase md:py-2 lg:py-2 text-blue tiempos sm:text-3xl md:text-4xl">
      top car deals</h1>


<!--    <div class="flex  w-full  flex-row justify-end pr-5 pl-5">-->

<!--      <div class=" lg:w-1/5 rounded-full bg-gray-100 w-full p-1 flex  mb-2 mt-4 relative shadow">-->
<!--        <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"-->
<!--             :class="{ 'left-1/2': this.paymentType, 'left-0': !this.paymentType}">-->
<!--          <div class="bg-blue rounded-full shadow-sm w-full h-full"></div>-->
<!--        </div>-->
<!--        <div @click="toggleFinance"-->
<!--             class="w-1/2 text-center py-2 text-sm relative cursor-pointer"-->
<!--             :class="{ 'text-white': !this.paymentType }">-->
<!--          Finance-->
<!--        </div>-->
<!--        <div @click="toggleCash"-->
<!--             class="py-2 w-1/2 text-sm text-center relative cursor-pointer"-->
<!--             :class="{ 'text-white': this.paymentType }">-->
<!--          Cash-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <div class="relative p-5 pr-5  ml-0 md:pr-5 lg:pr-0 md:ml-12 lg:ml-12 ">-->
    <div class="relative ">
      <div class=" top-car-deals">

        <carousel v-if="deals.length > 0" class="my-carosel" :itemsToShow="1.15" :breakpoints="breakpoints" :nav="true">
          <slide v-for="slide in deals" :key="slide">
            <div class="shadow" @click.prevent="gotoDetails(slide)" style="cursor: pointer">
              <div class="car-image w-full h-44 2xl:h-80">
                <img class="object-contain w-full h-44 2xl:h-80" :src="slide.image" alt="">
              </div>

              <div class="p-4">
                <h6 class="py-1 tracking-wider text-center uppercase text-blue tiempos text-sm 2xl:text-2xl"> {{ slide.title }}</h6>
                <div class="flex justify-between mb-5 mt-3">
                  <div>
                    <h6 class="pb-1 text-xs lg:text-sm 2xl:text-2xl text-gray-500 uppercase tiempos">{{ slide.miles }}</h6>
                    <p class="text-xs 2xl:text-xl tracking-wider text-gray-400 uppercase calibre">MILES</p>
                  </div>
                  <div>
                    <h6 class="pb-1 text-sm 2xl:text-2xl text-center text-gray-500 uppercase tiempos">{{slide.savings}}*</h6>
                    <p class="text-xs 2xl:text-xl tracking-wider text-center text-gray-400 uppercase calibre">
                      SAVINGS
                    </p>
                  </div>
                  <div>
                    <h6 class="pb-1 text-sm 2xl:text-2xl text-right text-gray-500 uppercase tiempos">
                      {{ slide.type }}</h6>
                    <p class="text-xs 2xl:text-xl tracking-wider text-right text-gray-400 uppercase calibre">
                      TITLE</p>
                  </div>
                </div>
                <div class="md:flex lg:flex flex grid grid-cols-3 gap-3 p-2 2xl:p-4 border border-black justify-between" style="border: 1px solid #374151;">
                  <div class="text-left text-center">
                    <h6 class="pb-1 text-sm 2xl:text-2xl uppercase text-sky-500 tiempos">
                      {{ slide.bid }}</h6>
                    <p class="text-xs 2xl:text-xl fa-w-4  tracking-wider text-black calibre">
                      List Price </p>
                  </div>
                  <div class="text-left text-center">
                    <h6 class="pb-1 text-sm 2xl:text-2xl uppercase text-sky-500 tiempos">
                      {{ slide.monthly_payments }}*</h6>
                    <p class="text-xs 2xl:text-xl fa-w-4 tracking-wider text-black calibre">
                      Per Month</p>
                  </div>
                  <div class="text-left text-center">
                    <h6 class="pb-1 text-sm 2xl:text-2xl uppercase text-sky-500 tiempos">
                      {{ slide.down_payment }}</h6>
                    <p class="text-xs 2xl:text-xl fa-w-4  tracking-wider text-black calibre">
                     Cash Down</p>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation/>
          </template>
        </carousel>


      </div>
    </div>
    </div>
  </section>
</template>

<script>
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import axios from "axios";

export default {
  name: 'Top',
  components: {
    Carousel,
    Slide,
    Navigation,
  },

  data: () => ({
    deals: [],
    paymentType: true,
    settings: {
      itemsToShow: 1,
      itemsToScroll: 4,
      snapAlign: 'start',
    },

    breakpoints: {
      700: {
        itemsToShow: 3,
        itemsToScroll: 3,
        snapAlign: 'start',
      },
      1024: {
        itemsToShow: 4,
        itemsToScroll: 4,
        snapAlign: 'start',
      },
    },
  }),

  mounted() {
    this.loadCars();
  },

  methods: {
    toggleFinance() {
      for (let i = 0; i < this.deals.length; i++) {
        this.deals[i].paymentType = false;
      }
      this.paymentType = !this.paymentType;
    },
    toggleCash() {
      for (let i = 0; i < this.deals.length; i++) {
        this.deals[i].paymentType = true;
      }
      this.paymentType = !this.paymentType;
    },

    gotoDetails(slide) {
      this.$store.dispatch("setSelectedDealId", slide.id);
      this.$router.push({ name: "Details", params: { vin: slide.id } });
    },
    loadCars() {
      axios
          .get(
              `https://m2v98plzh2.execute-api.us-west-2.amazonaws.com/Dev/popular-cars`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                crossDomain: true,
              }
          )
          .then((res) => {
            let deals = res.data;
            deals = deals.map(function (deal) {
              let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              return {
                id: deal.VIN,
                title: deal.year_make_model,
                miles: deal.Odometer,
                bid: formatter.format(deal.purchase_price),
                savings: formatter.format(deal.savings),
                finance_savings: Math.round(deal.finance_savings),
                bookValue: formatter.format(deal.retail_value),
                image: deal.thumbnail,
                details: deal.car_details,
                date: deal.bid_final_date,
                currentBid: Math.round(deal.kbb),
                type: deal.title,
                pdf: deal.pdf,
                series: deal.Series,
                monthly_payments: formatter.format(deal.monthly_payments),
                shipping_fee: deal.shipping_fee,
                down_payment: formatter.format(deal.down_payment),
                sales_tax: deal.sales_tax,
                paymentType: true
              };
            });

            this.deals = deals;
          })
          .catch((error) => {
            console.log(error);
            // Manage errors if found any
          });
    },
  }
}
</script>


<style>

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
  line-height: 10px;
}

.font-size-9 {
  font-size: 9px;
  letter-spacing: 2px;
}

.font-size-10 {
  font-size: 10px;
  letter-spacing: 1px;
}

.top-car-deals::after {
  content: '';
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 20%;
  height: 100%;
  background-image: url('../assets/Gradient1.png');
  background-repeat: no-repeat;
  background-position: right;
  z-index: 0;
}

.carousel__slide {

  padding: 15px 0 15px 15px;

}

.carousel__prev--in-active {
  display: none !important;
}
.carousel__prev {
  background: #008de9 !important;
}

.carousel__next {
  background: #008de9 !important;
}

.tiempos {
  font-family: 'Tiempos Headline', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif;
}

.text-blue {
  color: #0d213a;
}

.shadow {
  box-shadow: 0px 0px 3px 1px #e9e9e9;
}

.text-sky-500 {
  color: #008de9;
}

.border {
  border: 1px solid rgb(97, 97, 97);
}

@media (max-width: 640px) {
  .top-car-deals::after {
    width: auto;
  }
}
</style>