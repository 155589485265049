<template lang="">
    <a href="/">
      <img :style= "{'height': height}" class="flex-shrink-0" :src="isLight?'/images/white-logo.png':'/images/logo.png'"/>
    </a>
</template>
<script>
export default {
  props: {
    isLight: Boolean,
    height: {
      default: "2.5rem",
      type: String,
    },
  },
};
</script>
<style scoped>
img {
  height: 3rem;
}
</style>