<template>
  <div class="w-100 left-0 right-0 top-0 bg-white absolute pt-4">
    <div
      class="
        absolute
        top-full
        bg-gradient-to-b
        from-white
        to-transparent
        h-20
        left-0
        right-0
      "
    ></div>
    <div
      class="
        container
        mx-auto
        flex-col
        p-3
        lg:p-0 lg:flex-row
        justify-end
        text-xs
        font-display
        flex
      "
    >
      <div class="mr-auto">
        <Logo />
      </div>
      <a
        id="toggle-button"
        href="#"
        @click="toggle = !toggle"
        class="text-right pr-5 mr-3 relative lg:hidden"
        >Support & Contact <i class="arrow-down absolute top-1 right-0"></i
      ></a>
      <ul class="flex lg:flex lg:flex-row flex-col">
        <li class="">
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/about-us">
            About
          </a>
        </li>
        <li>
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/faqs">
            FAQs
          </a>
        </li>
        <li>
          <a class="p-3 cursor-pointer block hover:bg-blue" href="/contact-us">
            Contact Us
          </a>
        </li>
        <li v-if="loggedIn" class="p-3 cursor-pointer hover:bg-blue">
          {{ `Welcome ${currentUser.first_name} ${currentUser.last_name}` }}
        </li>
        <li v-if="loggedIn">
          <a
            class="p-3 cursor-pointer block hover:bg-blue"
            href="#"
            @click.prevent="logout"
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
  <LandingBanner />
  <CarDeals />
  <HowItWorks />
  <LifeStyle />
  <HaveQuestion />
  <GetUpdates />
  <Footer />
</template>

<script>
import CarDeals from "../components/CarDeals.vue";
import Footer from "../components/Footer.vue";
import GetUpdates from "../components/GetUpdates.vue";
import HaveQuestion from "../components/HaveQuestion.vue";
import HowItWorks from "../components/HowItWorks.vue";
import LandingBanner from "../components/LandingBanner.vue";
import LifeStyle from "../components/LifeStyle.vue";
import Logo from "../components/Logo.vue";

export default {
  name: "Home",
  components: {
    Logo,
    LandingBanner,
    CarDeals,
    HowItWorks,
    LifeStyle,
    HaveQuestion,
    GetUpdates,
    Footer,
  },
};
</script>

<style></style>